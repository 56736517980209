import React from 'react';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FaHome, FaPizzaSlice, FaHamburger, FaMapMarkedAlt, FaEuroSign, FaUserTie, FaHospitalUser } from "react-icons/fa";
import Skeleton from '@mui/material/Skeleton';

//import './css/sideBar.scss';
import './css/elements.css';
import './css/dashboard.css';

import Client from './components/Client';
//import Product from './components/Product';
import Combo from './components/Combo';
import CombosFilters from './components/CombosFilters';

import Visibility from './Visibility';


let theme = createTheme();

class Combos extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      combos: null,
      productClasses: null,
      productCategories: null,
      productSubCategories: null,
      allergens: null,
      addNewCombo: false,
    };

    const { loading = false } = props;

    this.applyFilters = this.applyFilters.bind(this);
    this.applyCategory = this.applyCategory.bind(this);
    this.setAllergens = this.setAllergens.bind(this);
    this.setCategories = this.setCategories.bind(this);
    this.setSubCategories = this.setSubCategories.bind(this);
    this.toggleAdd = this.toggleAdd.bind(this);
    this.getData = this.getData.bind(this);
    this.comboAdded = this.comboAdded.bind(this);
    this.restricted = new Visibility ("products_coombos");
  }

  getData() {
    let queryData = {
      "limit": 20,
      "orderBy_modifiedOn": true
    }
    let data = this.state.client.getCombos ( queryData );
    this.setState ( { combos: data.productCombos });

    let productsClasses = this.state.client.getProductsClasses ( );
    this.setClasses ( productsClasses.productClasses );

    let productsCategories = this.state.client.getProductsCategories ( );
    //this.setState ( { productCategories: productsCategories.productCategories });
    this.setCategories ( productsCategories.productCategories );

    let productsSubCategories = this.state.client.getProductsSubCategories ( );
    //this.setState ( { productSubCategories: productsSubCategories.productSubCategories });
    this.setSubCategories ( productsSubCategories.productSubCategories );

    let allergens = this.state.client.getAllergens ( );
    this.setAllergens ( allergens.Allergens );
  }

  componentDidMount() {
    if (this.restricted.scope.visible)
      this.getData ( );    
  }

  componentWillUnmount() {    
  }

  setAllergens ( allergens ) {
    let a = { }
    if ( allergens !== null && allergens !== undefined ) {
      allergens.map ( (allergen) => {
        a [ allergen.iref ] = allergen;        
      });
      this.setState ( { allergens : a });
    }
  }

  setClasses ( classes ) {
    let a = { }
    if ( classes !== null && classes !== undefined ) {
      classes.map ( (productClass) => {
        a [ productClass.iref ] = productClass;        
      });
      this.setState ( { productClasses : a });
    }
  }

  setCategories ( categories ) {
    let a = { }
    if ( categories !== null && categories !== undefined ) {
      categories.map ( (category) => {
        a [ category.iref ] = category;        
      });
      this.setState ( { productCategories : a });
    }
  }

  setSubCategories ( subCategories ) {
    //console.log ( "setSubCategories-111" );
    //console.log (subCategories);
    let a = { }
    if ( subCategories !== null && subCategories !== undefined ) {
      subCategories.map ( (subCategory) => {
        //console.log ( subCategory );
        a [ subCategory.iref ] = subCategory;        
      });
      this.setState ( { productSubCategories : a });
      //console.log ( "setSubCategories" );
      //console.log ( this.state.productSubCategories );
    }
  }

  //Function to change the main Products filter state
  applyFilters ( queryData ) {
    //console.log ( "+++++++++++++++++++++++++++");
    //console.log ( queryData );
    if ( queryData ) {
      ////console.log ( queryData );
      let data = this.state.client.filterCombos ( queryData );
      this.setState ( { combos: data.productCombos });
    }
  }

  applyCategory ( category ) {
    if ( category !== undefined && category !== null ) {
      let queryData = { };
      if ( category !== "" ) queryData [ "category" ] = category;        
      let productsSubCategories = this.state.client.getProductsSubCategories ( queryData );
      this.setSubCategories ( productsSubCategories.productSubCategories );
    }
  }

  toggleAdd ( ) {
    let doAdd = this.state.addNewCombo;
    this.setState ( {addNewCombo: !doAdd} );
  }

  comboAdded ( ) {
    let queryData = { };
    if ( this.state.filterData ) queryData = this.state.filterData;
    this.setState ( { combos: null });
    this.applyFilters ( queryData ); 
    //this.toggleAdd ( false ); 

    this.getData ( ); 
    this.setState ( { filterData: queryData } );

    window.scrollTo(0, 0);
  }

  render() {

    if (!this.restricted.scope.visible)
      return (<div></div>);

    let combosList;
    ////console.log ( this.state.combos );
    let combosCounter = 0;
    if ( this.state.combos ) {
      combosCounter = this.state.combos.length;
      combosList = this.state.combos.map ( (combo) => {
        ////console.log ( combo );
        let comboImage = "";        
        if ( combo.comboImage_productCombo.length > 0 )
          if ( combo.comboImage_productCombo[0].image ) {
            comboImage = combo.comboImage_productCombo[0].image;
            combo.comboImage_productCombo.map ( (pImage) => {
              if ( pImage.isMain === true )
                comboImage = pImage.image;
            });
          }

        return <Combo
          key={combo.iref}
          combo={combo}
          //image={comboImage}
          allergensList={this.state.allergens}
          comboUpdated={this.comboAdded}
          allowEdit={this.restricted.scope.edit}
        />
      });
    }

    ////console.log ( productsList);

    return (

 
      <div style={{ backgroundColor: "#273442", height: "100%" }}>
        <div className='sticky'>
          <CombosFilters
          applyFilters = {this.applyFilters}
          applyCategory = {this.applyCategory}
          toggleAdd = {this.toggleAdd}
          combosCounter = {combosCounter}
          classesList = {this.state.productClasses}
          categoriesList = {this.state.productCategories}
          subCategoriesList = {this.state.productSubCategories}
          allergensList = {this.state.allergens}
          sx={{ position: 'sticky', top: 0}}
          allowEdit={this.restricted.scope.edit}
          />

        </div>

        { this.state.addNewCombo ?
        <Combo
          key={0}
          combo={undefined}
          isNew={true}
          allergensList={this.state.allergens}
          comboUpdated={this.comboAdded}
          allowEdit={this.restricted.scope.edit}
        />
        : ""
        }

        {this.loading ? <Skeleton animation="wave" variant="rounded" width={800} height={400} /> : combosList }

      </div>

    );

  }
}

export default Combos;