import React from 'react';
import { useRef } from "react";
//import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import KImage from './KImage';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import { FaExternalLinkAlt } from 'react-icons/fa';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import noImage from './../images/noImage.png';
import Client from './Client';
import Autocomplete from '@mui/material/Autocomplete';
import DropDown from './DropDown';
import Config from './ClientConfig';
import BackOfficeConfig from './../Config';
import swal from 'sweetalert';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#273442",
    color: theme.palette.common.white,
    paddingLeft: "4px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    paddingLeft: "4px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


class KProductsTable extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      edit: [],
      products: [],
      options: { },
      canvas: null,
      saveURL: "",
      parentIref: "",
      showConfirm: false,
      removeIref: "",
      productsSearch: [],
      //showValue: []
    };

    //this.handleExpandClick = this.handleExpandClick.bind(this);
    this.sendChangedState = this.sendChangedState.bind(this);   
    this.saveBack = this.saveBack.bind(this);    
    this.changeValue = this.changeValue.bind(this);
    this.changePriceType = this.changePriceType.bind(this);
    this.editor = React.createRef();
    this.handleEditClick = this.handleEditClick.bind(this);
    this.changeName = this.changeName.bind(this);
    this.toggleAdd = this.toggleAdd.bind(this);
    this.removeProduct = this.removeProduct.bind(this);
    this.removeConfirm = this.removeConfirm.bind(this);
    this.removeCancel = this.removeCancel.bind(this);
    this.showConfirmDialog = this.showConfirmDialog.bind(this);

    this.productsSearch = this.productsSearch.bind(this);
    this.productChange = this.productChange.bind(this);
  }

  componentDidMount() {
    if ( this.props.products ) this.setState ( { products: this.props.products } );
    if ( this.props.edit ) this.setState ( { edit: this.props.edit } );
    if ( this.props.saveURL ) this.setState ( { saveURL: this.props.saveURL } );
    if ( this.props.removeURL ) this.setState ( { removeURL: this.props.removeURL } );
    if ( this.props.parentIref ) this.setState ( { parentIref: this.props.parentIref } );

    //console.log ( this.props.images );
    let edit = false;
    if ( this.props.edit ) edit = this.props.edit;
    this.setState ( {edit: edit} );

    let editArray = [];
    if ( this.props.products )
      if ( this.props.products.length > 0 ) {
        this.props.products.forEach(element => {
          /* let pair = {};
          pair [ element.iref ] = false;
          editArray.push ( pair ); */

          editArray [ element.iref ] = false;
        });
      }

    this.setState ( { edit: editArray } );
  }

  componentDidUpdate ( prevProps ) {    
    if ( prevProps !== this.props ) {
      if ( this.props.products ) this.setState ( { products: this.props.products } );
      if ( this.props.edit ) this.setState ( { edit: this.props.edit } );
      if ( this.props.saveURL ) this.setState ( { saveURL: this.props.saveURL } );
      if ( this.props.removeURL ) this.setState ( { removeURL: this.props.removeURL } );
      if ( this.props.parentIref ) this.setState ( { parentIref: this.props.parentIref } );

      //console.log ( this.props.products );
      let edit = false;
      if ( this.props.edit ) edit = this.props.edit;
      this.setState ( {edit: edit} );

      let editArray = [];
      if ( this.props.products )
        if ( this.props.products.length > 0 ) {
          this.props.products.forEach(element => {
            /* let pair = {};
            pair [ element.iref ] = false;
            editArray.push ( pair ); */

            editArray [ element.iref ] = false;
          });
        }

      this.setState ( { edit: editArray } );
    }
  }

  sendChangedState ( e ) {
    
  }

  saveBack ( product, e ) {
    let iref = e;
    let uProducts = this.state.products;
    for ( let i = 0; i < uProducts.length; i++ ) {
      if ( uProducts [ i ].iref == iref )
        uProducts [ i ].product = product;
    }
    this.setState ( { products: uProducts } );

    /* let edits = this.state.edit;
    edits [ iref ] = false;
    this.setState ( { edit: edits } ); */
  }

  pushData ( e ) {
    let image = null;
    let newMain = null;
    let comboIref = e;

    let data = null;
    for ( let i = 0; i < this.state.products.length; i++ ) {
      if ( this.state.products [ i ].iref == comboIref )
        data = this.state.products [ i ]
    }
    if (!data) {
      swal ( "Error", "Add/Update product failed !", "error" );
      return;
    }
    
    let rdata = this.state.client.pushData ( this.state.saveURL, data );
    if ( rdata ) {
      let products = this.state.products;
      if ( rdata ) {
        for ( let i = 0; i < products.length; i++ ) {
          if ( products [ i ].iref == e ) {
            products [ i ] = rdata;
          }
        }
        this.setState ( { products: products } );
        this.handleEditClick ( e );
        swal ( "Success", "Successfully added/modified product ! (" + rdata.iref + ")", "success", {
          buttons: false,
          timer: 2000,
        })
        .then((value) => {
          this.setState ( { addNew: false } );
          //this.props.imageUpdated ( );
          this.setState ( { somethingHasChanged: false } );            
        });        
      } else {
        if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
        swal ( "Error", "Add/Update image failed !", "error" );
      }
    }

  }

  changeName ( e ) {
    if ( this.state.products ) {
      if ( this.state.products.length > 0 ) {
        let uProducts = this.state.products;
        for ( let i = 0; i < uProducts.length; i++ ) {
          if ( uProducts [ i ].iref == e.target.id )
            uProducts [ i ].name = e.target.value;
        }        
        this.setState ( { products: uProducts } );
      }
    }
    let uImage = this.state.i
  }

  changeValue ( e ) {
    let products = [];
    //console.log (e);
    let value = e.target.value;
    let iref = e.target.id;
    //Check if float
    if ( this.state.products ) products = this.state.products;
    for ( var element of products ) {
      if ( element.iref == iref ) {
        element.priceValue = value;
      }
    }
    this.setState ( { products: products } );
  }

  changePriceType ( iref, v ) {
    let products = [];
    if ( this.state.products ) products = this.state.products;
    for ( var element of products ) {
      if ( element.iref == iref ) {
        element.priceType = v;
      }
    }
    this.setState ( { products: products } );
    /* if (v == 2) this.setState ( {showValue: true} );
    else this.setState ( {showValue: false} ); */
  }

  handleEditClick ( e ) {
    let pRef = e;
    let uEdit = this.state.edit;
    let flag = !uEdit [ pRef ];
    uEdit [ pRef ] = flag;
    this.setState({ edit: uEdit });
  }

  toggleAdd ( ) {
    let addNew = this.state.addNew;
    this.setState ( { addNew: !addNew } );

    let uProducts = this.state.products;
    
    let uEdit = this.state.edit;
    if ( !addNew ) {
      let newProduct = {
        iref: "newProduct",
        product: {}
      };
      uProducts.unshift ( newProduct );
      //uEdit.unshift ( { "newProduct": true } );
      uEdit [ "newProduct" ] = true;
    } else {
      if ( uProducts.length > 0 ) {
        if ( uProducts [ 0 ].iref === "newProduct" )
          uProducts.shift();
          //uEdit.shift();
          delete uEdit [ "newProduct" ];
      }
    }
    this.setState ( { products: uProducts } );
    this.setState ( { edit: uEdit } );
  }  

  removeProduct ( e ) {
    let products = this.state.products;
    let edit = this.state.edit;
    let image = { "iref": e };
    let rdata = this.state.client.pushData ( this.state.removeURL, image );
    if ( rdata ) {
      if ( rdata.status == "OK" ) {
        for ( let i = 0; i < products.length; i++ ) {
          if ( products [ i ].iref == e ) {
            products.splice ( i, 1 );
            edit.splice ( i, 1 );
          }
        }
        this.setState ( { products: products } );
        this.setState ( { edit: edit } );
        this.handleEditClick ( e );
        swal ( "Success", "Successfully added/modified image ! (" + rdata.iref + ")", "success", {
          buttons: false,
          timer: 2000,
        })
        .then((value) => {
          this.props.imageUpdated ( );
          this.setState ( { somethingHasChanged: false } );            
        });        
      } else {
        if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
        swal ( "Error", "Add/Update image failed !", "error" );
      }
    }
  }

  showConfirmDialog ( e ) {
    this.setState ( { showConfirm: true } );
    this.setState ( { removeIref: e } );
  }

  removeConfirm ( iref ) {
    //console.log ( "Removing: " + iref );
    let removeIref = this.state.removeIref;
    this.removeProduct ( removeIref );
    this.setState ( { showConfirm: false } );
    this.setState ( { removeIref: "" } );
  }

  removeCancel ( ) {
    this.setState ( { showConfirm: false } );
    this.setState ( { removeIref: "" } );
  }

  productsSearch ( e ) {
    if ( e.target.value.length > 3 ) {
      let queryData = {
        "nameLike" : e.target.value
      };
      let data = this.state.client.filterProducts ( queryData );
      let newPeoducts = [];
      let users = [];
      if ( data.products.length > 0 ) {
        Object.keys(data.products).forEach(key => {
          let fData = data.products [ key ];
          let name = fData.name;
          let description = fData.description;          
          let lineText = fData.iref + " - " + name + "[" + description + "]";// - " + fData.first_name + " " + fData.last_name + " (" + fData.ruid + ", " + fData.email + ")";
          if ( fData.pCategory )
            if ( fData.pCategory.name )
              lineText += " (" + fData.pCategory.name;
          if ( fData.pSubCategory )
            if ( fData.pSubCategory.name )
              lineText += "/" + fData.pSubCategory.name + ")";
            else
              lineText += ")";
          else
            lineText += ")";

          let newObj = { key: fData.iref, label: lineText };
          newPeoducts.push ( newObj );
          users.push ( fData );
          //counties [ fData.iref ] = fData.name;      
        });
      }
      this.setState ( { productsSearch: newPeoducts });
    }
  }

  productChange ( e, v, prevIref, comboIref ) {
    /* console.log (e );
    console.log ( v );
    console.log ( prevIref );
    console.log ( comboIref );
    console.log ( this.state.products ); */
    
    //let text = e.target.textContent;
    //let repIref = text.substring ( 0, text.indexOf(" - ") );
    let queryData = { iref: v.key };
    //let data = this.state.client.getData ( Config.USER_API_URL + repIref, queryData );
    let data = this.state.client.getData ( Config.API_URL_PRODUCT, queryData );
    let product = {};
    if ( data.products ) {
      if ( data.products.length == 1 ) product = data.products [ 0 ];
    }
    //console.log ( product );
    let products = this.state.products;
    for ( let i = 0; i < products.length; i++ ) {
      /* console.log ( products [ i ].product.iref );
      console.log ( products [ i ] ); */
      if ( products [ i ].product.iref == prevIref ) {
        /* console.log ( "=============== replacing ================");
        console.log ( products [ i ].product ); */
        products [ i ].product = product;     
        /* console.log ( products [ i ].product );
        console.log ( ">>> =============== replacing ================"); */
      }
      //console.log ( products [ i ].iref );
    }

    if ( product ) {
      //uProduct.user_person = product;
      this.setState ( { products: products } );    
      //this.setState ( { somethingChanged: true } );
    }    
  }

  render() {    
    let products = {};
    let productsCombo = {};

    let kImage = {
      data: noImage,
      height: 128
    };


    if ( this.state.products ) {
      products = this.state.products;
      productsCombo = this.state.products.map ((productCombo) => {

      let showConfirm = false;
      if ( this.state.showConfirm )
        showConfirm = this.state.showConfirm;

      let product = {};
      if ( productCombo.product ) {
        product = productCombo.product;

        let mainImage = noImage;
        if ( product.productImage_product ) {
          if ( product.productImage_product.length > 0 ) {
            mainImage = product.productImage_product [ 0 ].image;
            for ( var i = 1; i < product.productImage_product.length; i++ ) {
              if ( product.productImage_product [ i ].isMain )
                mainImage = product.productImage_product [ i ].image;
            }
          }
        }
        let kImage = {
          data: mainImage,
          height: 128
        };

        let productsSearch = [ ];
        if ( this.state.productsSearch ) productsSearch = this.state.productsSearch;

        let priceType = "Unknown";
        if ( productCombo.priceType !== undefined )
          priceType = BackOfficeConfig.PRODUCT_COMBO_PRODCUT_TYPES [ productCombo.priceType ];

        let showValue = false;
        if ( productCombo.priceType == 2 )
          showValue = true;

        return <TableRow key={product.iref}>
          <StyledTableCell>
            {this.state.edit [ productCombo.iref ] ?
              <Autocomplete
              disablePortal
              id="product"
              key={product.iref}
              name="Product"
              options={productsSearch}
              sx={{ width: "260px", marginTop: 1 }}
              //onChange={() => {this.productChange(product.iref);}}
              onChange={(e,v) => this.productChange(e, v, product.iref, productCombo.iref)}
              renderInput = {
                (params) =>
                  <TextField 
                    {...params}
                    label="Product (type keywords)"
                    onChange={this.productsSearch}
                  />}
              />
              :
              <KImage
                /* key = {image.iref} */
                iref = {product.iref}
                edit = { this.state.edit [ product.iref ] }
                image = {kImage}
                imageText = {product.name}
                saveBack = {this.saveBack}
                arrange = "flex"
              />
            }
          </StyledTableCell>          

          <StyledTableCell>
            {this.state.edit [ productCombo.iref ] ?
              <TextField
                id={product.iref}
                label="Name"
                value={product.name || ''}
                variant="outlined"
                onChange={this.changeName}
                sx={{minWidth: "90%", marginTop: 1}}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"100"}}
              />
              :
              product.name
            }
          </StyledTableCell>

          <StyledTableCell>
          {this.state.edit [ productCombo.iref ] ?
              <TextField
                id={productCombo.iref}
                label="Name in Combo"
                value={productCombo.name || ''}
                variant="outlined"
                onChange={this.changeName}
                sx={{minWidth: "90%", marginTop: 1}}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"100"}}
              />
              :
              productCombo.name
            }
          </StyledTableCell>

          <StyledTableCell>
            {this.state.edit [ productCombo.iref ] ?
              <DropDown
                id={productCombo.iref}
                name="Price type"
                value={productCombo.priceType !== undefined ? productCombo.priceType : ''}
                doptions={BackOfficeConfig.PRODUCT_COMBO_PRODCUT_TYPES}
                hasIcons={false}   
                minWidth={120}
                maxWidth={220}
                onDark={false}
                onChangeFunction={(e) => this.changePriceType(productCombo.iref,e)}
                marginTop={10}
                marginLeft={7}
                margin={0}
              />
              :
              priceType
            }            
          </StyledTableCell>

          <StyledTableCell>
            {showValue ? 
              this.state.edit [ productCombo.iref ] ?
                <TextField
                  id={productCombo.iref}
                  label="Value"
                  value={productCombo.priceValue || ''}
                  variant="outlined"
                  /* onChange={() => {this.changeValue(productCombo.iref, value);}} */
                  onChange={this.changeValue}
                  sx={{minWidth: "90%", marginTop: 1}}
                  onFocus={event => { event.target.select(); }}
                  inputProps={{maxLength:"100"}}
                />
                :
                productCombo.priceValue
              : ""
            }
          </StyledTableCell>

          <StyledTableCell align="right" sx={{padding: "1px"}}>
          <IconButton aria-label="settings" id={"ib-" + productCombo.iref} onClick={() => {this.handleEditClick(productCombo.iref);}}>
            <EditIcon
              id={productCombo.iref}
              sx={{ color: this.state.edit [ productCombo.iref ] ? "#8f241d" : "#e3e3e5" }}
            />
          </IconButton>
          {this.state.edit [ productCombo.iref ]  ?
            <IconButton aria-label="settings" id={productCombo.iref} onClick={() => {this.pushData(productCombo.iref);}}>
            <SaveIcon
              sx={{ color: "#4287f5" }}              
            />
          </IconButton>
          : ""}

          {this.state.edit [ productCombo.iref ]  ?
            <IconButton aria-label="settings" id={productCombo.iref} onClick={() => {this.showConfirmDialog(productCombo.iref);}}>
            <DeleteForeverIcon
              sx={{ color: "#8f241d" }}              
            />
          </IconButton>
          : ""}

          <Dialog
            open={showConfirm}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.removeCancel}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Remove product ?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                  This action will NOT delete the product ! This product will only be detached from the combo.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.removeCancel}>No</Button>
              <Button onClick={() => {this.removeConfirm(product.iref);}}>Yes</Button>
            </DialogActions>
          </Dialog>

        </StyledTableCell>

        </TableRow>
      }
      });
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "middle", justifyContent:"center", alignItems: "center", paddingTop: "2px" }}>
        <Table sx={{ minWidth: 700, textAlign: "middle", justifyContent:"center" }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <IconButton aria-label="Add Image" title="Add Image" onClick={() => this.toggleAdd()}> 
                  <Avatar alt="Add Image" sx={{ width: 24, height: 24, color: "#273442" }}>
                    {this.state.addNew ? <FaMinusCircle/> : <FaPlusCircle/>}
                  </Avatar>
                </IconButton>
                Product
              </StyledTableCell>
              <StyledTableCell align="left">Name</StyledTableCell>
              <StyledTableCell align="left">Name in Combo</StyledTableCell>              
              <StyledTableCell align="left">Price Type</StyledTableCell>
              <StyledTableCell align="left">Price</StyledTableCell>
              <StyledTableCell align="right">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productsCombo}
          </TableBody>
        </Table>
        
      </Box>
    );

  }
}

export default KProductsTable;
