import React from 'react';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { FaHome, FaPizzaSlice, FaHamburger, FaMapMarkedAlt, FaEuroSign, FaUserTie, FaHospitalUser } from "react-icons/fa";

//import './css/sideBar.scss';
//import './css/dashboard.css';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import noImage from './../images/icons/noImage_1.png';

let theme = createTheme();

class Allergen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      allergen: {
        iref: "",
        name: "",
        image: noImage,
        image_on: noImage,
        image_off: noImage,
      },
      edit: false,
      imageStatus: false,
      //doReset: false
    };
    
  }

  componentDidMount ( ) {
    if ( this.props ) {
      this.setState ( { allergen: this.props } );
      if ( this.props.edit !== undefined )
        this.setState ( { edit: this.props.edit} );
      if ( this.props.edit !== undefined )
        this.setState ( { edit: this.props.edit } );

      if ( this.props.isOn )
        this.setState ( { imageStatus: true } );
    }
  }

  componentDidUpdate ( prevProps ) {    
    if ( prevProps.doReset !== this.props.doReset && this.props.doReset === true ){
      //this.setState({doReset: this.props.doReset })
      this.setState ( { imageStatus: false } );
    }
    if ( prevProps != this.props ) {
      if ( this.props ) {
        this.setState ( { allergen: this.props } );
        if ( this.props.edit !== undefined )
          this.setState ( { edit: this.props.edit} );

        if ( this.props.isOn )
          this.setState ( { imageStatus: true } );
      }
    }
  }

  sendToggleFilter ( name, type ) {
    let imageStatus = this.state.imageStatus;
    this.props.onClickFunction ( name, type );
    this.setState ( { imageStatus: !imageStatus } );
  }

  render() {
    let iref = null;
    let name = "";
    let image = null;
    let image_on = "";
    let image_off = "";
    let onClickFunction = null;
    let doReset = false;

    if ( this.state.allergen ) {
      let allergen = this.state.allergen;
      if ( allergen.iref ) iref = allergen.iref;
      if ( allergen.name ) name = allergen.name;
      if ( allergen.image ) image = allergen.image;
      if ( allergen.image_on ) image_on = allergen.image_on;
      if ( allergen.image_off ) image_off = allergen.image_off;
      //if ( allergen.onClickFunction ) onClickFunction = allergen.onClickFunction;
      //if ( this.props.udpated ) udpated = this.props.udpated;
    }
    //this.setState ( {updated: updated} );

    //console.log ( this.state.updated );

    return (
    
          <IconButton
            key = {iref}
            aria-label = {name}
            title = {name}
            onClick = {() => this.sendToggleFilter ( "allergens", iref )} 
            disabled = {!this.state.edit}
          >       
            <Avatar
              key = {iref}
              alt={name}
              src={image ? image : this.state.imageStatus ? image_on : image_off}
              sx={{ width: 32, height: 32 }}
            />
          </IconButton>      
          
   
    );

  }
}

export default Allergen;