import React from 'react';
import { Avatar, createTheme, Box, Card, Button, TextField, MenuItem, Table, TableContainer, TableBody, TableCell, TableHead, TablePagination, TableRow, Paper, Tabs, Tab } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { format } from 'date-fns';
import Client from '../Client';
import Config from '../ClientConfig';
import SalesOrderView from './SalesOrderView';
import SalesLastInvoices from './SalesLastInvoice';


let theme = createTheme();

function thousandsSeparator(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
function addZero(i) {
  if (i < 10) {i = "0" + i}
  return i;
}

const deliveryTypes = [
  {iref: 9, name: 'All'},
  {iref: 0, name: 'Pickup'},
  {iref: 3, name: 'Glovo'},
  {iref: 4, name: 'Tazz'},
  {iref: 5, name: 'Bolt'},
  {iref: 6, name: 'Bringo'}
]

const printTypes = [
  {iref: 9, name: 'All'},
  {iref: 0, name: 'False'},
  {iref: 1, name: 'True'}
]

const invoiceTypes = [
  {iref: 9, name: 'All'},
  {iref: 0, name: 'False'},
  {iref: 1, name: 'True'}
]
/* function addDays(date, days) {
  if(date) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    result = format(result, 'yyyy-MM-dd');
    return result;
  }
} */

let d = new Date();
let today = format(d, 'yyyy-MM-dd');  // mm=week, MM=month
let yesterday = format(new Date().setDate(new Date().getDate() - 1), 'yyyy-MM-dd');

  
const objectToCsv = function (data) {
  const csvRows = [];
  /* Get headers as every csv data format 
  has header (head means column name)
  so objects key is nothing but column name 
  for csv data using Object.key() function.
  We fetch key of object as column name for 
  csv */
  const headers = Object.keys(data[0]);
  /* Using push() method we push fetched 
     data into csvRows[] array */
  csvRows.push(headers.join(','));
  // Loop to get value of each objects key
  for (const row of data) {
      const values = headers.map(header => {
          const val = row[header]
          return `"${val}"`;
      });
      // To add, separator between each value
      csvRows.push(values.join(','));
  }
  /* To add new line for each objects values
     and this return statement array csvRows
     to this function.*/
  return csvRows.join('\n');
};
class OverviewOrders extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      saleslocation_list: [],
      tabValue: "orders",
      page: 0,
      dtype: 9,
      dtypeName: "All",
      invoiced: 9,
      invoicedName: "All",
      printed: 9,
      printedName: "All",
      rowsPerPage: 10,
      date: today,
      location: 0,
      locationName: "All locations",
      sx_hidden: {display: 'none'},
      value: "1",
      locations: [],
      paytype: 9,
      filterData: {}
    };

    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addDate = this.addDate.bind(this);
    this.selectPeriod = this.selectPeriod.bind(this);
    this.selectLocation = this.selectLocation.bind(this);
    this.selectDelivery = this.selectDelivery.bind(this);
    this.selectPrinted = this.selectPrinted.bind(this);
    this.selectInvoiced = this.selectInvoiced.bind(this);
    this.selectPay = this.selectPay.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.queryData = { };
  }

  componentDidMount() {
    this.getData ();
  }

  getData ( ) {  
    let queryData = { 
      "type": 22,
      "location": this.state.location,
      "date": this.state.date,
      "dType": this.state.dtype,
      "printed": this.state.printed,
      "invoiced": this.state.invoiced,
      "payType": this.state.paytype
    }
    // console.log(queryData);
	  let data = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, queryData );
    if ( data ) {
      this.setState ( { orders_list: data });
    }
    this.setState ( { filterData: queryData } );

    let locationData = {
      "type": 25,
    }
    // let locations = this.state.client.getData ( Config.API_URL_LOCATIONS_SIMPLE, queryData );
    let locations = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, locationData );
    this.setState ( { locations: locations.locations });
  }
  
  doSync ( ) {
    this.getData ( );
    this.setState ({page: 0});
  }

  addDate = (event) => {
    this.setState (
      {date: event.target.value},
      function(){
        this.doSync ( );
      }
    );
  }

  selectPeriod = (event) => {
    switch ( event.target.value ) {
      case "today":
        this.setState ({sx_hidden: {display: 'none'}},  
        this.setState (
          {date: today},  //today
          function(){
          }
        ));
        break;
      case "yesterday":
        this.setState ({sx_hidden: {display: 'none'}},  
        this.setState (
          {date: yesterday}, //yesterday
          function(){
          }
        )); 
        break;                
      default:
        this.setState (
          {sx_hidden: {}},
          function(){
            this.doSync ( );
          }
        );
        // console.log(`No period selected.`);
    }
  }

  selectLocation = (event) => {
    this.setState (
      {location: event.target.value,
        locationName: this.state.locations.find((o) => o.iref === event.target.value)
      },
      function(){
        this.doSync ( );
      }
      );
  }
  selectPay = (event) => {
    this.setState (
      {paytype: event.target.value},
      function(){
        this.doSync ( );
      }
      );
  }
  selectDelivery = (event) => {
    this.setState (
      {dtype: event.target.value,
      dtypeName: deliveryTypes.find((o) => o.iref === event.target.value)},
      function(){
        this.doSync ( );
      }
      );
  }
  selectPrinted = (event) => {
    this.setState (
      {printed: event.target.value,
      printedName: printTypes.find((o) => o.iref === event.target.value)},
      function(){
        this.doSync ( );
      }
      );
  }
  selectInvoiced = (event) => {
    this.setState (
      {invoiced: event.target.value,
      invoicedName: invoiceTypes.find((o) => o.iref === event.target.value)},
      function(){
        this.doSync ( );
      }
      );
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  }

  handleChangePage = (event, newPage) => {
    this.setState ({page: newPage});
  }
  
  handleChangeRowsPerPage = (event) => {
    this.setState ({rowsPerPage: +event.target.value});
    this.setState ({page: 0});
  }

  onDownload = () => {
    const link = document.createElement("a");
    link.download = "ordersList_" + this.state.date + "_" + this.state.locationName.name +".csv";
    let csvData = [];
    if(this.state.orders_list.result.length>0){
      csvData = objectToCsv(this.state.orders_list.result);
    }
    let csvHeader = "Orders List\nDate: " + this.state.date + "\nLocation: " + this.state.locationName.name + "\nDelivery: " + this.state.dtypeName.name;
    let csv = csvHeader + "\n" + csvData;
    link.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
    // console.log(link);
    link.click();
  };

  handleTabChange ( event, newValue ) { 
    this.setState ( { tabValue: newValue } );
  }

  render() {


    let locations = [];
    if (this.state.locations){
      Object.keys(this.state.locations).forEach(key => {
        let fData = this.state.locations[key];
        let myData = {'iref': fData.iref, 'name': fData.name};
        locations.push ( myData );
      });
    }

		let ordersList = [];
		if (this.state.orders_list){
			if (this.state.orders_list.result) {
				Object.keys(this.state.orders_list.result).forEach(key => {
					let fData = this.state.orders_list.result[key];
					let p = {
						id: fData.iref,
						location: fData.pos__location__name,
						ZNumber: fData.ZNumber,
            receiptNumber: fData.receiptNumber,
            createdOn: new Date(fData.createdOn),
            invoiceNo: fData.invoice__serial,
						value: thousandsSeparator(parseInt(fData.totalAmount)),
            transaction: fData.transaction.reduce((prev, current) => `${prev}, ${current}`)
					}
					ordersList.push ( p );
				});
			}
    }

    return (
      <Paper sx={{ width: '100%', borderRadius:"16px", overflow: 'hidden' }}>
        <Card style={{ height: '640px' }} sx={{ borderRadius:"16px"}}>
          <Box sx={{ marginLeft: "10px", borderRadius:"16px", display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'flex-start'}} >
            <Tabs
            // style={{maxHeight: "20px", padding: "2px"}}
            value={this.state.tabValue}
            onChange={this.handleTabChange}
            // centered
            indicatorColor="secondary"
            >
            <Tab sx={{maxHeight: "20px", minHeight: "48px"}} label="orders" value="orders"/>
            <Tab sx={{maxHeight: "20px", minHeight: "48px"}} label="detail" value="view"/>
            <Tab sx={{maxHeight: "20px", minHeight: "48px"}} label="invoice" value="invoice"/>
            </Tabs>
          </Box>
						{ this.state.tabValue === "orders" ?
                <Card style={{ height: '600px' }} sx={{ borderRadius:"16px", height: '100%' }}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between'}}>
                  <Box sx={{ padding: '16px', alignItems: 'baseline' }}>
                    <TextField
                      id="standard-select-location"
                      select
                      label="Select Location"
                      defaultValue=""
                      // helperText="Select Location"
                      variant="standard"
                      sx={{ width: 150}}
                      onChange={this.selectLocation}
                    >
                        {locations.map((option) => (
                        <MenuItem key={option.iref} value={option.iref}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    &nbsp;
                    <TextField
                      // size='small'
                      id="date"
                      label="Date"
                      type="date"
                      variant="standard"
                      // defaultValue={currentDate}
                      value={this.state.date}
                      // inputFormat="DD/MM/YYYY"
                      sx={{ color: "#e3e3e5", alignSelf: "center", marginLeft: "6px", width:120}}
                      InputLabelProps={{
                        shrink: true,
                        sx : { color: "#5b5b5b" }
                      }}
                      InputProps={{ sx : { color: "#5b5b5b" }}}
                      inputProps={{ min: "2024-01-01", style : { color: "#5b5b5b", paddingRight: 5, paddingLeft: 5} }}
                      onChange={this.addDate}
                    />
                    &nbsp;
                    <TextField
                      id="standard-select-dType"
                      select
                      label="Delivery"
                      defaultValue="9"
                      // helperText="Select Delivery"
                      variant="standard"
                      sx={{ minWidth: 60}}
                      onChange={this.selectDelivery}
                    >
                      {deliveryTypes.map((option) => (
                        <MenuItem key={option.iref} value={option.iref}>
                          {option.name}
                        </MenuItem>
                      ))}
                      {/* <MenuItem value={9}>All</MenuItem>
                      <MenuItem value={0}>PickUp</MenuItem>
                      <MenuItem value={3}>Glovo</MenuItem>
                      <MenuItem value={4}>Tazz</MenuItem>
                      <MenuItem value={5}>Bolt</MenuItem>
                      <MenuItem value={6}>Bringo</MenuItem> */}
                    </TextField>
                    &nbsp;
                    <TextField
                      id="standard-select-printed"
                      select
                      label="Printed"
                      defaultValue="9"
                      // helperText="Is Printed"
                      variant="standard"
                      sx={{ minWidth: 60}}
                      onChange={this.selectPrinted}
                    >
                      {printTypes.map((option) => (
                        <MenuItem key={option.iref} value={option.iref}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    &nbsp;
                    <TextField
                      id="standard-select-invoiced"
                      select
                      label="Invoiced"
                      defaultValue="9"
                      // helperText="Is Invoice"
                      variant="standard"
                      sx={{ minWidth: 60}}
                      onChange={this.selectInvoiced}
                    >
                      {invoiceTypes.map((option) => (
                        <MenuItem key={option.iref} value={option.iref}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    &nbsp;
                    <TextField
                      id="standard-select-pay"
                      select
                      label="Paid"
                      defaultValue="9"
                      // helperText="Paid"
                      variant="standard"
                      sx={{ minWidth: 60}}
                      onChange={this.selectPay}
                    >
                      <MenuItem value={9}>All</MenuItem>
                      <MenuItem value={0}>Card</MenuItem>
                      <MenuItem value={1}>Cash</MenuItem>
                      <MenuItem value={2}>Credit</MenuItem>
                      <MenuItem value={6}>Voucher</MenuItem>
                    </TextField>
                    &nbsp;
                    &nbsp;
                    <Button
                      color="inherit"
                      size="small"
                      onClick={this.onDownload} 
                      variant="contained" 
                      // color="primary"
                      style={{maxWidth: '36px', minWidth: '36px', maxHeight: '36px', minHeight: '36px'}}
                    >
                      <Avatar sx={{ width: 24, height: 24, bgcolor: theme.palette.success.light}}> 
                        <FileDownloadIcon />
                      </Avatar>
                    </Button>
                  </Box >
                </Box>
                <Box>
                <TableContainer sx={{ height: '380px' }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{padding: "6px", textAlign: "center" }}>
                        <b>OrderNo</b>
                      </TableCell>
                      <TableCell style={{padding: "6px", textAlign: "center" }}>
                        <b>Created</b>
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        <b>Value</b>
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        <b>ZNumber</b>
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        <b>Receipt</b>
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        <b>Payment</b>
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        <b>Invoice</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ordersList.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((order) => {
                      return (
                        <TableRow
                          hover
                          key={order.id}
                          tabIndex={-1}
                        >
                          <TableCell style={{padding: "6px", textAlign: "center" }}>
                            {order.id}
                          </TableCell>
                          <TableCell style={{padding: "6px", textAlign: "center" }}>
                            {addZero(order.createdOn.getHours()) + ":" + addZero(order.createdOn.getMinutes())}
                          </TableCell>
                          <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                              {order.value}
                          </TableCell>
                          <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                              {order.ZNumber}
                          </TableCell>
                          <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                              {order.receiptNumber}
                          </TableCell>
                          <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                              {order.transaction}
                          </TableCell>
                          <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                              {order.invoiceNo}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, { value: ordersList.length, label: 'All' }]}
                  labelRowsPerPage="Rows:"
                  // showFirstButton="True"
                  // showLastButton="True"
                  component="div"
                  count={ordersList.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
                </Box>  
              </Card>
							: ""
						}
            { this.state.tabValue === "view" ?
							<SalesOrderView/>
							: ""
						}
            { this.state.tabValue === "invoice" ?
											<SalesLastInvoices/>
											: ""
										}

        </Card>
      </Paper>
    );

  }
}

export default OverviewOrders;
