import React from 'react';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
import { FaHome, FaPizzaSlice, FaHamburger, FaMapMarkedAlt, FaEuroSign, FaUserTie, FaHospitalUser, FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Checkbox from '@mui/material/Checkbox';
import Client from './Client';
import Allergen from './Allergen';
import Category from './Category';
import SubCategory from './SubCategory';
import ProductClass from './ProductClass';

//import './css/dashboard.css';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import noImage from './../images/icons/noImage_1.png';
import idle_off from './../images/icons/idle_on.png';
import idle_on from './../images/icons/idle_off.png';
import registered_off from './../images/icons/registered_on.png';
import registered_on from './../images/icons/registered_off.png';
import active_off from './../images/icons/active_on.png';
import active_on from './../images/icons/active_off.png';
import discontinued_off from './../images/icons/discontinued_on.png';
import discontinued_on from './../images/icons/discontinued_off.png';

import soia_off from './../images/icons/soia_off.png';
import soia_on from './../images/icons/soia_on.png';
import arahide_off from './../images/icons/arahide_off.png';
import arahide_on from './../images/icons/arahide_on.png';
import dioxid_off from './../images/icons/dioxid_off.png';
import dioxid_on from './../images/icons/dioxid_on.png';
import grau_off from './../images/icons/grau_off.png';
import grau_on from './../images/icons/grau_on.png';
import lapte_off from './../images/icons/lapte_off.png';
import lapte_on from './../images/icons/lapte_on.png';
import mustar_off from './../images/icons/mustar_off.png';
import mustar_on from './../images/icons/mustar_on.png';
import oua_off from './../images/icons/oua_off.png';
import oua_on from './../images/icons/oua_on.png';
import peste_off from './../images/icons/peste_off.png';
import peste_on from './../images/icons/peste_on.png';
import porumb_off from './../images/icons/porumb_off.png';
import porumb_on from './../images/icons/porumb_on.png';
import seminte_off from './../images/icons/seminte_off.png';
import seminte_on from './../images/icons/seminte_on.png';

import askPrice_off from './../images/icons/askPrice_off.png';
import askPrice_on from './../images/icons/askPrice_on.png';
import askQuantity_off from './../images/icons/askQuantity_off.png';
import askQuantity_on from './../images/icons/askQuantity_on.png';
import canCombo_off from './../images/icons/canCombo_off.png';
import canCombo_on from './../images/icons/canCombo_on.png';
import canGenerateFidelityPoints_off from './../images/icons/canGenerateFidelityPoints_off.png';
import canGenerateFidelityPoints_on from './../images/icons/canGenerateFidelityPoints_on.png';
import hasPackaging_off from './../images/icons/hasPackaging_off.png';
import hasPackaging_on from './../images/icons/hasPackaging_on.png';
import hasRecipe_off from './../images/icons/hasRecipe_off.png';
import hasRecipe_on from './../images/icons/hasRecipe_on.png';
import isDeliverable_off from './../images/icons/isDeliverable_off.png';
import isDeliverable_on from './../images/icons/isDeliverable_on.png';
import isDiscountable_off from './../images/icons/isDiscountable_off.png';
import isDiscountable_on from './../images/icons/isDiscountable_on.png';
import isForSale_off from './../images/icons/isForSale_off.png';
import isForSale_on from './../images/icons/isForSale_on.png';
import isFractionable_off from './../images/icons/isFractionable_off.png';
import isFractionable_on from './../images/icons/isFractionable_on.png';
import isNew_off from './../images/icons/isNew_off.png';
import isNew_on from './../images/icons/isNew_on.png';
import isPurchased_off from './../images/icons/isPurchased_off.png';
import isPurchased_on from './../images/icons/isPurchased_on.png';
import isSellable_off from './../images/icons/isSellable_off.png';
import isSellable_on from './../images/icons/isSellable_on.png';
import isSoldAlone_off from './../images/icons/isSoldAlone_off.png';
import isSoldAlone_on from './../images/icons/isSoldAlone_on.png';
import selfService_off from './../images/icons/selfService_off.png';
import selfService_on from './../images/icons/selfService_on.png';
import useScale_off from './../images/icons/useScale_off.png';
import useScale_on from './../images/icons/useScale_on.png';

let theme = createTheme();

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

class RecomFilters extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      productCategories: null,
      expanded: false,
      recom_active: 0,
      filtersCounter: 0,
      resetState: false,
      filters: { },
      allowEdit: false
    };

    this.queryData = { };
    //this.productStatus = { "registered": 0, "idle": 1, "active": 2, "discontinued": 9 };
    this.searchKeyword = React.createRef();

    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.resetStatus = this.resetStatus.bind(this);
    this.removeQueryElement = this.removeQueryElement.bind(this);
    this.addQueryElement = this.addQueryElement.bind(this);
    this.toggleElement = this.toggleElement.bind(this);
    this.searchKey = this.searchKey.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.setStatusFilters = this.setStatusFilters.bind(this);
    this.toggleActive = this.toggleActive.bind(this);    
  }

  componentDidMount() {        
    if (this.props) {
      if (this.props.allowEdit === true)
        this.setState ({allowEdit: true});
    } 
  }

  componentWillUnmount() {
    //this.searchKeyword = React.useRef(null);
  }

  /* componentWillReceiveProps ( nextProps ) {
    
  } */

  componentDidUpdate ( prevProps ) {
  }

  toggleActive ( ) {
    let active = !this.state.recom_active;
    this.setState ({recom_active: active});
    this.addQueryElement ("active", active == true ? 1:0);
    let filtersCounter = Object.keys(this.queryData).length;
    this.setState ( {filtersCounter: filtersCounter} );
    this.props.applyFilters ( this.queryData );
  }

  handleExpandClick ( ) {
    this.setState({ expanded: !this.state.expanded });
  }

  setStatusFilters ( value ) {
    this.setState ( {recom_active: value} );
  }

  searchKey ( e ) {    
    let keyword = e.target.value;
    if ( keyword.length > 2 ) {
      this.addQueryElement ( "nameLike", keyword );
      this.props.applyFilters ( this.queryData );
    } else {
      if ( "nameLike" in this.queryData ) {
        delete this.queryData [ "nameLike" ];
        this.props.applyFilters ( this.queryData );
      }
    }
    let filtersCounter = Object.keys(this.queryData).length;
    this.setState ( {filtersCounter: filtersCounter} );
  }

  resetStatus ( element ) {
    if ( element !== "active" ) this.setState ( { recom_active: 0 } );
  }

  removeQueryElement ( element ) {
    if ( element ) {
      if ( element in this.queryData ) {
        if ( this.queryData [ element ] !== null && this.queryData [ element ] !== undefined ) {
          delete this.queryData [ element ];
        }
      }
    }
  }

  addQueryElement ( element, value ) {
    if ( element ) {
      if ( element in this.queryData ) {
        if ( this.queryData [ element ] !== null && this.queryData [ element ] !== undefined && value !== null && value !== undefined ) {
          if ( this.queryData [ element ] != value ) this.queryData [ element ] = value;
          else delete this.queryData [ element ];
        } else {
          delete this.queryData [ element ];
        }
      } else {
        if ( value !== null && value !== undefined )
          this.queryData [ element ] = value;
        else
          this.queryData [ element ] = 1;
      }
    }
  }

  toggleElement ( prefix, element, value ) {
    let stateName = "filters_" + prefix + "_" + element;
    let status = this.state [ stateName ];
    this.setState ( { [stateName]: !status } );
    if ( prefix == "status" ) element = "status";
    if ( value !== null && value !== undefined ) {
      this.addQueryElement ( element, value );
    } else {
      this.addQueryElement ( element );
    }
  }


  toggleFilter ( filterType, filterName ) {
    switch ( filterType ) {
      case "status":
        this.resetStatus ( filterName );
        let statusValue = this.productStatus [ filterName ];
        this.toggleElement ( "status", filterName, statusValue );
        break;
    }

    let filtersCounter = Object.keys(this.queryData).length;
    this.setState ( {filtersCounter: filtersCounter} );

    this.props.applyFilters ( this.queryData );
    this.setState ( {resetState: false});
  }

  resetFilters ( ) {
    if ( this.queryData ) this.queryData = { };
    this.setStatusFilters ( 0 );
    this.setState ( {filters_reset: 1} );
    this.searchKeyword.current.value = "";
    this.setState ( {filtersCounter: 0} );
    this.props.applyFilters ( this.queryData );
    this.setState ( {resetState: true});
  }


  render() {
    let active = this.state.recom_active == 1;
    
    return (
      <Card sx={{ borderRadius: '16px', m: 1, backgroundColor: "#214a80" }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>

        <CardActions disableSpacing sx={{display: "flex", textAlign: "start"}}>
          <Checkbox id="status" color="info" checked={active} onChange={this.toggleActive} >Status</Checkbox>
        </CardActions>

        <TextField
          id="standard-basic"
          inputRef={this.searchKeyword}
          label="Keywords"
          variant="standard"
          inputProps={{ sx : { color: "#e3e3e5" } }}
          InputLabelProps={{ sx : { color: "#e3e3e5" } }}
          onChange={(e) => this.searchKey( e )}
        />

        <Typography
          variant="body2"
          color="#e3e3e5"
          sx={{ color: "#e3e3e5", marginTop: "revert", marginLeft: "32px" }}
        >
          Recommendations: {this.props.productsCounter}
        </Typography>

        <Typography
          variant="body2"
          color="#e3e3e5"
          sx={{ color: "#e3e3e5", marginTop: "revert", marginLeft: "32px" }}
        >
          Filters: {this.state.filtersCounter}
        </Typography>
        { this.state.filtersCounter > 0 ?
          <IconButton
            aria-label="Remove Filters"
            title="Remove Filters"
            onClick={() => this.resetFilters ( )}
          >
            <Avatar alt="Remove Filters" sx={{ width: 16, height: 16, color: "#99302e" }}>
              <HighlightOffIcon/>
              </Avatar>
          </IconButton>
            : ""
        }

        <ExpandMore
          expand={this.state.expanded}
          onClick={this.handleExpandClick}
          aria-expanded={this.state.expanded}
          aria-label="show more"
          sx={{ color: "#e3e3e5", textAlign: "end" }}
        >
          <ExpandMoreIcon />
        </ExpandMore>
        
      </Box>
      
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>            
          </CardContent>
        </Collapse>
    </Card>   
    );

  }
}

export default RecomFilters;