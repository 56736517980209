import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import noImage from './images/noImage.png';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BrowserNotSupportedIcon from '@mui/icons-material/BrowserNotSupported';
import Avatar from '@mui/material/Avatar';

import Config from './components/ClientConfig';
import Client from './components/Client';
import BackOfficeConfig from './Config';

import ListsPriceFilters from './components/ListsPriceFilters';
/*
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
*/
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


class ProductRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      //client: new Client ( "none" ),
      edit: false,   
    };

    this.handleEditClick = this.handleEditClick.bind(this);
  }

  handleEditClick ( ) {
    let cEdit = !this.state.edit;
    this.setState({ edit: !this.state.edit });
  }

  render() {
    let key = "";
    let name = "blah";
    let img = noImage;
    let sku = "";
    let description = "";
    let unitType = 0;
    let pClass = 0;
    let pCategory = 0;
    let pSubCategory = 0;
    let ccs = "";
    let value = 0.0;

    console.log ( ":::::::::::::::::::::::::::::::::::" );
    console.log ( this.props );

    /* let categoryImage = noImage;
    let subCategoryImage = noImage; */

    if ( this.props ) {
      if ( this.props.key ) key = this.props.key;
      if ( this.props.name ) name = this.props.name;      
      if ( this.props.sku ) sku = this.props.sku;
      if ( this.props.description ) description = this.props.description;
      if ( this.props.unitType ) unitType = this.props.unitType;
      if ( this.props.value ) value = this.props.value;

      if ( this.props.img ) {
        if ( this.props.img.length > 0 ) {
          img = this.props.img [ 0 ].image;
          for ( var i = 0; i < this.props.img.length; i++ ) {
            if ( this.props.img [ i ].isMain === true ) img = this.props.img [ i ].image;
          }
        }        
      }
      
      
    }

    return (
      <React.Fragment>
        <TableRow key={key}>
        <TableCell component="td" scope="row">
        <Box
              component="img"
              sx={{
                height: 48,
                width: 48,
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
                border: '1px dashed grey'
              }}
              alt="No Image"
              src={img}
            />
        </TableCell>

          <TableCell component="td" scope="row">{name}</TableCell>
          <TableCell>{sku}</TableCell>
          <TableCell>{description}</TableCell>
          <TableCell>{unitType}</TableCell>
          <TableCell sx={{ minWidth: 96 }} >
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Avatar title={this.props.pCategory.name} alt={this.props.pCategory.name} src={this.props.pCategory.image} sx={{ width: 48, height: 48 }}/>
              <Avatar title={this.props.pSubCategory.name} alt={this.props.pSubCategory.name} src={this.props.pSubCategory.image} sx={{ width: 48, height: 48 }}/>
            </Box>
          </TableCell>
          <TableCell align="right">
          {this.state.edit ?
            <TextField sx={{ maxWidth: 176 }} id="outlined-basic" label={value} variant="outlined" />
            : value
          }
          </TableCell>
          
          {/* <TableCell align="left">
          {isForSale ?
            <Checkbox icon={<CheckCircleOutlineIcon/>} color="success" checked disabled/>
            :
            <Checkbox icon={<BrowserNotSupportedIcon/>} color="default" disabled/>            
          }
          </TableCell> */}          

          <TableCell align="right">
            <IconButton aria-label="settings">
                <EditIcon
                  sx={{ color: this.state.edit ? "#8f241d" : "#e3e3e5" }}
                  onClick={this.handleEditClick}
                />
            </IconButton>
            {this.state.edit ?
              <IconButton aria-label="settings">
              <SaveIcon
                sx={{ color: "#4287f5" }}
                onClick={this.handleEditClick}
              />
          </IconButton>
            : ""}
          </TableCell>
        </TableRow>
      </React.Fragment>
      
    );

  }

}

class ComboRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      //client: new Client ( "none" ),
      edit: false,   
    };

    this.handleEditClick = this.handleEditClick.bind(this);
  }

  handleEditClick ( ) {
    let cEdit = !this.state.edit;
    this.setState({ edit: !this.state.edit });
  }

  render() {
    let key = "";
    let name = "blah";
    let img = noImage;
    let sku = "";
    let description = "";
    let unitType = 0;
    /* let pClass = 0;
    let pCategory = 0;
    let pSubCategory = 0; */
    let ccs = "";
    let value = 0.0;

    let categoryImage = noImage;
    let subCategoryImage = noImage;

    if ( this.props ) {
      if ( this.props.key ) key = this.props.key;
      if ( this.props.name ) name = this.props.name;      
      if ( this.props.sku ) sku = this.props.sku;
      if ( this.props.description ) description = this.props.description;
      if ( this.props.unitType ) unitType = this.props.unitType;
      if ( this.props.value ) value = this.props.value;

      if ( this.props.img ) {
        if ( this.props.img.length > 0 ) {
          img = this.props.img [ 0 ].image;
          for ( var i = 0; i < this.props.img.length; i++ ) {
            if ( this.props.img [ i ].isMain === true ) img = this.props.img [ i ].image;
          }
        }        
      }

      if ( this.props.categories ) {

      }
    }

    return (
      <React.Fragment>
        <TableRow key={key}>
        <TableCell component="td" scope="row">
        <Box
              component="img"
              sx={{
                height: 48,
                width: 48,
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
                border: '1px dashed grey'
              }}
              alt="No Image"
              src={img}
            />
        </TableCell>

          <TableCell component="td" scope="row">{name}</TableCell>
          <TableCell>{sku}</TableCell>
          <TableCell>{description}</TableCell>
          {/* <TableCell>{unitType}</TableCell> */}
          {/* <TableCell>{ccs}</TableCell> */}
          <TableCell align="right">
          {this.state.edit ?
            <TextField sx={{ maxWidth: 176 }} id="outlined-basic" label={value} variant="outlined" />
            : value
          }
          </TableCell>

          {/* <TableCell align="left">
          {isForSale ?
            <Checkbox icon={<CheckCircleOutlineIcon/>} color="success" checked disabled/>
            :
            <Checkbox icon={<BrowserNotSupportedIcon/>} color="default" disabled/>            
          }
          </TableCell> */}          

          <TableCell align="right">
            <IconButton aria-label="settings">
                <EditIcon
                  sx={{ color: this.state.edit ? "#8f241d" : "#e3e3e5" }}
                  onClick={this.handleEditClick}
                />
            </IconButton>
            {this.state.edit ?
              <IconButton aria-label="settings">
              <SaveIcon
                sx={{ color: "#4287f5" }}
                onClick={this.handleEditClick}
              />
          </IconButton>
            : ""}
          </TableCell>
        </TableRow>
      </React.Fragment>
      
    );

  }

}


class Row extends React.Component {

    constructor(props) {
      super(props);
  
      this.state = {
        //client: new Client ( "none" ),  
/*         categoriesWithSubCategories: null,  */
        edit: false,   
        opened: false,
      };
  
      this.handleEditClick = this.handleEditClick.bind(this);
      this.handleOpen = this.handleOpen.bind(this);
  
    }

    handleOpen ( ) {
      let opened = this.state.opened;
      this.setState ( { opened: !opened } );
    }

    handleEditClick ( ) {
      let cEdit = !this.state.edit;
      this.setState({ edit: !this.state.edit });
    }

    render() {

      let row = {};
      if ( this.props.row ) row = this.props.row;

      let locationName = "Undefined";
      if ( row.location ) locationName = row.location.name;
      let createdOn = "Undefined";
      if ( row.createdOn ) createdOn = row.createdOn;
      let dateFrom = "Undefined";
      if ( row.dateFrom ) dateFrom = row.dateFrom;
      let dateTo = "Undefined";
      if ( row.dateTo ) dateTo = row.dateTo;
      let description = "Undefined";
      if ( row.description ) description = row.description;
      let withVAT = "Undefined";
      if ( row.withVAT ) withVAT = row.withVAT;
      let currency = "Undefined";
      if ( row.currency !== null && row.currency !== undefined ) currency = BackOfficeConfig.CURRENCIES [ row.currency ];
      let version = "Undefined";
      if ( row.version !== null && row.version !== undefined ) version = row.version;
      let isActive = false;
      if ( row.isActive !== null && row.isActive !== undefined ) isActive = row.isActive;



      let img = noImage;
      if ( row.image ) img = row.image;
      let img_off = noImage;
      if ( row.image_off ) img_off = row.image_off;

      let products = [];
      if ( row.productPrice_list ) {
          let productsObject = Object.entries ( row.productPrice_list );
          if ( productsObject.length > 0 ) products = productsObject;
      }
      let pImage = noImage;

      let combos = [];
      if ( row.productCombo_list ) {          
          let combosObject = Object.entries ( row.productCombo_list );
          if ( combosObject.length > 0 ) combos = combosObject;
      }
      let cImage = noImage;

      /* products.map((pData) => {
        console.log ( pData );
      }); */

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>

          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => this.handleOpen()}
            >
              {this.state.opened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>          
          </TableCell>

          <TableCell align="left">
            {row.isActive ?
              <Checkbox  color="success" checked isDisabled={this.state.edit} />
              :
              <Checkbox checkedIcon={<CheckCircleOutlineIcon/>} icon={<BrowserNotSupportedIcon/>} color="default" disabled/>
            }
          </TableCell>

          <TableCell component="th" scope="row">
            {this.state.edit ?
              <TextField id="outlined-basic" label={locationName} variant="outlined" />
              : locationName
            }
          </TableCell>

          <TableCell align="left">
            {this.state.edit ?
              <TextField id="outlined-basic" label={createdOn} variant="outlined" />
              : createdOn
            }
          </TableCell>

          <TableCell align="left" sx={{minWidth: 72}}>
            {this.state.edit ?
              <TextField id="outlined-basic" label={dateFrom} variant="outlined" />
              : dateFrom
            }
          </TableCell>

          <TableCell align="left" sx={{minWidth: 72}}>
            {this.state.edit ?
              <TextField id="outlined-basic" label={dateTo} variant="outlined" />
              : dateTo
            }
          </TableCell>

          <TableCell align="left">
            {this.state.edit ?
              <TextField id="outlined-basic" label={description} variant="outlined" />
              : description
            }
          </TableCell>

          <TableCell align="left">
            {row.withVAT ?
              <Checkbox  color="success" checked isDisabled={this.state.edit} />
              :
              <Checkbox checkedIcon={<CheckCircleOutlineIcon/>} icon={<BrowserNotSupportedIcon/>} color="default" disabled/>
            }
          </TableCell>

          <TableCell align="left">
            {this.state.edit ?
              <TextField id="outlined-basic" label={currency} variant="outlined" />
              : currency
            }
          </TableCell>

          <TableCell align="left">
            {this.state.edit ?
              <TextField id="outlined-basic" label={version} variant="outlined" />
              : version
            }
          </TableCell>

          <TableCell align="right">
            <IconButton aria-label="settings">
                <EditIcon
                  sx={{ color: this.state.edit ? "#8f241d" : "#e3e3e5" }}
                  onClick={this.handleEditClick}
                />
            </IconButton>
            {this.state.edit ?
              <IconButton aria-label="settings">
              <SaveIcon
                sx={{ color: "#4287f5" }}
                onClick={this.handleEditClick}
              />
          </IconButton>
            : ""}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, width: "100% !important", minWidth: 700 }} colSpan={6}>
            
            <Collapse in={this.state.opened} timeout="auto" unmountOnExit sx={{width: "100% !important"}}>

              <Box sx={{ margin: 1, minWidth: 700, width: "100% !important" }}>
                <Typography variant="body1" gutterBottom component="div">
                  Products
                </Typography>
                <Table size="small" aria-label="purchases" sx={{minWidth: 700, backgroundColor: "#eeeee4"}}>
                  <TableHead sx={{backgroundColor: "#ddddd4"}}>
                    <TableRow>
                      <TableCell align="left">Image</TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">SKU</TableCell>
                      <TableCell align="left">Description</TableCell>
                      <TableCell align="left">Unit type</TableCell>
                      <TableCell align="left">Class/Category</TableCell>
                      <TableCell align="left">Value</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.map((pData) => (
                      
                      <ProductRow
                        key = {pData[1].iref}
                        name = {pData[1].product.name}
                        description = {pData[1].product.description}
                        img = {pData[1].product.productImage_product}
                        sku = {pData[1].product.sku}
                        unitType = {pData[1].product.unitType}
                        pClass = {pData[1].product.pClass}
                        pCategory = {pData[1].product.pCategory}
                        pSubCategory = {pData[1].product.pSubCategory}
                        value = {pData[1].value}
                      >                        
                      </ProductRow>                      
                    ))}
                    
                  </TableBody>
                </Table>
              </Box>
              <Box sx={{ margin: 1 }}>
                <Typography variant="body1" gutterBottom component="div">
                  Combos
                </Typography>
                <Table size="small" aria-label="purchases" sx={{minWidth: 700, backgroundColor: "#eeeee4"}}>
                  <TableHead sx={{backgroundColor: "#ddddd4"}}>
                    <TableRow>
                      <TableCell align="left">Image</TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">SKU</TableCell>
                      <TableCell align="left">Description</TableCell>
                      {/* <TableCell align="left">Unit type</TableCell> */}
                      {/* <TableCell align="left">Class/Category</TableCell> */}
                      <TableCell align="left">Value</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {combos.map((cData) => (
                      <ComboRow

                      key = {cData[1].iref}
                      name = {cData[1].productCombo.name}
                      description = {cData[1].productCombo.description}
                      img = {cData[1].productCombo.comboImage_productCombo}
                      sku = {cData[1].productCombo.sku}

                      /* unitType = {cData[1].productCombo.unitType} */
                      pClass = {cData[1].productCombo.pClass}
                      /* pCategory = {cData[1].productCombo.pCategory} */
                      /* pSubCategory = {cData[1].productCombo.pSubCategory} */
                      value = {cData[1].value}
                      >                        
                      </ComboRow>                      
                    ))}
                    
                  </TableBody>
                </Table>

              </Box>
            </Collapse>

          </TableCell>

        </TableRow>


      </React.Fragment>
    );
  }
}

class ListsPrice extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ), 
      addNewList: false, 
      qData: null,
      locations: {},
    };

    this.applyFilters = this.applyFilters.bind(this);    
    this.toggleAdd = this.toggleAdd.bind(this);
  }

  componentDidMount() {    
    let queryData = {};

    /* let pCategory = this.state.client.getData ( Config.API_URL_PRODUCT_CATEGORY, queryData );
    this.setState ( { categories: pCategory.productCategories });

    let pSubCategory = this.state.client.getData ( Config.API_URL_PRODUCT_SUBCATEGORY, queryData );
    this.setState ( { subCategories: pSubCategory.productSubCategories }); */    

    let data = this.state.client.getData ( Config.API_URL_LOCATIONS_SIMPLE, queryData );
    this.setState ( { locations: data.locations });  

    queryData [ "orderBy_Name" ] = true;
    let qData = this.state.client.getData ( Config.API_URL_LIST_PRICE, queryData );
    this.setState ( { qData: qData.priceList });    
  }

  //Function to change the main Products filter state
  applyFilters ( queryData ) {
    if ( queryData ) {
      ////console.log ( queryData );
      queryData [ "orderBy_Name" ] = true;
      let data = this.state.client.filterLists ( Config.API_URL_LIST_PRICE, queryData );
      this.setState ( { qData: data.priceList });
    }
  }

  toggleAdd ( ) {
    let doAdd = this.state.addNewList;
    this.setState ( {addNewList: !doAdd} );
  }

  render() {

    let rows = "";
    let listsCounter = 0;
    if ( this.state.qData ) {
      if ( this.state.qData.length > 0 ) {
        listsCounter = this.state.qData.length;
        rows = this.state.qData.map((row) => {
          return <Row
            key={row.iref}
            row={row}
            />
        });
      }
    }

    return (
      <div style={{ backgroundColor: "#273442", height: "100%" }}>
        <div className='sticky'>
          <ListsPriceFilters
              applyFilters = {this.applyFilters}
              listsCounter = {listsCounter}
              toggleAdd = {this.toggleAdd}
              /*
              applyCategory = {this.applyCategory}
              countries = {countries}
              counties = {counties}
              locationsGroups={groups}
               */
              //sx={{ position: 'sticky', top: 0}}
              locations = {this.state.locations}
            />
        </div>
        <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>
            <TableRow sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>
              <TableCell sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}} />
              <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Status</TableCell>
              <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Location Name</TableCell>
              <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Modified On</TableCell>
              <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Date From</TableCell>
              <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Date To</TableCell>
              <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Description</TableCell>
              <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>withVAT</TableCell>
              <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Currency</TableCell>
              <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Version</TableCell>
              <TableCell align="right" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.addNewList ? 
              <Row
                key="0"
              />
            : ""
            }
            {rows}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
    );
  }
}

export default ListsPrice;
