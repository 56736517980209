import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import noImage from './images/noImage.png';
import idle from './images/icons/idle_on.png';
import registered from './images/icons/registered_on.png';
import active from './images/icons/active_on.png';
import discontinued from './images/icons/discontinued_on.png';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BrowserNotSupportedIcon from '@mui/icons-material/BrowserNotSupported';
import Avatar from '@mui/material/Avatar';
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import swal from 'sweetalert';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import DropDown from './components/DropDown';
import Config from './components/ClientConfig';
import Client from './components/Client';
import BackOfficeConfig from './Config';

import RecomFilters from './components/RecomFilters';
import ProductsFilters from './components/ProductsFilters';
/*
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
*/
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


class RecommendsRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ), 
      edit: false,
      row: {},
      productsSearch: [],
      product: {},
      recommendedBy: ""
    };

    this.handleEditClick = this.handleEditClick.bind(this);
    this.productsSearch = this.productsSearch.bind(this);
    this.productChange = this.productChange.bind(this);
    this.pushData = this.pushData.bind(this);
  }

  componentDidMount () {
    if (this.props) {
      if (this.props.isNew === true) {
        this.handleEditClick();
      }
      if (this.props.row)
        this.setState ( {row: this.props.row } );
      if (this.props.saveURL) this.setState ( { saveURL: this.props.saveURL } );
      if (this.props.recommendedBy) this.setState ( { recommendedBy: this.props.recommendedBy } );
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps !== this.props) {
      if (this.props) {
        if (this.props.row) this.setState ( {row: this.props.row } );
        if (this.props.saveURL) this.setState ( { saveURL: this.props.saveURL } );
        if (this.props.recommendedBy) this.setState ( { recommendedBy: this.props.recommendedBy } );
      }
    }
  }

  handleEditClick ( ) {
    let cEdit = !this.state.edit;
    this.setState({ edit: !this.state.edit });
  }

  productsSearch ( e ) {
    if ( e.target.value.length > 3 ) {
      let queryData = {
        "nameLike" : e.target.value,
        "recommendedBy": this.state.recommendedBy
      };
      let data = this.state.client.filterProducts ( queryData );
      let newProducts = [];
      let users = [];
      if (data) {
        if (data.products) {
          if ( data.products.length > 0 ) {
            Object.keys(data.products).forEach(key => {
              let fData = data.products [ key ];
              let name = fData.name;
              let description = fData.description;          
              let lineText = fData.iref + " - " + name + "[" + description + "]";
              if ( fData.pCategory )
                if ( fData.pCategory.name )
                  lineText += " (" + fData.pCategory.name;
              if ( fData.pSubCategory )
                if ( fData.pSubCategory.name )
                  lineText += "/" + fData.pSubCategory.name + ")";
                else
                  lineText += ")";
              else
                lineText += ")";

              let newObj = { key: fData.iref, label: lineText };
              newProducts.push ( newObj );
              users.push ( fData );
              //counties [ fData.iref ] = fData.name;      
            });
          }
        }
      }
      this.setState ( { productsSearch: newProducts });
    }
  }

  productChange ( e, v, productIref, recommIref ) {
    let queryData = { iref: v.key };
    let data = this.state.client.getData ( Config.API_URL_PRODUCT, queryData );
    let product = {};
    if ( data.products ) {
      if ( data.products.length == 1 ) product = data.products [ 0 ];
    }
    
    if ( product ) {
      this.setState ( { product: product } );
    }    
  }

  pushData ( ) {

  }

  render() {
    let iref = "";
    let key = "";
    let name = "blah";
    let description = "";
    let status = 0;
    let sku = "";

    let pImage = noImage;
    let classImage = noImage;
    let pCategoryName = "";
    let pCategoryImage = noImage;
    let pSubCategoryName = "";
    let pSubCategoryImage = noImage;
    let product_status_alt = "";
    let product_status_image = noImage;

    let product_iref = "";
    if ( this.props ) {
      if (this.props.row) {
        let row = this.props.row;

        if (row.iref) iref = row.iref;
        if (row.name) name = row.name;
        if (row.description) description = row.description;
        if (row.status !== undefined ) status = row.status;
        if (row.sku) sku = row.sku;

        product_iref = iref;

        if ( row.pCategory ) {
          pCategoryImage = row.pCategory.image;
          pCategoryName = row.pCategory.name;
        }

        if ( row.pSubCategory ) {
          pSubCategoryImage = row.pSubCategory.image;
          pSubCategoryName = row.pSubCategory.name;
        }

        let pImg = row.productImage_product;
        if ( pImg ) {
          if ( pImg.length > 0 ) {
            pImage = pImg [ 0 ].image;
            for ( var i = 0; i < pImg.length; i++ ) {
              if ( pImg [ i ].isMain === true ) pImage = pImg [ i ].image;
            }
          }        
        }

        switch ( row.status ) {
          case 0:
            product_status_image = registered;
            product_status_alt = "Registered";
            break;
          case 1:
            product_status_image = idle;
            product_status_alt = "Idle";
            break;
          case 2:
            product_status_image = active;
            product_status_alt = "Active";
            break;
          case 9:
            product_status_image = discontinued;
            product_status_alt = "Discontinued";
            break;
        }

      }
 
    }

    let productsSearch = [ ];
    if ( this.state.productsSearch )
      productsSearch = this.state.productsSearch;

    return (
      <React.Fragment>
        <TableRow key={key}>

        {!this.state.edit ?
          <TableCell align="left">{iref}</TableCell>
          : <TableCell/>
        }
        {!this.state.edit ?
          <TableCell align="left">
            <Box
              component="img"
              sx={{
                height: 48,
                width: 48,
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
                border: '1px dashed grey'
              }}
              alt="No Image"
              src={pImage}
            />
          </TableCell>
          : <TableCell/>
        }

        {this.state.edit ?
            <Autocomplete
              disablePortal
              id="product"
              key={product_iref}
              name="Product"
              options={productsSearch}
              sx={{ width: "300px", marginTop: 1 }}
              onChange={(e,v) => this.productChange(e, v, product_iref, iref)}
              renderInput = {
                (params) =>
                  <TextField 
                    {...params}
                    label="Product (type keywords)"
                    onChange={this.productsSearch}
                  />}
            /> :
            <TableCell component="td" scope="row">{name}</TableCell>
          }
          {!this.state.edit ?
            <TableCell sx={{ minWidth: 96 }} >
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Avatar title={pCategoryName} alt={pCategoryName} src={pCategoryImage} sx={{ width: 48, height: 48 }}/>
                <Avatar title={pSubCategoryName} alt={pSubCategoryName} src={pSubCategoryImage} sx={{ width: 48, height: 48 }}/>
              </Box>
            </TableCell>
            : 
            <TableCell/>
          }
          {!this.state.edit ?
            <TableCell>{description}</TableCell>
            : <TableCell/>
          }

          {this.state.edit ?
            <TableCell/>
            :
            <TableCell>
              <Avatar alt={product_status_alt} src={product_status_image} title={product_status_alt}></Avatar>
            </TableCell>
          }

          {!this.state.edit ?
            <TableCell>{sku}</TableCell>
            : <TableCell/>
          }

          <TableCell align="right">
            <IconButton aria-label="settings">
                <EditIcon
                  sx={{ color: this.state.edit ? "#8f241d" : "#e3e3e5" }}
                  onClick={this.handleEditClick}
                />
            </IconButton>
            {this.state.edit ?
              <IconButton aria-label="settings">
              <SaveIcon
                sx={{ color: "#4287f5" }}
                onClick={this.handleEditClick}
              />
          </IconButton>
            : ""}
          </TableCell>
        </TableRow>
      </React.Fragment>
      
    );

  }

}

class Row extends React.Component {

    constructor(props) {
      super(props);
  
      this.state = {
        client: new Client ( "none" ), 
        edit: false,   
        opened: false,
        productsSearch: [],
        product: {},
        row: {
          active: false,
        },
        saveURL: "",
        active: false,
        addProduct: false,
        isNew: false
      };
  
      this.handleEditClick = this.handleEditClick.bind(this);
      this.handleOpen = this.handleOpen.bind(this);
      this.productsSearch = this.productsSearch.bind(this);
      this.productChange = this.productChange.bind(this);
      this.pushData = this.pushData.bind(this);
      this.changeStatus = this.changeStatus.bind(this);
      this.toggleAdd = this.toggleAdd.bind(this);
      this.productUpdated = this.productUpdated.bind(this);
      this.showConfirmDialog = this.showConfirmDialog.bind(this);
      this.removeConfirm = this.removeConfirm.bind(this);
      this.removeCancel = this.removeCancel.bind(this);
    }

    componentDidMount () {
      if (this.props) {
        if (this.props.isNew === true) {
          this.setState ({isNew: true});
          this.handleEditClick();
        }
        if (this.props.row)
          this.setState ( {row: this.props.row } );
        if (this.props.saveURL) this.setState ( { saveURL: this.props.saveURL } );
      }
    }

    componentDidUpdate (prevProps) {
      if (prevProps !== this.props) {
        if (this.props) {
          /* if (this.props.isNew === true) {
            this.handleEditClick();
          } */
          if (this.props.row) this.setState ( {row: this.props.row } );
          if (this.props.saveURL) this.setState ( { saveURL: this.props.saveURL } );
        }
      }
    }

    toggleAdd ( ) {
      let doAdd = this.state.addProduct;
      this.setState ( {addProduct: !doAdd} );
    }

    handleOpen ( ) {
      let opened = this.state.opened;
      this.setState ( { opened: !opened } );
    }

    handleEditClick ( ) {
      let cEdit = !this.state.edit;
      this.setState({ edit: !this.state.edit });
    }

    productsSearch ( e ) {
      if ( e.target.value.length > 3 ) {
        let queryData = {
          "nameLike" : e.target.value,
          "noRecommends": 1
        };
        let data = this.state.client.filterProducts ( queryData );
        let newProducts = [];
        let users = [];
        if (data) {
          if (data.products) {
            if ( data.products.length > 0 ) {
              Object.keys(data.products).forEach(key => {
                let fData = data.products [ key ];
                let name = fData.name;
                let description = fData.description;          
                let lineText = fData.iref + " - " + name + "[" + description + "]";
                if ( fData.pCategory )
                  if ( fData.pCategory.name )
                    lineText += " (" + fData.pCategory.name;
                if ( fData.pSubCategory )
                  if ( fData.pSubCategory.name )
                    lineText += "/" + fData.pSubCategory.name + ")";
                  else
                    lineText += ")";
                else
                  lineText += ")";
  
                let newObj = { key: fData.iref, label: lineText };
                newProducts.push ( newObj );
                users.push ( fData );
                //counties [ fData.iref ] = fData.name;      
              });
            }
          }
        }
        this.setState ( { productsSearch: newProducts });
      }
    }

    productChange ( e, v, productIref, recommIref ) {
      let queryData = { iref: v.key };
      let data = this.state.client.getData ( Config.API_URL_PRODUCT, queryData );
      let product = {};
      if ( data.products ) {
        if ( data.products.length == 1 ) product = data.products [ 0 ];
      }
      
      if ( product ) {
        this.setState ( { product: product } );
      }    
    }

    pushData ( e ) {
      let data = {}
      if (this.state.row) {
        data.iref = this.state.row.iref;
        data.active = this.state.row.active;
      }
      if (this.state.product) {
        if (this.state.product.iref) {
          data.productIref = this.state.product.iref;
        }
      }   

      let rdata = this.state.client.pushData ( this.state.saveURL, data );
      if ( rdata ) {
        if ( rdata ) {
          this.handleEditClick ( e );
          swal ( "Success", "Successfully added/modified recommendation ! (" + rdata.iref + ")", "success", {
            buttons: false,
            timer: 2000,
          })
          .then((value) => {
            this.setState ( { addNew: false } );
            this.props.recommendationUpdated();
            this.setState ( { somethingHasChanged: false } );            
          });        
        } else {
          if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
          swal ( "Error", "Add/Update recommendation failed !", "error" );
        }
      }
    }

    changeStatus ( e ) {
      let row = this.state.row;
      let nActive = !row.active;
      row.active = nActive;
      this.setState ({row: row});
    }

    productUpdated ( ) {

    }

    showConfirmDialog ( e ) {
      this.setState ( { showConfirm: true } );
      this.setState ( { removeIref: e } );
    }

    removeConfirm ( iref ) {
      let removeIref = this.state.removeIref;
      this.removeImage ( removeIref );
      this.setState ( { showConfirm: false } );
      this.setState ( { removeIref: "" } );
    }
  
    removeCancel ( ) {
      this.setState ( { showConfirm: false } );
      this.setState ( { removeIref: "" } );
    }

    render() {

      let row = {};
      if ( this.state.row ) row = this.state.row;

      let iref = "";
      let productName = "Undefined";
      let pImage = noImage;
      let pCategoryImage = noImage;
      let pCategoryName = "Undefined";
      let pSubCategoryImage = noImage;
      let pSubCategoryName = "Undefined";
      let productDescription = "Undefined";
      let productSKU = "Undefined";
      let productActive = false;
      let active = false;
      let product_iref = "";

      if (row.active !== undefined) active = row.active;

      let product = row.product;
      if ( product ) {
        iref = product.iref;
        product_iref = iref;
        productName = product.name;
        productDescription = product.description;
        productSKU = product.sku;

        if ( product.status === 2 ) productActive = true;

        if ( product.pCategory ) {
          pCategoryImage = product.pCategory.image;
          pCategoryName = product.pCategory.name;
        }

        if ( product.pSubCategory ) {
          pSubCategoryImage = product.pSubCategory.image;
          pSubCategoryName = product.pSubCategory.name;
        }

        let pImg = product.productImage_product;
        if ( pImg ) {
          if ( pImg.length > 0 ) {
            pImage = pImg [ 0 ].image;
            for ( var i = 0; i < pImg.length; i++ ) {
              if ( pImg [ i ].isMain === true ) pImage = pImg [ i ].image;
            }
          }        
        }
      }

      let recommendsCounter = 0;
      if ( row.recommends ) recommendsCounter += row.recommends.length;
    
      let recommendsList = [];
      if ( row.recommends ) {
          let recommendsListObject = Object.entries ( row.recommends );
          if ( recommendsListObject.length > 0 ) recommendsList = recommendsListObject;
      }
      
      let productsSearch = [ ];
        if ( this.state.productsSearch ) productsSearch = this.state.productsSearch;

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>

          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => this.handleOpen()}
            >
              {this.state.opened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>          
          </TableCell>


          {!this.state.edit ?
            <TableCell align="left">{iref}</TableCell>
            : ""
          }
          {!this.state.edit ?
            <TableCell align="left">
              <Box
                component="img"
                sx={{
                  height: 48,
                  width: 48,
                  maxHeight: { xs: 233, md: 167 },
                  maxWidth: { xs: 350, md: 250 },
                  border: '1px dashed grey'
                }}
                alt="No Image"
                src={pImage}
              />
            </TableCell>
            : ""
          }
          {!this.state.edit ?
            <TableCell sx={{ minWidth: 96 }} >
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Avatar title={pCategoryName} alt={pCategoryName} src={pCategoryImage} sx={{ width: 48, height: 48 }}/>
                <Avatar title={pSubCategoryName} alt={pSubCategoryName} src={pSubCategoryImage} sx={{ width: 48, height: 48 }}/>
              </Box>
            </TableCell>
            : ""
          }

          <TableCell align="left">
            <Checkbox id="status" color="success" checked={active} onChange={this.changeStatus} disabled={!this.state.edit}>Status</Checkbox>
          </TableCell>

          {this.state.edit ?
            <Autocomplete
              disablePortal
              id="product"
              key={product_iref}
              name="Product"
              options={productsSearch}
              sx={{ width: "360px", marginTop: 1 }}
              onChange={(e,v) => this.productChange(e, v, product_iref, iref)}
              renderInput = {
                (params) =>
                  <TextField 
                    {...params}
                    label="Product (type keywords)"
                    onChange={this.productsSearch}
                  />}
            /> :
            <TableCell align="left">
              {productName}
            </TableCell>
          }
          {!this.state.edit ?
            <TableCell align="left" sx={{minWidth: 72}}>
              {productDescription}
            </TableCell>
            : ""
          }
          {!this.state.edit ?
            <TableCell align="left">
              {productSKU}
            </TableCell>
            : ""
          }
          {!this.state.edit ?
            <TableCell align="left">
              {recommendsCounter}
            </TableCell>
            : ""
          }

          <TableCell align="right">
            <IconButton aria-label="settings" onClick={this.handleEditClick}>
                <EditIcon
                  sx={{ color: this.state.edit ? "#8f241d" : "#e3e3e5" }}
                />
            </IconButton>
            {this.state.edit && this.state.isNew ?
              <IconButton aria-label="settings" onClick={this.pushData}>
                <SaveIcon
                  sx={{ color: "#4287f5" }}
                />
              </IconButton>
            : ""}
            {this.state.edit && !this.state.isNew ?
              <IconButton aria-label="delete" id={product_iref} onClick={() => {this.showConfirmDialog(product_iref);}}>
                <DeleteForeverIcon
                  sx={{ color: "#8f241d" }}              
                />
              </IconButton>
            : ""}

            <Dialog
              open={this.state.showConfirm}
              TransitionComponent={Transition}
              keepMounted
              onClose={this.removeCancel}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{"Remove product recommendation ?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    This action will only remove the product from the recommendation list.
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.removeCancel}>No</Button>
                <Button onClick={() => {this.removeConfirm(product_iref);}}>Yes</Button>
              </DialogActions>
            </Dialog>

          </TableCell>

        </TableRow>

        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, width: "100% !important", minWidth: 700 }} colSpan={6}>
            
            <Collapse in={this.state.opened} timeout="auto" unmountOnExit sx={{width: "100% !important"}}>

              <Box sx={{ margin: 1, minWidth: 700, width: "100% !important" }}>
                <Typography variant="body1" gutterBottom component="div">
                  Recommended products
                </Typography>
                <Table size="small" aria-label="purchases" sx={{minWidth: 700, backgroundColor: "#eeeee4"}}>
                  <TableHead sx={{backgroundColor: "#ddddd4"}}>
                    <TableRow>
                    <TableCell>
                      <IconButton aria-label="Add Product" title="Add Product" onClick={() => this.toggleAdd()}>       
                        <Avatar alt="Add Product" sx={{ width: 20, height: 20, color: "#273442" }}>
                          {this.state.addProduct ? <FaMinusCircle/> : <FaPlusCircle/>}
                        </Avatar>
                      </IconButton>
                      Iref
                    </TableCell>
                      {/* <TableCell align="left">Iref</TableCell> */}
                      <TableCell align="left">Image</TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Category/SubCategory</TableCell>
                      <TableCell align="left">Description</TableCell>
                      <TableCell align="left">Status</TableCell>
                      <TableCell align="left">SKU</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.addProduct ?
                      <RecommendsRow
                        key = {0}
                        row = {{}}
                        isNew = {true}
                        recommendedBy = {iref}
                        saveURL={Config.API_URL_RECOMMENDATIONS_PRODUCT_ADD}
                        productUpdated={this.productUpdated}
                      />
                      : ""
                    }
                    {recommendsList.map((pData) => (                      
                      <RecommendsRow
                        key = {pData[1].iref}
                        row = {pData[1]}
                        recommendedBy = {iref}
                        saveURL={Config.API_URL_RECOMMENDATIONS_PRODUCT_ADD}
                        productUpdated={this.productUpdated}
                      >                        
                      </RecommendsRow>                      
                    ))}
                    
                  </TableBody>
                </Table>
              </Box>
              
            </Collapse>

          </TableCell>

        </TableRow>


      </React.Fragment>
    );
  }
}

class Recommendations extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ), 
      addNewList: false, 
      productRecommendations: null,
      locations: {},
      //productClasses: null,
      //productCategories: null,
      //productSubCategories: null,
    };

    this.applyFilters = this.applyFilters.bind(this);    
    this.toggleAdd = this.toggleAdd.bind(this);
    this.getData = this.getData.bind(this);
    this.recommendationUpdated = this.recommendationUpdated.bind(this);
  }

  componentDidMount() {    
    this.getData();
  }

  getData ( ) {
    let queryData = {};
    queryData [ "orderBy_Name" ] = true;
    queryData [ "limit" ] = 20;
    let productRecommendations = this.state.client.getData ( Config.API_URL_RECOMMENDATIONS, queryData );
    this.setState ( { productRecommendations: productRecommendations.productRecommendations });
  }

  recommendationUpdated () {
    if (this.state.addNewList)
      this.toggleAdd();
    this.getData();
  }

  //Function to change the main Products filter state
  applyFilters ( queryData ) {
    if ( queryData ) {
      queryData [ "orderBy_name" ] = true;
      queryData [ "limit" ] = 20;
      let productRecommendations = this.state.client.getData ( Config.API_URL_RECOMMENDATIONS, queryData );
      this.setState ( { productRecommendations: productRecommendations.productRecommendations });
    }
  }

  toggleAdd ( ) {
    let doAdd = this.state.addNewList;
    this.setState ( {addNewList: !doAdd} );
  }

  render() {

    let productsCounter = 0;
    if ( this.state.productRecommendations ) {
      productsCounter = this.state.productRecommendations.length;
    }

    let rows = "";
    let listsCounter = 0;
    if ( this.state.productRecommendations ) {
      if ( this.state.productRecommendations.length > 0 ) {
        listsCounter = this.state.productRecommendations.length;
        rows = this.state.productRecommendations.map((row) => {
          return <Row
            key={row.iref}
            row={row}
            recommendationUpdated={this.recommendationUpdated}
            saveURL={Config.API_URL_RECOMMENDATIONS_ADD}
            />
        });
      }
    }

    return (
      <div style={{ backgroundColor: "#273442", height: "100%" }}>
        <div className='sticky'>
          <RecomFilters
              applyFilters = {this.applyFilters}
              listsCounter = {listsCounter}
              toggleAdd = {this.toggleAdd}
              applyCategory = {this.applyCategory}
              classesList = {this.state.productClasses}
              categoriesList = {this.state.productCategories}
              subCategoriesList = {this.state.productSubCategories}
              allergensList = {this.state.allergens}
              productsCounter = {productsCounter}
            />
        </div>
        <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>
            <TableRow sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>
              

              <TableCell>
                <IconButton aria-label="Add Recommendations" title="Add Recommendations" onClick={() => this.toggleAdd()}>       
                  <Avatar alt="Add Recommendations" sx={{ width: 24, height: 24, color: "#273442" }}>
                    {this.state.addNewList ? <FaMinusCircle/> : <FaPlusCircle/>}
                  </Avatar>
                </IconButton>
              </TableCell>

              <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Iref</TableCell>
              <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Image</TableCell>
              <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Category/SubCategory</TableCell>
              <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Status</TableCell>
              <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Product Name</TableCell>
              <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Description</TableCell>
              <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>SKU</TableCell>
              <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Recom Counter</TableCell>
              <TableCell align="right" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.addNewList ? 
              <Row
                key="0"
                isNew={true}
                saveURL={Config.API_URL_RECOMMENDATIONS_ADD}
                recommendationUpdated={this.recommendationUpdated}
              />
            : ""
            }
            {rows}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
    );
  }
}

export default Recommendations;
