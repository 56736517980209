import React from 'react';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ResponsiveAppBar from './TopBar'

import Client from './components/Client';
import Config from './components/ClientConfig';
//import BackOfficeConfig from './Config';

import Stats from './Stats';
import Products from './Products';
import Combos from './Combos';
import Categories from './Categories';
import Classes from './Classes';
import Profile from './Profile';
import Locations from './Locations';
import LocationsGroups from './LocationsGroups';
import Employees from './Employees';

import ListsPrice from './ListsPrice';
import ListsPromotion from './ListsPromotion';
import ListsCompany from './ListsCompany';
import ListsProduct from './ListsProduct';
import ListsCombo from './ListsCombo';
import ListsAcquisition from './ListsAcquisition'
import UsersInternal from './UsersInternal'
import UsersClients from './UsersClients'
import Persons from './Persons'
import Companies from './Companies'

import ConfigPlatform from './ConfigPlatform'
import ConfigSalesApp from './ConfigSalesApp'
import ConfigMobileApp from './ConfigMobileApp'
import ConfigWebsite from './ConfigWebsite'
import ConfigIdentity from './ConfigIdentity'

import Recommendations from './Recommendations'

import ClientsAccounts from './ClientsAccounts'
import ClientsFamilies from './ClientsFamilies'
import ClientsProfessionals from './ClientsProfessionals'

import RapClients from './RapClients'
import RapSales from './RapSales'
import RapSales2 from './RapSales2'
import RapProducts from './RapProducts'
import RapProducts2 from './RapProducts2'
import RapOrders from './RapOrders';

import './css/sideBar.scss';
import './css/dashboard.css';
import { height } from '@mui/system';

import SideBar from './components/SideBar';

import Visibility from './Visibility';


/* let theme = createTheme();

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    //marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  large: {
    //width: theme.spacing(20),
    //height: theme.spacing(20),
  },
})); */

//const classes = useStyles();

//export default function Dashboard() {

class Dashboard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      mainContent: "stats",
      mainContentElement: "",
      sideBar_collapsed: false,
      geoData: {},
      hideSideBar: false,

    };

    this.restricted = new Visibility ("dashboard");

    this.toggleSideCollapsed = this.toggleSideCollapsed.bind(this);
    this.setActiveContent = this.setActiveContent.bind(this);
    this.toggleSideBarCollapsed = this.toggleSideBarCollapsed.bind(this);

  }
  //console.log ( "-- Dashboard -- " );
  //const classes = useStyles();
  //const [anchorEl, setAnchorEl] = React.useState(null);
  //const open = Boolean(anchorEl);

  /* let sUser = '{ "fname":"Bula", "lname":"Ionel" }';
  const user = JSON.parse(sUser); */

  componentDidMount() {
    //this.handleProfile ( );
    /* let queryData = {
      "status" : 2
    } */
    let queryData = {}
    let data = this.state.client.getGeoData (  );
    this.setState ( { geoData: data });

    queryData [ "orderBy" ] = "countryName";
    let lData = this.state.client.getData ( Config.API_URL_LANGUAGE, queryData );
    this.setState ( { languages: lData.Languages } );

    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({hideSideBar: window.innerWidth <= 760});
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }



  toggleSideCollapsed ( collapsed ) {
    this.setState({ sideBar_collapsed: collapsed });
  }

  setActiveContent ( e ) {
    this.setState({ mainContent : e })
  }

  toggleSideBarCollapsed (state) {
    this.setState ({sideBar_collapsed: true});
    this.setState ({hideSideBar: !state});
  }

  render() {
    if (!this.restricted.scope.visible)
      return (<div></div>);

    let mainContentElement;
    switch ( this.state.mainContent ) {
      case "stats":
        mainContentElement = <Stats />;
        break;
      case "profile":
        mainContentElement = <Profile />;
        break;
      case "products":
        mainContentElement = <Products />;
        break;
      case "combos":
        mainContentElement = <Combos />;
        break;
      case "categories":
        mainContentElement = <Categories />;
        break;
      case "classes":
        mainContentElement = <Classes />;
        break;
      case "locations":
        mainContentElement = <Locations geoData={this.state.geoData}/>;
        break;
      case "locations_groups":
        mainContentElement = <LocationsGroups geoData={this.state.geoData}/>;
        break;
      case "employees":
        mainContentElement = <Employees geoData={this.state.geoData}/>;
        break;

      case "listsPrice":
        mainContentElement = <ListsPrice geoData={this.state.geoData}/>;
        break;
      case "listsPromotion":
        mainContentElement = <ListsPromotion geoData={this.state.geoData}/>;
        break;
      case "listsCompany":
        mainContentElement = <ListsCompany geoData={this.state.geoData}/>;
        break;
      case "listsProduct":
        mainContentElement = <ListsProduct geoData={this.state.geoData}/>;
        break;
      case "listsCombo":
        mainContentElement = <ListsCombo geoData={this.state.geoData}/>;
        break;
      case "listsAcquisition":
        mainContentElement = <ListsAcquisition geoData={this.state.geoData}/>;
        break;

      case "recommendations":
        mainContentElement = <Recommendations geoData={this.state.geoData}/>;
        break;

      case "usersInternal":
        mainContentElement = <UsersInternal geoData={this.state.geoData}/>;
        break;

      case "usersClients":
        mainContentElement = <UsersClients geoData={this.state.geoData}/>;
        break;

      case "addressBook_persons":
        mainContentElement = <Persons geoData={this.state.geoData}/>;
        break;

      case "addressBook_companies":
        mainContentElement = <Companies geoData={this.state.geoData}/>;
        break;

      case "config_platform":
        mainContentElement = <ConfigPlatform/>;
        break;
      case "config_salesapp":
        mainContentElement = <ConfigSalesApp/>;
        break;
      case "config_mobileapp":
        mainContentElement = <ConfigMobileApp/>;
        break;
      case "config_website":
        mainContentElement = <ConfigWebsite/>;
        break;
      case "config_identity":
        mainContentElement = <ConfigIdentity languages={this.state.languages}/>;
        break;

      case "clientsAccounts":
        mainContentElement = <ClientsAccounts geoData={this.state.geoData}/>
        break;
      case "clientsFamilies":
        mainContentElement = <ClientsFamilies/>;
        break;
      case "clientsProfessionals":
        mainContentElement = <ClientsProfessionals/>;
        break;

      case "rap_sales":
          mainContentElement = <RapSales/>;
          break;
      case "rap_sales2":
            mainContentElement = <RapSales2/>;
            break;
      case "rap_products":
            mainContentElement = <RapProducts/>;
            break;
      case "rap_products2":
        mainContentElement = <RapProducts2/>;
        break;        
      case "rap_orders":
        mainContentElement = <RapOrders/>;
        break;                  
      case "rap_clients":
        mainContentElement = <RapClients/>;
        break; 
 

    }

    const content=[
      {
          icon: 'icon-class-name',
          label: 'Label of Item',
          to: '#a-link',
      },
      {
          icon: 'icon-class-name',
          label: 'Second Item',
          content: [
              {
                  icon: 'icon-class-name',
                  label: 'Sub Menu of Second Item',
                  to: '#another-link',
              },
          ],
      },
    ];

    

    //const showSidebar = () => setSidebar(!sidebar);
    //const { sideCollapsed, setSideCollapsed } = this.state;

    let mainContentMarginLeft = "160px";
    if ( this.state.sideBar_collapsed ) mainContentMarginLeft = "50px";
    if ( this.state.hideSideBar ) mainContentMarginLeft = "0px";

    return (

 
      <div style={{ backgroundColor: "#273442", height: "100vh" }}>


        <div className="topBar">
          <ResponsiveAppBar
            toggleSideBarCollapsed = {this.toggleSideBarCollapsed}
          />
        </div>
        
        {!this.state.hideSideBar ?
          <SideBar
            toggleSideCollapsed = {this.toggleSideCollapsed }
            setActiveContent = {this.setActiveContent}
          />
          : ""
        }

        
        <div className="mainContent" style={{ marginLeft: mainContentMarginLeft }}>
          { mainContentElement }
          
        </div>


      </div>

      /* </ThemeProvider> */
    );
  }
}

export default Dashboard;