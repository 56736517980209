import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import noImage from './images/noImage.png';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BrowserNotSupportedIcon from '@mui/icons-material/BrowserNotSupported';
import Avatar from '@mui/material/Avatar';

import Config from './components/ClientConfig';
import Client from './components/Client';
import BackOfficeConfig from './Config';

import ListsProductFilters from './components/ListsProductFilters';
import ProductsFilters from './components/ProductsFilters';
/*
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
*/
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


class PriceRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      //client: new Client ( "none" ),
      edit: false,   
    };

    this.handleEditClick = this.handleEditClick.bind(this);
  }

  handleEditClick ( ) {
    let cEdit = !this.state.edit;
    this.setState({ edit: !this.state.edit });
  }

  render() {
    let key = "";
    let name = "blah";
    let description = "";
    let active = false;
    let withVAT = false;
    let value = 0.0;

    /* let categoryImage = noImage;
    let subCategoryImage = noImage; */

    if ( this.props ) {
      if ( this.props.key ) key = this.props.key;
      if ( this.props.name ) name = this.props.name;      
      if ( this.props.description ) description = this.props.description;
      if ( this.props.active ) active = this.props.active;
      if ( this.props.withVAT ) withVAT = this.props.withVAT;
      if ( this.props.value ) value = this.props.value;      
    }

    return (
      <React.Fragment>
        <TableRow key={key}>
          <TableCell component="td" scope="row">{name}</TableCell>
          <TableCell>{description}</TableCell>
          <TableCell align="left">
            {active ?
              <Checkbox  color="success" checked isDisabled={this.state.edit} />
              :
              <Checkbox checkedIcon={<CheckCircleOutlineIcon/>} icon={<BrowserNotSupportedIcon/>} color="default" disabled/>
            }
          </TableCell>
          <TableCell align="left">
            {withVAT ?
              <Checkbox  color="success" checked isDisabled={this.state.edit} />
              :
              <Checkbox checkedIcon={<CheckCircleOutlineIcon/>} icon={<BrowserNotSupportedIcon/>} color="default" disabled/>
            }
          </TableCell>
          
          <TableCell align="right">
          {this.state.edit ?
            <TextField sx={{ maxWidth: 72 }} id="outlined-basic" label={value} variant="outlined" />
            : value
          }
          </TableCell>
          
               

          <TableCell align="right">
            <IconButton aria-label="settings">
                <EditIcon
                  sx={{ color: this.state.edit ? "#8f241d" : "#e3e3e5" }}
                  onClick={this.handleEditClick}
                />
            </IconButton>
            {this.state.edit ?
              <IconButton aria-label="settings">
              <SaveIcon
                sx={{ color: "#4287f5" }}
                onClick={this.handleEditClick}
              />
          </IconButton>
            : ""}
          </TableCell>
        </TableRow>
      </React.Fragment>
      
    );

  }

}

class Row extends React.Component {

    constructor(props) {
      super(props);
  
      this.state = {
        //client: new Client ( "none" ),  
/*         categoriesWithSubCategories: null,  */
        edit: false,   
        opened: false,
      };
  
      this.handleEditClick = this.handleEditClick.bind(this);
      this.handleOpen = this.handleOpen.bind(this);
  
    }

    handleOpen ( ) {
      let opened = this.state.opened;
      this.setState ( { opened: !opened } );
    }

    handleEditClick ( ) {
      let cEdit = !this.state.edit;
      this.setState({ edit: !this.state.edit });
    }

    render() {

      let row = {};
      if ( this.props.row ) row = this.props.row;

      let productName = "Undefined";
      let pImage = noImage;
      let pCategoryImage = noImage;
      let pCategoryName = "Undefined";
      let pSubCategoryImage = noImage;
      let pSubCategoryName = "Undefined";
      let productDescription = "Undefined";
      let productSKU = "Undefined";
      let productActive = false;

      let product = row.product;
      if ( product ) {
        productName = product.name;
        productDescription = product.description;
        productSKU = product.sku;

        if ( product.status === 2 ) productActive = true;

        if ( product.pCategory ) {
          pCategoryImage = product.pCategory.image;
          pCategoryName = product.pCategory.name;
        }

        if ( product.pSubCategory ) {
          pSubCategoryImage = product.pSubCategory.image;
          pSubCategoryName = product.pSubCategory.name;
        }

        let pImg = product.productImage_product;
        if ( pImg ) {
          if ( pImg.length > 0 ) {
            pImage = pImg [ 0 ].image;
            for ( var i = 0; i < pImg.length; i++ ) {
              if ( pImg [ i ].isMain === true ) pImage = pImg [ i ].image;
            }
          }        
        }
      }

      let listsCounter = 0;
      if ( row.priceLists ) listsCounter += row.priceLists.length;
      if ( row.promotionList ) listsCounter += row.promotionList.length;
      if ( row.companyList ) listsCounter += row.companyList.length;

      let priceLists = [];
      if ( row.priceLists ) {
          let priceListsObject = Object.entries ( row.priceLists );
          if ( priceListsObject.length > 0 ) priceLists = priceListsObject;
      }

      let promotionLists = [];
      if ( row.promotionLists ) {
          let promotionListsObject = Object.entries ( row.promotionLists );
          if ( promotionListsObject.length > 0 ) promotionLists = promotionListsObject;
      }

      let companyLists = [];
      if ( row.companyLists ) {
          let companyListsObject = Object.entries ( row.companyLists );
          if ( companyListsObject.length > 0 ) companyLists = companyListsObject;
      }
      

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>

          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => this.handleOpen()}
            >
              {this.state.opened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>          
          </TableCell>

          <TableCell align="left">
            <Box
              component="img"
              sx={{
                height: 48,
                width: 48,
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
                border: '1px dashed grey'
              }}
              alt="No Image"
              src={pImage}
            />
          </TableCell>

          <TableCell sx={{ minWidth: 96 }} >
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Avatar title={pCategoryName} alt={pCategoryName} src={pCategoryImage} sx={{ width: 48, height: 48 }}/>
              <Avatar title={pSubCategoryName} alt={pSubCategoryName} src={pSubCategoryImage} sx={{ width: 48, height: 48 }}/>
            </Box>
          </TableCell>
          
          <TableCell align="left">
            {productActive ?
              <Checkbox  color="success" checked isDisabled={this.state.edit} />
              :
              <Checkbox checkedIcon={<CheckCircleOutlineIcon/>} icon={<BrowserNotSupportedIcon/>} color="default" disabled/>
            }
          </TableCell>

          

          <TableCell align="left">
            {this.state.edit ?
              <TextField id="outlined-basic" label={productName} variant="outlined" />
              : productName
            }
          </TableCell>

          <TableCell align="left" sx={{minWidth: 72}}>
            {this.state.edit ?
              <TextField id="outlined-basic" label={productDescription} variant="outlined" />
              : productDescription
            }
          </TableCell>
          <TableCell align="left">
            {this.state.edit ?
              <TextField id="outlined-basic" label={productSKU} variant="outlined" />
              : productSKU
            }
          </TableCell>
          <TableCell align="left">
            {this.state.edit ?
              <TextField id="outlined-basic" label={listsCounter} variant="outlined" />
              : listsCounter
            }
          </TableCell>
          <TableCell align="right">
            <IconButton aria-label="settings">
                <EditIcon
                  sx={{ color: this.state.edit ? "#8f241d" : "#e3e3e5" }}
                  onClick={this.handleEditClick}
                />
            </IconButton>
            {this.state.edit ?
              <IconButton aria-label="settings">
              <SaveIcon
                sx={{ color: "#4287f5" }}
                onClick={this.handleEditClick}
              />
          </IconButton>
            : ""}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, width: "100% !important", minWidth: 700 }} colSpan={6}>
            
            <Collapse in={this.state.opened} timeout="auto" unmountOnExit sx={{width: "100% !important"}}>

              <Box sx={{ margin: 1, minWidth: 700, width: "100% !important" }}>
                <Typography variant="body1" gutterBottom component="div">
                  Price Lists
                </Typography>
                <Table size="small" aria-label="purchases" sx={{minWidth: 700, backgroundColor: "#eeeee4"}}>
                  <TableHead sx={{backgroundColor: "#ddddd4"}}>
                    <TableRow>
                      <TableCell align="left">Location name</TableCell>
                      <TableCell align="left">Description</TableCell>
                      <TableCell align="left">Active</TableCell>
                      <TableCell align="left">With VAT</TableCell>
                      <TableCell align="left">Value</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {priceLists.map((pData) => (                      
                      <PriceRow
                        key = {pData[1].iref}
                        name = {pData[1].location.name}
                        description = {pData[1].location.description}
                        active = {pData[1].isActive}
                        withVAT = {pData[1].withVAT}                        
                        value = {pData[1].value}                        
                      >                        
                      </PriceRow>                      
                    ))}
                    
                  </TableBody>
                </Table>
              </Box>

              <Box sx={{ margin: 1, minWidth: 700, width: "100% !important" }}>
                <Typography variant="body1" gutterBottom component="div">
                  Promotion Lists
                </Typography>
                <Table size="small" aria-label="purchases" sx={{minWidth: 700, backgroundColor: "#eeeee4"}}>
                  <TableHead sx={{backgroundColor: "#ddddd4"}}>
                    <TableRow>
                      <TableCell align="left">Location name</TableCell>
                      <TableCell align="left">Description</TableCell>
                      <TableCell align="left">Active</TableCell>
                      <TableCell align="left">With VAT</TableCell>
                      <TableCell align="left">Value</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {promotionLists.map((pData) => (                      
                      <PriceRow
                        key = {pData[1].iref}
                        name = {pData[1].location.name}
                        description = {pData[1].location.description}
                        active = {pData[1].isActive}
                        withVAT = {pData[1].withVAT}                        
                        value = {pData[1].value}                        
                      >                        
                      </PriceRow>                      
                    ))}
                    
                  </TableBody>
                </Table>
              </Box>

              <Box sx={{ margin: 1, minWidth: 700, width: "100% !important" }}>
                <Typography variant="body1" gutterBottom component="div">
                  Company Lists
                </Typography>
                <Table size="small" aria-label="purchases" sx={{minWidth: 700, backgroundColor: "#eeeee4"}}>
                  <TableHead sx={{backgroundColor: "#ddddd4"}}>
                    <TableRow>
                      <TableCell align="left">Company name</TableCell>
                      <TableCell align="left">ID</TableCell>
                      <TableCell align="left">Active</TableCell>
                      <TableCell align="left">With VAT</TableCell>
                      <TableCell align="left">Value</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {companyLists.map((pData) => (                      
                      <PriceRow
                        key = {pData[1].iref}
                        name = {pData[1].company.name}
                        description = {pData[1].company.cuid}
                        active = {pData[1].isActive}
                        withVAT = {pData[1].withVAT}                        
                        value = {pData[1].value}                        
                      >                        
                      </PriceRow>                      
                    ))}
                    
                  </TableBody>
                </Table>
              </Box>
              
            </Collapse>

          </TableCell>

        </TableRow>


      </React.Fragment>
    );
  }
}

class ListsProduct extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ), 
      addNewList: false, 
      qData: null,
      locations: {},
      productClasses: null,
      productCategories: null,
      productSubCategories: null,
    };

    this.applyFilters = this.applyFilters.bind(this);    
    this.toggleAdd = this.toggleAdd.bind(this);
    this.applyCategory = this.applyCategory.bind(this);
    this.setAllergens = this.setAllergens.bind(this);
    this.setCategories = this.setCategories.bind(this);
    this.setSubCategories = this.setSubCategories.bind(this);    
  }

  componentDidMount() {    
    let queryData = {};

    let data = this.state.client.getData ( Config.API_URL_LOCATIONS_SIMPLE, queryData );
    this.setState ( { locations: data.locations });

    let productsClasses = this.state.client.getProductsClasses ( );
    this.setClasses ( productsClasses.productClasses );

    let productsCategories = this.state.client.getProductsCategories ( );
    //this.setState ( { productCategories: productsCategories.productCategories });
    this.setCategories ( productsCategories.productCategories );

    let productsSubCategories = this.state.client.getProductsSubCategories ( );
    //this.setState ( { productSubCategories: productsSubCategories.productSubCategories });
    this.setSubCategories ( productsSubCategories.productSubCategories );

    let allergens = this.state.client.getAllergens ( );
    //this.setState ( { allergens: allergens.Allergens });
    this.setAllergens ( allergens.Allergens );

    queryData [ "orderBy_Name" ] = true;
    let qData = this.state.client.getData ( Config.API_URL_LIST_PRODUCT, queryData );
    this.setState ( { qData: qData.pcPriceLists });    
  }

  //Function to change the main Products filter state
  applyFilters ( queryData ) {
    if ( queryData ) {
      ////console.log ( queryData );
      queryData [ "orderBy_Name" ] = true;
      let data = this.state.client.filterProductList ( Config.API_URL_LIST_PRODUCT_FILTER, queryData );
      this.setState ( { qData: data.pcPriceLists });
    }
  }

  toggleAdd ( ) {
    let doAdd = this.state.addNewList;
    this.setState ( {addNewList: !doAdd} );
  }

  setClasses ( classes ) {
    let a = { }
    if ( classes !== null && classes !== undefined ) {
      classes.map ( (productClass) => {
        a [ productClass.iref ] = productClass;        
      });
      this.setState ( { productClasses : a });
    }
  }

  setCategories ( categories ) {
    let a = { }
    if ( categories !== null && categories !== undefined ) {
      categories.map ( (category) => {
        a [ category.iref ] = category;        
      });
      this.setState ( { productCategories : a });
    }
  }

  setSubCategories ( subCategories ) {
    //console.log ( "setSubCategories-111" );
    //console.log (subCategories);
    let a = { }
    if ( subCategories !== null && subCategories !== undefined ) {
      subCategories.map ( (subCategory) => {
        //console.log ( subCategory );
        a [ subCategory.iref ] = subCategory;        
      });
      this.setState ( { productSubCategories : a });
      //console.log ( "setSubCategories" );
      //console.log ( this.state.productSubCategories );
    }
  }

  applyCategory ( category ) {
    if ( category !== undefined && category !== null ) {
      let queryData = { };
      if ( category !== "" ) queryData [ "category" ] = category;        
      let productsSubCategories = this.state.client.getProductsSubCategories ( queryData );
      this.setSubCategories ( productsSubCategories.productSubCategories );
    }
  }

  setAllergens ( allergens ) {
    let a = { }
    if ( allergens !== null && allergens !== undefined ) {
      allergens.map ( (allergen) => {
        a [ allergen.iref ] = allergen;        
      });
      this.setState ( { allergens : a });
    }
  }

  render() {

    let productsCounter = 0;
    if ( this.state.qData ) {
      productsCounter = this.state.qData.length;
    }

    let rows = "";
    let listsCounter = 0;
    if ( this.state.qData ) {
      if ( this.state.qData.length > 0 ) {
        listsCounter = this.state.qData.length;
        rows = this.state.qData.map((row) => {
          return <Row
            key={row.iref}
            row={row}
            />
        });
      }
    }

    return (
      <div style={{ backgroundColor: "#273442", height: "100%" }}>
        <div className='sticky'>
          <ListsProductFilters
              applyFilters = {this.applyFilters}
              listsCounter = {listsCounter}
              toggleAdd = {this.toggleAdd}
              applyCategory = {this.applyCategory}
              classesList = {this.state.productClasses}
              categoriesList = {this.state.productCategories}
              subCategoriesList = {this.state.productSubCategories}
              allergensList = {this.state.allergens}
              productsCounter = {productsCounter}
              /*
              applyCategory = {this.applyCategory}
              countries = {countries}
              counties = {counties}
              locationsGroups={groups}
               */
              //sx={{ position: 'sticky', top: 0}}
              //locations = {this.state.locations}
            />
        </div>
        <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>
            <TableRow sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>
              <TableCell sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}} />
              <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Image</TableCell>
              <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Category/SubCategory</TableCell>
              <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Status</TableCell>
              <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Product Name</TableCell>
              <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Description</TableCell>
              <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>SKU</TableCell>
              <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Lists Counter</TableCell>
              <TableCell align="right" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.addNewList ? 
              <Row
                key="0"
              />
            : ""
            }
            {rows}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
    );
  }
}

export default ListsProduct;
