import React from 'react';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
import { FaHome, FaPizzaSlice, FaHamburger, FaMapMarkedAlt, FaEuroSign, FaUserTie, FaHospitalUser, FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
/* import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'; */
import Client from './../components/Client';
import Allergen from './Allergen';
import Category from './Category';
import SubCategory from './SubCategory';
import ProductClass from './ProductClass';

//import './css/dashboard.css';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import noImage from './../images/icons/noImage_1.png';
import idle_off from './../images/icons/idle_on.png';
import idle_on from './../images/icons/idle_off.png';
import registered_off from './../images/icons/registered_on.png';
import registered_on from './../images/icons/registered_off.png';
import active_off from './../images/icons/active_on.png';
import active_on from './../images/icons/active_off.png';
import discontinued_off from './../images/icons/discontinued_on.png';
import discontinued_on from './../images/icons/discontinued_off.png';

import soia_off from './../images/icons/soia_off.png';
import soia_on from './../images/icons/soia_on.png';
import arahide_off from './../images/icons/arahide_off.png';
import arahide_on from './../images/icons/arahide_on.png';
import dioxid_off from './../images/icons/dioxid_off.png';
import dioxid_on from './../images/icons/dioxid_on.png';
import grau_off from './../images/icons/grau_off.png';
import grau_on from './../images/icons/grau_on.png';
import lapte_off from './../images/icons/lapte_off.png';
import lapte_on from './../images/icons/lapte_on.png';
import mustar_off from './../images/icons/mustar_off.png';
import mustar_on from './../images/icons/mustar_on.png';
import oua_off from './../images/icons/oua_off.png';
import oua_on from './../images/icons/oua_on.png';
import peste_off from './../images/icons/peste_off.png';
import peste_on from './../images/icons/peste_on.png';
import porumb_off from './../images/icons/porumb_off.png';
import porumb_on from './../images/icons/porumb_on.png';
import seminte_off from './../images/icons/seminte_off.png';
import seminte_on from './../images/icons/seminte_on.png';

import askPrice_off from './../images/icons/askPrice_off.png';
import askPrice_on from './../images/icons/askPrice_on.png';
import askQuantity_off from './../images/icons/askQuantity_off.png';
import askQuantity_on from './../images/icons/askQuantity_on.png';
import canCombo_off from './../images/icons/canCombo_off.png';
import canCombo_on from './../images/icons/canCombo_on.png';
import canGenerateFidelityPoints_off from './../images/icons/canGenerateFidelityPoints_off.png';
import canGenerateFidelityPoints_on from './../images/icons/canGenerateFidelityPoints_on.png';
import hasPackaging_off from './../images/icons/hasPackaging_off.png';
import hasPackaging_on from './../images/icons/hasPackaging_on.png';
import hasRecipe_off from './../images/icons/hasRecipe_off.png';
import hasRecipe_on from './../images/icons/hasRecipe_on.png';
import isDeliverable_off from './../images/icons/isDeliverable_off.png';
import isDeliverable_on from './../images/icons/isDeliverable_on.png';
import isDiscountable_off from './../images/icons/isDiscountable_off.png';
import isDiscountable_on from './../images/icons/isDiscountable_on.png';
import isForSale_off from './../images/icons/isForSale_off.png';
import isForSale_on from './../images/icons/isForSale_on.png';
import isFractionable_off from './../images/icons/isFractionable_off.png';
import isFractionable_on from './../images/icons/isFractionable_on.png';
import isNew_off from './../images/icons/isNew_off.png';
import isNew_on from './../images/icons/isNew_on.png';
import isPurchased_off from './../images/icons/isPurchased_off.png';
import isPurchased_on from './../images/icons/isPurchased_on.png';
import isSellable_off from './../images/icons/isSellable_off.png';
import isSellable_on from './../images/icons/isSellable_on.png';
import isSoldAlone_off from './../images/icons/isSoldAlone_off.png';
import isSoldAlone_on from './../images/icons/isSoldAlone_on.png';
import selfService_off from './../images/icons/selfService_off.png';
import selfService_on from './../images/icons/selfService_on.png';
import useScale_off from './../images/icons/useScale_off.png';
import useScale_on from './../images/icons/useScale_on.png';

let theme = createTheme();

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

class ListsProductFilters extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      productCategories: null,
      expanded: false,
      addNewProduct: false,
      filters_reset: 1,
      filters_status_active: 0,
      filters_status_idle: 0,
      filters_status_registered: 0,
      filters_status_discontinued: 0,
      filters_allergens_soia: 0,
      filters_allergens_arahide: 0,
      filters_allergens_dioxid: 0,
      filters_allergens_grau: 0,
      filters_allergens_lapte: 0,
      filters_allergens_mustar: 0,
      filters_allergens_oua: 0,
      filters_allergens_peste: 0,
      filters_allergens_porumb: 0,
      filters_allergens_seminte: 0,

      filters_flags_askPrice: 0,
      filters_flags_askQuantity: 0,
      filters_flags_canCombo: 0,
      filters_flags_canGenerateFidelityPoints: 0,
      filters_flags_hasPackaging: 0,
      filters_flags_hasRecipe: 0,
      filters_flags_isDeliverable: 0,
      filters_flags_isDiscountable: 0,
      filters_flags_isForSale: 0,
      filters_flags_isFractionable: 0,
      filters_flags_isNew: 0,
      filters_flags_isPurchased: 0,
      filters_flags_isSellable: 0,
      filters_flags_isSoldAlone: 0,
      filters_flags_selfService: 0,
      filters_flags_useScale: 0,
      filtersCounter: 0,
      resetState: false,
      resetClasses: false,
      resetCategories: false,
      resetCategoriesList: { },
      resetSubCategoriesList: { },
      resetClassesList: { },
      resetSubCategories: false,
      filters: { },

    };

    this.queryData = { };
    this.productStatus = { "registered": 0, "idle": 1, "active": 2, "discontinued": 9 };
    this.searchKeyword = React.createRef();
    this.categoriesStates = { };

    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.resetStatus = this.resetStatus.bind(this);
    this.removeQueryElement = this.removeQueryElement.bind(this);
    this.addQueryElement = this.addQueryElement.bind(this);
    this.toggleElement = this.toggleElement.bind(this);
    this.searchKey = this.searchKey.bind(this);
    this.handleAllergen = this.handleAllergen.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.setFlagsFilters = this.setFlagsFilters.bind(this);
    this.setStatusFilters = this.setStatusFilters.bind(this);
    this.getAllergens = this.getAllergens.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.getSubCategories = this.getSubCategories.bind(this);
    this.getClasses = this.getClasses.bind(this);
    this.toggleAdd = this.toggleAdd.bind(this);

    this.resetAllCategoriesButThis = this.resetAllCategoriesButThis.bind(this);
    
  }

  componentDidMount() {        
    /* let productsCategories = this.state.client.getProductsCategories ( );
    this.setState ( { productCategories: productsCategories.productCategories }); */
  }

  componentWillUnmount() {
    //this.searchKeyword = React.useRef(null);
  }

  /* componentWillReceiveProps ( nextProps ) {
    
  } */

  componentDidUpdate ( prevProps ) {
    if ( prevProps.categoriesList !== this.props.categoriesList ) {
      let categoriesRender = "";
      if ( this.props.categoriesList ) {
        let categoriesArr = Object.entries ( this.props.categoriesList );

        var categoriesStates = this.state.resetCategoriesList;
        categoriesRender = categoriesArr.map ( (category) => {
          let iref = "";
          if ( category.length === 2 ) iref = category [ 1 ].iref;          
          else iref = category [ 0 ].iref;          
          categoriesStates [ iref ] = false;
        });
        this.setState ( {resetCategoriesList: categoriesStates} );
      }
    }

    if ( prevProps.subCategoriesList !== this.props.subCategoriesList ) {
      let subCategoriesRender = "";
      if ( this.props.subCategoriesList ) {
        let subCategoriesArr = Object.entries ( this.props.subCategoriesList );

        var subCategoriesStates = this.state.resetSubCategoriesList;
        subCategoriesRender = subCategoriesArr.map ( (category) => {
          let iref = "";
          if ( category.length === 2 ) iref = category [ 1 ].iref;          
          else iref = category [ 0 ].iref;          
          subCategoriesStates [ iref ] = false;
        });
        this.setState ( {resetSubCategoriesList: subCategoriesStates} );
      }
    }

    if ( prevProps.classesList !== this.props.classesList ) {
      let classesRender = "";
      if ( this.props.classesList ) {
        let classesArr = Object.entries ( this.props.classesList );

        var classesStates = this.state.resetClassesList;
        classesRender = classesArr.map ( (cls) => {
          let iref = "";
          if ( cls.length === 2 ) iref = cls [ 1 ].iref;          
          else iref = cls [ 0 ].iref;          
          classesStates [ iref ] = false;
        });
        this.setState ( {resetClassesList: classesStates} );
      }
    }
  }

  handleExpandClick ( ) {
    this.setState({ expanded: !this.state.expanded });
  }

  setStatusFilters ( value ) {
    this.setState ( {filters_status_active: value} );
    this.setState ( {filters_status_idle: value} );
    this.setState ( {filters_status_registered: value} );
    this.setState ( {filters_status_discontinued: value} );
  }

  setFlagsFilters ( value ) {
    this.setState ( {filters_flags_askPrice: value} );
    this.setState ( {filters_flags_askQuantity: value} );
    this.setState ( {filters_flags_canCombo: value} );
    this.setState ( {filters_flags_canGenerateFidelityPoints: value} );
    this.setState ( {filters_flags_hasPackaging: value} );
    this.setState ( {filters_flags_hasRecipe: value} );
    this.setState ( {filters_flags_isDeliverable: value} );
    this.setState ( {filters_flags_isDiscountable: value} );
    this.setState ( {filters_flags_isForSale: value} );
    this.setState ( {filters_flags_isFractionable: value} );
    this.setState ( {filters_flags_isNew: value} );
    this.setState ( {filters_flags_isPurchased: value} );
    this.setState ( {filters_flags_isSellable: value} );
    this.setState ( {filters_flags_isSoldAlone: value} );
    this.setState ( {filters_flags_selfService: value} );
    this.setState ( {filters_flags_useScale: value} );
  }

  toggleAdd ( ) {
    let addButton = this.state.addNewProduct;
    this.setState ( {addNewProduct: !addButton} );
    this.props.toggleAdd ( );
  }

  searchKey ( e ) {    
    let keyword = e.target.value;
    if ( keyword.length > 2 ) {
      this.addQueryElement ( "nameLike", keyword );
      this.props.applyFilters ( this.queryData );
    } else {
      if ( "nameLike" in this.queryData ) {
        delete this.queryData [ "nameLike" ];
        this.props.applyFilters ( this.queryData );
      }
    }
    let filtersCounter = Object.keys(this.queryData).length - 1;
    this.setState ( {filtersCounter: filtersCounter} );
  }

  resetStatus ( element ) {
    /* if ( "status" in this.queryData )        
      delete this.queryData.status; */
    if ( element !== "active" ) this.setState ( { filters_status_active: 0 } );
    if ( element !== "idle" ) this.setState ( { filters_status_idle: 0 } );
    if ( element !== "registered" ) this.setState ( { filters_status_registered: 0 } );
    if ( element !== "discontinued" ) this.setState ( { filters_status_discontinued: 0 } );
  }

  removeQueryElement ( element ) {
    if ( element ) {
      if ( element in this.queryData ) {
        if ( this.queryData [ element ] !== null && this.queryData [ element ] !== undefined ) {
          delete this.queryData [ element ];
        }
      }
    }
  }

  addQueryElement ( element, value ) {
    //console.log ( "addQueryElement: " + element + " -> " + value );
    if ( element ) {
      if ( element in this.queryData ) {
        if ( this.queryData [ element ] !== null && this.queryData [ element ] !== undefined && value !== null && value !== undefined ) {
          if ( this.queryData [ element ] != value ) this.queryData [ element ] = value;
          else delete this.queryData [ element ];
        } else {
          delete this.queryData [ element ];
        }
      } else {
        if ( value !== null && value !== undefined )
          this.queryData [ element ] = value;
        else
          this.queryData [ element ] = 1;
      }
    }

    //console.log ( "addQueryElement ---> " );
    //console.log ( this.queryData );
  }

  toggleElement ( prefix, element, value ) {
    let stateName = "filters_" + prefix + "_" + element;
    let status = this.state [ stateName ];
    this.setState ( { [stateName]: !status } );
    if ( prefix == "status" ) element = "status";
    if ( value !== null && value !== undefined ) {
      this.addQueryElement ( element, value );
    } else {
      this.addQueryElement ( element );
    }
  }

  handleAllergen ( name ) {
    let allergens = {};
    if ( !this.queryData [ "allergens" ] ) this.queryData [ "allergens" ] = {};    
    allergens = this.queryData [ "allergens" ];
    if ( allergens [ name ] ) delete allergens [ name ];
    else allergens [ name ] = 1;

    if ( Object.keys(allergens).length > 0 ) this.queryData [ "allergens" ] = allergens; //JSON.stringify ( allergens );    
    if ( Object.keys(allergens).length == 0 ) delete this.queryData [ "allergens" ];
  }

  toggleFilter ( filterType, filterName ) {
    ////console.log ( this.state.productCategories );

    switch ( filterType ) {
      case "status":
        this.resetStatus ( filterName );
        let statusValue = this.productStatus [ filterName ];
        this.toggleElement ( "status", filterName, statusValue );
        break;
      case "allergens":
        this.handleAllergen ( filterName );
        break;
      case "flags":
        this.toggleElement ( "flags", filterName );
        break;
      case "categories":
        this.resetAllCategoriesButThis ( filterName );
        this.removeQueryElement ( "pSubCategory" );
        this.resetAllSubCategoriesButThis ( "" );
        this.addQueryElement ( "pCategory", filterName );
        if ( !this.queryData [ "pCategory" ] ) filterName = "";
        this.props.applyCategory ( filterName );
        break;
      case "subcategories":
        this.resetAllSubCategoriesButThis ( filterName );
        this.addQueryElement ( "pSubCategory", filterName );
        break;
      case "classes":
        this.resetAllClassesButThis ( filterName );
        this.addQueryElement ( "pClass", filterName );
        this.setState ( {resetClasses: true});
        break;
    }

    let filtersCounter = Object.keys(this.queryData).length - 1;
    this.setState ( {filtersCounter: filtersCounter} );
    //console.log ( this.queryData );

    //if ( Object.keys(this.queryData).length > 0 ) {
    this.props.applyFilters ( this.queryData );
    this.setState ( {resetState: false});
    this.setState ( {resetClasses: false});
    this.setState ( {resetCategories: false});
    this.setState ( {resetSubCategories: false});
    //}
  }

  resetAllCategoriesButThis ( category ) {
    let categoriesArr = Object.entries ( this.state.resetCategoriesList );
    let cat = {}
    for ( var filter of categoriesArr ) {
      if ( filter [ 0 ] === category ) cat [ filter [ 0 ] ] = false; //this.categoriesStates [ filter ] = false;
      else cat [ filter [ 0 ] ] = true; //this.categoriesStates [ filter ] = true;
    }
    this.setState ( { resetCategoriesList : cat } );
  }

  resetAllSubCategoriesButThis ( subCategory ) {
    let categoriesArr = Object.entries ( this.state.resetSubCategoriesList );
    let cat = {}
    for ( var filter of categoriesArr ) {
      if ( filter [ 0 ] === subCategory ) cat [ filter [ 0 ] ] = false; //this.categoriesStates [ filter ] = false;
      else cat [ filter [ 0 ] ] = true; //this.categoriesStates [ filter ] = true;
    }
    //console.log ( cat );
    this.setState ( { resetSubCategoriesList : cat } );
    //console.log ( this.state.resetSubCategoriesList );
  }

  resetAllClassesButThis ( category ) {
    let classesArr = Object.entries ( this.state.resetClassesList );
    let cat = {}
    for ( var filter of classesArr ) {
      if ( filter [ 0 ] === category ) cat [ filter [ 0 ] ] = false; //this.classesStates [ filter ] = false;
      else cat [ filter [ 0 ] ] = true; //this.classesStates [ filter ] = true;
    }
    this.setState ( { resetClassesList : cat } );
  }

  resetFilters ( ) {
    if ( this.queryData ) {
      this.queryData = { };
      this.queryData [ "orderBy_Name" ] = true;
    }
    
    this.setStatusFilters ( 0 );
    this.setFlagsFilters ( 0 );
    this.setState ( {filters_reset: 1} );
    this.searchKeyword.current.value = "";
    this.setState ( {filtersCounter: 0} );
    this.props.applyFilters ( this.queryData );
    this.setState ( {resetState: true});
    this.setState ( {resetClasses: true});
    this.setState ( {resetCategories: true});
    this.setState ( {resetSubCategories: true});
    this.resetAllClassesButThis ( "" );
    this.resetAllSubCategoriesButThis ( "" );
    this.resetAllCategoriesButThis ( "" );

  }

  getAllergens ( ) {
    let allergensRender = "";
    if ( this.props.allergensList ) {
      let allergensArr = Object.entries ( this.props.allergensList );      
      allergensRender = allergensArr.map ( (allergen) => {
        let img_on = "";
        let img_off = "";
        let name = "";
        let img = "";
        let iref = "";
        if ( allergen.length === 2 ) {          
          iref = allergen [ 1 ].iref;
          name = allergen [ 1 ].name;
          img_on = allergen [ 1 ]. image;
          img_off = allergen [ 1 ]. image_off;
        } else {
          iref = allergen [ 0 ].iref;
          name = allergen [ 0 ].name;
          img_on = allergen [ 0 ]. image;
          img_off = allergen [ 0 ]. image_off;
        }
        img = img_off;
        
      return <Allergen
        iref = {iref}
        name = {name}        
        image_on = {img_on}
        image_off = {img_off}
        onClickFunction = {this.toggleFilter}
        doReset={this.state.resetState}
        />
      });
    }
    return allergensRender;
  }

  getCategories ( ) {    
    let categoriesRender = "";
    if ( this.props.categoriesList ) {
      let categoriesArr = Object.entries ( this.props.categoriesList );      
      categoriesRender = categoriesArr.map ( (category) => {
        let img_on = "";
        let img_off = "";
        let name = "";
        let img = "";
        let iref = "";
        if ( category.length === 2 ) {          
          iref = category [ 1 ].iref;
          name = category [ 1 ].name;
          img_on = category [ 1 ]. image;
          img_off = category [ 1 ]. image_off;
        } else {
          iref = category [ 0 ].iref;
          name = category [ 0 ].name;
          img_on = category [ 0 ]. image;
          img_off = category [ 0 ]. image_off;
        }
        img = img_off;

        this.categoriesStates [ iref ] = false;
        
        return <Category
          iref = {iref}
          name = {name}        
          image_on = {img_on}
          image_off = {img_off}
          onClickFunction = {this.toggleFilter}
          doReset={this.state.resetCategoriesList [ iref ]}
          //doReset={this.categoriesStates [ iref ]}
          />
      });
    }
    return categoriesRender;
  }

  getSubCategories ( ) {    
    let subCategoriesRender = "";
    if ( this.props.subCategoriesList ) {
      let subCategoriesArr = Object.entries ( this.props.subCategoriesList );      
      subCategoriesRender = subCategoriesArr.map ( (subCategory) => {
        let img_on = "";
        let img_off = "";
        let name = "";
        let img = "";
        let iref = "";
        if ( subCategory.length === 2 ) {          
          iref = subCategory [ 1 ].iref;
          name = subCategory [ 1 ].name;
          img_on = subCategory [ 1 ]. image;
          img_off = subCategory [ 1 ]. image_off;
        } else {
          iref = subCategory [ 0 ].iref;
          name = subCategory [ 0 ].name;
          img_on = subCategory [ 0 ]. image;
          img_off = subCategory [ 0 ]. image_off;
        }
        img = img_off;
        
      return <SubCategory
        iref = {iref}
        name = {name}        
        image_on = {img_on}
        image_off = {img_off}
        onClickFunction = {this.toggleFilter}
        doReset={this.state.resetSubCategoriesList [ iref ]}
        />
      });
    }
    return subCategoriesRender;
  }

  getClasses ( ) {
    let classesRender = "";
    if ( this.props.classesList ) {
      let classesArr = Object.entries ( this.props.classesList );      
      classesRender = classesArr.map ( (productClass) => {
        let img_on = "";
        let img_off = "";
        let name = "";
        let img = "";
        let iref = "";
        if ( productClass.length === 2 ) {          
          iref = productClass [ 1 ].iref;
          name = productClass [ 1 ].name;
          img_on = productClass [ 1 ]. image;
          img_off = productClass [ 1 ]. image_off;
        } else {
          iref = productClass [ 0 ].iref;
          name = productClass [ 0 ].name;
          img_on = productClass [ 0 ]. image;
          img_off = productClass [ 0 ]. image_off;
        }
        img = img_off;
        
      return <ProductClass
        iref = {iref}
        name = {name}        
        image_on = {img_on}
        image_off = {img_off}
        onClickFunction = {this.toggleFilter}
        doReset={this.state.resetClassesList [ iref ]}
        />
      });
    }
    return classesRender;
  }

  render() { 
    
    let allergensRender = null;
    allergensRender = this.getAllergens ( );
    if ( this.state.filters_reset === 1 ) {
      //allergensRender = this.getAllergens ( );
      //if ( allergensRender != null ) this.setState ( {filters_reset: 0} );
    }

    let categoriesRender = null;
    categoriesRender = this.getCategories ( );
    if ( this.state.filters_reset === 1 ) {
      //categoriesRender = this.getcategories ( );
      //if ( categoriesRender != null ) this.setState ( {filters_reset: 0} );
    }

    let subCategoriesRender = null;
    subCategoriesRender = this.getSubCategories ( );
    if ( this.state.filters_reset === 1 ) {
      //categoriesRender = this.getcategories ( );
      //if ( categoriesRender != null ) this.setState ( {filters_reset: 0} );
    }

    let classesRender = null;
    classesRender = this.getClasses ( );
    if ( this.state.filters_reset === 1 ) {
      //classesRender = this.getclasses ( );
      //if ( classesRender != null ) this.setState ( {filters_reset: 0} );
    }

    /* if ( this.props.allergensList ) {
      let allergensArr = Object.entries ( this.props.allergensList );      
      allergensRender = allergensArr.map ( (allergen) => {
        let img_on = "";
        let img_off = "";
        let name = "";
        let img = "";
        let iref = "";
        if ( allergen.length === 2 ) {          
          iref = allergen [ 1 ].iref;
          name = allergen [ 1 ].name;
          img_on = allergen [ 1 ]. image;
          img_off = allergen [ 1 ]. image_off;
        } else {
          iref = allergen [ 0 ].iref;
          name = allergen [ 0 ].name;
          img_on = allergen [ 0 ]. image;
          img_off = allergen [ 0 ]. image_off;
        }
        img = img_off;
        
      return <Allergen
        iref = {iref}
        name = {name}        
        image_on = {img_on}
        image_off = {img_off}
        onClickFunction = {this.toggleFilter}
        />
      });
    } */


    return (
      <Card sx={{ borderRadius: '16px', backgroundColor: "#214a80" }}>

      {/* <Box sx={{ display: 'inline-flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', textAlign: "start" }}> */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>

        {/* <CardActions disableSpacing sx={{display: "block", textAlign: "flex-end"}}>          
          <IconButton aria-label="Add Product" title="Add Product" onClick={() => this.toggleAdd()}>       
            <Avatar alt="Add Product" sx={{ width: 24, height: 24, color: "#273442" }}>
            {this.state.addNewProduct ? <FaMinusCircle/> : <FaPlusCircle/>}
              </Avatar>
          </IconButton>
        </CardActions> */}


        <CardActions disableSpacing sx={{display: "flex", textAlign: "start"}}>
          <IconButton aria-label="Active" title="Active" onClick={() => this.toggleFilter("status", "active")}>
            <Avatar alt="Active" src={this.state.filters_status_active ? active_on : active_off} sx={{ width: 24, height: 24 }}/>
          </IconButton>
          <IconButton aria-label="Idle" title="Idle" onClick={() => this.toggleFilter( "status", "idle" )}>
            <Avatar alt="Idle" src={this.state.filters_status_idle ? idle_on : idle_off} sx={{ width: 24, height: 24 }}/>
          </IconButton>
          <IconButton aria-label="Registered" title="Registered" onClick={() => this.toggleFilter( "status", "registered" )}>
            <Avatar alt="Registered" src={this.state.filters_status_registered ? registered_on : registered_off} sx={{ width: 24, height: 24 }}/>
          </IconButton>
          <IconButton aria-label="Discontinued" title="Discontinued" onClick={() => this.toggleFilter( "status", "discontinued" )}>
            <Avatar alt="Discontinued" src={this.state.filters_status_discontinued ? discontinued_on : discontinued_off} sx={{ width: 24, height: 24 }}/>
          </IconButton>
        </CardActions>
      {/* </Box>

      <Box sx={{ display: 'inline-flex', flexDirection: 'row' }}> */}
        <TextField
          id="standard-basic"
          inputRef={this.searchKeyword}
          label="Keywords"
          variant="standard"
          inputProps={{ sx : { color: "#e3e3e5" } }}
          InputLabelProps={{ sx : { color: "#e3e3e5" } }}
          onChange={(e) => this.searchKey( e )}
        />

        <Typography
          variant="body2"
          color="#e3e3e5"
          sx={{ color: "#e3e3e5", marginTop: "revert", marginLeft: "32px" }}
        >
          Products: {this.props.productsCounter}
        </Typography>

        <Typography
          variant="body2"
          color="#e3e3e5"
          sx={{ color: "#e3e3e5", marginTop: "revert", marginLeft: "32px" }}
        >
          Filters: {this.state.filtersCounter}
        </Typography>
        { this.state.filtersCounter > 0 ?
          <IconButton
            aria-label="Remove Filters"
            title="Remove Filters"
            onClick={() => this.resetFilters ( )}
          >
            <Avatar alt="Remove Filters" sx={{ width: 16, height: 16, color: "#99302e" }}>
              <HighlightOffIcon/>
              </Avatar>
          </IconButton>
            : ""
        }

        {/* sx={{ color: "#e3e3e5", marginTop: "revert", marginLeft: "32px" }} */}

      {/* </Box>

      <Box sx={{ display: 'inline-flex', flexDirection: 'column' }}> */}
        

      {/* </Box>

      <Box sx={{ display: 'inline-flex', flexDirection: 'column' }}> */}
        <ExpandMore
          expand={this.state.expanded}
          onClick={this.handleExpandClick}
          aria-expanded={this.state.expanded}
          aria-label="show more"
          sx={{ color: "#e3e3e5", textAlign: "end" }}
        >
          <ExpandMoreIcon />
        </ExpandMore>
        
      </Box>

      {/* <Box sx={{ display: 'flex', flexDirection: 'row' }}> */}

        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Box sx={{ display: 'block', flexDirection: 'row' }}>  
            <CardActions disableSpacing sx={{display: "block", textAlign: "left"}}>
              {classesRender}
            </CardActions>         
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}> 
            <Box sx={{ display: 'block', flexDirection: 'column' }}> 
              <CardActions disableSpacing sx={{display: "block", textAlign: "left"}}>
                {categoriesRender}

              </CardActions>   
            </Box>      
            <Box sx={{ display: 'block', flexDirection: 'column' }}> 
              <CardActions disableSpacing sx={{display: "block", textAlign: "right"}}>
                {subCategoriesRender}
              </CardActions>   
            </Box>
          </Box>
          <Box sx={{ display: 'block', flexDirection: 'row' }}>  
            <CardActions disableSpacing sx={{display: "block", textAlign: "left"}}>
              {allergensRender}
            {/* <IconButton aria-label="Arahide" title="Arahide" onClick={() => this.toggleFilter("allergens_arahide")}>       
              <Avatar alt="Arahide" src={this.state.filters_allergens_arahide ? arahide_on : arahide_off} sx={{ width: 32, height: 32 }}/>
            </IconButton>      
            <IconButton aria-label="Dioxid" title="Dioxid" onClick={() => this.toggleFilter("allergens_dioxid")}>
            <Avatar alt="Dioxid" src={this.state.filters_allergens_dioxid ? dioxid_on : dioxid_off} sx={{ width: 32, height: 32 }}/>
            </IconButton>
            <IconButton aria-label="Grau" title="Grau" onClick={() => this.toggleFilter("allergens_grau")}>       
              <Avatar alt="Grau" src={this.state.filters_allergens_grau ? grau_on : grau_off} sx={{ width: 32, height: 32 }}/>
            </IconButton>      
            <IconButton aria-label="Lapte" title="Lapte" onClick={() => this.toggleFilter("allergens_lapte")}>
            <Avatar alt="Lapte" src={this.state.filters_allergens_lapte ? lapte_on : lapte_off} sx={{ width: 32, height: 32 }}/>
            </IconButton>
            <IconButton aria-label="Mustar" title="Mustar" onClick={() => this.toggleFilter("allergens_mustar")}>       
              <Avatar alt="Mustar" src={this.state.filters_allergens_mustar ? mustar_on : mustar_off} sx={{ width: 32, height: 32 }}/>
            </IconButton>      
            <IconButton aria-label="Ou" title="Ou" onClick={() => this.toggleFilter("allergens_oua")}>
            <Avatar alt="Ou" src={this.state.filters_allergens_oua ? oua_on : oua_off} sx={{ width: 32, height: 32 }}/>
            </IconButton>
            <IconButton aria-label="Peste" title="Peste" onClick={() => this.toggleFilter("allergens_peste")}>       
              <Avatar alt="Peste" src={this.state.filters_allergens_peste ? peste_on : peste_off} sx={{ width: 32, height: 32 }}/>
            </IconButton>      
            <IconButton aria-label="Porumb" title="Porumb" onClick={() => this.toggleFilter("allergens_porumb")}>
            <Avatar alt="Porumb" src={this.state.filters_allergens_porumb ? porumb_on : porumb_off} sx={{ width: 32, height: 32 }}/>
            </IconButton>
            <IconButton aria-label="Seminte" title="Seminte" onClick={() => this.toggleFilter("allergens_seminte")}>       
              <Avatar alt="Seminte" src={this.state.filters_allergens_seminte ? seminte_on : seminte_off} sx={{ width: 32, height: 32 }}/>
            </IconButton>      
            <IconButton aria-label="Soia" title="Soia" onClick={() => this.toggleFilter("allergens_soia")}>
              <Avatar alt="Soia" src={this.state.filters_allergens_soia ? soia_on : soia_off} sx={{ width: 32, height: 32 }}/>
            </IconButton> */}

            </CardActions>         
          </Box>

          <Box sx={{ display: 'block', flexDirection: 'row' }}>
          <CardActions disableSpacing sx={{display: "block", textAlign: "left" }}>
            <IconButton aria-label="askPrice" title="askPrice" onClick={() => this.toggleFilter ( "flags", "askPrice" )}>
              <Avatar alt="askPrice" src={this.state.filters_flags_askPrice ? askPrice_off : askPrice_on} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton aria-label="askQuantity" title="askQuantity" onClick={() => this.toggleFilter( "flags", "askQuantity" )}>
                <Avatar alt="askQuantity" src={this.state.filters_flags_askQuantity ? askQuantity_off : askQuantity_on} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton aria-label="canCombo" title="canCombo" onClick={() => this.toggleFilter( "flags", "canCombo" )}>
                <Avatar alt="canCombo" src={this.state.filters_flags_canCombo ? canCombo_off : canCombo_on} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton aria-label="canGenerateFidelityPoints" title="canGenerateFidelityPoints" onClick={() => this.toggleFilter( "flags", "canGenerateFidelityPoints" )}>
                <Avatar alt="canGenerateFidelityPoints" src={this.state.filters_flags_canGenerateFidelityPoints ? canGenerateFidelityPoints_off : canGenerateFidelityPoints_on} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton aria-label="hasPackaging" title="hasPackaging" onClick={() => this.toggleFilter( "flags", "hasPackaging" )}>
                <Avatar alt="hasPackaging" src={this.state.filters_flags_hasPackaging ? hasPackaging_off : hasPackaging_on} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton aria-label="hasRecipe" title="hasRecipe" onClick={() => this.toggleFilter( "flags", "hasRecipe" )}>
                <Avatar alt="hasRecipe" src={this.state.filters_flags_hasRecipe ? hasRecipe_off : hasRecipe_on} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton aria-label="isDeliverable" title="isDeliverable" onClick={() => this.toggleFilter( "flags", "isDeliverable" )}>
                <Avatar alt="isDeliverable" src={this.state.filters_flags_isDeliverable ? isDeliverable_off : isDeliverable_on} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton aria-label="isDiscountable" title="isDiscountable" onClick={() => this.toggleFilter( "flags", "isDiscountable" )}>
                <Avatar alt="isDiscountable" src={this.state.filters_flags_isDiscountable ? isDiscountable_off : isDiscountable_on} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton aria-label="isForSale" title="isForSale" onClick={() => this.toggleFilter( "flags", "isForSale" )}>
                <Avatar alt="isForSale" src={this.state.filters_flags_isForSale ? isForSale_off : isForSale_on} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton aria-label="isFractionable" title="isFractionable" onClick={() => this.toggleFilter( "flags", "isFractionable" )}>
                <Avatar alt="isFractionable" src={this.state.filters_flags_isFractionable ? isFractionable_off : isFractionable_on} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton aria-label="isNew" title="isNew" onClick={() => this.toggleFilter( "flags", "isNew" )}>
                <Avatar alt="isNew" src={this.state.filters_flags_isNew ? isNew_off : isNew_on} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton aria-label="isPurchased" title="isPurchased" onClick={() => this.toggleFilter( "flags", "isPurchased" )}>
                <Avatar alt="isPurchased" src={this.state.filters_flags_isPurchased ? isPurchased_off : isPurchased_on} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton aria-label="isSellable" title="isSellable" onClick={() => this.toggleFilter( "flags", "isSellable" )}>
                <Avatar alt="isSellable" src={this.state.filters_flags_isSellable ? isSellable_off : isSellable_on} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton aria-label="isSoldAlone" title="isSoldAlone" onClick={() => this.toggleFilter( "flags", "isSoldAlone" )}>
                <Avatar alt="isSoldAlone" src={this.state.filters_flags_isSoldAlone ? isSoldAlone_off : isSoldAlone_on} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton aria-label="selfService" title="selfService" onClick={() => this.toggleFilter( "flags", "selfService" )}>
                <Avatar alt="selfService" src={this.state.filters_flags_selfService ? selfService_off : selfService_on} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton aria-label="useScale" title="useScale" onClick={() => this.toggleFilter( "flags", "useScale" )}>
                <Avatar alt="useScale" src={this.state.filters_flags_useScale ? useScale_off : useScale_on} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            </CardActions>
          </Box>
            
          </CardContent>

        </Collapse>

        {/* </Box> */}




    </Card>   
    );

  }
}

export default ListsProductFilters;