import React from 'react';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { FaHome, FaPizzaSlice, FaHamburger, FaMapMarkedAlt, FaEuroSign, FaUserTie, FaHospitalUser, FaThinkPeaks } from "react-icons/fa";
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import DropDown from './DropDown';
import isNumeric from 'validator/lib/isNumeric';
import Autocomplete from '@mui/material/Autocomplete';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import isDecimal from 'validator/lib/isDecimal';
import swal from 'sweetalert';
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import NetworkWifi2BarIcon from '@mui/icons-material/NetworkWifi2Bar';
import NetworkWifiIcon from '@mui/icons-material/NetworkWifi';


import noImage from './../images/noImage.png';
import idle from './../images/icons/idle_on.png';
import registered from './../images/icons/registered_on.png';
import active from './../images/icons/active_on.png';
import discontinued from './../images/icons/discontinued_on.png';

import ScheduleIcon from '@mui/icons-material/Schedule';
import PeopleIcon from '@mui/icons-material/People';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import CancelIcon from '@mui/icons-material/Cancel';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

/* import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker'; */
//import { TimeField } from '@mui/x-date-pickers/TimeField';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import KImagesTable from './KImagesTable';
import POS from './POS';
import BackOfficeConfig from './../Config';

import Client from './Client';
import Config from './ClientConfig';

let theme = createTheme();

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#273442",
    color: theme.palette.common.white,
    paddingLeft: "4px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    paddingLeft: "4px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const LocationGroupSelectMenuProps = {
  PaperProps: {
    style: {
      maxHeight: "300px"
    },
  },
};

const LocationEmployeeSelectMenuProps = {
  PaperProps: {
    style: {
      maxHeight: "300px"
    },
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class EmployeeRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {    
      client: new Client ( "none" ),  
      edit: false,
      row: {},
      somethingHasChanged: false,
      showConfirm: false,
      //addNewProduct: false,
      allowEdit: false
    };

    this.handleEditClick = this.handleEditClick.bind(this);
    this.pushData = this.pushData.bind(this);
    this.changeLevel = this.changeLevel.bind(this);
    this.removeEmployee = this.removeEmployee.bind(this);
    this.removeConfirm = this.removeConfirm.bind(this);
    this.removeCancel = this.removeCancel.bind(this);
    this.showConfirmDialog = this.showConfirmDialog.bind(this);
    
  }

  componentDidMount ( ) {
    //console.log ( this.props.dataRow );
    if ( this.props.dataRow ) {
      if (this.props.dataRow.allowEdit ===  true) {
        this.setState ({allowEdit: true});
      }
      this.setState ( { row: this.props.dataRow } );
    }
  }

  handleEditClick ( ) {
    if (!this.state.allowEdit)
      return;
    let cEdit = !this.state.edit;
    this.setState({ edit: !this.state.edit });
  }

  pushData ( ) {
    if ( this.state.edit && this.state.somethingHasChanged ) {
      let row = this.state.row;
      let data = {
        iref: row.iref,
        level: row.level
      };
      let rdata = this.state.client.pushData ( Config.API_URL_LOCATIONEMPLOYEES_UPDATE_LEVEL, data );
      if ( rdata ) {
        if ( rdata.status == "OK" ) {          
          this.handleEditClick ( );
          swal ( "Success", "Successfully updated employee ! (" + rdata.iref + ")", "success", {
            buttons: false,
            timer: 2000,
          })
          .then((value) => {
            this.props.employeeUpdated ( );
            this.setState ( { somethingHasChanged: false } );
          });        
        } else {
          if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
          swal ( "Error", "Add/Update employee failed !", "error" );
        }
      }
    }
  }

  removeEmployee ( ) {
    if (!this.state.allowEdit)
      return;
    if ( this.state.edit ) {
      let row = this.state.row;
      let data = {
        iref: row.iref,
      };
      let rdata = this.state.client.pushData ( Config.API_URL_LOCATIONEMPLOYEES_REMOVE, data );
      if ( rdata ) {
        if ( rdata.status == "OK" ) {          
          this.handleEditClick ( );
          swal ( "Success", "Successfully remove employee ! (" + rdata.iref + ")", "success", {
            buttons: false,
            timer: 2000,
          })
          .then((value) => {
            this.props.employeeUpdated ( );
            this.setState ( { somethingHasChanged: false } );
          });        
        } else {
          if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
          swal ( "Error", "Remove employee failed !", "error" );
        }
      }
    }
  }

  changeLevel ( e ) {
    let uRow = this.state.row;
    uRow.level = parseInt ( e );
    this.setState ( { row: uRow } );
    this.setState ( { somethingHasChanged: true } );
  }

  showConfirmDialog ( ) {
    this.setState ( { showConfirm: true } );
  }

  removeConfirm ( ) {
    this.removeEmployee ();
    this.setState ( { showConfirm: false } );
  }

  removeCancel ( ) {
    this.setState ( { showConfirm: false } );
  }

  render() {
      let row = {};
      let level = "Employee";
      let levelId = 0;
      let firstName = "Undefined";
      let lastName = "Undefined";
      let phone = "Undefined";
      let email = "Undefined";
      let birthday = "Undefined";
      let image = noImage;
      let username = "Undefined";
      let card = "Undefined";

      if ( this.state.row !== null && this.state.row != undefined ) {
        row = this.state.row;
        levelId = row.level;
        level = BackOfficeConfig.EMPLOYEE_TYPES [ levelId ];
        /* switch ( row.level ) {
          case 0:        
            level = "Employee";
            break;
          case 1:
            level = "Coordinator";
            break;
          case 2:        
            level = "Owner";
            break;
        }       */
        if ( row.employee ) {
          if ( row.employee.person ) {
            firstName = row.employee.person.firstName;
            lastName = row.employee.person.lastName;
            phone = row.employee.person.phone;
            email = row.employee.person.email;
            birthday = row.employee.person.birthday;
            image = row.employee.person.image;
          }        
          if ( row.employee.username ) username = row.employee.username;        
          if ( row.employee.card ) card = row.employee.card;
        }
      }
      let showSave = false;
      if ( this.state.edit && this.state.somethingHasChanged )
        showSave = true;

      let showConfirm = false;
      if ( this.state.showConfirm )
        showConfirm = this.state.showConfirm;

    return (
        <StyledTableRow key={row.iref} sx={{padding: "1px"}}>

          <StyledTableCell align="left" sx={{padding: "1px"}}>        
            <Box
              component="img"
              sx={{
                height: 48,
                width: 48,
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
                border: '1px dashed grey'
              }}
              alt={firstName + " " + lastName}
              src={image}
            />
          </StyledTableCell>

          <StyledTableCell component="td" scope="row" sx={{padding: "1px"}}>
            {firstName}
          </StyledTableCell>

          <StyledTableCell component="td" scope="row" sx={{padding: "1px"}}>         
            {lastName}
          </StyledTableCell>

          <StyledTableCell component="td" scope="row" sx={{padding: "1px"}}>
            {phone}
          </StyledTableCell>

          <StyledTableCell component="td" scope="row" sx={{padding: "1px"}}>
            {email}
          </StyledTableCell>

          <StyledTableCell component="td" scope="row" sx={{padding: "1px"}}>
            {birthday}
          </StyledTableCell>

          <StyledTableCell component="td" scope="row" sx={{padding: "1px"}}>
            {username}
          </StyledTableCell>

          <StyledTableCell component="td" scope="row" sx={{padding: "1px"}}>
            {card}
          </StyledTableCell>

          <StyledTableCell component="td" scope="row" sx={{padding: "1px"}}>
            {this.state.edit ?
              <DropDown
                name="Employee Level"
                value={levelId !== undefined ? levelId : ''}
                doptions={BackOfficeConfig.EMPLOYEE_TYPES}
                hasIcons={false}   
                minWidth={120}
                maxWidth={220}
                onDark={false}
                onChangeFunction={this.changeLevel}
                marginTop={4}
                marginLeft={0}
                margin={0}
              />
            : level
            }
          </StyledTableCell>
          <StyledTableCell component="td" scope="row" sx={{padding: "1px"}} align="right">
            <IconButton aria-label="settings" onClick={this.handleEditClick}>
              <EditIcon
                sx={{ color: this.state.edit ? "#8f241d" : "#e3e3e5" }}              
              />
            </IconButton>
            {showSave ?
              <IconButton aria-label="settings" onClick={this.pushData}>
              <SaveIcon
                sx={{ color: "#4287f5" }}              
              />
            </IconButton>
            : ""}
            {this.state.edit ?
              <IconButton aria-label="settings" onClick={this.showConfirmDialog}>
              <DeleteForeverIcon
                sx={{ color: "#8f241d" }}              
              />
            </IconButton>
            : ""}


            <Dialog
              open={showConfirm}
              TransitionComponent={Transition}
              keepMounted
              onClose={this.removeCancel}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{"Remove employee ?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  This action will not delete the employee, it will just remove the employee from the location.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.removeCancel}>No</Button>
                <Button onClick={this.removeConfirm}>Yes</Button>
              </DialogActions>
            </Dialog>
          </StyledTableCell>
        </StyledTableRow>
    );
  }
}

class ScheduleRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {  
      client: new Client ( "none" ),    
      edit: false,
      row: {},
      location: "",
      hourFrom: {},
      hourTo: {},
      somethingHasChanged: false,
      valid_day: false,
      valid_from: false,
      valid_to: false,
      showConfirm: false,
      //addNewProduct: false,
      allowEdit: false
    };

    this.handleEditClick = this.handleEditClick.bind(this);
    this.changeWeekDay = this.changeWeekDay.bind(this);
    this.changeFrom = this.changeFrom.bind(this);
    this.changeTo = this.changeTo.bind(this);
    this.removeSchedule = this.removeSchedule.bind(this);
    this.pushData = this.pushData.bind(this);
    this.propsToState = this.propsToState.bind(this);
    this.removeConfirm = this.removeConfirm.bind(this);
    this.removeCancel = this.removeCancel.bind(this);
    this.showConfirmDialog = this.showConfirmDialog.bind(this);
  }

  propsToState ( ) {
    if ( this.props.dataRow ) {
      if (this.props.dataRow.allowEdit ===  true) {
        this.setState ({allowEdit: true});
      }
      this.setState ( { row: this.props.dataRow } );
      if ( this.props.dataRow.day > 0 )
        this.setState ( { valid_day: true } );
      let sFrom = this.props.dataRow.interval_start;
      let sTo = this.props.dataRow.interval_end;
      this.setState ( { hourFrom: sFrom } );
      this.setState ( { hourTo: sTo } );
      let elementsTo = this.props.dataRow.interval_start.split(':');

      if ( this.props.dataRow.iref == "newSchedule" )
        this.handleEditClick ( );
    }
    if ( this.props.location )
      this.setState ( { location: this.props.location } );
  }

  componentDidMount ( ) {
    this.propsToState ( );
  }

  componentDidUpdate ( oldProps ) {
    if ( this.props != oldProps ) {
      this.propsToState ( );
    }
  }

  handleEditClick ( ) {
    if (!this.state.allowEdit)
      return;
    this.setState({ edit: !this.state.edit });
  }

  changeWeekDay ( day ) {
    let uRow = this.state.row;
    if ( uRow.day !== undefined ) {
      uRow.day = parseInt ( day );
      this.setState ( { row: uRow } );
      this.setState ( { somethingHasChanged: true } );
      this.setState ( { valid_day: true } );
    }
  }

  changeFrom ( e ) {
    this.setState ( { hourFrom: e } );
    let uRow = this.state.row;
    uRow.interval_start = e.$H + ":" + e.$m + ":" + "00";
    this.setState ( { row: uRow } );
    this.setState ( { somethingHasChanged: true } );
    this.setState ( { valid_from: true } );
  }

  changeTo ( e ) {
    this.setState ( { hourTo: e } );
    let uRow = this.state.row;
    uRow.interval_end = e.$H + ":" + e.$m + ":" + "00";
    this.setState ( { row: uRow } );
    this.setState ( { somethingHasChanged: true } );
    this.setState ( { valid_to: true } );
  }

  removeSchedule ( ) {
    if (!this.state.allowEdit)
      return;
    if ( this.state.edit ) {
      let rdata = this.state.client.pushData ( Config.API_URL_LOCATION_SCHEDULE_REMOVE, this.state.row );
      if ( rdata ) {
        if ( rdata.status == "OK" ) {          
          this.handleEditClick ( );
          swal ( "Success", "Successfully removed schedule ! (" + rdata.iref + ")", "success", {
            buttons: false,
            timer: 2000,
          })
          .then((value) => {
            this.props.scheduleUpdated ( );
            this.setState ( { somethingHasChanged: false } );
          });        
        } else {
          if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
          swal ( "Error", "Remove schedule failed !", "error" );
        }
      }
    }
  }

  pushData ( ) {
    if ( this.state.edit ) {
      let data = {
        location: this.state.location,
        schedule: this.state.row
      };
      let rdata = this.state.client.pushData ( Config.API_URL_LOCATION_SCHEDULE_ADD, data );
      if ( rdata ) {
        if ( rdata.status == "OK" ) {          
          this.handleEditClick ( );
          swal ( "Success", "Successfully updated schedule ! (" + rdata.iref + ")", "success", {
            buttons: false,
            timer: 2000,
          })
          .then((value) => {
            this.props.scheduleUpdated ( );
            this.setState ( { somethingHasChanged: false } );
          });        
        } else {
          if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
          swal ( "Error", "Add/Update schedule failed !", "error" );
        }
      }
    }
  }

  showConfirmDialog ( ) {
    this.setState ( { showConfirm: true } );
  }

  removeConfirm ( ) {
    this.removeSchedule ();
    this.setState ( { showConfirm: false } );
  }

  removeCancel ( ) {
    this.setState ( { showConfirm: false } );
  }


  render() {
      let row = null;
      if ( this.state.row !== null && this.state.row != undefined ) {
        row = this.state.row;
      }

      let dayId = 0;
      let day = "Undefined";
      let from = "Undefined";
      let to = "Undefined";      
      let prevFrom = "2023-01-01T15:30";
      let prevTo = "2023-01-01T15:30";
      if ( row ) {
        dayId = row.day;
        day = BackOfficeConfig.WEEKDAYS_RO [ row.day ];
        from = row.interval_start;
        to = row.interval_end;
        prevFrom = "2023-01-01T18:22";// + from;
        prevTo = "2023-01-01T" + to;
      }

      let showSave = false;
      if ( this.state.edit && this.state.somethingHasChanged && this.state.valid_day && 
          this.state.valid_from && this.state.valid_to )
        showSave = true;

      let showConfirm = false;
      if ( this.state.showConfirm )
        showConfirm = this.state.showConfirm;
   
    return (
      <StyledTableRow key={row.iref} sx={{padding: "1px"}}>

        <StyledTableCell component="td" scope="row" sx={{padding: "1px"}}>
          {this.state.edit ?
            <DropDown
              name="Weekday"
              value={dayId !== undefined ? dayId : ''}
              doptions={BackOfficeConfig.WEEKDAYS_RO}
              hasIcons={false}   
              minWidth={120}
              maxWidth={220}
              onDark={false}
              onChangeFunction={this.changeWeekDay}
              marginTop={4}
              marginLeft={0}
              margin={0}
            />
          : day
          }
        </StyledTableCell>

        <StyledTableCell component="td" scope="row" sx={{padding: "1px"}}>         
          {this.state.edit ?
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileTimePicker
                label="Start Time"
                mask='__:__'
                defaultValue={dayjs(prevFrom)}
                renderInput={(params) => <TextField {...params} />}
                //renderInput={() => <TextField value={this.state.hourFrom}/>}
                onChange={this.changeFrom}
                value={this.state.hourFrom}
              />
            </LocalizationProvider>            
          : from
          }
        </StyledTableCell>

        <StyledTableCell component="td" scope="row" sx={{padding: "1px"}}>
          {this.state.edit ?
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileTimePicker
                label="End Time"
                defaultValue={dayjs(prevTo)}
                renderInput={(params) => <TextField {...params} />}
                onChange={this.changeTo}
                value={this.state.hourTo}
                mask='__:__'
              />
            </LocalizationProvider>            
          : to
          }
        </StyledTableCell>

        <StyledTableCell align="right" sx={{padding: "1px"}}>
          <IconButton aria-label="settings" onClick={this.handleEditClick}>
            <EditIcon
              sx={{ color: this.state.edit ? "#8f241d" : "#e3e3e5" }}              
            />
          </IconButton>
          {showSave ?
            <IconButton aria-label="settings" onClick={this.pushData}>
            <SaveIcon
              sx={{ color: "#4287f5" }}              
            />
          </IconButton>
          : ""}
          {this.state.edit ?
            <IconButton aria-label="settings" onClick={this.showConfirmDialog}>
            <DeleteForeverIcon
              sx={{ color: "#8f241d" }}              
            />
          </IconButton>
          : ""}

          <Dialog
            open={showConfirm}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.removeCancel}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Remove schedule entry ?"}</DialogTitle>
            <DialogContent>                
            </DialogContent>
            <DialogActions>
              <Button onClick={this.removeCancel}>No</Button>
              <Button onClick={this.removeConfirm}>Yes</Button>
            </DialogActions>
          </Dialog>
        </StyledTableCell>

      </StyledTableRow>
    );
  }
}


class Location extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      expanded: false,
      edit: false,
      saveButtonVisible: false,
      location: {
        iref: "",
        name: "",
        description: "",
        uid: "",
        prefix: "",
        status: 1,
        sRadius: 200,
        eRadius: 1000,
        address: {
          name: "",
          street: "",
          number: "",
          entrance: "",
          local: "",
          floor: "",
          postalCode: "",
          gps_lg: "",
          gps_lt: "",
          city: {
            iref: 0,
            county: {
              iref: 0,
              country: {
                iref: "country061"
              }
            }
          }
        }
      },
      counties: { },
      cities: { },
      somethingHasChanged: false,
      groupsSearch: [],
      companiesSearch: [],
      locationGroupsNames: [],
      locationGroups: [],
      locationEmployeesNames: [],
      locationEmployees: [],
      //combo_mainImage: null,
      valid_uid: false,
      valid_prefix: false,
      valid_sRadius: true,
      valid_eRadius: true,
      valid_gps_lt: false,
      valid_gps_lg: false,
      detailsVisible: false,
      tabValue: "schedule",
      addNewSchedule: false,
      addNewEmployee: false,
      editEmployees: false, 
      addNewPOS: false,
      allowEdit: false
    };

    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.getElementByName = this.getElementByName.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.addCountry = this.addCountry.bind(this);
    this.addCounty = this.addCounty.bind(this);
    this.addCity = this.addCity.bind(this);
    this.editGeoData = this.editGeoData.bind(this);
    this.updateGeoData = this.updateGeoData.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    //this.groupSearch = this.groupSearch.bind(this);
    //this.groupChange = this.groupChange.bind(this);
    this.companySearch = this.companySearch.bind(this);
    this.companyChange = this.companyChange.bind(this);
    this.detachGroup = this.detachGroup.bind(this);
    this.detachCompany = this.detachCompany.bind(this);
    this.changeType = this.changeType.bind(this);
    this.changeRelation = this.changeRelation.bind(this);
    this.generateUID = this.generateUID.bind(this); 
    this.generatePrefix = this.generatePrefix.bind(this);
    this.generateRandom = this.generateRandom.bind(this);
    this.changeSupplyFrequency = this.changeSupplyFrequency.bind(this);
    this.changeLocationGroup = this.changeLocationGroup.bind(this);
    this.pushData = this.pushData.bind(this);
    this.imageUpdated = this.imageUpdated.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.scheduleUpdated = this.scheduleUpdated.bind(this);
    this.toggleAddSchedule = this.toggleAddSchedule.bind(this);
    this.toggleAddEmployee = this.toggleAddEmployee.bind(this);
    this.handleEmployeeEditClick = this.handleEmployeeEditClick.bind(this);
    this.handleEmployeesEditClick = this.handleEmployeesEditClick.bind(this);
    this.changeLocationEmployee = this.changeLocationEmployee.bind(this);
    this.pushEmployeeData = this.pushEmployeeData.bind(this);
    this.resetEmployeeData = this.resetEmployeeData.bind(this);
    this.locationEmployeesUpdated = this.locationEmployeesUpdated.bind(this);
    this.getLocationEmployeesNames = this.getLocationEmployeesNames.bind(this);
    this.posUpdated = this.posUpdated.bind(this);
    this.toggleAddPOS = this.toggleAddPOS.bind(this);
  }

  componentDidMount() {
    if ( Object.entries(this.props.location).length === 0 ) {
      if (this.props.allowEdit === true)
        this.setState ({allowEdit: true}, this.handleEditClick ( ));
    } else {
      if ( this.props.location !== null ) {
        let uLocation = this.props.location;

        if (uLocation.allowEdit === true)
          this.setState ({allowEdit: true});

        this.setState ( { location: uLocation } );

        if ( uLocation.uid )
          if ( uLocation.uid.length > 0 ) this.setState ( { valid_uid: true } );
        if ( uLocation.prefix )
          if ( uLocation.prefix.length > 0 ) this.setState ( { valid_prefix: true } );
        if ( uLocation.sRadius )
          if ( isNumeric ( uLocation.sRadius ) ) this.setState ( { valid_sRadius: true } );
        if ( uLocation.eRadius )
          if ( isNumeric ( uLocation.eRadius ) ) this.setState ( { valid_eRadius: true } );

        if ( uLocation.address ) {
          if ( uLocation.address.gps_lt )
            if ( isDecimal ( uLocation.address.gps_lt ) )
              this.setState ( { valid_gps_lt: true } ); 
          if ( uLocation.address.gps_lg )
            if ( isDecimal ( uLocation.address.gps_lg ) )
              this.setState ( { valid_gps_lg: true } ); 
        }

        if ( this.props.location.locationGroup ) {
          let eloc = this.props.location.locationGroup;
          if ( eloc.length > 0 ) {
            let els = [];
            eloc.map((el) => {
              let groupStatus = BackOfficeConfig.LOCATION_GROUPS_STATUS [ el.status ].name;
              let groupType = BackOfficeConfig.LOCATION_GROUPS_TYPES [ el.groupType ];
              let company = null;
              if ( el.company ) {
                if ( el.company.name ) company = el.company.name;
                if ( el.company.cuid ) company += ", " + el.company.cuid;
              }
              let newLocationGroups = "[" + el.iref + "][" + el.name + ", " + groupStatus + ", " + groupType + "]";
              if ( company ) newLocationGroups += " (" + company + ")";
              els.push ( newLocationGroups );
            });
            this.setState ( { locationGroupsNames: els } );
          }
        }        
        this.getLocationEmployeesNames ( );
        this.setState ( { detailsVisible: true } );
      }
    }
  }

  getLocationEmployeesNames ( ) {
    if ( this.props.location.employeeLocation_location ) {
      let eloc = this.props.location.employeeLocation_location;
      if ( eloc.length > 0 ) {
        let els = [];
        eloc.map((el) => {
          //let employee_level = BackOfficeConfig.EMPLOYEE_TYPES [ el.level ];
          let employee_status = "NON Active";
          if ( el.employee.isActive ) employee_status = "Active";
          let person = "Undefined";
          if ( el.employee.person ) {
            person = el.employee.person.firstName + " " + el.employee.person.lastName;
            person += ", " + el.employee.person.email + ", " + el.employee.person.phone;
          }
          let newLocationEmployees = "[" + el.employee.iref + "][" + person + "][" + employee_status + "]";
          els.push ( newLocationEmployees );
        });
        this.setState ( { locationEmployeesNames: els } );
      }
    }
  }

  handleExpandClick ( ) {
    this.setState({ expanded: !this.state.expanded });
  }

  handleEditClick ( ) {
    if (!this.state.allowEdit)
      return;
    let cEdit = !this.state.edit;
    this.setState({ edit: !this.state.edit });
    if ( this.state.expanded === false && cEdit === true )
      this.state.expanded = true;
    if ( this.state.expanded === true && cEdit === false )
      this.state.expanded = false;
    this.setState({ saveButtonVisible: cEdit });
    if ( cEdit ) this.getLocationGroups ( );
    if ( cEdit ) this.getLocationEmployees ( );
    this.editGeoData ( );
  }

  getLocationGroups ( ) {
    let queryData = {
      "orderBy_county": true,
      "orderBy_name": true
    }
    let data = this.state.client.getData ( Config.API_URL_LOCATIONSGROUPS, queryData );
    if ( data ) {
      if ( data.locationsGroups ) {
        let locationGroups = [];
        data.locationsGroups.map((locationGroup) => {
          let group_status = BackOfficeConfig.LOCATION_GROUPS_STATUS [ locationGroup.status ].name;
          let group_type = BackOfficeConfig.LOCATION_GROUPS_TYPES [ locationGroup.groupType ];
          let company = null;
          if ( locationGroup.company ) {
            if ( locationGroup.company.name ) company = locationGroup.company.name;
            if ( locationGroup.company.cuid ) company += ", " + locationGroup.company.cuid;
          }
          let newLocationGroups = "[" + locationGroup.iref + "][" + locationGroup.name + ", " + group_status + ", " + group_type + "]";
          if ( company ) newLocationGroups += " (" + company + ")";
          locationGroups.push ( newLocationGroups );
        });
        this.setState ( { locationGroups: locationGroups } );
      }
    }
    //this.setState ( { employees: data.employees });
  }

  getLocationEmployees ( ) {
    let queryData = {
      "orderBy_level": true,
      //"location": this.state.location.iref
    }
    let data = this.state.client.getData ( Config.API_URL_EMPLOYEES, queryData );
    if ( data ) {
      if ( data.employees ) {
        let locationEmployees = [];
        data.employees.map((locationEmployee) => {          
          let employee_status = "NON Active";
          if ( locationEmployee.isActive ) employee_status = "Active";
          let person = "Undefined";
          if ( locationEmployee.person ) {
            person = locationEmployee.person.firstName + " " + locationEmployee.person.lastName;
            person += ", " + locationEmployee.person.email + ", " + locationEmployee.person.phone;
          }
          let newLocationEmployees = "[" + locationEmployee.iref + "][" + person + "][" + employee_status + "]";
          locationEmployees.push ( newLocationEmployees );
        });
        this.setState ( { locationEmployees: locationEmployees } );
      }
    }
    //this.setState ( { employees: data.employees });
  }

  getElementByName ( elements, name ) {    
    let e = {
      "iref" : null,
      "name" : "X",
      "image" : "X",
      "image_off" : "X"
    };
    if ( elements !== null && elements !== undefined ) {
      for ( var i = 0; i < elements.length; i++ ) {
        let el = elements [ i ];
        if ( el.length === 2 ) {
          if ( el [ 1 ].name === name ) e = el [ 1 ];
        } else {
          if ( el [ 0 ].name === name ) e = el [ 0 ];
        }        
      }
    }
    return e;    
  }

  changeValue ( e ) {
    let id = null;
    if ( e.target.id !== null ) id = e.target.id;
    let value = null;
    if  ( e.target.value !== null ) value = e.target.value;
    if ( id !== null) {
      let uLocation = this.state.location;
      if ( !uLocation.address ) uLocation [ "address" ] = {};
      switch ( id ) {
        case "c_a_lname":
          uLocation.name = value;
          if ( value.length > 2 ) this.setState ( { location_name_valid: true } );
          else this.setState ( { location_name_valid: false } );
          break;
        case "c_a_name":
          if ( uLocation.address ) {
            if ( uLocation.address.name !== undefined ) {
              uLocation.address.name = value;
              if ( value.length > 2 ) this.setState ( { location_address_name_valid: true } );
              else this.setState ( { location_address_name_valid: false } );
            }
          }
          break;
        case "c_a_street":
          if ( uLocation.address ) {
            if ( uLocation.address.street !== undefined ) {
              uLocation.address.street = value;
              if ( value.length > 2 ) this.setState ( { location_address_street_valid: true } );
              else this.setState ( { location_address_street_valid: false } );
            }
          }
          break;
        case "c_a_number":
          if ( uLocation.address ) {
            if ( uLocation.address.number !== undefined ) {
              uLocation.address.number = value;              
            }
          }
          break;
        case "c_a_entrance":
          if ( uLocation.address ) {
            if ( uLocation.address.entrance !== undefined ) {
              uLocation.address.entrance = value;            
            }
          }
          break;
        case "c_a_local":
          if ( uLocation.address ) {
            if ( uLocation.address.local !== undefined ) {
              uLocation.address.local = value;
            }
          }
          break;
        case "c_a_floor":
          if ( uLocation.address ) {
            if ( uLocation.address.floor !== undefined ) {
              uLocation.address.floor = value;
            }
          }
          break;
        case "c_a_zip":
          if ( uLocation.address ) {
            if ( uLocation.address.postalCode !== undefined ) {
              uLocation.address.postalCode = value;
              /* if ( value.length > 2 ) this.setState ( { location_address_zip_valid: true } );
              else this.setState ( { location_address_zip_valid: false } ); */
            }
          }
          break;
        case "gps_lg":
          if ( uLocation.address ) {
            uLocation.address.gps_lg = value;
            if ( isDecimal ( value ) )
              this.setState ( { valid_gps_lg: true } );
          }                  
          break;
        case "gps_lt":
          if ( uLocation.address ) {
            uLocation.address.gps_lt = value;
            if ( isDecimal ( value ) )
              this.setState ( { valid_gps_lt: true } );
          }       
          break;

          
        case "description":
          if ( uLocation.description !== undefined ) uLocation.description = value;
          break;
        case "uid":
          if ( value.length > 0 && value.length < 64 )
            this.setState ( { valid_uid: true } );
          else
            this.setState ( { valid_uid: false } );
          if ( uLocation.uid !== undefined ) uLocation.uid = value;
          break;
        case "prefix":
          if ( value.length > 0 && value.length < 64 )
            this.setState ( { valid_prefix: true } );
          else
            this.setState ( { valid_prefix: false } );
          if ( uLocation.prefix !== undefined ) uLocation.prefix = value;
          break;
        case "sRadius":
          if ( value.length > 0 && isNumeric ( value ) )
            if ( parseInt ( value ) <= parseInt (uLocation.eRadius) )
              this.setState ( { valid_sRadius: true } );
            else
              this.setState ( { valid_sRadius: false } );
          else
            this.setState ( { valid_sRadius: false } );
          if ( uLocation.sRadius !== undefined ) uLocation.sRadius = parseInt ( value );          
          break;
        case "eRadius":
          if ( value.length > 0 && isNumeric ( value ) )
            if ( parseInt ( value ) >= parseInt ( uLocation.sRadius ) )
              this.setState ( { valid_eRadius: true } );
            else
              this.setState ( { valid_eRadius: false } );
          else
            this.setState ( { valid_eRadius: false } );
          if ( uLocation.eRadius !== undefined ) uLocation.eRadius = parseInt ( value );
          break;
        case "blockNegativeStock":
          //console.log ( e );
          if ( e.target )
            uLocation.blockNegativeStock = e.target.checked;          
          break;
      }

      if ( parseInt ( uLocation.sRadius ) <= parseInt ( uLocation.eRadius ) ) {
        this.setState ( { valid_sRadius: true } );
        this.setState ( { valid_eRadius: true } );
      }

      this.setState ( { location: uLocation } );
      this.setState ( { somethingHasChanged: true } );
    }
  }

  addCountry ( e ) {
    if ( this.state.location ) {
      let uLocation = this.state.location;
      if ( uLocation.address ) {
        if ( uLocation.address.city ) {            
          uLocation.address.city.iref = "";
          if ( uLocation.address.city.county ) {             
            uLocation.address.city.county.iref = "";
            //debugger;
            if ( uLocation.address.city.county.country ) {
              if ( uLocation.address.city.county.country.iref )
                uLocation.address.city.county.country.iref = e;
              else
                uLocation.address.city.county.country = e;
            }
          }            
        }
      }
      this.setState ( {location: uLocation} );
      this.setState ( { somethingHasChanged: true } );
    }

    let queryData = {
      country: e,
      orderBy_Name: true
    }
    let data = this.state.client.getData ( Config.API_URL_COUNTY, queryData );
    if ( data ) {
        this.setState ( { counties: data.counties });
    } else {
      this.setState ( { counties: {} });
    }      
    this.setState ( { cities: {} });
  }

  addCounty ( e ) {
    if ( this.state.location ) {
      let uLocation = this.state.location;
      if ( uLocation.address ) {
        if ( uLocation.address.city ) {            
          uLocation.address.city.iref = "";
          if ( uLocation.address.city.county ) {             
            uLocation.address.city.county.iref = e;
          }            
        }
      }
      this.setState ( {location: uLocation} );
      this.setState ( { somethingHasChanged: true } );
    }

    let queryData = {
      county: e,
      orderBy_Name: true
    }
    //queryData [ "user_type_gte" ] = "15"
    let data = this.state.client.getData ( Config.API_URL_CITY, queryData );
    if ( data ) {
      this.setState ( { cities: data.cities });
    }
  }

  addCity ( e ) {
    if ( this.state.location ) {
      let uLocation = this.state.location;
      if ( uLocation.address ) {
        if ( uLocation.address.city ) {            
          uLocation.address.city.iref = e;
          let queryData = { };
          let cityData = this.state.client.getData ( Config.API_URL_CITY + e, queryData );
          if ( cityData ) {
            if ( cityData.name ) uLocation.address.city.name = cityData.name;
            if ( cityData.county )
              if ( cityData.county.name ) uLocation.address.city.county.name = cityData.county.name;
            /* if ( cityData.county.country )
              if ( cityData.county.country.name ) uLocation.address.city.county.country.name = cityData.county.country.name; */
            
            if ( cityData.zipCode ) {
              let zipCode = cityData.zipCode;
              if ( parseInt(zipCode) !== 0 ) {
                uLocation.address.zipCode = zipCode;
                uLocation.address.postalCode = zipCode;                
              } else {
                uLocation.address.zipCode = "";
                uLocation.address.postalCode = ""; 
              }              
            } else {
              uLocation.address.zipCode = "";
              uLocation.address.postalCode = "";
            }
          }
        }
      }
      this.setState ( {location: uLocation} );
      this.setState ( { somethingHasChanged: true } );
    }
  }

  editGeoData ( ) {
    let country_id = "";
    let county_id = "";
    if ( this.state.location ) {
      if ( this.state.location.address ) {
        if ( this.state.location.address.city ) {
          if ( this.state.location.address.city.county ) {
            county_id = this.state.location.address.city.county.iref;
            if ( this.state.location.address.city.county.country )
              country_id = this.state.location.address.city.county.country.iref;
          }
        }
      }

      let queryData = {
        country: country_id,
        orderBy_Name: true
      }
      let data = this.state.client.getData ( Config.API_URL_COUNTY, queryData );
      if ( data ) {
        this.setState ( { counties: data.counties });
      }

      queryData = {
        county: county_id,
        orderBy_Name: true
      }
      let citiesData = this.state.client.getData ( Config.API_URL_CITY, queryData );
      if ( citiesData ) {
        this.setState ( { cities: citiesData.cities });
      }

    }
  }

  updateGeoData ( country_id, county_id, uLocation ) {
    let queryData = {
      country: country_id,
      orderBy_Name: true
    }
    let data = this.state.client.getData ( Config.API_URL_COUNTY, queryData );
    if ( data ) {
      this.setState ( { counties: data.counties });
    }

    queryData = {
      county: county_id,
      orderBy_Name: true
    }
    let citiesData = this.state.client.getData ( Config.API_URL_CITY, queryData );
    if ( citiesData ) {
      this.setState ( { cities: citiesData.cities }, this.setState ( { location : uLocation } ) );
    }    
  }

  changeStatus ( e ) {
    if ( e ) {
      if ( this.state.location ) {
        let uLocation = this.state.location;
        uLocation.status = parseInt ( e );
        this.setState ( { location: uLocation } );
        this.setState ( { somethingHasChanged: true } );
      }      
    }
  }

  changeType ( e ) {
    if ( e ) {
      if ( this.state.location ) {
        let uLocation = this.state.location;
        uLocation.locationType = parseInt ( e );
        this.setState ( { location: uLocation } );
        this.setState ( { somethingHasChanged: true } );
      }      
    }
  }

  changeRelation ( e ) {
    if ( e ) {
      if ( this.state.location ) {
        let uLocation = this.state.location;
        uLocation.relation = parseInt ( e );
        this.setState ( { location: uLocation } );
        this.setState ( { somethingHasChanged: true } );
      }      
    }
  }

  changeSupplyFrequency ( e ) {
    if ( e ) {
      if ( this.state.location ) {
        let uLocation = this.state.location;
        uLocation.supplyFrequency = parseInt ( e );
        this.setState ( { location: uLocation } );
        this.setState ( { somethingHasChanged: true } );
      }      
    }
  }

  generateRandom ( length, strong = false ) {
    let newToken = "";
    //const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    if ( strong ) characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      newToken += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return newToken;
  }

  generateUID ( ) {
    /* let newToken = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    ); */
    let newToken = this.generateRandom ( 12 );
    let uLocation = this.state.location;
    uLocation.uid = newToken;
    this.setState ( { location: uLocation } );
    this.setState ( { somethingHasChanged: true } );
    this.setState ( { valid_uid : true } );
  }

  generatePrefix ( ) {
    let newToken = this.generateRandom ( 12 );
    let uLocation = this.state.location;
    uLocation.prefix = newToken;
    uLocation.prefixFlag = true;
    this.setState ( { location: uLocation } );
    this.setState ( { somethingHasChanged: true } );
    this.setState ( { valid_prefix: true } );
  }

  companySearch ( e ) {
    if ( e.target.value.length > 3 ) {
      let queryData = {
        "nameLike" : e.target.value
      };
      let data = this.state.client.filterCompanies ( queryData );
      let newCompanies = [];
      let companies = [];
      Object.keys(data.Companies).forEach(key => {
        let fData = data.Companies [ key ];
        let lineText = fData.iref + " - " + fData.cuid + " - " + fData.name + " (" + fData.ruid + ", " + fData.email + ")";
        let newObj = { key: fData.iref, label: lineText };
        newCompanies.push ( newObj );
        companies.push ( fData );
        //counties [ fData.iref ] = fData.name;      
      });

      this.setState ( { companiesSearch: newCompanies });
    }
  }

  companyChange ( e ) {
    let text = e.target.textContent;
    let repIref = text.substring ( 0, text.indexOf(" - ") );
    let queryData = { };
    let data = this.state.client.getData ( Config.API_URL_COMPANY + repIref, queryData );
    let uLocation = this.state.location;
    uLocation.company = data;
    this.setState ( { location: uLocation } );    
    this.setState ( { somethingHasChanged: true } );
  }

  detachCompany ( ) {
    let uLocation = this.state.location;
    let prevCompany = uLocation.company;
    uLocation.company = {};
    uLocation.prevCompany = prevCompany;
    this.setState ( { location: uLocation } );
    this.setState ( { somethingHasChanged: true } );
  }

  detachGroup ( ) {
    let uLocation = this.state.location;
    let prevLocationGroup = uLocation.locationGroup;
    uLocation.locationGroup = {};
    uLocation.prevLocationGroup = prevLocationGroup;
    this.setState ( { location: uLocation } );
    this.setState ( { somethingHasChanged: true } );
  }

  changeLocationGroup ( e ) {
    //let uLocationNames = this.state.locationGroupsNames;
    let value = e.target.value;
    let newValue = typeof value === 'string' ? value.split(',') : value;
    
    let locationsIrefs = [];
    newValue.map((v) => {
      let c1 = v.indexOf ( "[" ) + 1;
      let c2 = v.indexOf ( "]" );
      let iref = v.substring ( c1, c2 );
      locationsIrefs.push ( iref );
    });

    let uLocation = this.state.location;
    uLocation.locationGroup = locationsIrefs;
    this.setState ( { locationGroupsNames: newValue } );
    this.setState ( { location: uLocation } );
    this.setState ( { somethingHasChanged: true } );
  }

  imageUpdated ( ) {
    //this.props.locationUpdated ( );
    let queryData = {
      iref: this.state.location.iref
    }
    let rdata = this.state.client.getData ( Config.API_URL_LOCATIONS + this.state.location.iref, queryData );
    if ( rdata ) {
      if ( rdata.locationImage_location ) {
        let uLocation = this.state.location;
        uLocation.locationImage_location = rdata.locationImage_location;
        this.setState ( { location: uLocation } );
      }
    }
  }

  pushData ( e ) {
    if ( this.state.edit && this.state.somethingHasChanged && this.state.valid_uid && this.state.valid_prefix && this.state.valid_sRadius && this.state.valid_eRadius ) {
      let location = this.state.location;
      if ( location.employeeLocation_location ) location.employeeLocation_location = [];
      if ( location.locationImage_location ) location.locationImage_location = [];
      let locationGroupsList = [];
      if ( location.locationGroup ) {
        for ( var i = 0; i < location.locationGroup.length; i++ ) {   
          let iref = null;
          if ( location.locationGroup [ i ].iref ) iref = location.locationGroup [ i ].iref;
          else iref = location.locationGroup [ i ];
          //location.locationGroup [ i ] = {};
          //location.locationGroup [ i ][ "iref" ] = iref;
          if ( iref ) locationGroupsList.push ( iref );
        }
      }
      location [ "locationGroupNew" ] = locationGroupsList;

      let rdata = this.state.client.pushData ( Config.API_URL_LOCATIONS_ADD, location );
      if ( rdata ) {
        if ( rdata.status == "OK" ) {
          let uLocation = this.state.location;
          uLocation.iref = rdata.iref;
          this.setState ( { location: uLocation } );          
          this.handleEditClick ( );
          swal ( "Success", "Successfully added/modified location ! (" + rdata.iref + ")", "success", {
            buttons: false,
            timer: 2000,
          })
          .then((value) => {
            this.setState ( { detailsVisible: true } );
            this.props.locationUpdated ( );
            this.setState ( { somethingHasChanged: false } );
          });        
        } else {
          if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
          swal ( "Error", "Add/Update location failed !", "error" );
        }
      }
    }
  }

  handleTabChange ( event, newValue ) {
    this.setState ( { tabValue: newValue } );
  }

  scheduleUpdated ( ) {
    let locationIref = this.state.location.iref;
    let queryData = {
      location: locationIref
    };
    let schedules = this.state.client.getData ( Config.API_URL_LOCATION_SCHEDULE, queryData );
    if ( schedules ) {
      if ( schedules.locationsSchedule ) {
        let uLocation = this.state.location;
        uLocation.locationSchedule_location = schedules.locationsSchedule;
        this.setState ( { location: uLocation } );
      }
    }
    this.setState ( { addNewSchedule: false } );
  }

  toggleAddSchedule ( ) {
    if (!this.state.allowEdit)
      return;
    let addNewSchedule = this.state.addNewSchedule;
    this.setState ( { addNewSchedule: !addNewSchedule } );

    let uLocation = this.state.location;
    if ( !addNewSchedule ) {
      let newSchedule = {
        iref: "newSchedule",
        day: 0,
        interval_start: "00:00:00",
        interval_end: "00:00:00"
      }
      uLocation.locationSchedule_location.unshift ( newSchedule );
    } else {
      if ( uLocation.locationSchedule_location [ 0 ].iref === "newSchedule" ) {
        uLocation.locationSchedule_location.shift ( );
      }
    }
  }

  toggleAddEmployee ( ) {
    if (!this.state.allowEdit)
      return;
    this.handleEmployeesEditClick ( );
  }

  handleEmployeesEditClick ( ) {
    if (!this.state.allowEdit)
      return;
    let cEdit = !this.state.editEmployees;
    this.setState({ editEmployees: !this.state.editEmployees });
    if ( cEdit ) this.getLocationEmployees ( );
  }

  changeLocationEmployee ( e ) {
    let value = e.target.value;
    let newValue = typeof value === 'string' ? value.split(',') : value;
    
    let employeesIrefs = [];
    newValue.map((v) => {
      let c1 = v.indexOf ( "[" ) + 1;
      let c2 = v.indexOf ( "]" );
      let iref = v.substring ( c1, c2 );
      employeesIrefs.push ( iref );
    });
    let uLocation = this.state.location;
    uLocation.employeeLocation_location = employeesIrefs;
    this.setState ( { locationEmployeesNames: newValue } );
    this.setState ( { location: uLocation } );
    this.setState ( { somethingHasChanged: true } );
  }

  locationEmployeesUpdated ( ) {
    
    let queryData = {
      "location": this.state.location.iref,
      "orderBy_level": true,
      //"location": this.state.location.iref
    }
    let data = this.state.client.getData ( Config.API_URL_LOCATIONEMPLOYEES, queryData );
    console.log ( data );
    console.log ( this.state.location );
    if ( data ) {
      if ( data.employees ) {
        let uLocation = this.state.location;
        uLocation.employeeLocation_location = data.employees;
        this.setState ( { location: uLocation }, this.getLocationEmployeesNames ( ) );
        //this.setState ( { locationEmployees: locationEmployees } );
      }
    }
  }

  pushEmployeeData ( ) {
    if ( this.state.editEmployees ) {
      let employees = this.state.location.employeeLocation_location;
      let employeesData = {
        "iref": this.state.location.iref,
        "employees": employees
      }
      //if ( location.employeeLocation_location ) location.employeeLocation_location = [];
      let rdata = this.state.client.pushData ( Config.API_URL_LOCATIONEMPLOYEES_ADD, employeesData );
      if ( rdata ) {
        if ( rdata.status == "OK" ) {        
          this.handleEmployeesEditClick ( );
          swal ( "Success", "Successfully added/modified location employees! (" + rdata.iref + ")", "success", {
            buttons: false,
            timer: 2000,
          })
          .then((value) => {
            this.locationEmployeesUpdated ( );
          });        
        } else {
          if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
          swal ( "Error", "Add/Update location employees failed !", "error" );
        }
      }
    }
  }

  resetEmployeeData ( ) {
    this.handleEmployeesEditClick ( );
    this.locationEmployeesUpdated ( );
  }

  handleEmployeeEditClick ( ) {
    if (!this.state.allowEdit)
      return;
  }

  toggleAddPOS ( ) {
    if (!this.state.allowEdit)
      return;
    let anp = this.state.addNewPOS;
    this.setState ( { addNewPOS: !anp } );

    let uLocation = this.state.location;
    if ( !anp ) {
      let newPOS = {
        iref: "newPOS",
      }
      uLocation.pos_location.unshift ( newPOS );
    } else {
      if ( uLocation.pos_location [ 0 ].iref === "newPOS" ) {
        uLocation.pos_location.shift ( );
      }
    }
  }

  posUpdated ( ) {
    let locationIref = this.state.location.iref;
    let queryData = {
      location: locationIref,
      orderBy_name: true
    };
    let data = this.state.client.getData ( Config.API_URL_LOCATION_POS, queryData );
    if ( data ) {
      if ( data.posList ) {
        let uLocation = this.state.location;
        uLocation.pos_location = data.posList;
        this.setState ( { location: uLocation } );
      }
    }
    this.setState ( { addNewPOS: false } );
  }

  render() {

    let location = { };
    let iref = "";
    let location_name = "";
    let location_description = "";

    let owner = {
      "name" : "Undefined",
      "image": noImage
    };

    let address_country = "Country";
    let address_country_select = "";
    let address_county = "County";
    let address_county_select = "";
    let address_city = "City";
    let address_city_select = "";

    let location_status_image = noImage;
    let location_status_alt = "Unknown";

    let locationImages;

    let locationAddress_name = "";
    let locationAddress = "";    
    let locationAddress_gps = { "lt": 0, "lg": 0 };
    let locationAddress_city = "";
    let locationAddress_county = "";
    let locationAddress_country = "";
    let location_reg = "";

    let locationAddress_street = "";
    let locationAddress_number = "";
    let locationAddress_entrance = "";
    let locationAddress_local = "";
    let locationAddress_floor = "";
    let locationAddress_complement = "";
    let locationAddress_postalCode = "";

    let company = {};
    let companyDisplay = "";

    let repImage = noImage;
    let repName = "";

    let allData ="";
    let allSchedules ="";

    let status = 0;
    let locationType = 0;
    let locationRelation = 0;
    let location_uid = "";
    let location_prefix = "";
    let location_sRadius = 0;
    let location_eRadius = 0;

    let generated_uid = "";
    let generated_prefix = "";

    let blockNegativeStock = false;
    let supplyFrequency = 0;
    let locationGPS = "";

    let posList = "";


    if ( this.state.location ) {
      location = this.state.location;
      if ( location.iref ) iref = location.iref;
      if ( location.name ) location_name = location.name;
      if ( location.description ) location_description = location.description;
      if ( location.status !== undefined ) status = parseInt ( location.status );
      if ( location.locationType !== undefined ) locationType = parseInt ( location.locationType );
      if ( location.relation !== undefined ) locationRelation = parseInt ( location.relation );

      if ( location.blockNegativeStock !== undefined ) blockNegativeStock = location.blockNegativeStock; // ) == 1 ? true : false;
      if ( location.supplyFrequency !== undefined ) supplyFrequency = parseInt ( location.supplyFrequency );

      if ( location.uid !== undefined ) {
        location_uid = location.uid;
        generated_uid = location_uid;
      }
      if ( location.prefix !== undefined ) {
        location_prefix = location.prefix;
        generated_prefix = location.prefix;
      }
      if ( location.sRadius !== undefined ) location_sRadius = parseInt ( location.sRadius );
      if ( location.eRadius !== undefined ) location_eRadius = parseInt ( location.eRadius );

      switch ( location.status ) {
        case 0:
          location_status_image = registered;
          location_status_alt = "Registered";
          break;      
        case 1:
          location_status_image = active;
          location_status_alt = "Active";
          break;
        case 2:
          location_status_image = discontinued;
          location_status_alt = "Discontinued";
          break;
      }

      if ( location.locationImage_location ) {
        locationImages = <KImagesTable
                            images = {location.locationImage_location}
                            edit = {this.state.edit}
                            viewDetails = {this.state.viewDetails}
                            saveURL = {Config.API_URL_LOCATIONS_ADD_IMAGE}
                            removeURL = {Config.API_URL_LOCATIONS_REMOVE_IMAGE}
                            parentIref = {location.iref}
                            imageUpdated = {this.imageUpdated}
                          />;
      }

      if ( location.address ) {
        let address = location.address;
        if ( address.name ) locationAddress_name = address.name;
        if ( address.street ) { locationAddress = address.street; locationAddress_street = address.street; }
        if ( address.number ) { locationAddress += ", nr. " + address.number; locationAddress_number = address.number; }
        if ( address.entrance ) { locationAddress += ", intrare " + address.entrance; locationAddress_entrance = address.entrance; }
        if ( address.local ) { locationAddress += ", local " + address.local; locationAddress_local = address.local; }
        if ( address.floor ) { locationAddress += ", etaj " + address.floor; locationAddress_floor = address.floor; }
        if ( address.complement ) { locationAddress += ", complement " + address.complement; locationAddress_complement = address.complement; }
        if ( address.postalCode ) { locationAddress += ", Cod Postal " + address.postalCode; locationAddress_postalCode = address.postalCode; }
  
        if ( address.city ) {
          locationAddress_city = address.city.name; 
          if ( address.city.county ) {
            locationAddress_county = address.city.county.name;
            if ( address.city.county.country )
              locationAddress_country = address.city.county.country.name;
          }
        }

        if ( address.gps_lt !== undefined ) { locationAddress_gps [ "lt" ] = address.gps_lt; locationGPS = "Lat: " + address.gps_lt; }
        if ( address.gps_lg !== undefined ) { locationAddress_gps [ "lg" ] = address.gps_lg; locationGPS += ", Long: " + address.gps_lg; }

        if ( address.city ) {
          if ( address.city.county ) {
            if ( address.city.county.country ) {
                if ( address.city.county.country.iref )
                  address_country_select = address.city.county.country.iref;
                else
                  address_country_select = address.city.county.country;
                address_country = address.city.county.country.name;
            }
            address_county = address.city.county.name;
            address_county_select = address.city.county.iref; 
          }
          address_city = address.city.name;
          address_city_select = "";
          address_city_select = address.city.iref;          
        }
      }

      if ( location.company ) {
        company = location.company;
        companyDisplay = company.name + " (" + company.cuid + ")";
        if ( company.address ) {
          if ( company.address.city ) companyDisplay += ", " + company.address.city.name;
        }

        if ( location.company.representative ) {
          repName = location.company.representative.firstName + " " + location.company.representative.lastName;
          if ( location.company.representative.image ) repImage = location.company.representative.image;
        }
      }
      
      
      if ( location.employeeLocation_location ) {
        allData = location.employeeLocation_location.map((row) => {
          //let row = employee.employee;
          return <EmployeeRow
                  key = {row.iref}
                  dataRow = {row}
                  employeeUpdated = {this.locationEmployeesUpdated}
                  allowEdit={this.state.allowEdit}
                  />
        });
      }
      
      if ( location.locationSchedule_location ) {
        allSchedules = location.locationSchedule_location.map((row) => {
          return <ScheduleRow 
                    key = {row.iref}
                    dataRow = {row}
                    scheduleUpdated={this.scheduleUpdated}
                    location={this.state.location.iref}
                    allowEdit={this.state.allowEdit}
                  />
        });
      }
      if ( location.pos_location ) {
        posList = location.pos_location.map((pos) => {
          return <POS 
                    key = {pos.iref}
                    pos = {pos}
                    posUpdated={this.posUpdated}
                    cardColor="#eeeee4"
                    location={location.iref}
                    allowEdit={this.state.allowEdit}
                  />
        });
      }
    }

    let location_image = noImage; 
    let locationImage = "";
    if ( location.locationImage_location )
      if ( location.locationImage_location.length > 0 )
        if ( location.locationImage_location[0].image ) {
          locationImage = location.locationImage_location[0].image;
          location.locationImage_location.map ( (pImage) => {
            if ( pImage.isMain === true ) {
              locationImage = pImage.image;
              //check if actual file name              
            }
          });
          location_image = locationImage;
          /* let fileName = locationImage.replace(/\.[^/.]+$/, "")
          let fileExtensions = locationImage.split(".").pop();
          location_image = fileName + "_m." + fileExtensions; */
        }

    let countriesValue = "";
    let countries = {};
    if ( this.props.countries ) countries = this.props.countries;
    if ( Object.keys(countries).length === 1 ) countriesValue = Object.keys(countries)[0];

    let counties = {};
    let countiesObj = {};
    if ( this.state.counties ) countiesObj = this.state.counties;
    Object.keys(countiesObj).forEach(key => {
      let fData = countiesObj [ key ];
      counties [ fData.iref ] = fData.name;      
    });

    let cities = {};
    let citiesObj = {};
    if ( this.state.cities ) citiesObj = this.state.cities;
    Object.keys(citiesObj).forEach(key => {
      let fData = citiesObj [ key ];
      cities [ fData.iref ] = fData.name;      
    });

    //let locationGroup = "";

    let groupsSearch = [];
    if ( this.state.groupsSearch ) groupsSearch = this.state.groupsSearch;

    let companiesSearch = [];
    if ( this.state.companiesSearch ) companiesSearch = this.state.companiesSearch;

    let locationGroups = [ ];
    if ( this.state.locationGroups ) locationGroups = this.state.locationGroups;

    let locationGroupsNames = [];
    if ( this.state.locationGroupsNames.length > 0 ) locationGroupsNames = this.state.locationGroupsNames;

    let locationDetails = "Status: " + BackOfficeConfig.LOCATION_STATUS[status].name + 
                          ", Type: " + BackOfficeConfig.LOCATION_TYPES[locationType] + 
                          ", Relation: " + BackOfficeConfig.LOCATION_RELATION[locationRelation];

    let locationActions = "Supply: " + BackOfficeConfig.LOCATION_SUPPLY_FREQUENCY [supplyFrequency];
    if ( blockNegativeStock ) locationActions += ", Block negative stock";

    let showSave = false;
    if ( this.state.edit && this.state.somethingHasChanged && this.state.valid_uid && this.state.valid_prefix &&
      this.state.valid_sRadius && this.state.valid_eRadius )
      showSave = true;

    let tabValue = "schedule";
    if ( this.state.tabValue ) tabValue = this.state.tabValue;

    let showEdployeesSave = true;

    let locationEmployees = [ ];
    if ( this.state.locationEmployees ) locationEmployees = this.state.locationEmployees;

    let locationEmployeesNames = [];
    if ( this.state.locationEmployeesNames.length > 0 ) locationEmployeesNames = this.state.locationEmployeesNames;

    return (
      /* , display: 'flex' */
    <Card sx={{ borderRadius: '16px', m: 1 }}>

      <Box sx={{ display: 'block', flexDirection: 'row', width: "100%" }}>
        <CardHeader
          avatar={          
            <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%" }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                <Avatar alt={location_status_alt} src={location_status_image} title={location_status_alt}>
                </Avatar>  
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
              <Typography variant="subtitle1" color="text.secondary">
                {iref}
              </Typography>
              </Box>
            </Box>
                    
          }        
          action={            
            <IconButton aria-label="settings" onClick={this.handleEditClick}>
              <EditIcon
                sx={{ color: this.state.edit ? "#8f241d" : "#e3e3e5" }}                
              />
            </IconButton>
          }
          title={location_name}
          subheader={companyDisplay}
          titleTypographyProps={{variant:'h5' }}
        />
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%" }}>
        <Box sx={{ display: 'block', flexDirection: 'column', width: '15%'}}>
          <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%" }}>
            <CardMedia
              component="img"
              height="120"
              sx={{ width: 128, marginLeft: 1 }}
              image={location_image}
              alt={location_name}
            />
          </Box>
          {this.state.edit ?
            <Box sx={{ flexDirection: 'row', display: "flex"}}>
              <IconButton aria-label={repName} title={repName}>       
                <Avatar alt={repName} src={repImage} sx={{ width: 48, height: 48 }}/>
              </IconButton>
            </Box>
          : ""
          }
        </Box>

        <Box sx={{ flexDirection: 'column', display: "block", width: '35%' }}>        
            {this.state.edit ?
              <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "start", justifyContent:"left" }}>
                <Box sx={{ flexDirection: 'column', display: "inline-table", width: '33%' }}>
                  <DropDown
                    name="Location Status"
                    value={status !== undefined ? status : ''}
                    doptions={BackOfficeConfig.LOCATION_STATUS}
                    hasIcons={true}   
                    minWidth={120}
                    maxWidth={220}
                    onDark={false}
                    onChangeFunction={this.changeStatus}
                    marginTop={0}
                    marginLeft={0}
                    margin={0}
                  />
                </Box>
                <Box sx={{ flexDirection: 'column', display: "inline-table", width: '33%' }}>
                  <DropDown
                    name="Location Type"
                    value={locationType !== undefined ? locationType : ''}
                    doptions={BackOfficeConfig.LOCATION_TYPES}
                    hasIcons={false}   
                    minWidth={120}
                    maxWidth={220}
                    onDark={false}
                    onChangeFunction={this.changeType}
                    marginTop={0}
                    marginLeft={0}
                    margin={0}
                  />
                </Box>
                <Box sx={{ flexDirection: 'column', display: "inline-table", width: '33%' }}>
                  <DropDown
                    name="Location Relation"
                    value={locationRelation !== undefined ? locationRelation : ''}
                    doptions={BackOfficeConfig.LOCATION_RELATION}
                    hasIcons={false}   
                    minWidth={120}
                    maxWidth={220}
                    onDark={false}
                    onChangeFunction={this.changeRelation}
                    marginTop={0}
                    marginLeft={0}
                    margin={0}
                  />
                </Box>

                {/* <Box sx={{ flexDirection: 'column', display: "flex", width: '15%' }}>
                  <IconButton aria-label={owner.name} title={owner.name}>       
                    <Avatar alt={owner.name} src={owner.image} sx={{ width: 48, height: 48 }}/>
                  </IconButton>
                </Box>
                <Box sx={{ flexDirection: 'column', display: "flex", width: '15%' }}>
                  <IconButton aria-label={repName} title={repName}>       
                    <Avatar alt={repName} src={repImage} sx={{ width: 48, height: 48 }}/>
                  </IconButton>
                </Box> */}
              </Box>
              :
              <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "start", justifyContent:"left" }}>
                <Typography variant="body2" color="text.secondary">
                  {locationDetails}
                </Typography>
              </Box>
            }        

        <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "start", justifyContent:"left" }}>          
            {this.state.edit ?               
              <TextField
                id="description"
                label="Description"
                value={location_description || ''}
                variant="outlined"
                onChange={this.changeValue}
                sx={{minWidth: "90%", marginTop: 1}}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"255"}}
              />
              : 
              <Typography variant="body2" color="text.secondary">
                {"Description: " + location_description}
              </Typography>                         
            }         
        </Box>

            {this.state.edit ? 
              <Box sx={{ display: 'inline-flex', flexDirection: 'row', textAlign: "start", justifyContent:"left" }}>
                <Box sx={{ flexDirection: 'column', display: "block", width: '75%' }}>              
                  <Autocomplete
                    disablePortal
                    id="company"
                    name="Company"
                    options={companiesSearch}
                    sx={{ width: 340, marginTop: 1 }}
                    onChange={this.companyChange}
                    renderInput = {
                      (params) =>
                        <TextField 
                          {...params}
                          label="Company (type keywords)"
                          onChange={this.companySearch}
                        />}
                  />
                </Box>
                <Box sx={{ flexDirection: 'column', display: "flex", width: '25%' }}>
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    sx={{ color: "#b51f0b", marginTop: 1, marginLeft: 0.5 }}
                    startIcon={<DeleteForeverIcon />}
                    onClick={this.detachCompany}
                  >
                    Detach Company
                  </Button>
                </Box>
              </Box>
              : 
              <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "start", justifyContent:"left" }}>
                <IconButton aria-label={repName} title={repName}>       
                  <Avatar alt={repName} src={repImage} sx={{ width: 48, height: 48 }}/>
                </IconButton>                     
              </Box>    
            }

          <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "start", justifyContent:"left", marginTop: 1 }}>          
            <Box sx={{ display: 'block', flexDirection: 'column', textAlign: "start", justifyContent:"left" }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "start", justifyContent:"left"}}>
                {this.state.edit ?
                  <TextField
                    id="uid"
                    label="UID"
                    value={location_uid || ''}
                    variant="outlined"
                    onChange={this.changeValue}
                    sx={{minWidth: "30%"}}
                    onFocus={event => { event.target.select(); }}
                    inputProps={{maxLength:"32"}}
                    error={this.state.valid_uid === false}
                  />
                  : 
                  <Typography variant="body2" color="text.secondary">
                    {"UID: " + location_uid}
                  </Typography>                         
                }
                {this.state.edit ? 
                  <IconButton aria-label="Generate" onClick={this.generateUID} sx={{marginTop: 2, marginLeft: 0}}>
                    <SmartButtonIcon titleAccess="Generate UID"/>
                  </IconButton>            
                  : ""
                }
                {this.state.edit ? 
                  <IconButton aria-label="Generate" onClick={() => {navigator.clipboard.writeText(generated_uid)}} sx={{marginTop: 2, marginLeft: 0}}>
                    <ContentCopyIcon titleAccess="Copy to clipboard" fontSize="small"/>
                  </IconButton>            
                  : ""
                }
              </Box>
            </Box>
            <Box sx={{ display: 'block', flexDirection: 'column', textAlign: "start", justifyContent:"left" }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "start", justifyContent:"left"}}>
                {this.state.edit ?
                  <TextField
                    id="prefix"
                    label="Prefix"
                    value={location_prefix || ''}
                    variant="outlined"
                    onChange={this.changeValue}
                    sx={{minWidth: "30%", marginLeft: 1}}
                    onFocus={event => { event.target.select(); }}
                    inputProps={{maxLength:"32"}}
                    error={this.state.valid_prefix === false}
                  />
                  : 
                  <Typography variant="body2" color="text.secondary">
                    {", Prefix: " + location_prefix}
                  </Typography>                         
                }
                {this.state.edit ? 
                  <IconButton aria-label="Generate" onClick={this.generatePrefix} sx={{marginTop: 2, marginLeft: 0}}>
                    <SmartButtonIcon titleAccess="Generate Prefix"/>
                  </IconButton>            
                  : ""
                }
                {this.state.edit ? 
                  <IconButton aria-label="Generate" onClick={() => {navigator.clipboard.writeText(generated_prefix)}} sx={{marginTop: 2, marginLeft: 0}}>
                    <ContentCopyIcon titleAccess="Copy to clipboard" fontSize="small"/>
                  </IconButton>            
                  : ""
                }
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "start", justifyContent:"left", marginTop: 1 }}>          
            <Box sx={{ display: 'block', flexDirection: 'column', textAlign: "start", justifyContent:"left" }}>
              <IconButton aria-label="Proximity Radius" sx={{marginTop: 1, marginLeft: 0}}>
                <NetworkWifi2BarIcon titleAccess="Proximity Radius"/>
              </IconButton>
            </Box>
            <Box sx={{ display: 'block', flexDirection: 'column', textAlign: "start", justifyContent:"left" }}>
              {this.state.edit ?
                <TextField
                  id="sRadius"
                  label="sRadius (m)"
                  value={location_sRadius || ''}
                  variant="outlined"
                  onChange={this.changeValue}
                  sx={{minWidth: "40%"}}
                  onFocus={event => { event.target.select(); }}
                  inputProps={{maxLength:"16"}}
                  error={this.state.valid_sRadius === false}
                />
                : 
                <Typography variant="body2" color="text.secondary" sx={{marginTop: 2}}>
                  {location_sRadius}
                </Typography>                                        
              }         
            </Box>
            <Box sx={{ display: 'block', flexDirection: 'column', textAlign: "start", justifyContent:"left" }}>
              <IconButton aria-label="Activation Radius" sx={{marginTop: 1, marginLeft: 0}}>
                <NetworkWifiIcon titleAccess="Activation Radius"/>
              </IconButton>
            </Box>
            <Box sx={{ display: 'block', flexDirection: 'column', textAlign: "start", justifyContent:"left" }}>
              {this.state.edit ?
                <TextField
                  id="eRadius"
                  label="eRadius (m)"
                  value={location_eRadius || ''}
                  variant="outlined"
                  onChange={this.changeValue}
                  sx={{minWidth: "40%", marginLeft: 1}}
                  onFocus={event => { event.target.select(); }}
                  inputProps={{maxLength:"16"}}
                  error={this.state.valid_eRadius === false}
                />
                : 
                <Typography variant="body2" color="text.secondary" sx={{marginTop: 2}}>
                  {location_eRadius}
                </Typography>                         
              }         
            </Box>
          </Box>      

          <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "start", justifyContent:"left", marginTop: 1 }}>          
            <Box sx={{ display: 'block', flexDirection: 'column', textAlign: "start", justifyContent:"left" }}>
              {this.state.edit ?
                <DropDown
                  name="Location Supply Frequency"
                  value={supplyFrequency !== undefined ? supplyFrequency : ''}
                  doptions={BackOfficeConfig.LOCATION_SUPPLY_FREQUENCY}
                  hasIcons={false}   
                  minWidth={150}
                  maxWidth={250}
                  onDark={false}
                  onChangeFunction={this.changeSupplyFrequency}
                  marginTop={0}
                  marginLeft={0}
                  margin={0}
                />
              : ""
              }
            { this.state.edit ?
              <FormControlLabel
                control={<Checkbox id="blockNegativeStock" color="success" checked={blockNegativeStock} onChange={this.changeValue}/>}
                label="Block Negative Stock"
                sx = {{marginLeft: 1}}
              />
              : ""
            }
            </Box>
            {!this.state.edit ?
              <Typography variant="body2" color="text.secondary">
                {locationActions}
              </Typography>              
            : ""
            }
          </Box>

          
          <Box sx={{ display: 'inline-flex', flexDirection: 'row', textAlign: "start", justifyContent:"left" }}>
            <FormControl sx={{marginTop: 1, width: "80%" }}>
              <InputLabel id="locationGroupsSelect">Location Groups</InputLabel>
              <Select
                labelId="locationGroupsSelect"
                id="locationGroupsCheckBox"
                multiple
                value={locationGroupsNames}
                onChange={this.changeLocationGroup}
                input={<OutlinedInput label="Location Groups" />}                  
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, width: "80%"}}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}                    
                  /* selected.join(', ')} */
                sx={{ minWidth: "300px", maxWidth: "100%", width: "800px" }}
                MenuProps={LocationGroupSelectMenuProps}
                disabled={!this.state.edit}
              >
                {locationGroups.map((locationGroup) => (
                  <MenuItem key={locationGroup} value={locationGroup}>
                    <Checkbox checked={locationGroupsNames.indexOf(locationGroup) > -1} />
                    <ListItemText primary={locationGroup} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>              
          </Box>
            

          {/* <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "start", justifyContent:"left" }}>
            <CardActions disableSpacing sx={{display: "flex", justifyContent: "left" }}>
              <IconButton aria-label={owner.name} title={owner.name}>       
                <Avatar alt={owner.name} src={owner.image} sx={{ width: 48, height: 48 }}/>
              </IconButton>
              <IconButton aria-label={repName} title={repName}>       
                <Avatar alt={repName} src={repImage} sx={{ width: 48, height: 48 }}/>
              </IconButton>
            </CardActions>
          </Box>           */}
        </Box>

        <Box sx={{ flexDirection: 'column', justifyContent:"right", width: '50%' }}>        
          {/* <CardActions disableSpacing sx={{display: "block", textAlign: "end"}}> */}
          <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "end", justifyContent:"right", marginRight: 1 }}>
            { this.state.edit ?
              <TextField
                id="c_a_lname"
                label="Location Name"
                value={location_name || ''}
                variant="outlined"
                onChange={this.changeValue}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"128"}}
              />
              : ""
            }
            { this.state.edit ?
              <TextField
                id="c_a_name"
                label="Address Name"
                value={locationAddress_name || ''}
                variant="outlined"
                onChange={this.changeValue}
                sx= {{marginLeft: 1, marginRight: 1 }}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"128"}}
              />
              : locationAddress_name ? locationAddress_name + ", " : ""
            }
          </Box>
          {/* </CardActions>
          <CardActions disableSpacing sx={{display: "block", textAlign: "end"}}> */}
          <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "end", justifyContent:"right", marginTop: 1, marginRight: 1 }}>
            { this.state.edit ?
                <DropDown
                  name="Country"
                  value={address_country_select || ''}
                  doptions={countries}
                  hasIcons={false}   
                  minWidth={100}
                  maxWidth={200}
                  onDark={false}
                  onChangeFunction={this.addCountry}
                />
                : locationAddress_country ? locationAddress_country + ", " : ""
              }
            
              { this.state.edit ?
                <DropDown
                  name="County"
                  value={address_county_select || ''}
                  doptions={counties}
                  hasIcons={false}   
                  minWidth={100}
                  maxWidth={200}
                  onDark={false}
                  onChangeFunction={this.addCounty}
                />
                : locationAddress_county ? locationAddress_county + ", " : ""
              }
            
              { this.state.edit ?
                <DropDown
                  name="City"
                  value={address_city_select || ''}
                  doptions={cities}
                  hasIcons={false}   
                  minWidth={100}
                  maxWidth={200}
                  onDark={false}
                  onChangeFunction={this.addCity}
                />
                : locationAddress_city
              }     
          </Box>

            { this.state.edit ?
              <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "end", justifyContent:"right", marginTop: 1, marginRight: 1 }}>
                <TextField
                  id="c_a_street"
                  label="Street"
                  value={locationAddress_street || ''}
                  variant="outlined"
                  onChange={this.changeValue}
                  sx={{minWidth: "30%", marginLeft: 1}}
                  onFocus={event => { event.target.select(); }}
                  inputProps={{maxLength:"255"}}
                />
                <TextField
                  id="c_a_number"
                  label="Number"
                  value={locationAddress_number || ''}
                  variant="outlined"
                  onChange={this.changeValue}
                  sx={{maxWidth: "64px", minWidth: "10%", marginLeft: 1}}
                  onFocus={event => { event.target.select(); }}
                  inputProps={{maxLength:"16"}}
                />
                <TextField
                  id="c_a_entrance"
                  label="Entrance"
                  value={locationAddress_entrance || ''}
                  variant="outlined"
                  onChange={this.changeValue}
                  sx={{maxWidth: "128px", minWidth: "10%", marginLeft: 1}}
                  onFocus={event => { event.target.select(); }}
                  inputProps={{maxLength:"32"}}
                />
              </Box>
            : ""
            }

            { this.state.edit ?
              <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "end", justifyContent:"right", marginTop: 1, marginRight: 1 }}>
                <TextField
                  id="c_a_local"
                  label="Local"
                  value={locationAddress_local || ''}
                  variant="outlined"
                  onChange={this.changeValue}
                  sx={{maxWidth: "128px", minWidth: "10%", marginLeft: 1}}
                  onFocus={event => { event.target.select(); }}
                  inputProps={{maxLength:"128"}}
                />
                <TextField
                  id="c_a_floor"
                  label="Floor"
                  value={locationAddress_floor || ''}
                  variant="outlined"
                  onChange={this.changeValue}
                  sx={{maxWidth: "64px", minWidth: "10%", marginLeft: 1}}
                  onFocus={event => { event.target.select(); }}
                  inputProps={{maxLength:"16"}}
                />
                <TextField
                  id="c_a_zip"
                  label="Postal Code"
                  value={locationAddress_postalCode || ''}
                  variant="outlined"
                  onChange={this.changeValue}
                  sx={{maxWidth: "96px", minWidth: "10%", marginLeft: 1}}
                  onFocus={event => { event.target.select(); }}
                  inputProps={{maxLength:"16"}}
                />
              </Box>
              :
              <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "end", justifyContent:"right", marginTop: 1, marginRight: 1 }}>
                {locationAddress}
              </Box> 
              }

              { this.state.edit ?
              <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "end", justifyContent:"right", marginTop: 1, marginRight: 1 }}>
                <Box sx={{ display: 'contents', flexDirection: 'column', textAlign: "start", justifyContent:"left" }}>
                  <IconButton aria-label="GPS Location" sx={{marginTop: 1, marginLeft: 0}}>
                    <LocationOnIcon titleAccess="GPS Location"/>
                  </IconButton>
                </Box>
                <Box sx={{ display: 'contents', flexDirection: 'column', textAlign: "start", justifyContent:"left" }}>
                  <TextField
                    id="gps_lt"
                    label="GPS Lat"
                    value={locationAddress_gps [ "lt" ] || ''}
                    variant="outlined"
                    onChange={this.changeValue}
                    sx={{maxWidth: "128px", minWidth: "40%", marginLeft: 1}}
                    onFocus={event => { event.target.select(); }}
                    inputProps={{maxLength:"128"}}
                  />
                </Box>
                <Box sx={{ display: 'contents', flexDirection: 'column', textAlign: "start", justifyContent:"left" }}>
                  <TextField
                    id="gps_lg"
                    label="GPS Long"
                    value={locationAddress_gps [ "lg" ] || ''}
                    variant="outlined"
                    onChange={this.changeValue}
                    sx={{maxWidth: "64px", minWidth: "40%", marginLeft: 1}}
                    onFocus={event => { event.target.select(); }}
                    inputProps={{maxLength:"16"}}
                  />
                </Box>
              </Box>
              :
              <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "end", justifyContent:"right", marginTop: 1, marginRight: 1 }}>
                {locationGPS}
              </Box> 
              
            }        

        </Box>
      </Box>         
          
      <Box sx={{ flexDirection: 'row', width: '100%', display: 'flex' }}>
        <Box sx={{ flexDirection: 'column', justifyContent:"right", width: '100%' }}>
          <CardActions disableSpacing sx={{display: "block", textAlign: "end" }}>            
          <ExpandMore
            expand={this.state.expanded}
            onClick={this.handleExpandClick}
            aria-expanded={this.state.expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
          </CardActions>
        </Box>
      </Box>
   
      <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "end", justifyContent:"center", alignItems: "center", paddingTop: "8px", paddingBottom: "8px" }}>
            { showSave ?
              <Button
                variant="contained"
                size="small"
                sx={{ color: "#e3e3e5" }}
                startIcon={<SaveIcon />}
                onClick={this.pushData}
              >
                Save
              </Button>
              : ""
            }
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit sx={{width: "100% !important"}}>
        <CardContent sx={{paddingTop: "2px"}}>

        { this.state.detailsVisible ?
          <Box sx={{ width: '100%' }}>
            <Tabs
              value={tabValue}
              onChange={this.handleTabChange}
              centered
              indicatorColor="secondary"
            >
              <Tab label="Schedule" value="schedule" icon={<ScheduleIcon />} iconPosition="start"/>
              <Tab label="Employees" value="employee" icon={<PeopleIcon />} iconPosition="start"/>
              <Tab label="Images" value="images" icon={<PermMediaIcon />} iconPosition="start"/>
              <Tab label="POS" value="pos" icon={<PointOfSaleIcon />} iconPosition="start"/>
            </Tabs>
          </Box>
        : ""
        }


          { this.state.detailsVisible && this.state.tabValue === "schedule" ?
            <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "middle", justifyContent:"center", alignItems: "center", paddingTop: "2px" }}>
              <Table sx={{ minWidth: 700, textAlign: "middle", justifyContent:"center" }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      <IconButton aria-label="Add Location Schedule" title="Add Location Schedule" onClick={() => this.toggleAddSchedule()}> 
                        <Avatar alt="Add Location Schedule" sx={{ width: 24, height: 24, color: "#273442" }}>
                          {this.state.addNewSchedule ? <FaMinusCircle/> : <FaPlusCircle/>}
                        </Avatar>
                      </IconButton>
                      Day
                      </StyledTableCell>
                    <StyledTableCell align="left">From</StyledTableCell>
                    <StyledTableCell align="left">To</StyledTableCell>                  
                    <StyledTableCell align="right">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allSchedules}
                </TableBody>
              </Table>
            </Box>
          : ""
          }

          { this.state.detailsVisible  && this.state.tabValue === "employee" ?
            <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "middle", justifyContent:"center", alignItems: "center", paddingTop: "2px" }}>
              {this.state.editEmployees ?
                <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "middle", justifyContent:"center", alignItems: "center"}}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: "rigth", justifyContent:"rigth", alignItems: "rigth"}}>
                    <FormControl sx={{marginTop: 1, width: "80%" }}>
                      <InputLabel id="locationEmployeesSelect">Location Employees</InputLabel>
                      <Select
                        labelId="locationEmployeesSelect"
                        id="locationEmployeesCheckBox"
                        multiple
                        value={locationEmployeesNames}
                        onChange={this.changeLocationEmployee}
                        input={<OutlinedInput label="Location Employees" />}                  
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, width: "80%"}}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}                    
                          /* selected.join(', ')} */
                        sx={{ minWidth: "300px", maxWidth: "100%", width: "800px" }}
                        MenuProps={LocationEmployeeSelectMenuProps}
                        disabled={!this.state.editEmployees}
                      >
                        {locationEmployees.map((locationEmployee) => (
                          <MenuItem key={locationEmployee} value={locationEmployee}>
                            <Checkbox checked={locationEmployeesNames.indexOf(locationEmployee) > -1} />
                            <ListItemText primary={locationEmployee} />
                          </MenuItem>
                        ))}
                      </Select>                    
                    </FormControl>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: "rigth", justifyContent:"rigth", alignItems: "rigth"}}>
                    {showEdployeesSave ?
                      <IconButton aria-label="settings" onClick={this.pushEmployeeData}>
                        <SaveIcon
                          sx={{ color: "#4287f5" }}              
                        />
                    </IconButton>
                    : ""}
                    {showEdployeesSave ?
                      <IconButton aria-label="settings" onClick={this.resetEmployeeData}>
                        <CancelIcon
                          sx={{ color: "gray" }}              
                        />
                    </IconButton>
                    : ""}
                  </Box>
                </Box>
                :
                <Table sx={{ minWidth: 700, textAlign: "middle", justifyContent:"center" }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>
                        <IconButton aria-label="Add Location Employee" title="Add Location Employee" onClick={() => this.toggleAddEmployee()}> 
                          <Avatar alt="Add Location Employee" sx={{ width: 24, height: 24, color: "#273442" }}>
                            {this.state.addNewEmployee ? <FaMinusCircle/> : <FaPlusCircle/>}
                          </Avatar>
                        </IconButton>
                        Image
                      </StyledTableCell>
                      <StyledTableCell align="left">First Name</StyledTableCell>
                      <StyledTableCell align="left">Last Name</StyledTableCell>            
                      <StyledTableCell align="left">Phone</StyledTableCell>            
                      <StyledTableCell align="left">Email</StyledTableCell>
                      <StyledTableCell align="left">Birthday</StyledTableCell>
                      <StyledTableCell align="left">Username</StyledTableCell>
                      <StyledTableCell align="left">Card</StyledTableCell>
                      <StyledTableCell align="left">Level</StyledTableCell>
                      <StyledTableCell align="left">
                        Action
                        <IconButton aria-label="settings" onClick={this.handleEmployeesEditClick}>
                          <EditIcon
                            sx={{ color: this.state.editEmployees ? "#8f241d" : "#e3e3e5" }}              
                          />
                        </IconButton>
                      </StyledTableCell>
                      {/* <StyledTableCell align="right">Action</StyledTableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allData}
                  </TableBody>
                </Table>
              }              
            </Box>
          : ""
          }

          { this.state.detailsVisible  && this.state.tabValue === "images" ?
            locationImages
          : ""
          }

          { this.state.detailsVisible  && this.state.tabValue === "pos" ?
          <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "middle", justifyContent:"center", alignItems: "center"}}>
            <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "left", justifyContent:"left", alignItems: "left", backgroundColor: "#ddddd4", height: "38px", borderRadius: "8px"}}>
              <Box sx={{ display: 'block', flexDirection: 'column', textAlign: "middle", justifyContent:"center", alignItems: "center", width: "80%", marginLeft: 1 }}>
                
                <IconButton aria-label="Add POS" title="Add POS" onClick={() => this.toggleAddPOS()}>       
                  <Avatar alt="Add POS" sx={{ width: 24, height: 24, color: "#273442" }}>
                  {this.state.addNewPOS ? <FaMinusCircle/> : <FaPlusCircle/>}
                    </Avatar>
                </IconButton>

              </Box>
            </Box>
            <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "middle", justifyContent:"center", alignItems: "center"}}>
              {posList}
            </Box>
          </Box>  
          : ""
          }
          
          </CardContent>

        </Collapse>

      </Box>
    </Card>
   
    );

  }
}

export default Location;
