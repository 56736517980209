import React from 'react';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FaHome, FaPizzaSlice, FaHamburger, FaMapMarkedAlt, FaEuroSign, FaUserTie, FaHospitalUser } from "react-icons/fa";
import Skeleton from '@mui/material/Skeleton';

//import './css/sideBar.scss';
import './css/elements.css';
import './css/dashboard.css';

import Client from './components/Client';
import Config from './components/ClientConfig';
//import Product from './components/Product';
import Person from './components/Person';
import PersonsFilters from './components/PersonsFilters';



let theme = createTheme();

class Persons extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      persons: null,
      //locationsGroups: null,
      addNewPerson: false,
      filterData: {}
    };

    const { loading = false } = props;

    this.applyFilters = this.applyFilters.bind(this);    
    this.toggleAdd = this.toggleAdd.bind(this);
    this.personAdded = this.personAdded.bind(this);
    this.personUpdated = this.personUpdated.bind(this);
  }

  componentDidMount() {
    let queryData = {
      "orderByReverse" : "modifiedOn",
      "limit" : 20
    }  
    let data = this.state.client.getData ( Config.API_URL_PERSON, queryData );
    if ( data ) {
      if ( data.persons ) this.setState ( { persons: data.persons });
    }    
    this.setState ( { filterData: queryData } );
  }

  componentWillUnmount() {    
  }

  //Function to change the main Products filter state
  applyFilters ( queryData ) {
    //console.log ( queryData );
    if ( queryData ) {
      let data = this.state.client.filterPersons ( queryData );
      if ( data ) {
        if ( data.persons ) this.setState ( { persons: data.persons });
      }
      this.setState ( { filterData: queryData } );
    }
  }

  toggleAdd ( buttonState ) {
    let doAdd = this.state.addNewPerson;
    if ( buttonState !== undefined ) doAdd = buttonState;
    this.setState ( {addNewPerson: doAdd} );
  }

  personUpdated ( ) {
    let queryData = { };
    if ( this.state.filterData ) queryData = this.state.filterData;
    this.applyFilters ( queryData );

    let data = this.state.client.getData ( Config.API_URL_PERSON, queryData );
    if ( data ) {
      if ( data.persons ) this.setState ( { persons: data.persons });
    }    
    this.setState ( { filterData: queryData } );

    window.scrollTo(0, 0);
  }

  personAdded ( ) {
    let queryData = { };
    if ( this.state.filterData ) queryData = this.state.filterData;
    this.setState ( { persons: null });
    this.applyFilters ( queryData ); 
    this.toggleAdd ( false ); 

    let data = this.state.client.getData ( Config.API_URL_PERSON, queryData );
    if ( data ) {
      if ( data.persons ) this.setState ( { persons: data.persons });
    }    
    this.setState ( { filterData: queryData } );

    window.scrollTo(0, 0);
  }

  render() {
    let personsList;

    let geoData = {};
    if ( this.props.geoData ) geoData = this.props.geoData;

    let countries = {};
    let counties = {};
    Object.keys(geoData).forEach(key => {
      let fData = geoData [ key ];
      countries [ key ] = fData.name;
      //console.log ( fData );
      Object.keys ( fData ).forEach( fKey => {
        if ( fKey !== "name" ) counties [ fKey ] = fData [ fKey ].name;
      });
    });

    let personsCounter = 0;
    if ( this.state.persons ) {
      personsCounter = this.state.persons.length;
      personsList = this.state.persons.map ( (person) => {
        return <Person
          key={person.iref}
          person={person}
          geoData={this.props.geoData}
          countries = {countries}
          personUpdated = {this.personUpdated}
        />
      });
    }   

    return (
      <div style={{ backgroundColor: "#273442", height: "100%" }}>
        <div className='sticky'>

          <PersonsFilters
            applyFilters = {this.applyFilters}            
            toggleAdd = {this.toggleAdd}
            personsCounter = {personsCounter}
            countries = {countries}
            counties = {counties}            
            sx={{ position: 'sticky', top: 0}}
            addNewPerson = {this.state.addNewPerson}
          />

        </div>

        { this.state.addNewPerson ?
        <Person
          person={{}}
          geoData={this.props.geoData}
          countries = {countries}
          personUpdated = {this.personAdded}
        />
        : ""
        }

        {this.loading ? <Skeleton animation="wave" variant="rounded" width={800} height={400} /> : personsList }

      </div>

    );

  }
}

export default Persons;