import React from 'react';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent';
import Client from './../components/Client';
import { ProSidebar, Menu as SidebarMenu, MenuItem as SidebarMenuItem, SubMenu, SidebarHeader, SidebarFooter, SidebarContent } from 'react-pro-sidebar';
import { FaHome, FaPizzaSlice, FaHamburger, FaMapMarkedAlt, FaEuroSign, FaUserTie, FaHospitalUser, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Link } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import ChatIcon from '@mui/icons-material/Chat';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import EuroIcon from '@mui/icons-material/Euro';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import HomeIcon from '@mui/icons-material/Home';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ImageIcon from '@mui/icons-material/Image';

import Visibility from './../Visibility';

let theme = createTheme();


class SideBar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      sideBar_collapsed: false,
      restricted: {}
    };

    this.toggleSideCollapsed = this.toggleSideCollapsed.bind(this);
    this.setActiveContent = this.setActiveContent.bind(this);
    this.restricted = new Visibility ();
  }

  componentDidMount() {
    /* let restricted = new Visibility ();
    if (restricted) {
      this.setState ({restricted: restricted});
    }  */
  }

  componentWillUnmount() {
    
  }

  toggleSideCollapsed () {
    let collapsed = !this.state.sideBar_collapsed;
    //let collapsed = !this.state.sideBar_collapsed;
    //this.setSideCollapsed ( collapsed => !collapsed );
    this.setState({ sideBar_collapsed: !this.state.sideBar_collapsed });

    this.props.toggleSideCollapsed ( collapsed );
  }

  setActiveContent ( e ) {
    //this.setState({ mainContent : e })
    this.props.setActiveContent ( e );
  }


  render() {

    
    let showPage = true;
    let restricted = this.restricted.getAllFalse();
    if (this.restricted)
      if (this.restricted.scope !== undefined && this.restricted.scope !== null)
        if (Object.keys(this.restricted.scope).length > 0)
          restricted = this.restricted.scope;
    
    let dashboard_show = false;
    let products_show = false;
    let products_products_show = false;
    let products_combos_show = false;
    let products_categories_show = false;
    let products_classes_show = false;

    let priceLists_show = false;
    let priceLists_locations_show = false;
    let priceLists_promotions_show = false;
    let priceLists_companies_show = false;
    let priceLists_products_show = false;
    let priceLists_combos_show = false;
    let priceLists_acquisitions_show = false;

    let clients_show = false;
    let clients_accounts_show = false;
    let clients_families_show = false;
    let clients_professional_show = false;

    let locations_show = false;
    let locations_locations_show = false;
    let locations_groups_show = false;
    let locations_employees_show = false;

    let users_show = false;
    let users_internal_show = false;
    let users_clients_show = false;

    let registry_show = false;
    let registry_persons_show = false;
    let registry_companies_show = false;

    let config_show = false;
    let config_platform_show = false;
    let config_identity_show = false;
    let config_salesapp_show = false;
    let config_website_show = false;
    let config_mobileapp_show = false;

    let messages_show = false;
    let messages_internal_show = false;
    let messages_customers_show = false;

    let logs_show = false;
    let logs_internal_show = false;
    let logs_customers_show = false;

    let biReports_show = false;
    let biReports_sales_show = false;
    let biReports_forecast_show = false;
    let biReports_products_show = false;
    let biReports_categories_show = false;
    let biReports_orders_show = false;
    let biReports_clients_show = false;
        
    if (restricted.dashboard)
      if (restricted.dashboard.visible)
        dashboard_show = true;
    if (restricted.products) {
      if (restricted.products.visible)
        products_show = true;
      if (restricted.products.products)
        if (restricted.products.products.visible)
          products_products_show = true;
      if (restricted.products.combos)
        if (restricted.products.combos.visible)
          products_combos_show = true;
      if (restricted.products.categories)
        if (restricted.products.categories.visible)
          products_categories_show = true;
      if (restricted.products.classes)
        if (restricted.products.classes.visible)
          products_classes_show = true;
    }
    if (restricted.priceLists) {
      if (restricted.priceLists.visible)
        priceLists_show = true;
      if (restricted.priceLists.locations)
        if (restricted.priceLists.locations.visible)
          priceLists_locations_show = true;
      if (restricted.priceLists.companies)
        if (restricted.priceLists.companies.visible)
          priceLists_companies_show = true;
      if (restricted.priceLists.promotions)
        if (restricted.priceLists.promotions.visible)
          priceLists_promotions_show = true;
      if (restricted.priceLists.products)
        if (restricted.priceLists.products.visible)
          priceLists_products_show = true;
      if (restricted.priceLists.combos)
        if (restricted.priceLists.combos.visible)
          priceLists_combos_show = true;
      if (restricted.priceLists.acquisitions)
        if (restricted.priceLists.acquisitions.visible)
          priceLists_acquisitions_show = true;
    }

    if (restricted.clients) {
      if (restricted.clients.visible)
        clients_show = true;
      if (restricted.clients.accounts)
        if (restricted.clients.accounts.visible)
          clients_accounts_show = true;
      if (restricted.clients.families)
        if (restricted.clients.families.visible)
          clients_families_show = true;
      if (restricted.clients.professional)
        if (restricted.clients.professional.visible)
          clients_professional_show = true;
    }

    if (restricted.locations) {
      if (restricted.locations.visible)
        locations_show = true;
      if (restricted.locations.locations)
        if (restricted.locations.locations.visible)
          locations_locations_show = true;
      if (restricted.locations.groups)
        if (restricted.locations.groups.visible)
          locations_groups_show = true;
      if (restricted.locations.employees)
        if (restricted.locations.employees.visible)
          locations_employees_show = true;
    }

    if (restricted.users) {
      if (restricted.users.visible)
        users_show = true;
      if (restricted.users.internal)
        if (restricted.users.internal.visible)
          users_internal_show = true;
      if (restricted.users.clients)
        if (restricted.users.clients.visible)
          users_clients_show = true;
    }

    if (restricted.registry) {
      if (restricted.registry.visible)
        registry_show = true;
      if (restricted.registry.persons)
        if (restricted.registry.persons.visible)
          registry_persons_show = true;
      if (restricted.registry.companies)
        if (restricted.registry.companies.visible)
          registry_companies_show = true;
    }

    if (restricted.config) {
      if (restricted.config.visible)
        config_show = true;
      if (restricted.config.platform)
        if (restricted.config.platform.visible)
          config_platform_show = true;
      if (restricted.config.identity)
        if (restricted.config.identity.visible)
          config_identity_show = true;
      if (restricted.config.salesapp)
        if (restricted.config.salesapp.visible)
          config_salesapp_show = true;
      if (restricted.config.website)
        if (restricted.config.website.visible)
          config_website_show = true;
      if (restricted.config.mobileapp)
        if (restricted.config.mobileapp.visible)
          config_mobileapp_show = true;
    }

    if (restricted.messages) {
      if (restricted.messages.visible)
        messages_show = true;
      if (restricted.messages.internal)
        if (restricted.messages.internal.visible)
          messages_internal_show = true;
      if (restricted.messages.customers)
        if (restricted.messages.customers.visible)
          messages_customers_show = true;
    }

    if (restricted.logs) {
      if (restricted.logs.visible)
        logs_show = true;
      if (restricted.logs.internal)
        if (restricted.logs.internal.visible)
          logs_internal_show = true;
      if (restricted.logs.customers)
        if (restricted.logs.customers.visible)
          logs_customers_show = true;
    }

    if (restricted.biReports) {
      if (restricted.biReports.visible)
        biReports_show = true;
      if (restricted.biReports.sales)
        if (restricted.biReports.sales.visible)
          biReports_sales_show = true;
      if (restricted.biReports.forecast)
        if (restricted.biReports.forecast.visible)
          biReports_forecast_show = true;
      if (restricted.biReports.products)
        if (restricted.biReports.products.visible)
          biReports_products_show = true;
      if (restricted.biReports.categories)
        if (restricted.biReports.categories.visible)
          biReports_categories_show = true;
      if (restricted.biReports.orders)
        if (restricted.biReports.orders.visible)
          biReports_orders_show = true;
      if (restricted.biReports.clients)
        if (restricted.biReports.clients.visible)
          biReports_clients_show = true;
    }

    return (
        <div className="sideBar">
          {showPage ?
          <ProSidebar collapsedWidth="70px" collapsed={this.state.sideBar_collapsed ? 1 : 0} sx={{ paddingTop: "0px", paddingBottom: "0px"}}>
          <SidebarMenu iconShape="square" sx={{ paddingTop: "0px", paddingBottom: "0px"}}>
            <SidebarMenuItem
              icon={this.state.sideBar_collapsed ? <FaChevronRight /> : <FaChevronLeft />}
              onClick={this.toggleSideCollapsed}
              collapsed={this.state.sideBar_collapsed ? 1 : 0}
              sx={{ paddingTop: "0px", paddingBottom: "0px"}}
            >
              <Link to="/" />
            </SidebarMenuItem>
            {dashboard_show ?
              <SidebarMenuItem icon={<HomeIcon />}>
                Dashboard
                <Link to="#" onClick={() => this.setActiveContent("stats")} />
              </SidebarMenuItem>
              : ""
            }
            {biReports_show ?
              <SubMenu title="BI Report" icon={<AnalyticsIcon />}>
                {biReports_sales_show ?
                  <SidebarMenuItem>
                    Sales
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("rap_sales")} />
                  </SidebarMenuItem>
                  : ""
                }
                {biReports_forecast_show ?
                  <SidebarMenuItem>
                    Forecast
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("rap_sales2")} />
                  </SidebarMenuItem>
                  : ""
                }
                {biReports_products_show ?
                  <SidebarMenuItem>
                    Products
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("rap_products")} />
                  </SidebarMenuItem>
                  : ""
                }
                {biReports_categories_show ?
                  <SidebarMenuItem>
                    Categories
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("rap_products2")} />
                  </SidebarMenuItem>
                  : ""
                }
                {biReports_orders_show ?
                <SidebarMenuItem>
                  Orders
                  <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("rap_orders")} />
                </SidebarMenuItem>
                  : ""
                }
                {biReports_clients_show ?                                      
                  <SidebarMenuItem>
                    Clients
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("rap_clients")} />
                  </SidebarMenuItem>
                  : ""
                }
              </SubMenu>
              : ""
            }
            {products_show ?
              <SubMenu title="Products" icon={<FastfoodIcon />}>
              {products_products_show ?              
                <SidebarMenuItem>
                  Products
                  <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("products")} />
                </SidebarMenuItem>
                : ""
              }
              {products_combos_show ?
                <SidebarMenuItem>
                  Combos
                  <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("combos")} />
                </SidebarMenuItem>
                : ""
              }
              {products_categories_show ?
                <SidebarMenuItem>
                  Categories
                  <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("categories")} />
                </SidebarMenuItem>
                : ""
              }
              {products_classes_show ?
                <SidebarMenuItem>
                  Classes
                  <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("classes")} />
                </SidebarMenuItem>
                : ""
              }
              </SubMenu>
              : ""
            }

            {priceLists_show ?
              <SubMenu title="Price Lists" icon={<EuroIcon />}>
                {priceLists_locations_show ?
                  <SidebarMenuItem>
                    Locations
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("listsPrice")} />
                  </SidebarMenuItem>
                  : ""
                }
                {priceLists_promotions_show ?
                  <SidebarMenuItem>
                    Promotions
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("listsPromotion")} />
                  </SidebarMenuItem>
                  : ""
                }
                {priceLists_companies_show ?
                  <SidebarMenuItem>
                    Companies
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("listsCompany")} />
                  </SidebarMenuItem>
                  : ""
                }
                {priceLists_products_show ?
                  <SidebarMenuItem>
                    Products
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("listsProduct")} />
                  </SidebarMenuItem>
                  : ""
                }
                {priceLists_combos_show ?
                  <SidebarMenuItem>
                    Combos
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("listsCombo")} />
                  </SidebarMenuItem>
                  : ""
                }
                {priceLists_acquisitions_show ?
                  <SidebarMenuItem>
                    Acquisitions
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("listsAcquisition")} />
                  </SidebarMenuItem>
                  : ""
                }
              </SubMenu>
              : ""
            }

            {clients_show ?
              <SubMenu title="Magic" icon={<AutoAwesomeIcon />}>
                {clients_accounts_show ?
                  <SidebarMenuItem>
                    Recom
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("recommendations")} />
                  </SidebarMenuItem>
                  : ""
                }
                {clients_families_show ?
                  <SidebarMenuItem>
                    Promotions
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("clientsFamilies")} />
                  </SidebarMenuItem>
                  : ""
                }
                {clients_professional_show ?
                  <SidebarMenuItem>
                    Other
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("clientsProfessionals")} />
                  </SidebarMenuItem>
                  : ""
                }
              </SubMenu>
              : ""
            }

            {clients_show ?
              <SubMenu title="Clients" icon={<ShoppingBagIcon />}>
                {clients_accounts_show ?
                  <SidebarMenuItem>
                    Accounts
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("clientsAccounts")} />
                  </SidebarMenuItem>
                  : ""
                }
                {clients_families_show ?
                  <SidebarMenuItem>
                    Families
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("clientsFamilies")} />
                  </SidebarMenuItem>
                  : ""
                }
                {clients_professional_show ?
                  <SidebarMenuItem>
                    Professional
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("clientsProfessionals")} />
                  </SidebarMenuItem>
                  : ""
                }
              </SubMenu>
              : ""
            }

            {locations_show ?
              <SubMenu title="Locations" icon={<EditLocationAltIcon />}>
                {locations_locations_show ?
                  <SidebarMenuItem>
                    Locations
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("locations")} />
                  </SidebarMenuItem>
                  : ""
                }
                {locations_groups_show ?
                  <SidebarMenuItem>
                    Groups
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("locations_groups")} />
                  </SidebarMenuItem>
                  : ""
                }
                {locations_employees_show ?
                  <SidebarMenuItem>
                    Employees
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("employees")} />
                  </SidebarMenuItem>
                  : ""
                }
              </SubMenu>
              : ""
            }

            {users_show ?
              <SubMenu title="Users" icon={<PeopleAltIcon />}>
                {users_internal_show ?
                  <SidebarMenuItem>
                    Internal
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("usersInternal")} />
                  </SidebarMenuItem>
                  : ""
                }
                {users_clients_show ?
                  <SidebarMenuItem>
                    Clients
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("usersClients")} />
                  </SidebarMenuItem>
                  : ""
                }
              </SubMenu>
              : ""
            }

            {registry_show ?
              <SubMenu title="Registry" icon={<AssignmentIndIcon />}>
                {registry_persons_show ?
                  <SidebarMenuItem>
                    Persons
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("addressBook_persons")} />
                  </SidebarMenuItem>
                  : ""
                }
                {registry_companies_show ?
                  <SidebarMenuItem>
                    Companies
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("addressBook_companies")} />
                  </SidebarMenuItem>
                  : ""
                }
              </SubMenu>
              : ""
            }
            {config_show ?
              <SubMenu title="Config" icon={<SettingsIcon />}>
                {config_platform_show ?
                  <SidebarMenuItem>
                    Platform
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("config_platform")} />
                  </SidebarMenuItem>
                  : ""
                }
                {config_identity_show ?
                  <SidebarMenuItem>
                    Identity
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("config_identity")} />
                  </SidebarMenuItem>
                  : ""
                }
                {config_salesapp_show ?
                  <SidebarMenuItem>
                    SalesApp
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("config_salesapp")} />
                  </SidebarMenuItem>
                  : ""
                }
                {config_website_show ?
                  <SidebarMenuItem>
                    WebSite
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("config_website")} />
                  </SidebarMenuItem>
                  : ""
                }
                {config_mobileapp_show ?
                  <SidebarMenuItem>
                    MobileApp
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("config_mobileapp")} />
                  </SidebarMenuItem>
                  : ""
                }
              </SubMenu>
              : ""
            }
            {messages_show ?
              <SubMenu title="Messages" icon={<ChatIcon />}>
                {messages_internal_show ?
                  <SidebarMenuItem>
                    Internal
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("config_platform")} />
                  </SidebarMenuItem>
                  : ""
                }
                {messages_customers_show ?
                  <SidebarMenuItem>
                    Customers
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("config_salesapp")} />
                  </SidebarMenuItem>
                  : ""
                }
              </SubMenu>
              : ""
            }

            {logs_show ?
              <SubMenu title="Logs" icon={<SummarizeIcon />}>
                {logs_internal_show ?
                  <SidebarMenuItem>
                    Internal
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("config_platform")} />
                  </SidebarMenuItem>
                  : ""
                }
                {logs_customers_show ?
                  <SidebarMenuItem>
                    Customers
                    <Link id="MenuItemHome" to="#" onClick={() => this.setActiveContent("config_salesapp")} />
                  </SidebarMenuItem>
                  : ""
                }
              </SubMenu>
              : ""
            }
          </SidebarMenu>
        </ProSidebar>
        : ""
        }
        </div>
    );

  }
}

export default SideBar;