import React from 'react';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
import { FaHome, FaPizzaSlice, FaHamburger, FaMapMarkedAlt, FaEuroSign, FaUserTie, FaHospitalUser, FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
/* import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'; */
import Allergen from './Allergen';
import Category from './Category';
import SubCategory from './SubCategory';
import ProductClass from './ProductClass';
import DropDown from './DropDown';

//import './css/dashboard.css';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import noImage from './../images/icons/noImage_1.png';
import idle_off from './../images/icons/idle_on.png';
import idle_on from './../images/icons/idle_off.png';
import registered_off from './../images/icons/registered_on.png';
import registered_on from './../images/icons/registered_off.png';
import active_off from './../images/icons/active_on.png';
import active_on from './../images/icons/active_off.png';
import discontinued_off from './../images/icons/discontinued_on.png';
import discontinued_on from './../images/icons/discontinued_off.png';
import noUser_on from './../images/icons/noUser_on.png';
import noUser_off from './../images/icons/noUser_off.png';

import franchise_on from './../images/icons/franchise_on.png';
import franchise_off from './../images/icons/franchise_off.png';
import supplier_on from './../images/icons/supplier_on.png';
import supplier_off from './../images/icons/supplier_off.png';
import client_on from './../images/icons/client_on.png';
import client_off from './../images/icons/client_off.png';
import collaboration_on from './../images/icons/collaboration_on.png';
import collaboration_off from './../images/icons/collaboration_off.png';

import BackOfficeConfig from '../Config';
import Config from './ClientConfig';
import Client from './Client';

let theme = createTheme();

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

class CompaniesFilters extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      productCategories: null,
      expanded: false,
      addNewCompany: false,
      filters_reset: 1,
      

      filtersCounter: 0,
      resetState: false,
      resetClasses: false,
      resetCategories: false,
      resetCategoriesList: { },
      resetSubCategoriesList: { },
      resetClassesList: { },
      resetSubCategories: false,
      filters: { },
      countrySelected: "country061",
      counties : {},
      countySelected: "",
      locationGroupSelect: "",

    };

    this.queryData = {
      "orderByReverse" : "modifiedOn",
      "limit" : 20
    };

    this.companyRelation = { "collaboration": 0, "client": 1, "franchise": 2, "supplier": 9 };
    this.searchKeyword = React.createRef();
    this.categoriesStates = { };

    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.resetStatus = this.resetStatus.bind(this);
    this.removeQueryElement = this.removeQueryElement.bind(this);
    this.addQueryElement = this.addQueryElement.bind(this);
    this.toggleElement = this.toggleElement.bind(this);
    this.searchKey = this.searchKey.bind(this);
    
    this.resetFilters = this.resetFilters.bind(this);
    this.setFlagsFilters = this.setFlagsFilters.bind(this);
    this.setStatusFilters = this.setStatusFilters.bind(this);
    
    
    
    /* this.getClasses = this.getClasses.bind(this); */
    this.toggleAdd = this.toggleAdd.bind(this);

    this.addCountry = this.addCountry.bind(this);
    this.addCounty = this.addCounty.bind(this);
    //this.addLocationsGroup = this.addLocationsGroup.bind(this);
    this.addType = this.addType.bind(this);
  }

  componentDidMount() {        
    /* let productsCategories = this.state.client.getProductsCategories ( );
    this.setState ( { productCategories: productsCategories.productCategories }); */

    let counties = { };
    if ( Object.entries(this.props.counties).length !== 0 ) counties = this.props.counties;
    this.setState ( { counties: counties } );

    let queryData = {
      country: this.state.countrySelected,
      orderBy_Name: true
    }
    //queryData [ "user_type_gte" ] = "15"
    let data = this.state.client.getData ( Config.API_URL_COUNTY, queryData );
    if ( data ) {
        this.setState ( { counties: data.counties });
    } else {
      this.setState ( { counties: {} });
    }

  }

  componentWillUnmount() {
    //this.searchKeyword = React.useRef(null);
    /* let counties = { };
    if ( Object.entries(this.props.counties).length !== 0 ) counties = this.props.counties;
    this.setState ( { counties: counties } ); */
  }

  /* componentWillReceiveProps ( nextProps ) {
    
  } */

  componentDidUpdate ( newProps ) {
    /* if ( prevProps.classesList !== this.props.classesList ) {
      let classesRender = "";
      if ( this.props.classesList ) {
        let classesArr = Object.entries ( this.props.classesList );

        var classesStates = this.state.resetClassesList;
        classesRender = classesArr.map ( (cls) => {
          let iref = "";
          if ( cls.length === 2 ) iref = cls [ 1 ].iref;          
          else iref = cls [ 0 ].iref;          
          classesStates [ iref ] = false;
        });
        this.setState ( {resetClassesList: classesStates} );
      }
    } */
    if ( newProps.addNewCompany != this.props.addNewCompany ) {
      this.setState ( { addNewCompany: this.props.addNewCompany } )
    }
  }

  /* componentWillReceiveProps ( newProps ) {
    if ( newProps.addNewCompany != this.props.addNewCompany ) {
      this.setState ( { addNewCompany: this.props.addNewCompany } )
    }
  } */

  handleExpandClick ( ) {
    this.setState({ expanded: !this.state.expanded });
  }

  setStatusFilters ( value ) {
    this.setState ( {filters_status_client: value} );
    this.setState ( {filters_status_collaboration: value} );
    this.setState ( {filters_status_franchise: value} );
    this.setState ( {filters_status_supplier: value} );
    this.setState ( {filters_status_noUser: value} );
  }

  setFlagsFilters ( value ) {
    //this.setState ( {filters_flags_canGenerateFidelityPoints: value} );
    this.setState ( {filters_flags_isDeliverable: value} );
    this.setState ( {filters_flags_isDiscountable: value} );
    this.setState ( {filters_flags_isForSale: value} );
    this.setState ( {filters_flags_isNew: value} );
    this.setState ( {filters_flags_isSellable: value} );
  }

  toggleAdd ( ) {
    let addButton = this.state.addNewCompany;
    this.setState ( {addNewCompany: !addButton} );
    this.props.toggleAdd ( !addButton );
  }

  searchKey ( e ) {    
    let keyword = e.target.value;
    if ( keyword.length >= 2 ) {
      this.addQueryElement ( "nameLike", keyword );
      this.props.applyFilters ( this.queryData );
    } else {
      if ( "nameLike" in this.queryData ) {
        delete this.queryData [ "nameLike" ];
        this.props.applyFilters ( this.queryData );
      }
    }
    let filtersCounter = Object.keys(this.queryData).length;
    this.setState ( {filtersCounter: filtersCounter} );
  }

  resetStatus ( element ) {
    /* if ( "status" in this.queryData )        
      delete this.queryData.status; */
    if ( element !== "client" ) this.setState ( { filters_status_client: 0 } );
    if ( element !== "collaboration" ) this.setState ( { filters_status_collaboration: 0 } );
    if ( element !== "franchise" ) this.setState ( { filters_status_franchise: 0 } );
    if ( element !== "supplier" ) this.setState ( { filters_status_supplier: 0 } );
    //if ( element !== "noUser" ) this.setState ( { filters_status_noUser: 0 } );    
  }

  removeQueryElement ( element ) {
    if ( element ) {
      if ( element in this.queryData ) {
        if ( this.queryData [ element ] !== null && this.queryData [ element ] !== undefined ) {
          delete this.queryData [ element ];
        }
      }
    }
  }

  addQueryElement ( element, value ) {
    //console.log ( "addQueryElement: " + element + " -> " + value );
    if ( element ) {
      if ( element in this.queryData ) {
        if ( this.queryData [ element ] !== null && this.queryData [ element ] !== undefined && value !== null && value !== undefined ) {
          if ( this.queryData [ element ] != value ) this.queryData [ element ] = value;
          else delete this.queryData [ element ];
        } else {
          delete this.queryData [ element ];
        }
      } else {
        if ( value !== null && value !== undefined )
          this.queryData [ element ] = value;
        else
          this.queryData [ element ] = 1;
      }
    }

    //console.log ( "addQueryElement ---> " );
    //console.log ( this.queryData );
  }

  toggleElement ( prefix, element, value ) {
    let stateName = "filters_" + prefix + "_" + element;
    let status = this.state [ stateName ];
    this.setState ( { [stateName]: !status } );
    //console.log ( prefix + " - " + element + " - " +  value );
    if ( prefix == "status" ) {
      switch ( element ) {
        case "collaboration":
          element = "isCollaboration";
          break;
        case "client":
          element = "isclient";
          break;
        case "franchise":
          element = "isFranchise";
          break;
        case "supplier":
          element = "isSupplier";
          break;
      }
      //element = "user_status";
    }

    if ( value !== null && value !== undefined ) {
      this.addQueryElement ( element, value );
    } else {
      this.addQueryElement ( element );
    }
  }


  toggleFilter ( filterType, filterName ) {
    ////console.log ( this.state.productCategories );

    switch ( filterType ) {
      case "status":
        this.resetStatus ( filterName );
        let statusValue = null; //this.companyRelation [ filterName ];
        this.toggleElement ( "status", filterName, statusValue );

        break;      
      case "subcategories":
        this.resetAllSubCategoriesButThis ( filterName );
        this.addQueryElement ( "pSubCategory", filterName );
        break;
      case "classes":
        this.resetAllClassesButThis ( filterName );
        this.addQueryElement ( "pClass", filterName );
        this.setState ( {resetClasses: true});
        break;
    }

    let filtersCounter = Object.keys(this.queryData).length;
    this.setState ( {filtersCounter: filtersCounter} );
    //console.log ( this.queryData );

    //if ( Object.keys(this.queryData).length > 0 ) {
    this.props.applyFilters ( this.queryData );
    this.setState ( {resetState: false});
    this.setState ( {resetClasses: false});
    this.setState ( {resetCategories: false});
    this.setState ( {resetSubCategories: false});
    //}
  }

  addCountry ( key ) {
    if ( key === "0" ) this.removeQueryElement ( "country" );
    else this.addQueryElement ( "country", key );
    this.setState ( {countrySelected: key} );
    let filtersCounter = Object.keys(this.queryData).length;
    this.setState ( {filtersCounter: filtersCounter} );    
    this.props.applyFilters ( this.queryData );

    let queryData = {
      country: key,
      orderBy_Name: true
    }
    //queryData [ "user_type_gte" ] = "15"
    let data = this.state.client.getData ( Config.API_URL_COUNTY, queryData );
    if ( data ) {
        this.setState ( { counties: data.counties });
    } else {
      this.setState ( { counties: {} });
    }
  }

  addCounty ( key ) {
    if ( key === "0" ) this.removeQueryElement ( "county" );
    else this.addQueryElement ( "county", key );
    this.setState ( { countySelected: key } );
    let filtersCounter = Object.keys(this.queryData).length;
    this.setState ( {filtersCounter: filtersCounter} );    
    this.props.applyFilters ( this.queryData );
  }

  addCity ( key ) {
    if ( key === "0" ) this.removeQueryElement ( "county" );
    else this.addQueryElement ( "county", key );
    let filtersCounter = Object.keys(this.queryData).length;
    this.setState ( {filtersCounter: filtersCounter} );    
    this.props.applyFilters ( this.queryData );
  }

  addLocationsGroup ( key ) {
    if ( key === "0" ) this.removeQueryElement ( "locationGroup" );
    else this.addQueryElement ( "locationGroup", key );
    let filtersCounter = Object.keys(this.queryData).length;
    this.setState ( {filtersCounter: filtersCounter} );    
    this.props.applyFilters ( this.queryData );
  }

  addType ( key ) {
    if ( key === "0" ) this.removeQueryElement ( "user_type" );
    else this.addQueryElement ( "user_type", key );
    let filtersCounter = Object.keys(this.queryData).length;
    this.setState ( {filtersCounter: filtersCounter} );    
    this.props.applyFilters ( this.queryData );
  }

  resetAllClassesButThis ( category ) {
    let classesArr = Object.entries ( this.state.resetClassesList );
    let cat = {}
    for ( var filter of classesArr ) {
      if ( filter [ 0 ] === category ) cat [ filter [ 0 ] ] = false; //this.classesStates [ filter ] = false;
      else cat [ filter [ 0 ] ] = true; //this.classesStates [ filter ] = true;
    }
    this.setState ( { resetClassesList : cat } );
  }

  resetFilters ( ) {
    if ( this.queryData )
      this.queryData = {
        "orderByReverse" : "modifiedOn",
        "limit" : 20
      };
    this.setStatusFilters ( 0 );
    this.setFlagsFilters ( 0 );
    this.removeQueryElement ( "country" );
    //this.setState ( { locationGroupSelect: 0 } );
    this.removeQueryElement ( "county" );
    this.setState ( { countrySelected: "country061" } );
    this.setState ( { countySelected: "" } );    
    this.removeQueryElement ( "locationGroup" );
    this.setState ( { locationGroupSelect: "" } );
    this.setState ( {filters_reset: 1} );
    this.searchKeyword.current.value = "";
    this.setState ( {filtersCounter: 0} );
    this.props.applyFilters ( this.queryData );
    this.setState ( {resetState: true});
    this.setState ( {resetClasses: true});
    this.setState ( {resetCategories: true});
    this.setState ( {resetSubCategories: true});
    //this.resetAllClassesButThis ( "" );
    //this.resetAllSubCategoriesButThis ( "" );
    //this.resetAllCategoriesButThis ( "" );

    let queryData = {
      country: "country061",
      orderBy_Name: true
    }
    //queryData [ "user_type_gte" ] = "15"
    let data = this.state.client.getData ( Config.API_URL_COUNTY, queryData );
    if ( data ) {
        this.setState ( { counties: data.counties });
    } else {
      this.setState ( { counties: {} });
    }

  }

  render() { 
    let countriesValue = "";
    let countries = {};
    if ( this.props.countries ) countries = this.props.countries;
    if ( Object.keys(countries).length === 1 ) countriesValue = Object.keys(countries)[0];
    
    let counties = {};
    let countiesObj = {};
    if ( Object.entries(this.state.counties).length !== 0 ) {
      countiesObj = this.state.counties;
      Object.keys(countiesObj).forEach(key => {
        let fData = countiesObj [ key ];
        counties [ fData.iref ] = fData.name;      
      });
    }


    let locationsGroups = {"0": "All"};
    if ( this.props.locationsGroups ) Object.assign ( locationsGroups, locationsGroups, this.props.locationsGroups );

    let countrySelect = this.state.countrySelected;
    let countySelect = this.state.countySelected;

    let userTypes = BackOfficeConfig.USER_TYPES;

    return (
      <Card sx={{ borderRadius: '16px', m: 1, backgroundColor: "#214a80" }}>
      
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>

        <CardActions disableSpacing sx={{display: "block", textAlign: "flex-end"}}>          
          <IconButton aria-label="Add Location" title="Add Location" onClick={() => this.toggleAdd()}>       
            <Avatar alt="Add Location" sx={{ width: 24, height: 24, color: "#273442" }}>
            {this.state.addNewCompany ?  <FaMinusCircle/> : <FaPlusCircle/> }
              </Avatar>
          </IconButton>
        </CardActions>


        <CardActions disableSpacing sx={{display: "flex", textAlign: "start"}}>
          <IconButton aria-label="collaboration" title="collaboration" onClick={() => this.toggleFilter("status", "collaboration")}>
            <Avatar alt="collaboration" src={this.state.filters_status_collaboration ? collaboration_on : collaboration_off} sx={{ width: 24, height: 24 }}/>
          </IconButton>
          <IconButton aria-label="client" title="client" onClick={() => this.toggleFilter( "status", "client" )}>
            <Avatar alt="client" src={this.state.filters_status_client ? client_on : client_off} sx={{ width: 24, height: 24 }}/>
          </IconButton>
          <IconButton aria-label="franchise" title="franchise" onClick={() => this.toggleFilter( "status", "franchise" )}>
            <Avatar alt="franchise" src={this.state.filters_status_franchise ? franchise_on : franchise_off} sx={{ width: 24, height: 24 }}/>
          </IconButton>
          <IconButton aria-label="supplier" title="supplier" onClick={() => this.toggleFilter("status", "supplier")}>
            <Avatar alt="supplier" src={this.state.filters_status_supplier ? supplier_on : supplier_off} sx={{ width: 24, height: 24 }}/>
          </IconButton>
          
        </CardActions>

        <DropDown
          name="Country"
          value={countrySelect}
          doptions={countries}
          hasIcons={false}   
          minWidth={100}
          onDark={true}
          onChangeFunction={this.addCountry}
          size="small"
          marginTop={8}
        />

        <DropDown
          name="County"
          value={countySelect}
          doptions={counties}
          hasIcons={false}   
          minWidth={100}  
          onDark={true}
          onChangeFunction={this.addCounty}
          size="small"
          marginTop={8}
          marginLeft={2}
        />

        {/* <DropDown
          name="Type"
          value={0}
          doptions={userTypes}
          hasIcons={false}   
          minWidth={100}  
          onDark={true}
          onChangeFunction={this.addType}
        /> */}

       {/*  <DropDown
          name="Group"
          value={this.state.locationGroupSelect}
          doptions={locationsGroups}
          hasIcons={false}   
          minWidth={100}  
          onDark={true}
          onChangeFunction={this.addLocationsGroup}
        /> */}
      
        <TextField
          id="standard-basic"
          inputRef={this.searchKeyword}
          label="Keywords"
          variant="standard"
          inputProps={{ sx : { color: "#e3e3e5" } }}
          /* InputLabelProps={{ sx : { color: "#e3e3e5" } }} */
          onChange={(e) => this.searchKey( e )}
          sx={{ color: "#e3e3e5", marginLeft: "32px" }}
        />

        <Typography
          variant="body2"
          color="#e3e3e5"
          sx={{ color: "#e3e3e5", marginTop: "auto", marginLeft: "32px" }}
        >
          Companies: {this.props.companiesCounter}
        </Typography>

        <Typography
          variant="body2"
          color="#e3e3e5"
          sx={{ color: "#e3e3e5", marginTop: "auto", marginLeft: "32px" }}
        >
          Filters: {this.state.filtersCounter}
        </Typography>
        { this.state.filtersCounter > 0 ?
          <IconButton
            aria-label="Remove Filters"
            title="Remove Filters"
            onClick={() => this.resetFilters ( )}
            sx = {{marginTop: "auto"}}
          >
            <Avatar alt="Remove Filters" sx={{ width: 16, height: 16, color: "#99302e" }}>
              <HighlightOffIcon/>
              </Avatar>
          </IconButton>
            : ""
        }

        <ExpandMore
          expand={this.state.expanded}
          onClick={this.handleExpandClick}
          aria-expanded={this.state.expanded}
          aria-label="show more"
          sx={{ color: "#e3e3e5", textAlign: "end" }}
        >
          <ExpandMoreIcon />
        </ExpandMore>
        
      </Box>    

        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Box sx={{ display: 'block', flexDirection: 'row' }}>  
            <CardActions disableSpacing sx={{display: "block", textAlign: "left"}}>
              {/* {classesRender} */}
            </CardActions>         
          </Box>
          
          <Box sx={{ display: 'block', flexDirection: 'row' }}>  
            <CardActions disableSpacing sx={{display: "block", textAlign: "left"}}>
              {/* "aaa" */}
            </CardActions>         
          </Box>

          <Box sx={{ display: 'block', flexDirection: 'row' }}>
            <CardActions disableSpacing sx={{display: "block", textAlign: "left" }}>
            </CardActions>
          </Box>
            
          </CardContent>

        </Collapse>

        {/* </Box> */}




    </Card>   
    );

  }
}

export default CompaniesFilters;