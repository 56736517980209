import React from 'react';
import { Avatar, Box, Card, CardContent, LinearProgress, Stack, SvgIcon, Typography } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Client from '../Client';
import Config from '../ClientConfig';

/* function thousandsSeparator(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } */

class OverviewProductsProgress extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      products_cat: [],
      filterData: {}
    };

    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);

  }

  componentDidMount() {
    this.getData ( );
  }

  getData ( ) {

      let queryData = { "type": 0 }
	  let data = this.state.client.getData ( Config.API_URL_REPORTS_PRODUCTS, queryData );
      if ( data ) {
        this.setState ( { products_cat: data });
      }
      this.setState ( { filterData: queryData } );
    }

  doSync ( ) {
    this.getData ( );
  }

  render() {
    let categDataT = [];
    let progress_prod = 0;

    if ( this.state.products_cat ) {
        let products_cat = this.state.products_cat;
        if ( products_cat.Total !== undefined ) categDataT = parseInt(products_cat.Total);
        progress_prod = parseInt(categDataT / 200 *100);
    }

    return (
      <Card sx={{ borderRadius:"16px", height: '100%' }}>
        <CardContent>
          <Stack
            alignItems="flex-start"
            direction="row"
            justifyContent="space-between"
            spacing={3}
          >
            <Stack spacing={1}>
              <Typography
                color="text.secondary"
                gutterBottom
                variant="overline"
              >
                Products Progress
              </Typography>
              <Typography variant="h4">
                {progress_prod}%
              </Typography>
            </Stack>
            <Avatar
              sx={{
                backgroundColor: 'warning.main',
                height: 56,
                width: 56
              }}
            >
              <SvgIcon>
              <FormatListBulletedIcon />
              </SvgIcon>
            </Avatar>
          </Stack>
          <Box sx={{ mt: 3 }}>
            <LinearProgress
              value={progress_prod}
              variant="determinate"
            />
          </Box>
        </CardContent>
      </Card>
    );

  }
}

export default OverviewProductsProgress;