import React from 'react';
import { Avatar, createTheme, Box, CardContent, Button, MenuItem, TableContainer, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
// import { format } from 'date-fns';
import Client from '../Client';
import Config from '../ClientConfig';

let theme = createTheme();
function thousandsSeparator(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

const objectToCsv = function (data) {
  const csvRows = [];
  /* Get headers as every csv data format 
  has header (head means column name)
  so objects key is nothing but column name 
  for csv data using Object.key() function.
  We fetch key of object as column name for 
  csv */
  const headers = Object.keys(data[0]);
  /* Using push() method we push fetched 
     data into csvRows[] array */
  csvRows.push(headers.join(','));
  // Loop to get value of each objects key
  for (const row of data) {
      const values = headers.map(header => {
          const val = row[header]
          return `"${val}"`;
      });
      // To add, separator between each value
      csvRows.push(values.join(','));
  }
  /* To add new line for each objects values
     and this return statement array csvRows
     to this function.*/
  return csvRows.join('\n');
};

class SalesTargetCategory extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      saleslocation_list: [],
      page: 0,
      rowsPerPage: 12,
      period: 1,
      periods: [{'iref': 0, 'name': "Last Month"}, {'iref': 1, 'name': "This Month"}],
      filterData: {}
    };

    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.selectPeriod = this.selectPeriod.bind(this);

  }

  componentDidMount() {
    this.getData ( );
  }

  getData ( ) {
    // console.log(this.state.period);
    let queryData = { 
      "type": 15,
      "period": this.state.period,
  }
	  let data = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, queryData );
    if ( data ) {
      this.setState ( { saleslocation_list: data });
    }
    this.setState ( { filterData: queryData } );
  }

  doSync ( ) {
    this.getData ( );
  }

  selectPeriod = (event) => {
    this.setState (
      {period: event.target.value},
      function(){
        this.doSync ( );
      }
      );
  }

  handleChangePage = (event, newPage) => {
    this.setState ({page: newPage});
  }
  
  handleChangeRowsPerPage = (event) => {
    this.setState ({rowsPerPage: +event.target.value});
    this.setState ({page: 0});
  }

  onDownload = () => {
    const link = document.createElement("a");
    link.download = "targetCategory.csv";
    // console.log(this.state.saleslocation_list);
    // console.log(this.state.saleslocation_list.result);
    let csvData = objectToCsv(this.state.saleslocation_list.result);
    let csvHeader = "Category Target";
    let csv = csvHeader + "\n" + csvData;
    link.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
    // console.log(link);
    link.click();
  };
  

  render() {
    let periods = [{'iref': 0, 'name': "Last Month"}, {'iref': 1, 'name': "This Month"}];
    let page = this.state.page;
    let rowsPerPage = this.state.rowsPerPage;
    let locationSalesData = [];
    let locationSalesList = [];
    if (this.state.saleslocation_list){
      if (this.state.saleslocation_list.result) {
        let counter = 0;
        Object.keys(this.state.saleslocation_list.result).forEach(key => {
          let fData = this.state.saleslocation_list.result[key];
          let myData = {'x': fData.location, 'y': (parseInt(fData.salesMTD/1000)+ "K")};
          let p = {
            id: counter+1,
            location: fData.location,
            salesMTD: fData.salesMTD,
            targetTM: fData.targetTM,
            targetMTD: fData.targetMTD
          }
          if (counter<10){
            locationSalesData.push ( myData );
          }
          counter += 1;
          locationSalesList.push ( p );
        });
      }
    }

    return (
      <CardContent sx={{borderRadius: "2px", padding: "2px"}}>
        <Box sx={{ marginLeft: "10px", borderRadius:"16px", display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between'}}>
          <Box sx={this.state.sx_hidden} style={{ alignItems: 'baseline', justifyContent: 'space-around'}}>
              <Typography
                sx={{lineHeight: 1.5}}
                color="#1a237e"
                variant="overline"
              >
                TARGET CARTOFEST
              </Typography>
            <TextField
                id="standard-select-period"
                select
                // label="Select"
                defaultValue="1"
                // helperText="Select Period"
                variant="standard"
                sx={{ width: 150}}
                onChange={this.selectPeriod}
              >
                  {periods.map((option) => (
                  <MenuItem key={option.iref} value={option.iref}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
          </Box>
          <Button
            color="inherit"
            size="small"
            onClick={this.onDownload} 
            variant="contained" 
            // color="primary"
            style={{maxWidth: '36px', minWidth: '36px'}}
          >
            <Avatar sx={{ width: 24, height: 24, bgcolor: theme.palette.success.light}}> 
              <FileDownloadIcon />
            </Avatar>
          </Button>
        </Box>
        <Box>
          <TableContainer sx={{ height: '480px' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {/* <TableCell style={{padding: "6px", textAlign: "center" }}>
                  <b>Top</b>
                </TableCell> */}
                <TableCell style={{padding: "6px", textAlign: "left" }}>
                  <b>Location</b>
                </TableCell>
                <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                  <b>Sales MTD</b>
                </TableCell>
                <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                  <b>Target TM</b>
                </TableCell>
                <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                  <b>% TM</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {locationSalesList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((location) => {
                return (
                  <TableRow
                    hover
                    key={location.id}
                    tabIndex={-1}
                  >
                    {/* <TableCell style={{padding: "6px", textAlign: "center" }}>
                      {location.id}
                    </TableCell> */}
                    <TableCell style={{padding: "6px", textAlign: "left" }}>
                      {location.location}
                    </TableCell>
                    <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        {thousandsSeparator(parseInt(location.salesMTD))}
                    </TableCell>
                    <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        {thousandsSeparator(parseInt(location.targetTM))}
                    </TableCell>
                    <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        {location.targetMTD === 0 ? 0+"%" : parseInt((location.salesMTD/location.targetMTD)*100)+"%"} 
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[12, { value: locationSalesList.length, label: 'All' }]}
            labelRowsPerPage="Rows:"
            // showFirstButton="True"
            // showLastButton="True"
            component="div"
            count={locationSalesList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Box>
      </CardContent>
    );

  }
}

export default SalesTargetCategory;
