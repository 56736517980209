import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import Modal from '@mui/material/Modal';
import DropDown from './components/DropDown';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextareaAutosize from '@mui/base/TextareaAutosize';
// import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import PreviewIcon from '@mui/icons-material/Preview';
import CardMedia from '@mui/material/CardMedia';
import Checkbox from '@mui/material/Checkbox';
import swal from 'sweetalert';
import KImage from './components/KImage';
import Config from './components/ClientConfig';
import Client from './components/Client';
import BackOfficeConfig from './Config';

import noImage from './images/noImage.png';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#273442",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

class DataRowText extends React.Component {
  constructor(props) {
    super(props);

    this.state = {   
      client: new Client ( "none" ),   
      edit: false,
      row: {},
      keyValid: false,
      valueValid: false,
      somethingHasChanged: false,
      showPreview: false
    };

    
    this.handleEditClick = this.handleEditClick.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.pushData = this.pushData.bind(this);
    this.changeOption = this.changeOption.bind(this);
    this.closePreview = this.closePreview.bind(this);
    this.showPreview = this.showPreview.bind(this);
  }

  componentDidMount() {
    let row = {};
    if ( this.props.row ) {
      row = this.props.row;
      this.setState ( { row: row } );
      if ( row.key.length > 0 ) this.setState ( {keyValid: true } );
      if ( row.value.length > 0 ) this.setState ( {valueValid: true } );
    }
    if ( this.props.edit ) this.setState ( { edit: true } );
  }

  handleEditClick ( ) {
    let cEdit = !this.state.edit;
    this.setState({ edit: !this.state.edit });
  }

  changeValue ( e ) {
    let id = null;
    if ( e.target.id !== null ) id = e.target.id;
    let value = null;
    if  ( e.target.value !== null ) value = e.target.value;
    //if ( id !== null && value !== null && value !== undefined ) {
    if ( id ) {
      let row = {};
      if ( this.state.row ) row = this.state.row;
      switch ( id ) {
        case "key":
          if ( value.length > 0 ) {
            row.key = value;
            this.setState ( { keyValid: true });
          } else {
            row.key = "";
            this.setState ( { keyValid: false });
          }
          this.setState ( { somethingHasChanged: true } );
          break;
        case "value":
          if ( value.length > 0 ) {
            row.value = value;
            this.setState ( { valueValid: true });
          } else {
            row.value = "";
            this.setState ( { valueValid: false });
          }
          this.setState ( { somethingHasChanged: true } );
          break;
        case "exposeToFront":            
          row.exposeToFront = !row.exposeToFront;
          this.setState ( { somethingHasChanged: true } );
          break;
      }
      this.setState ( { row: row });
    }
  }

  pushData ( e ) {
    if ( this.state.somethingHasChanged && this.state.keyValid && this.state.valueValid ) {
      let rdata = this.state.client.pushData ( Config.API_URL_CONFIG_IDENTITY_TEXT_ADD, this.state.row );
      if ( rdata ) {
        if ( rdata.status == "OK" ) {
          let uRow = this.state.row;
          uRow.iref = rdata.iref;
          this.setState ( { row: uRow } );
          this.handleEditClick ( );
          swal ( "Success", "Successfully added/modified configuration value ! (" + rdata.iref + ")", "success", {
            buttons: false,
            timer: 2000,
          })
          .then((value) => {
            //this.props.personUpdated ( );
            this.setState ( {edit: false} );
            this.setState ( { somethingHasChanged: false } );
            this.props.rowAdded ();
          });        
        } else {
          if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
          swal ( "Error", "Add/Update configuration value failed !\n\n" + rdata.message, "error" );
        }
      }
    } else {
      swal ( "Error", "Add/Update configuration value failed ! Some fields are not validated !", "error" );
    }
  }

  changeOption ( e ) {    
    if ( this.state.row ) {
      let uRow = this.state.row;
      let newLanguage = {};
      if ( this.props.languages ) {
        let langs = this.props.languages;  
        Object.keys(langs).forEach(key => {
          if ( key === e ) newLanguage = langs [ key ];
        });
      }
      uRow.language = newLanguage;
      this.setState ( { row: uRow } );
      this.setState ( { somethingHasChanged: true } );
    }
  }

  showPreview ( ) {
    this.setState ( { showPreview: true} );
  }

  closePreview ( ) {
    this.setState ( { showPreview: false} );
  }

  render() {
    let row = {};
    let iref = "0";
    let key = "";
    let dvalue = "";
    let value_show = "";
    let language = "";
    let language_text = "";
    let exposeToFront = false;
    if ( this.state.row ) {
      row = this.state.row;
      if ( row.iref ) iref = row.iref;
      if ( row.key ) key = row.key;
      if ( row.value ) {
        dvalue = row.value;
        value_show = dvalue;
        if ( dvalue.length > 32 ) value_show = dvalue.slice ( 0, 32 ) + " ...";
      }
      if ( row.language ) {
        language = row.language.iref;
        language_text = row.language.languageName;
      }
      if ( row.exposeToFront === 1 || row.exposeToFront === "True" || row.exposeToFront === true ) exposeToFront = row.exposeToFront;
    }
    if ( this.props.dataRow !== null && this.props.dataRow != undefined ) {
      row = this.props.dataRow;
    }

    let showSave = false;
    if ( this.state.edit === true && this.state.somethingHasChanged === true && this.state.keyValid === true && this.state.valueValid === true )
      showSave = true;

    let languages = [];
    if ( this.props.languages ) languages = this.props.languages;

    let showPreview = false;
    if ( this.state.showPreview ) showPreview = this.state.showPreview;

    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(dvalue, 'text/html');
    //let reactCode = 

    return (
        <StyledTableRow key={row.iref}>
          <StyledTableCell align="left">
            {iref}
          </StyledTableCell>
          <StyledTableCell scope="row">
            {this.state.edit ? 
            <TextField
              id="key"
              label="Key"
              variant="outlined"
              value={key || ''}
              onChange={this.changeValue}
              onFocus={event => { event.target.select(); }}
              inputProps={{maxLength:"32"}}
              error={this.state.keyValid === false}
              required={true}
              sx={{minWidth: "360px"}}
            />
            : key }
          </StyledTableCell>
          
          <StyledTableCell align="left">
            {this.state.edit ? 
              <TextareaAutosize
                id="value"
                label="Value"
                variant="outlined"
                value={dvalue || ''}
                onChange={this.changeValue}
                onFocus={event => { event.target.select(); }}
                //inputProps={{maxLength:"64"}}
                //error={this.state.valueValid === false}                
                minRows={5}
                maxRows={10}
                required={true}
              />
              :value_show}
            </StyledTableCell>
            <StyledTableCell align="left">
              {this.state.edit ?           
                <DropDown
                  name="Language"
                  value={language}
                  doptions={languages}
                  hasIcons={true} 
                  minWidth={100}
                  onChangeFunction={this.changeOption}
                />
                : language_text
              }
            </StyledTableCell>

            <StyledTableCell align="left">
              {this.state.edit ?
                  <Checkbox id="exposeToFront" color="success" checked={exposeToFront} onChange={this.changeValue}/>
                  :
                  <Checkbox  color="success" checked={exposeToFront} disabled/>
                }
            </StyledTableCell>            
          
          <StyledTableCell align="right">
            <IconButton aria-label="settings" onClick={this.showPreview} title="Preview">
                <PreviewIcon />
            </IconButton>
            <IconButton aria-label="settings" onClick={this.handleEditClick} title="Edit">
              <EditIcon
                sx={{ color: this.state.edit ? "#8f241d" : "#e3e3e5" }}              
              />
          </IconButton>
          {showSave ?
            <IconButton aria-label="settings" onClick={this.pushData} title="Save">
              <SaveIcon
                sx={{ color: "#4287f5" }}            
              />
        </IconButton>
          : ""}
          </StyledTableCell>

          <StyledTableCell align="right" sx={{width: 0}}>
            <Modal
              open={showPreview}
              onClose={this.closePreview}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              
              <Box sx={modalStyle}>
                
                <div dangerouslySetInnerHTML={{ __html: dvalue }} />
              </Box>

            </Modal>
          </StyledTableCell>
          

        </StyledTableRow>
    );
  }
}

class DataRowImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {   
      client: new Client ( "none" ),   
      edit: false,
      row: {},
      keyValid: false,
      somethingHasChanged: false,
      showPreview: false
    };

    this.handleEditClick = this.handleEditClick.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.pushData = this.pushData.bind(this);
    this.changeOption = this.changeOption.bind(this);
    this.getImage = this.getImage.bind(this);
    this.closePreview = this.closePreview.bind(this);
    this.showPreview = this.showPreview.bind(this);
  }

  componentDidMount() {
    let row = {};
    if ( this.props.row ) {
      row = this.props.row;
      this.setState ( { row: row } );
      if ( row.key.length > 0 ) this.setState ( {keyValid: true } );      
    }
    if ( this.props.edit ) this.setState ( { edit: true } );
  }

  handleEditClick ( ) {
    let cEdit = !this.state.edit;
    this.setState({ edit: !this.state.edit });
  }

  getImage ( newImage ) {
    if ( newImage ) {
      if ( this.state.row ) {
        let nRow = this.state.row;
        nRow.image = newImage;
        this.setState ( { row: nRow }, this.setState ( { somethingHasChanged: true } ) );
      }
    }
  }

  changeValue ( e ) {
    let id = null;
    if ( e.target.id !== null ) id = e.target.id;
    let value = null;
    if  ( e.target.value !== null ) value = e.target.value;
    if ( id ) {
      let row = {};
      if ( this.state.row ) row = this.state.row;
      switch ( id ) {
        case "key":
          if ( value.length > 0 ) {
            row.key = value;
            this.setState ( { keyValid: true });
          } else {
            row.key = "";
            this.setState ( { keyValid: false });
          }
          this.setState ( { somethingHasChanged: true } );
          break;
        case "exposeToFront":            
          row.exposeToFront = !row.exposeToFront;
          this.setState ( { somethingHasChanged: true } );
          break;
      }
      this.setState ( { row: row });
    }
  }

  pushData ( e ) {
    if ( this.state.somethingHasChanged && this.state.keyValid ) {
      let rdata = this.state.client.pushData ( Config.API_URL_CONFIG_IDENTITY_IMAGE_ADD, this.state.row );
      if ( rdata ) {
        if ( rdata.status == "OK" ) {
          let uRow = this.state.row;
          uRow.iref = rdata.iref;
          this.setState ( { row: uRow } );
          this.handleEditClick ( );
          swal ( "Success", "Successfully added/modified configuration value ! (" + rdata.iref + ")", "success", {
            buttons: false,
            timer: 2000,
          })
          .then((value) => {
            //this.props.personUpdated ( );
            this.setState ( {edit: false} );
            this.setState ( { somethingHasChanged: false } );
            this.props.rowAdded ();
          });        
        } else {
          if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
          swal ( "Error", "Add/Update configuration value failed !\n\n" + rdata.message, "error" );
        }
      }
    } else {
      swal ( "Error", "Add/Update configuration value failed ! Some fields are not validated !", "error" );
    }
  }

  changeOption ( e ) {    
    if ( this.state.row ) {
      let uRow = this.state.row;
      let newLanguage = {};
      if ( this.props.languages ) {
        let langs = this.props.languages;  
        Object.keys(langs).forEach(key => {
          if ( key === e ) newLanguage = langs [ key ];
        });
      }
      uRow.language = newLanguage;
      this.setState ( { row: uRow } );
      this.setState ( { somethingHasChanged: true } );
    }
  }

  getImage ( newImage ) {
    if ( newImage ) {
      if ( this.state.row ) {
        let nRow = this.state.row;
        nRow.image = newImage;
        this.setState ( { row: nRow }, this.setState ( { somethingHasChanged: true } ) );
      }
    }
  }

  showPreview ( ) {
    this.setState ( { showPreview: true} );
  }

  closePreview ( ) {
    this.setState ( { showPreview: false} );
  }

  render() {
    let row = {};
    let iref = "0";
    let key = "";
    let value = "";
    let image = noImage;
    let imageFullURL = "";
    let language = "";
    let language_text = "";
    let exposeToFront = false;
    if ( this.state.row ) {
      row = this.state.row;
      if ( row.iref ) iref = row.iref;
      if ( row.key ) key = row.key;
      if ( row.value ) value = row.value;
      if ( row.image ) {
        image = row.image; 
        imageFullURL = window.location.protocol + "//" + window.location.host + image;
      }
      if ( row.language ) {
        language = row.language.iref;
        language_text = row.language.languageName;
      }    
      if ( row.exposeToFront === 1 || row.exposeToFront === "True" || row.exposeToFront === true ) exposeToFront = row.exposeToFront;
    }
    if ( this.props.dataRow !== null && this.props.dataRow != undefined ) {
      row = this.props.dataRow;
    }

    let kImage = {
      data: image,
      height: 128,
      width: 128
    };
    let imageName = key;

    let showSave = false;
    if ( this.state.edit === true && this.state.somethingHasChanged === true && this.state.keyValid === true )
      showSave = true;

    let languages = [];
    if ( this.props.languages ) languages = this.props.languages;

    let showPreview = false;
    if ( this.state.showPreview ) showPreview = this.state.showPreview;

    return (
      <StyledTableRow key={row.iref}>

        <StyledTableCell align="left">
          {iref}
        </StyledTableCell>

        <StyledTableCell scope="row">
          {this.state.edit ? 
          <TextField
            id="key"
            label="Key"
            variant="outlined"
            value={key || ''}
            onChange={this.changeValue}
            onFocus={event => { event.target.select(); }}
            inputProps={{maxLength:"32"}}
            error={this.state.keyValid === false}
            required={true}
            sx={{minWidth: "360px"}}
          />
          : key }
        </StyledTableCell>
        
        <StyledTableCell align="left">
          <KImage
            edit = {this.state.edit}
            image = {kImage}
            imageText = {imageName}
            saveBack = {this.getImage}
           />
        </StyledTableCell>

        <StyledTableCell align="left">
          {this.state.edit ?           
            <DropDown
              name="Language"
              value={language}
              doptions={languages}
              hasIcons={true} 
              minWidth={100}
              onChangeFunction={this.changeOption}
            />
            : language_text
          }
          </StyledTableCell>

          <StyledTableCell align="left">
            {this.state.edit ?
                <Checkbox id="exposeToFront" color="success" checked={exposeToFront} onChange={this.changeValue}/>
                :
                <Checkbox  color="success" checked={exposeToFront} disabled/>
              }
          </StyledTableCell>

        
        <StyledTableCell align="right">
          <IconButton aria-label="settings" onClick={this.showPreview} title="Preview">
            <PreviewIcon />
          </IconButton>
          <IconButton aria-label="settings" onClick={this.handleEditClick} title="Edit">
            <EditIcon
              sx={{ color: this.state.edit ? "#8f241d" : "#e3e3e5" }}              
            />
        </IconButton>
        {showSave ?
          <IconButton aria-label="settings" onClick={this.pushData} title="Save">
            <SaveIcon
              sx={{ color: "#4287f5" }}            
            />
          </IconButton>
        : "" }
        </StyledTableCell>
        <StyledTableCell align="right" sx={{width: 0}}>
            <Modal
              open={showPreview}
              onClose={this.closePreview}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              
              <Box sx={modalStyle}>
              <CardMedia
                component="img"
                height={512}
                sx={{ width: 512, marginLeft: 1 }}
                image={image}
                alt="preview"
              />
              
              <Typography
                variant="subtitle1"
                sx={{fontSize: "0.7em"}}
              >
                {imageFullURL}
              </Typography>
              
              </Box>
            </Modal>
          </StyledTableCell>
      </StyledTableRow>
    );
  }
}


class ConfigIdentity extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      rowsText: null,
      rowsImage: null,
      editText: false,
      editImage: false,
      addRowText: false,
      queryDataText: {},
      queryDataImage: {},
      //addNewProduct: false,
      expandedText: false,
      expandedImage: false
    };

    this.rowTextAdded = this.rowTextAdded.bind(this);
    this.rowImageAdded = this.rowImageAdded.bind(this);
    this.toggleAddText = this.toggleAddText.bind(this);
    this.toggleAddImage = this.toggleAddImage.bind(this);
    this.getDataText = this.getDataText.bind(this);
    this.getDataImage = this.getDataImage.bind(this);
    this.expandTextPanel = this.expandTextPanel.bind(this);
    this.expandImagePanel = this.expandImagePanel.bind(this);
    

  }

  getDataText ( ) {
    let queryData = {};
    queryData [ "orderBy" ] = "key";
    this.setState ( { queryData: queryData } );
    let data = this.state.client.getData ( Config.API_URL_CONFIG_IDENTITY_TEXT, queryData );
    this.setState ( { rowsText: data.IdentityTextConfigs });
  }
  getDataImage ( ) {
    let queryData = {};
    queryData [ "orderBy" ] = "key";
    this.setState ( { queryData: queryData } );
    let data = this.state.client.getData ( Config.API_URL_CONFIG_IDENTITY_IMAGE, queryData );
    this.setState ( { rowsImage: data.IdentityImageConfigs });
  }

  componentDidMount() {    
    this.getDataText ( );    
    this.getDataImage ( );
  }

  componentWillUnmount() {    
  }

  rowTextAdded ( ) {
    this.getDataText ( );
    this.setState ( { addRowText: false } );
    this.state.client.setConfigLocalStorage ( );
    window.dispatchEvent(new Event("storageUpdate"));
  }

  rowImageAdded ( ) {
    this.getDataImage ( );
    this.setState ( { addRowImage: false } );
    this.state.client.setConfigLocalStorage ( );
    window.dispatchEvent(new Event("storageUpdate"));
  }

  toggleAddText ( ) {
    let addTextButton = this.state.addRowText;
    this.setState ( {addRowText: !addTextButton} );
    //this.props.toggleAdd ( !addButton );
  }

  toggleAddImage ( ) {
    let addImageButton = this.state.addRowImage;
    this.setState ( {addRowImage: !addImageButton} );
    //this.props.toggleAdd ( !addButton );
  }

  expandTextPanel ( ) {
    let expandedText = this.state.expandedText;
    this.setState ( { expandedText: !expandedText } );
  }

  expandImagePanel ( ) {
    let expandedImage = this.state.expandedImage;
    this.setState ( { expandedImage: !expandedImage } );
  }

  
  render() {
    
/*     let productClasses = [];
    if ( this.state.productClasses !== null && this.state.productClasses !== undefined ) {
      productClasses = Object.values ( this.state.productClasses );
    } */

    let languages = {};
    if ( this.props.languages ) {
      let langs = this.props.languages;

      Object.keys(langs).forEach(key => {
        let fData = langs [ key ];
        let newObj = { iref: fData.iref, name: fData.languageName, image: fData.image, languageName: fData.languageName, };
        languages [ fData.iref ] = newObj;
      });

    }

    let allDataText ="";
    if ( this.state.rowsText ) {
      //console.log ( this.state.rows );
      if ( this.state.rowsText.length > 0 ) {
        let rowsText = this.state.rowsText;
        allDataText = rowsText.map((rowText) => {
          return <DataRowText
            key = {rowText.iref}
            row = {rowText}
            rowAdded={this.rowTextAdded}
            languages = {languages}
          />
        });
      }      
    }

    let allDataImage ="";
    if ( this.state.rowsImage ) {
      //console.log ( this.state.rows );
      if ( this.state.rowsImage.length > 0 ) {
        let rowsImage = this.state.rowsImage;
        allDataImage = rowsImage.map((rowImage) => {
          return <DataRowImage
            key = {rowImage.iref}
            row = {rowImage}
            rowAdded={this.rowImageAdded}
            languages = {languages}
          />
        });
      }      
    }

    let expandedText = false;
    if ( this.state.expandedText ) expandedText = this.state.expandedText;
    let expandedImage = false;
    if ( this.state.expandedImage ) expandedImage = this.state.expandedImage;

    return (
      <div>
        <Accordion id="textAccordion" expanded={expandedText} onChange={this.expandTextPanel} sx={{backgroundColor: "#2e3b49", marginTop: 2}}>
          <AccordionSummary sx={{color: "#adadad"}}
            expandIcon={<ExpandMoreIcon id="textAccordion" sx={{color: "#adadad"}}/>}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ width: '33%', flexShrink: 0, color: "#adadad" }}>
              Identity Configuration
            </Typography>
            <Typography sx={{ color: 'text.secondary', color: "#adadad" }}>Texts</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      <IconButton aria-label="Add Location" title="Add Location" onClick={() => this.toggleAddText()}>       
                        <Avatar alt="Add Location" sx={{ width: 24, height: 24, color: "#273442" }}>
                          {this.state.addRowText ? <FaMinusCircle/> : <FaPlusCircle/>}
                        </Avatar>
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell>
                      Name
                    </StyledTableCell>
                    <StyledTableCell align="left">Value</StyledTableCell>
                    <StyledTableCell align="left">Language</StyledTableCell>
                    <StyledTableCell align="left">Expose To Front</StyledTableCell>
                    <StyledTableCell align="right">Action</StyledTableCell>
                    <StyledTableCell align="right" sx={{width: 0}}></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.addRowText ?
                  <DataRowText key={0} edit={true} rowAdded={this.rowTextAdded} languages={languages}/>
                  : ""
                  }
                  {allDataText}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>

        <Accordion id="imageAccordion" expanded={expandedImage} onChange={this.expandImagePanel} sx={{backgroundColor: "#2e3b49", marginTop: 1}}>
          <AccordionSummary sx={{color: "#adadad"}}
            expandIcon={<ExpandMoreIcon id="imageAccordion" sx={{color: "#adadad"}}/>}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ width: '33%', flexShrink: 0, color: "#adadad" }}>
              Identity Configuration
            </Typography>
            <Typography sx={{ color: 'text.secondary', color: "#adadad" }}>Images</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      <IconButton aria-label="Add Location" title="Add Location" onClick={() => this.toggleAddImage()}>       
                        <Avatar alt="Add Location" sx={{ width: 24, height: 24, color: "#273442" }}>
                          {this.state.addRowImage ? <FaMinusCircle/> : <FaPlusCircle/>}
                        </Avatar>
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell>
                      Name
                    </StyledTableCell>
                    <StyledTableCell align="left">Image</StyledTableCell>
                    <StyledTableCell align="left">Language</StyledTableCell>
                    <StyledTableCell align="left">Expose To Front</StyledTableCell>
                    <StyledTableCell align="right">Action</StyledTableCell>
                    <StyledTableCell align="right" sx={{width: 0}}></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.addRowImage ?
                  <DataRowImage key={0} edit={true} rowAdded={this.rowImageAdded} languages={languages}/>
                  : ""
                  }
                  {allDataImage}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </div>      

    );

  }
}

export default ConfigIdentity;
