import React from 'react';
import { useRef } from "react";
//import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import KImage from './KImage';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import { FaExternalLinkAlt } from 'react-icons/fa';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import swal from 'sweetalert';
import Client from './Client';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#273442",
    color: theme.palette.common.white,
    paddingLeft: "4px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    paddingLeft: "4px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


class KImagesTable extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      edit: [],
      images: [],
      options: { },
      canvas: null,      
      imageModifIdx: 0,
      saveURL: "",
      parentIref: "",
      showConfirm: false,
      removeIref: "",
    };

    //this.handleExpandClick = this.handleExpandClick.bind(this);
    this.sendChangedState = this.sendChangedState.bind(this);   
    this.saveBack = this.saveBack.bind(this);    
    this.changeValue = this.changeValue.bind(this);
    this.editor = React.createRef();
    this.handleEditClick = this.handleEditClick.bind(this);
    this.changeName = this.changeName.bind(this);
    this.toggleAdd = this.toggleAdd.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.removeConfirm = this.removeConfirm.bind(this);
    this.removeCancel = this.removeCancel.bind(this);
    this.showConfirmDialog = this.showConfirmDialog.bind(this);
  }

  componentDidMount() {
    if ( this.props.images ) this.setState ( { images: this.props.images } );
    if ( this.props.edit ) this.setState ( { edit: this.props.edit } );
    if ( this.props.saveURL ) this.setState ( { saveURL: this.props.saveURL } );
    if ( this.props.removeURL ) this.setState ( { removeURL: this.props.removeURL } );
    if ( this.props.parentIref ) this.setState ( { parentIref: this.props.parentIref } );

    //console.log ( this.props.images );
    let edit = false;
    if ( this.props.edit ) edit = this.props.edit;
    this.setState ( {edit: edit} );

    let editArray = [];
    if ( this.props.images )
      if ( this.props.images.length > 0 ) {
        this.props.images.forEach(element => {
          /* let pair = {};
          pair [ element.iref ] = false;
          editArray.push ( pair ); */

          editArray [ element.iref ] = false;
        });
      }

    this.setState ( { edit: editArray } );
  }

  componentDidUpdate ( prevProps ) {    
    if ( prevProps !== this.props ) {
      if ( this.props.images ) this.setState ( { images: this.props.images } );
      if ( this.props.edit ) this.setState ( { edit: this.props.edit } );
      if ( this.props.saveURL ) this.setState ( { saveURL: this.props.saveURL } );
      if ( this.props.removeURL ) this.setState ( { removeURL: this.props.removeURL } );
      if ( this.props.parentIref ) this.setState ( { parentIref: this.props.parentIref } );

      //console.log ( this.props.images );
      let edit = false;
      if ( this.props.edit ) edit = this.props.edit;
      this.setState ( {edit: edit} );

      let editArray = [];
      if ( this.props.images )
        if ( this.props.images.length > 0 ) {
          this.props.images.forEach(element => {
            /* let pair = {};
            pair [ element.iref ] = false;
            editArray.push ( pair ); */

            editArray [ element.iref ] = false;
          });
        }

      this.setState ( { edit: editArray } );
    }
  }

  sendChangedState ( e ) {
    
  }

  saveBack ( image, e ) {
    let iref = e;
    let uImages = this.state.images;
    for ( let i = 0; i < uImages.length; i++ ) {
      if ( uImages [ i ].iref == iref )
        uImages [ i ].image = image;
    }
    this.setState ( { images: uImages } );

    /* let edits = this.state.edit;
    edits [ iref ] = false;
    this.setState ( { edit: edits } ); */
  }

  pushData ( e ) {
    let image = null;
    let newMain = null;
    let images = this.state.images;
    for ( let i = 0; i < images.length; i++ ) {
      if ( images [ i ].iref == e ) {
        image = images [ i ];
      }
      if ( images [ i ].isMain == true )
        newMain = images [ i ].iref;
    }
    image [ "newMain" ] = newMain;
    image [ "parentIref" ] = this.state.parentIref;
      
    let rdata = this.state.client.pushData ( this.state.saveURL, image );
    if ( rdata ) {
      if ( rdata.status == "OK" ) {
        for ( let i = 0; i < images.length; i++ ) {
          if ( images [ i ].iref == e ) {
            images [ i ].iref = rdata.iref;
          }
        }
        this.setState ( { images: images } );
        this.handleEditClick ( e );
        swal ( "Success", "Successfully added/modified image ! (" + rdata.iref + ")", "success", {
          buttons: false,
          timer: 2000,
        })
        .then((value) => {
          this.setState ( { addNew: false } );
          this.props.imageUpdated ( );
          this.setState ( { somethingHasChanged: false } );            
        });        
      } else {
        if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
        swal ( "Error", "Add/Update image failed !", "error" );
      }
    }

  }

  changeName ( e ) {
    if ( this.state.images ) {
      if ( this.state.images.length > 0 ) {
        let uImages = this.state.images;
        for ( let i = 0; i < uImages.length; i++ ) {
          if ( uImages [ i ].iref == e.target.id )
            uImages [ i ].name = e.target.value;
        }        
        this.setState ( { images: uImages } );
      }
    }
    let uImage = this.state.i
  }

  changeValue ( e ) {
    let images = [];
    if ( this.state.images ) images = this.state.images;
    for ( var element of images ) {
      element.isMain = false;
      if ( element.iref === e.target.id )
        element.isMain = true;
    }
    this.setState ( { images: images } );
  }

  handleEditClick ( e ) {
    let imgRef = e;
    let uEdit = this.state.edit;
    let flag = !uEdit [ imgRef ];
    uEdit [ imgRef ] = flag;
    this.setState({ edit: uEdit });
  }

  toggleAdd ( ) {
    let addNew = this.state.addNew;
    this.setState ( { addNew: !addNew } );

    let uImages = this.state.images;
    let uEdit = this.state.edit;
    if ( !addNew ) {
      let newImage = {
        iref: "newImage"
      };
      uImages.unshift ( newImage );
      //uEdit.unshift ( { "newImage": true } );
      uEdit [ "newImage" ] = true;
    } else {
      if ( uImages.length > 0 ) {
        if ( uImages [ 0 ].iref === "newImage" )
          uImages.shift();
          //uEdit.shift();
          delete uEdit [ "newImage" ];
      }
    }
    this.setState ( { images: uImages } );
    this.setState ( { edit: uEdit } );
  }  

  removeImage ( e ) {
    let images = this.state.images;
    let edit = this.state.edit;
    let image = { "iref": e };
    let rdata = this.state.client.pushData ( this.state.removeURL, image );
    if ( rdata ) {
      if ( rdata.status == "OK" ) {
        for ( let i = 0; i < images.length; i++ ) {
          if ( images [ i ].iref == e ) {
            images.splice ( i, 1 );
            edit.splice ( i, 1 );
          }
        }
        this.setState ( { images: images } );
        this.setState ( { edit: edit } );
        this.handleEditClick ( e );
        swal ( "Success", "Successfully added/modified image ! (" + rdata.iref + ")", "success", {
          buttons: false,
          timer: 2000,
        })
        .then((value) => {
          this.props.imageUpdated ( );
          this.setState ( { somethingHasChanged: false } );            
        });        
      } else {
        if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
        swal ( "Error", "Add/Update image failed !", "error" );
      }
    }
  }

  showConfirmDialog ( e ) {
    this.setState ( { showConfirm: true } );
    this.setState ( { removeIref: e } );
  }

  removeConfirm ( iref ) {
    //console.log ( "Removing: " + iref );
    let removeIref = this.state.removeIref;
    this.removeImage ( removeIref );
    this.setState ( { showConfirm: false } );
    this.setState ( { removeIref: "" } );
  }

  removeCancel ( ) {
    this.setState ( { showConfirm: false } );
    this.setState ( { removeIref: "" } );
  }

  render() {
    let images = {}
    if ( this.state.images ) {
      images = this.state.images.map ((image) => {
        let kImage = {
          data: image.image,
          height: 128
        };
    let showConfirm = false;
    if ( this.state.showConfirm )
      showConfirm = this.state.showConfirm;

        return <TableRow key={image.iref}>
          <StyledTableCell>
            <KImage
              /* key = {image.iref} */
              iref = {image.iref}
              edit = { this.state.edit [ image.iref ] }
              image = {kImage}
              imageText = {image.name}
              saveBack = {this.saveBack}
              arrange = "flex"
            />
          </StyledTableCell>
          <StyledTableCell>
            {this.state.edit [ image.iref ] ?
              <TextField
                id={image.iref}
                label="Name"
                value={image.name || ''}
                variant="outlined"
                onChange={this.changeName}
                sx={{minWidth: "90%", marginTop: 1}}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"100"}}
              />
              :
              image.name
            }
          </StyledTableCell>
          <StyledTableCell>
            <Checkbox id={image.iref} color="success" checked={image.isMain} onChange={this.changeValue} disabled={!this.state.edit [ image.iref ]}>Main</Checkbox>
          </StyledTableCell>
          <StyledTableCell align="right" sx={{padding: "1px"}}>
          <IconButton aria-label="settings" id={"ib-" + image.iref} onClick={() => {this.handleEditClick(image.iref);}}>
            <EditIcon
              id={image.iref}
              sx={{ color: this.state.edit [ image.iref ] ? "#8f241d" : "#e3e3e5" }}
            />
          </IconButton>
          {this.state.edit [ image.iref ]  ?
            <IconButton aria-label="settings" id={image.iref} onClick={() => {this.pushData(image.iref);}}>
            <SaveIcon
              sx={{ color: "#4287f5" }}              
            />
          </IconButton>
          : ""}

          {this.state.edit [ image.iref ]  ?
            <IconButton aria-label="settings" id={image.iref} onClick={() => {this.showConfirmDialog(image.iref);}}>
            <DeleteForeverIcon
              sx={{ color: "#8f241d" }}              
            />
          </IconButton>
          : ""}

          <Dialog
            open={showConfirm}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.removeCancel}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Remove image ?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                  This action will delete the image and it is irreversible.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.removeCancel}>No</Button>
              <Button onClick={() => {this.removeConfirm(image.iref);}}>Yes</Button>
            </DialogActions>
          </Dialog>

        </StyledTableCell>

        </TableRow>
      });
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "middle", justifyContent:"center", alignItems: "center", paddingTop: "2px" }}>
        <Table sx={{ minWidth: 700, textAlign: "middle", justifyContent:"center" }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <IconButton aria-label="Add Image" title="Add Image" onClick={() => this.toggleAdd()}> 
                  <Avatar alt="Add Image" sx={{ width: 24, height: 24, color: "#273442" }}>
                    {this.state.addNew ? <FaMinusCircle/> : <FaPlusCircle/>}
                  </Avatar>
                </IconButton>
                Image
              </StyledTableCell>
              <StyledTableCell align="left">Name</StyledTableCell>
              <StyledTableCell align="left">is Main</StyledTableCell>              
              <StyledTableCell align="right">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {images}
          </TableBody>
        </Table>
        
      </Box>
    );

  }
}

export default KImagesTable;
