import React from 'react';
import { Avatar, createTheme, Box, CardContent, Button, TableContainer, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { format } from 'date-fns';
import Client from '../Client';
import Config from '../ClientConfig';

let theme = createTheme();
Date.prototype.getISODay = function(){ return (this.getDay() + 6) % 7 + 1; }
let d = new Date();
let today = format(d, 'yyyy-MM-dd');  // mm=week, MM=month
// let yesterday = format(new Date().setDate(new Date().getDate() - 1), 'yyyy-MM-dd');
let d1Month = format(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'yyyy-MM-dd'); //beginning of the month
// let dzMonth = format(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), 'yyyy-MM-dd'); //end of the month
let d1LastMonth = format(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'yyyy-MM-dd'); //beginning of the month
let dzLastMonth = format(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), 'yyyy-MM-dd'); //end of the month
// let d1Year = format(new Date(new Date().getFullYear(), 0, 1), 'yyyy-MM-dd'); //beginning of the year
// let dzYear = format(new Date(new Date().getFullYear(), 11, 31), 'yyyy-MM-dd'); //end of the year
// let d1Week = format(new Date().setDate(new Date().getDate() - new Date().getISODay() + 1), 'yyyy-MM-dd'); //beginning of the week
// let dzWeek = format(new Date().setDate(new Date().getDate() - new Date().getISODay() + 7), 'yyyy-MM-dd'); //end of the week
// let d1LWeek = format(new Date().setDate(new Date().getDate() - new Date().getISODay() - 13), 'yyyy-MM-dd'); //beginning of last week
// let dzLWeek = format(new Date().setDate(new Date().getDate() - new Date().getISODay() + 7), 'yyyy-MM-dd'); //end of last week
// console.log(today);  

function thousandsSeparator(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


const objectToCsv = function (data) {
  const csvRows = [];
  /* Get headers as every csv data format 
  has header (head means column name)
  so objects key is nothing but column name 
  for csv data using Object.key() function.
  We fetch key of object as column name for 
  csv */
  const headers = Object.keys(data[0]);
  /* Using push() method we push fetched 
     data into csvRows[] array */
  csvRows.push(headers.join(','));
  // Loop to get value of each objects key
  for (const row of data) {
      const values = headers.map(header => {
          const val = row[header]
          return `"${val}"`;
      });
      // To add, separator between each value
      csvRows.push(values.join(','));
  }
  /* To add new line for each objects values
     and this return statement array csvRows
     to this function.*/
  return csvRows.join('\n');
};

class SalesTargetLocationList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      saleslocation_list: [],
      page: 0,
      rowsPerPage: 12,
      dateFrom: d1Month,
      dateTo: today,
      sx_hidden: {display: 'none'},
      value: "1",
      filterData: {}
    };

    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.addDateFrom = this.addDateFrom.bind(this);
    this.addDateTo = this.addDateTo.bind(this);
    this.selectPeriod = this.selectPeriod.bind(this);

  }

  componentDidMount() {
    this.getData ( );
  }

  getData ( ) {
    let queryData = { 
      "type": 16,
      "dateFrom": this.state.dateFrom,
      "dateTo": this.state.dateTo
  }
	  let data = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, queryData );
    if ( data ) {
      this.setState ( { saleslocation_list: data });
    }
    this.setState ( { filterData: queryData } );
  }

  doSync ( ) {
    this.getData ( );
  }

  addDateFrom = (event) => {
    this.setState ({dateFrom: event.target.value});
  }

  addDateTo = (event) => {
    this.setState ({dateTo: event.target.value});
  }

  selectPeriod = (event) => {
    switch ( event.target.value ) {
      case "month": 
        this.setState ({sx_hidden: {display: 'none'}},  
        this.setState (
          {dateFrom: d1Month},  //beginning of the month
          function(){
          }
        ));
        this.setState (
          {dateTo: today},  //today
          function(){
            this.doSync ( );
          }
        ); 
        break;            
      case "last":
        this.setState ({sx_hidden: {display: 'none'}},
        this.setState (
          {dateFrom: d1LastMonth},  //beginning of last month
          function(){
          }
        ));
        this.setState (
          {dateTo: dzLastMonth},  //end of last month
          function(){
            this.doSync ( );
          }
        );
        break;            
      default:
        this.setState (
          {sx_hidden: {}},
          function(){
            this.doSync ( );
          }
        );
        // console.log(`No period selected.`);
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState ({page: newPage});
  }
  
  handleChangeRowsPerPage = (event) => {
    this.setState ({rowsPerPage: +event.target.value});
    this.setState ({page: 0});
  }

  onDownload = () => {
    const link = document.createElement("a");
    link.download = "salesTarget.csv";
    // console.log(this.state.saleslocation_list);
    // console.log(this.state.saleslocation_list.result);
    let csvData = objectToCsv(this.state.saleslocation_list.result);
    let csvHeader = "Sales Target";
    let csv = csvHeader + "\n" + csvData;
    link.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
    // console.log(link);
    link.click();
  };
  
  render() {
    let page = this.state.page;
    let rowsPerPage = this.state.rowsPerPage;
    let locationSalesData = [];
    let locationSalesList = [];
    if (this.state.saleslocation_list){
      if (this.state.saleslocation_list.result) {
        let counter = 0;
        Object.keys(this.state.saleslocation_list.result).forEach(key => {
          let fData = this.state.saleslocation_list.result[key];
          let myData = {'x': fData.location, 'y': (parseInt(fData.forecastTM/1000)+ "K")};
          let p = {
            id: counter+1,
            location: fData.location,
            forecastTM: fData.forecastTM,
            salesMTD: fData.salesMTD,
            salesLM: fData.salesLM,
            forecastMTD: fData.forecastMTD,
            targetTM: fData.targetTM,
            targetLM: fData.targetLM,
            targetMTD: fData.targetMTD
          }
          if (counter<10){
            locationSalesData.push ( myData );
          }
          counter += 1;
          locationSalesList.push ( p );
        });
      }
    }

    return (
      <CardContent sx={{borderRadius: "2px", padding: "2px"}}>
        <Box sx={{ marginLeft: "10px", borderRadius:"16px", display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between'}}>
          <Typography
              sx={{lineHeight: 1.5}}
              color="#1a237e"
              variant="overline"
            >
              TARGET TOTAL SALES
          </Typography>
          <Button
            color="inherit"
            size="small"
            onClick={this.onDownload} 
            variant="contained" 
            // color="primary"
            style={{maxWidth: '36px', minWidth: '36px'}}
          >
            <Avatar sx={{ width: 24, height: 24, bgcolor: theme.palette.success.light}}> 
              <FileDownloadIcon />
            </Avatar>
          </Button>
        </Box>
        <Box>
          <TableContainer sx={{ height: '480px' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {/* <TableCell style={{padding: "6px", textAlign: "center" }}>
                  <b>Top</b>
                </TableCell> */}
                <TableCell style={{padding: "6px", textAlign: "left" }}>
                  <b>Location</b>
                </TableCell>
                <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                  <b>Sales MTD</b>
                </TableCell>
                <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                  <b>Target TM</b>
                </TableCell>
                <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                  <b>% TM</b>
                </TableCell>
                <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                  <b>Sales LM</b>
                </TableCell>
                <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                  <b>Target LM</b>
                </TableCell>
                <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                  <b>% LM</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {locationSalesList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((location) => {
                return (
                  <TableRow
                    hover
                    key={location.id}
                    tabIndex={-1}
                  >
                    {/* <TableCell style={{padding: "6px", textAlign: "center" }}>
                      {location.id}
                    </TableCell> */}
                    <TableCell style={{padding: "6px", textAlign: "left" }}>
                      {location.location}
                    </TableCell>
                    <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        {thousandsSeparator(parseInt(location.salesMTD))}
                    </TableCell>
                    <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        {thousandsSeparator(parseInt(location.targetTM))}
                    </TableCell>
                    <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        {location.targetMTD === 0 ? 0+"%" : parseInt((location.salesMTD/location.targetMTD)*100)+"%"} 
                    </TableCell>
                    <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        {thousandsSeparator(parseInt(location.salesLM))}
                    </TableCell>
                    <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        {thousandsSeparator(parseInt(location.targetLM))}
                    </TableCell>
                    <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        {location.targetLM === 0 ? 0+"%" : parseInt((location.salesLM/location.targetLM)*100)+"%"} 
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[12, { value: locationSalesList.length, label: 'All' }]}
            labelRowsPerPage="Rows:"
            // showFirstButton="True"
            // showLastButton="True"
            component="div"
            count={locationSalesList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Box>
      </CardContent>
    );

  }
}

export default SalesTargetLocationList;
