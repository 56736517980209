import React from 'react';
import { Avatar, createTheme, Box, Card, Button, MenuItem, Paper, TableContainer, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { format } from 'date-fns';
import Client from '../Client';
import Config from '../ClientConfig';

let theme = createTheme();

let d = new Date();
let today = format(d, 'yyyy-MM-dd');  // mm=week, MM=month
let yesterday = format(new Date().setDate(new Date().getDate() - 1), 'yyyy-MM-dd');
let d1Month = format(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'yyyy-MM-dd'); //beginning of the month
// let dzMonth = format(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), 'yyyy-MM-dd'); //end of the month
let d1Year = format(new Date(new Date().getFullYear(), 0, 1), 'yyyy-MM-dd'); //beginning of the year
// let dzYear = format(new Date(new Date().getFullYear(), 11, 31), 'yyyy-MM-dd'); //end of the year
let d1Week = format(new Date().setDate(new Date().getDate() - new Date().getDay() + 1), 'yyyy-MM-dd'); //beginning of the week
// let dzWeek = format(new Date().setDate(new Date().getDate() - new Date().getDay() + 7), 'yyyy-MM-dd'); //end of the week
// let d1LWeek = format(new Date().setDate(new Date().getDate() - new Date().getDay() - 13), 'yyyy-MM-dd'); //beginning of last week
// let dzLWeek = format(new Date().setDate(new Date().getDate() - new Date().getDay() + 7), 'yyyy-MM-dd'); //end of last week
// console.log(today);  

function thousandsSeparator(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

const period = [
    {
        value: 'today',
        label: 'Today'
    },
    {
      value: 'yesterday',
      label: 'Yesterday'
  },
    {
        value: 'week',
        label: 'This Week'
    },
    {
        value: 'month',
        label: 'This Month'
    },
    {
        value: 'year',
        label: 'This Year'
    },
    {
        value: 'custom',
        label: 'Custom'
    }
];

const objectToCsv = function (data) {
  const csvRows = [];
  /* Get headers as every csv data format 
  has header (head means column name)
  so objects key is nothing but column name 
  for csv data using Object.key() function.
  We fetch key of object as column name for 
  csv */
  const headers = Object.keys(data[0]);
  /* Using push() method we push fetched 
     data into csvRows[] array */
  csvRows.push(headers.join(','));
  // Loop to get value of each objects key
  for (const row of data) {
      const values = headers.map(header => {
          const val = row[header]
          return `"${val}"`;
      });
      // To add, separator between each value
      csvRows.push(values.join(','));
  }
  /* To add new line for each objects values
     and this return statement array csvRows
     to this function.*/
  return csvRows.join('\n');
};

class SalesYTDLocationList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      saleslocation_list: [],
      page: 0,
      rowsPerPage: 12,
      dateFrom: d1Month,
      dateTo: today,
      sx_hidden: {display: 'none'},
      value: "1",
      filterData: {}
    };

    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.addDateFrom = this.addDateFrom.bind(this);
    this.addDateTo = this.addDateTo.bind(this);
    this.selectPeriod = this.selectPeriod.bind(this);

  }

  componentDidMount() {
    this.getData ( );
  }

  getData ( ) {
    let queryData = { 
      "type": 8,
      "dateFrom": this.state.dateFrom,
      "dateTo": this.state.dateTo
  }
	  let data = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, queryData );
    if ( data ) {
      this.setState ( { saleslocation_list: data });
    }
    this.setState ( { filterData: queryData } );
  }

  doSync ( ) {
    this.getData ( );
  }

  addDateFrom = (event) => {
    this.setState ({dateFrom: event.target.value});
  }

  addDateTo = (event) => {
    this.setState ({dateTo: event.target.value});
  }

  selectPeriod = (event) => {
    switch ( event.target.value ) {
      case "today":
        this.setState ({sx_hidden: {display: 'none'}},  
        this.setState (
          {dateFrom: today},  //today
          function(){
          }
        ));
        this.setState (
          {dateTo: today},  //today
          function(){
            this.doSync ( );
          }
        );
        break;
      case "yesterday":
        this.setState ({sx_hidden: {display: 'none'}},  
        this.setState (
          {dateFrom: yesterday}, //yesterday
          function(){
          }
        )); 
        this.setState (
          {dateTo: yesterday}, //yesterday
          function(){
            this.doSync ( );
          }
        );
        break;
      case "week":
        this.setState ({sx_hidden: {display: 'none'}},  
        this.setState (
          {dateFrom: d1Week},  //beginning of the week
          function(){
          }
        ));
        this.setState (
          {dateTo: today},  //today
          function(){
            this.doSync ( );
          }
        );
        break;  
      case "month": 
        this.setState ({sx_hidden: {display: 'none'}},  
        this.setState (
          {dateFrom: d1Month},  //beginning of the month
          function(){
          }
        ));
        this.setState (
          {dateTo: today},  //today
          function(){
            this.doSync ( );
          }
        ); 
        break;            
      case "year":
        this.setState ({sx_hidden: {display: 'none'}},
        this.setState (
          {dateFrom: d1Year},  //beginning of the year
          function(){
          }
        ));
        this.setState (
          {dateTo: today},  //today
          function(){
            this.doSync ( );
          }
        );
        break;            
      default:
        this.setState (
          {sx_hidden: {}},
          function(){
            this.doSync ( );
          }
        );
        // console.log(`No period selected.`);
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState ({page: newPage});
  }
  
  handleChangeRowsPerPage = (event) => {
    this.setState ({rowsPerPage: +event.target.value});
    this.setState ({page: 0});
  }
  
  onDownload = () => {
    const link = document.createElement("a");
    link.download = "salesHours_" + this.state.dateFrom  +"-"+ this.state.dateTo + ".csv";
    // console.log(this.state.saleslocation_list);
    // console.log(this.state.saleslocation_list.result);
    let csvData = objectToCsv(this.state.saleslocation_list.result);
    let csvHeader = "Sales value Hours\nDate From: "+ this.state.dateFrom + "\nDate To: "+ this.state.dateTo;
    let csv = csvHeader + "\n" + csvData;
    link.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
    // console.log(link);
    link.click();
  };

  render() {
    let page = this.state.page;
    let rowsPerPage = this.state.rowsPerPage;
    let locationSalesData = [];
    let locationSalesList = [];
    if (this.state.saleslocation_list){
      if (this.state.saleslocation_list.result) {
        let counter = 0;
        Object.keys(this.state.saleslocation_list.result).forEach(key => {
          let fData = this.state.saleslocation_list.result[key];
          let myData = {'x': fData.name, 'y': (parseInt(fData.value/1000)+ "K")};
          let p = {
            id: counter+1,
            name: fData.name,
            value: thousandsSeparator(parseInt(fData.value)),
            transactions: thousandsSeparator(parseInt(fData.transactions)),
            value12: thousandsSeparator(parseInt(fData.value_12)),
            value15: thousandsSeparator(parseInt(fData.value_15)),
            value18: thousandsSeparator(parseInt(fData.value_18)),
            value21: thousandsSeparator(parseInt(fData.value_21))
          }
          if (counter<10){
            locationSalesData.push ( myData );
          }
          counter += 1;
          locationSalesList.push ( p );
        });
      }
    }

    return (
      <Paper sx={{ width: '100%', borderRadius:"16px", overflow: 'hidden' }}>
      <Card style={{ height: '600px' }} sx={{ borderRadius:"16px", height: '100%' }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between'}}>
            <Box sx={{ padding: '16px', alignItems: 'baseline' }}>
              <TextField
                id="standard-select-period"
                select
                // label="Select"
                defaultValue="month"
                // helperText="Select Period"
                variant="standard"
                sx={{ width: 120}}
                onChange={this.selectPeriod}
              >
                {period.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box >
            <Box sx={this.state.sx_hidden} style={{ alignItems: 'baseline', justifyContent: 'space-around'}}>
              <TextField
                size='small'
                id="date"
                label="From"
                type="date"
                // defaultValue={currentDate}
                value={this.state.dateFrom}
                // inputFormat="DD/MM/YYYY"
                sx={{ color: "#e3e3e5", alignSelf: "center", marginLeft: "6px", width:120}}
                InputLabelProps={{
                  shrink: true,
                  sx : { color: "#5b5b5b" }
                }}
                InputProps={{ sx : { color: "#5b5b5b" }}}
                inputProps={{ style : { color: "#5b5b5b", paddingRight: 5, paddingLeft: 5} }}
                onChange={this.addDateFrom}
              />
              <TextField
                size='small'
                id="date"
                label="To"
                type="date"
                // defaultValue={currentDate}
                value={this.state.dateTo}
                sx={{ color: "#e3e3e5", alignSelf: "center", marginLeft: "6px", width:120 }}
                InputLabelProps={{
                  shrink: true,
                  sx : { color: "#5b5b5b" }
                }}
                InputProps={{ sx : { color: "#5b5b5b" }}}
                inputProps={{ style : { color: "#5b5b5b", paddingRight: 5, paddingLeft: 5} }}
                onChange={this.addDateTo}
              />
              <Button color="inherit" onClick={() => this.doSync()} style={{maxWidth: '36px', minWidth: '36px'}}>
                <Avatar sx={{ width: 24, height: 24, bgcolor: theme.palette.primary.light}}> 
                  <AutorenewIcon />
                </Avatar>
              </Button>
            </Box>
            <Button
              color="inherit"
              size="small"
              onClick={this.onDownload} 
              variant="contained" 
              // color="primary"
              style={{maxWidth: '36px', minWidth: '36px'}}
            >
              <Avatar sx={{ width: 24, height: 24, bgcolor: theme.palette.success.light}}> 
                <FileDownloadIcon />
              </Avatar>
            </Button>
          </Box>
          <Box sx={{ paddingLeft: '10px'}}>
            <TableContainer sx={{ height: '440px' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {/* <TableCell style={{padding: "6px", textAlign: "center" }}>
                    <b>Top</b>
                  </TableCell> */}
                  <TableCell style={{padding: "6px", textAlign: "left" }}>
                    <b>Name</b>
                  </TableCell>
                  <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                    <b>Trans.</b>
                  </TableCell>
                  <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                    <b>Value</b>
                  </TableCell>
                  <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                    <b>Value_12</b>
                  </TableCell>
                  <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                    <b>Value_15</b>
                  </TableCell>
                  <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                    <b>Value_18</b>
                  </TableCell>
                  <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                    <b>Value_21</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {locationSalesList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((location) => {
                  return (
                    <TableRow
                      hover
                      key={location.id}
                      tabIndex={-1}
                    >
                      {/* <TableCell style={{padding: "6px", textAlign: "center" }}>
                        {location.id}
                      </TableCell> */}
                      <TableCell style={{padding: "6px", textAlign: "left" }}>
                        {location.name}
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                          {location.transactions}
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                          {location.value}
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                          {location.value12}
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                          {location.value15}
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                          {location.value18}
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                          {location.value21}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[12, { value: locationSalesList.length, label: 'All' }]}
              labelRowsPerPage="Rows:"
              // showFirstButton="True"
              // showLastButton="True"
              component="div"
              count={locationSalesList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </Box>
      </Card>
      </Paper>
    );

  }
}

export default SalesYTDLocationList;
