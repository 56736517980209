import React from 'react';
import {Box, Card} from '@mui/material';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import OverviewTotalSales from './components/biReports/OverviewTotalSales';
import OverviewMonthSales from './components/biReports/OverviewMonthSales';
import OverviewMonthCustomers from './components/biReports/OverviewMonthCustomers';
import OverviewTotalTransactions from './components/biReports/OverviewTotalTransactions';
import SalesForecastLocationList from './components/biReports/SalesForecastLocationList';
import SalesTargetLocationList from './components/biReports/SalesTargetLocationList';
import SalesTargetCategory from './components/biReports/SalesTargetCategory';
import Client from './components/Client';
import { format } from 'date-fns';

// const now = new Date();
let d = new Date();
let today = format(d, 'yyyy-MM-dd');  // mm=week, MM=month

class RapSales2 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      /* locationGroups: null, */
      edit: false,
	  date: today,
	  tabValue: "value"
    };

	this.handleTabChange = this.handleTabChange.bind(this);

  }

  componentDidMount() {  
  }

  componentWillUnmount() {    
  }

  /* setLocationsGroups ( groups ) {
    let a = { }
    if ( groups !== null && groups !== undefined ) {
      groups.map ( (locationGroup) => {
        a [ locationGroup.iref ] = locationGroup;        
      });
      this.setState ( { locationGroups : a });
    }
  }
 */

  addDate = (event) => {
    this.setState ({date: event.target.value});
  }

  handleTabChange ( event, newValue ) {
	// this.addDate = this.addDate.bind(this);  
    this.setState ( { tabValue: newValue } );
	// console.log(this.state.date);
  }

  render() {

	// let tabValue = "value";
    // if ( this.state.tabValue ) {tabValue = this.state.tabValue};

		return (
			<Box component="main" sx={{flexGrow: 1, py: 8, paddingTop: "30px", paddingBottom: "30px"}}>
				<Container style={{paddingLeft: 5, paddingRight:5}} maxWidth="xl">
					<Grid container spacing={1} sx={{width: 'calc(100% + 60px)', marginLeft: "-32px"}}>
					<Grid item xs={12}>
						<Grid container spacing={1} >
							<Grid item lg={4} md={6} sm={6} xs={6} >
								<OverviewTotalSales />
							</Grid>
							<Grid item lg={4} md={6} sm={6} xs={6}>
								<OverviewTotalTransactions />
							</Grid>
							<Grid item lg={4} md={12} sm={12} xs={12}>
								<Grid container spacing={1}>
									<Grid item sm={6} xs={6} md={6} lg={6}>
										<OverviewMonthSales />
									</Grid>
									<Grid item sm={6} xs={6} md={6} lg={6}>
										<OverviewMonthCustomers />
									</Grid>
								</Grid>
							</Grid>		
						</Grid>
					</Grid>		
					<Grid item xs={12} lg={12} >
						<Grid container spacing={1}>
							<Grid item xs={12} lg={4} >
								<Card style={{ height: '600px' }} sx={{ borderRadius:"16px"}}>
									<Box sx={{ marginLeft: "10px", borderRadius:"16px", display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'flex-start'}} >
										<SalesForecastLocationList />
									</Box>
								</Card>		
							</Grid>		
							<Grid item xs={12} lg={5} >
								<Card style={{ height: '600px' }} sx={{ borderRadius:"16px"}}>
									<Box sx={{ marginLeft: "10px", borderRadius:"16px", display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'flex-start'}} >
										<SalesTargetLocationList />
									</Box>
								</Card>		
							</Grid>		
							<Grid item xs={12} lg={3} >
								<Card style={{ height: '600px' }} sx={{ borderRadius:"16px"}}>
									<Box sx={{ marginLeft: "10px", borderRadius:"16px", display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'flex-start'}} >
										<SalesTargetCategory />
									</Box>
								</Card>		
							</Grid>	
						</Grid>	
					</Grid>		
					</Grid>
				</Container>
			</Box>
		);
	}
}

export default RapSales2;
