import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Autocomplete from '@mui/material/Autocomplete';
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import CardMedia from '@mui/material/CardMedia';
import noImage from './images/noImage.png';
import idle from './images/icons/idle_on.png';
import registered from './images/icons/registered_on.png';
import active from './images/icons/active_on.png';
import discontinued from './images/icons/discontinued_on.png';
import swal from 'sweetalert';
//To be removed
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
//import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';

import DropDown from './components/DropDown';
import KImage from './components/KImage';
import Config from './components/ClientConfig';
import Client from './components/Client';
import BackOfficeConfig from './Config';

import Visibility from './Visibility';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

class DataRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {    
      client: new Client ( "none" ),  
      edit: false,
      row: {},
      companiesSearch: [],
      usersSearch: [],
      nameValid: false,
      descriptionValid: false,
      somethingHasChanged: false,
      allowEdit: false
    };

    this.handleEditClick = this.handleEditClick.bind(this);
    this.companyChange = this.companyChange.bind(this);
    this.companySearch = this.companySearch.bind(this);
    this.userChange = this.userChange.bind(this);
    this.userSearch = this.userSearch.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.changeType = this.changeType.bind(this);
    this.changeRelation = this.changeRelation.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.pushData = this.pushData.bind(this);

  }

  componentDidMount() {
    if (this.props)
      if (this.props.allowEdit === true)
        this.setState ({allowEdit: true});
    if ( this.props.dataRow ) {
      let uRow = this.props.dataRow;
      this.setState ( { row: uRow } );
      if ( uRow.name.length > 0 ) this.setState ( {nameValid: true } );
      if ( uRow.description.length > 0 ) this.setState ( {descriptionValid: true } );      
    }
    if ( this.props.edit ) this.setState ( {edit: true } );
  }

  handleEditClick ( ) {
    if (!this.state.allowEdit)
      return;
    let cEdit = !this.state.edit;
    this.setState({ edit: !this.state.edit });

    //this.setState({ saveButtonVisible: cEdit });
  }

  companySearch ( e ) {
    if ( e.target.value.length >= 2 ) {
      let queryData = { "nameLike" : e.target.value };
      let data = this.state.client.filterCompanies ( queryData );
      let newCompanies = [];
      let companies = [];
      Object.keys(data.Companies).forEach(key => {
        let fData = data.Companies [ key ];
        let lineText = fData.iref + " - " + fData.cuid + " - " + fData.name + " (" + fData.ruid + ", " + fData.email + ")";
        let newObj = { key: fData.iref, label: lineText };
        newCompanies.push ( newObj );
        companies.push ( fData );
        //counties [ fData.iref ] = fData.name;      
      });

      this.setState ( { companiesSearch: newCompanies });
    }
  }

  companyChange ( e ) {
    let text = e.target.textContent;
    let repIref = text.substring ( 0, text.indexOf(" - ") );
    let queryData = { };
    let data = this.state.client.getData ( Config.API_URL_COMPANY + repIref, queryData );
    let uRow = this.state.row;
    uRow.company = data;
    this.setState ( { row: uRow } );    
    this.setState ( { somethingHasChanged: true } );
  }

  userSearch ( e ) {
    if ( e.target.value.length >= 2 ) {
      let queryData = { "nameLike" : e.target.value };
      let data = this.state.client.filterUsers ( queryData );
      let newUsers = [];
      let users = [];
      if ( data.usersProfiles.length > 0 ) {
        Object.keys(data.usersProfiles).forEach(key => {
          let fData = data.usersProfiles [ key ];
          let userType = BackOfficeConfig.USER_TYPES [ fData.user_type ];
          let lineText = fData.iref + " - " + userType + "[" + fData.username + "] - " + fData.first_name + " " + fData.last_name + " (" + fData.ruid + ", " + fData.email + ")";
          let newObj = { key: fData.iref, label: lineText };
          newUsers.push ( newObj );
          users.push ( fData );
          //counties [ fData.iref ] = fData.name;      
        });
      }
      this.setState ( { usersSearch: newUsers });
    }
  }

  userChange ( e ) {
    let text = e.target.textContent;
    let repIref = text.substring ( 0, text.indexOf(" - ") );
    let queryData = { iref: repIref };
    //let data = this.state.client.getData ( Config.USER_API_URL + repIref, queryData );
    let data = this.state.client.getData ( Config.API_URL_USERS_PROFILES, queryData );
    let userWithPerson = {};
    if ( data.usersProfiles ) {
      if ( data.usersProfiles.length == 1 ) userWithPerson = data.usersProfiles [ 0 ];
    }
    let uRow = this.state.row;
    if ( userWithPerson ) {
      uRow.user = userWithPerson;
      this.setState ( { row: uRow } );    
      this.setState ( { somethingHasChanged: true } );
    }    
  }

  changeValue ( e ) {
    let id = null;
    if ( e.target.id !== null ) id = e.target.id;
    let value = null;
    if  ( e.target.value !== null ) value = e.target.value;
    //if ( id !== null && value !== null && value !== undefined ) {
    if ( id ) {
      let row = {};
      if ( this.state.row ) row = this.state.row;
      switch ( id ) {
        case "name":
          if ( value.length > 0 ) {
            row.name = value;
            this.setState ( { nameValid: true });
          } else {
            row.name = "";
            this.setState ( { nameValid: false });
          }
          this.setState ( { somethingHasChanged: true } );
          break;
        case "description":
          if ( value.length > 0 ) {
            row.description = value;
            this.setState ( { descriptionValid: true });
          } else {
            row.description = "";
            this.setState ( { descriptionValid: false });
          }
          this.setState ( { somethingHasChanged: true } );
          break;
      }
      this.setState ( { row: row });
    }
  }

  changeType ( e ) {
    let uRow = this.state.row;
    uRow.groupType = parseInt ( e );
    this.setState ( { row: uRow } );
    this.setState ( { somethingHasChanged: true } );
  }

  changeRelation ( e ) {
    let uRow = this.state.row;
    uRow.relation = parseInt ( e );
    this.setState ( { row: uRow } );
    this.setState ( { somethingHasChanged: true } );
  }

  changeStatus ( e ) {
    let uRow = this.state.row;
    uRow.status = parseInt ( e );
    this.setState ( { row: uRow } );
    this.setState ( { somethingHasChanged: true } );
  }

  pushData ( e ) {
    if ( this.state.somethingHasChanged && this.state.nameValid && this.state.descriptionValid ) {
      let rdata = this.state.client.pushData ( Config.API_URL_LOCATIONSGROUPS_ADD, this.state.row );
      if ( rdata ) {
        if ( rdata.status == "OK" ) {
          let uRow = this.state.row;
          uRow.iref = rdata.iref;
          this.setState ( { row: uRow } );
          this.handleEditClick ( );
          swal ( "Success", "Successfully added/modified location group ! (" + rdata.iref + ")", "success", {
            buttons: false,
            timer: 2000,
          })
          .then((value) => {
            //this.props.personUpdated ( );
            this.setState ( {edit: false} );
            this.setState ( { somethingHasChanged: false } );
            this.props.locationGroupAdded ();
          });        
        } else {
          if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
          swal ( "Error", "Add/Update location group failed !\n\n" + rdata.message, "error" );
        }
      }
    } else {
      swal ( "Error", "Add/Update location group failed ! Some fields are not validated !", "error" );
    }
  }


  render() {
    let row = [];
    let iref = "0";
    let name = "";
    let description = "";
    let coordinator_alt = "";
    let coordinator_img = noImage;
    let company_name = "";
    if ( this.state.row ) {
      row = this.state.row;
      if ( row.iref ) iref = row.iref;
      if ( row.name ) name = row.name;
      if ( row.description ) description = row.description;

      if ( row.user ) {
        if ( row.user.person ) {
          coordinator_alt = row.user.person.firstName + " " + row.user.person.lastName;
          coordinator_img = row.user.person.image;
        }
      }

      if ( row.company ) {
        company_name = row.company.name + "(" + row.company.cuid + ")";
      }
    }

    let coordinator = null;
    if ( row.user )
      if ( row.user.person )
        coordinator = row.user.person;

    let coordinators = {};
    if ( this.props.coordinators ) {
      let x = this.props.coordinators.map ( (c) => {
        coordinators [ c.iref ] = {};
        coordinators [ c.iref ][ "name" ] = c.first_name + " " + c.last_name;
        if ( c.person )
          coordinators [ c.iref ][ "image" ] = c.person.image;
      });
    }

    let companies = {};
    if ( this.props.companies ) {
      let x = this.props.companies.map ( (c) => {
        companies [ c.iref ] = c.cuid + "-" + c.name;            
      });
    }
      
    let groupType = 0;
    if ( row.groupType ) groupType = parseInt ( row.groupType );
    let locationGroup_type_alt = BackOfficeConfig.LOCATION_GROUPS_TYPES_DEF [ groupType ];

    /* let relation = 0;
    if ( row.relation ) relation = parseInt ( row.relation );
    let locationGroup_relation_alt = BackOfficeConfig.LOCATION_RELATION_DEF [ relation ]; */

    let locationGroup_status_image = noImage;
    let locationGroup_status_alt = "Unknown"
    switch ( row.status ) {
      case 0:
        locationGroup_status_image = registered;
        locationGroup_status_alt = "Registered";
        break;
      case 1:
        locationGroup_status_image = active;
        locationGroup_status_alt = "Active";
        break;
      case 2:        
        locationGroup_status_image = discontinued;
        locationGroup_status_alt = "Suspended";
        break;     
    }

      //let locationGroup_type_add = <DropDown/>;

      const actions = [
        { icon: <FileCopyIcon />, name: 'Copy' },
        { icon: <SaveIcon />, name: 'Save' },
        { icon: <PrintIcon />, name: 'Print' },
        { icon: <ShareIcon />, name: 'Share' },
      ];

    let companiesSearch = [ ];
    if ( this.state.companiesSearch ) companiesSearch = this.state.companiesSearch;
    let usersSearch = [ ];
    if ( this.state.usersSearch ) usersSearch = this.state.usersSearch;

    let showSave = false;
    if ( this.state.edit === true && this.state.somethingHasChanged === true && this.state.nameValid === true && this.state.descriptionValid === true )
      showSave = true;

    
    return (
      <StyledTableRow key={row.iref} sx={{padding: "1px"}}>

        <StyledTableCell align="left" sx={{marginLeft : "1px"}}>
          {iref}
        </StyledTableCell>

        <StyledTableCell component="td" scope="row" sx={{padding: "1px"}}>
          {this.state.edit ? 
            <TextField
              id="name"
              label="Name"
              variant="outlined"
              value={name || ''}
              onChange={this.changeValue}
              onFocus={event => { event.target.select(); }}
              inputProps={{maxLength:"32"}}
              error={this.state.nameValid === false}
              required={true}
              sx={{minWidth: "80px", maxWidth: "200px"}}
              size="small"
            />
            : name }
        </StyledTableCell>

        <StyledTableCell component="td" scope="row" sx={{padding: "1px"}}>
          {this.state.edit ? 
            <TextField
              id="description"
              label="Description"
              variant="outlined"
              size="small"
              value={description || ''}
              onChange={this.changeValue}
              onFocus={event => { event.target.select(); }}
              inputProps={{maxLength:"64"}}
              error={this.state.descriptionValid === false}
              sx={{minWidth: "80px", maxWidth: "200px"}}
              required={true}
            />
            : description }
        </StyledTableCell>

        <StyledTableCell align="left" sx={{padding: "1px"}}>
          {this.state.edit ?
            <Autocomplete
              disablePortal
              id="user"
              name="User"
              options={usersSearch}
              sx={{ minWidth: "200px", marginTop: 0 }}
              onChange={this.userChange}
              renderInput = {
                (params) =>
                  <TextField 
                    {...params}
                    label="User (type keywords)"
                    onChange={this.userSearch}
                  />}
              size="small"
            />          
            :
            <CardMedia
              component="img"
              height="48px"
              sx={{ width: "48px", marginLeft: 1 }}
              image={coordinator_img}
              alt={coordinator_alt}
              title={coordinator_alt}
            />
          }
        </StyledTableCell>

        <StyledTableCell component="th" scope="row" sx={{padding: "1px"}}>
          {this.state.edit ?           
          <DropDown
            name="Type"
            value={row.groupType}
            doptions={BackOfficeConfig.LOCATION_GROUPS_TYPES}
            hasIcons={false} 
            minWidth={120}
            onChangeFunction={this.changeType}
            size="small"
          />
          : locationGroup_type_alt }
        </StyledTableCell>

        {/* <StyledTableCell align="left" sx={{padding: "1px"}}>
          {this.state.edit ? 
            <DropDown
              name="Relation"
              value={row.relation}
              doptions={BackOfficeConfig.LOCATION_GROUPS_RELATION}
              hasIcons={false} 
              minWidth={120}
              onChangeFunction={this.changeRelation}
              size="small"
            />
          :locationGroup_relation_alt}
        </StyledTableCell> */}

        <StyledTableCell align="left" sx={{padding: "1px"}}>
          {this.state.edit ? 
            <DropDown
            name="Status"
            value={row.status}
            doptions={BackOfficeConfig.LOCATION_GROUPS_STATUS}
            hasIcons={true} 
            minWidth={140}
            onChangeFunction={this.changeStatus}
            size="small"
          />
            :

            <CardMedia
              component="img"
              height="48px"
              sx={{ width: "48px", marginLeft: 1 }}
              image={locationGroup_status_image}
              alt={locationGroup_status_alt}
              title={locationGroup_status_alt}
            />
          }
        </StyledTableCell>

        <StyledTableCell align="left" sx={{padding: "1px"}}>
          {this.state.edit ? 
            <Autocomplete
              disablePortal
              id="company"
              name="Company"
              options={companiesSearch}
              sx={{ width: "200px", marginTop: 0 }}
              onChange={this.companyChange}
              renderInput = {
                (params) =>
                  <TextField 
                    {...params}
                    label="Company (type keywords)"
                    onChange={this.companySearch}
                  />}
              size="small"
            />
            : company_name
          }
        </StyledTableCell>


        <StyledTableCell align="right" sx={{padding: "1px"}}>
          <IconButton aria-label="settings" onClick={this.handleEditClick}>
            <EditIcon
              sx={{ color: this.state.edit ? "#8f241d" : "#e3e3e5" }}
            />
          </IconButton>
          {showSave ?
            <IconButton aria-label="settings" onClick={this.pushData}>
            <SaveIcon
              sx={{ color: "#4287f5" }}
            />
          </IconButton>
          : ""}
        </StyledTableCell>

      </StyledTableRow>
    );
  }
}


class LocationsGroups extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      locationGroups: null,
      usersProfiles: null,
      companies: null,
      edit: false,
      addGroup: false
      //addNewProduct: false,
    };

    //this.groupAdded = this.groupAdded.bind(this);
    this.toggleAdd = this.toggleAdd.bind(this);
    this.getData = this.getData.bind(this);
    this.locationGroupAdded = this.locationGroupAdded.bind(this);
    this.restricted = new Visibility ("locations_groups");
  }

  componentDidMount() {
    if (this.restricted.scope.visible)
      this.getData ( );
  }

  componentWillUnmount() {    
  }

  getData ( ) {   
    let queryData = {};
    queryData [ "orderBy" ] = "name"; 
    let locationsGroups = this.state.client.getLocationsGroups ( queryData );
    this.setLocationsGroups ( locationsGroups.locationsGroups );

    queryData = {};
    queryData [ "user_status" ] = 1;
    queryData [ "user_type_greater" ] = 27;
    queryData [ "orderBy_userType" ] = true;
    let coordinators = this.state.client.getData ( Config.API_URL_USERS_PROFILES, queryData );
    this.setState ( {usersProfiles : coordinators.usersProfiles } );
    
    let queryDataC = {};
    queryDataC [ "orderBy_Name" ] = true;
    let companies = this.state.client.getData ( Config.API_URL_COMPANY, queryDataC );
    this.setState ( {companies : companies.Companies } );
  }

  toggleAdd ( ) {
    let addButton = this.state.addGroup;
    this.setState ( {addGroup: !addButton} );
    //this.props.toggleAdd ( !addButton );
  }

  setLocationsGroups ( groups ) {
    let a = { }
    if ( groups !== null && groups !== undefined ) {
      groups.map ( (locationGroup) => {
        a [ locationGroup.iref ] = locationGroup;        
      });
      this.setState ( { locationGroups : a });
    }
  }

  locationGroupAdded ( ) {
    this.getData ( );
    this.setState ( { addGroup: false } );
  }
  
  
  render() {
    if (!this.restricted.scope.visible)
      return (<div></div>);
    let locationGroupsValues = [];
    if ( this.state.locationGroups !== null && this.state.locationGroups !== undefined ) {
      locationGroupsValues = Object.values ( this.state.locationGroups );
    }
    //console.log ( locationGroupsValues );

    let allData ="";
    if ( locationGroupsValues ) {
      allData = locationGroupsValues.map((row) => {
        return <DataRow
          key = {row.iref}
          dataRow = {row}
          coordinators = {this.state.usersProfiles}
          companies = {this.state.companies}
          locationGroupAdded={this.locationGroupAdded}
          allowEdit={this.restricted.scope.edit}
        />
      });
    }

    return (

      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
      <TableHead sx={{backgroundColor: "#273442", color: "#FFFFFF !important" }}>
          <TableRow>
            <TableCell>
              <IconButton aria-label="Add Location Group" title="Add Location Group" onClick={() => this.toggleAdd()}>       
                <Avatar alt="Add Location Group" sx={{ width: 24, height: 24, color: "#273442" }}>
                  {this.state.addGroup ? <FaMinusCircle/> : <FaPlusCircle/>}
                </Avatar>
              </IconButton>
            </TableCell>
            <TableCell sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Name</TableCell>
            <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Description</TableCell>
            <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Coordinator</TableCell>            
            <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Type</TableCell>
            {/* <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Relation</TableCell> */}
            <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Status</TableCell>
            <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Company</TableCell>
            <TableCell align="right" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.addGroup ?
            <DataRow key={0} locationGroupAdded={this.locationGroupAdded} edit={true} allowEdit={this.restricted.scope.edit}/>
            : ""
          }
          {allData}
        </TableBody>
      </Table>
    </TableContainer>
      

    );

  }
}

export default LocationsGroups;
