import React from 'react';
import { useRef } from "react";
//import { makeStyles } from '@mui/styles';
import { ThemeProvider } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { FaHome, FaPizzaSlice, FaHamburger, FaMapMarkedAlt, FaEuroSign, FaUserTie, FaHospitalUser } from "react-icons/fa";
import InputLabel from '@mui/material/InputLabel';
//import FormControl from '@mui/material/FormControl';
//import NativeSelect from '@mui/material/NativeSelect';
//import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import Slider from '@mui/material/Slider';
import AvatarEditor from 'react-avatar-editor'
import swal from 'sweetalert';
import CropRotateIcon from '@mui/icons-material/CropRotate';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import GradientIcon from '@mui/icons-material/Gradient';
import ContrastIcon from '@mui/icons-material/Contrast';
import TonalityIcon from '@mui/icons-material/Tonality';

//import { TextField, createMuiTheme, ThemeProvider } from "@material-ui/core";

//import './css/sideBar.scss';
//import './css/dashboard.css';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

//import noImage from './../images/icons/noImage_1.png';
import noImage from './../images/noImage.png';

//let theme = createTheme();

/* const theme = createTheme({
  overrides: {
    MuiInputLabel: { // Name of the component ⚛️ / style sheet
      root: { // Name of the rule
        color: "orange",
        borderColor: "orange",
        "&$focused": { // increase the specificity for the pseudo class
          color: "blue",
          borderColor: "blue",
        }
      }
    }
  }
});
 */
class KImage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      edit: false,
      iref: "",
      image: {
        data: noImage,
        edited: false,
        width: 128,
        height: 128,
        border: 20,
        borderRadius: 0,
        color: [255, 255, 255, 0.6],
        scale: 1.0,
        rotate: 0,
        newImage: noImage
      },
      imageText: "",
      options: { },
      canvas: null,
      can: noImage,
      saveColor: "primary",
      imageSaved: false,
      showImageModif: false,
      imageModifIdx: 0,
      arrange: "block",
      key: ""
    };

    //this.handleExpandClick = this.handleExpandClick.bind(this);
    this.sendChangedState = this.sendChangedState.bind(this);
    this.handleRotateChange = this.handleRotateChange.bind(this);
    this.handleScaleChange = this.handleScaleChange.bind(this);
    this.handleBorderRadiusChange = this.handleBorderRadiusChange.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleImageEdit = this.handleImageEdit.bind(this);
    this.reset = this.reset.bind(this);
    this.save = this.save.bind(this);
    this.saveBack = this.saveBack.bind(this);
    this.changeImageModif = this.changeImageModif.bind(this);
    
    
    this.editor = React.createRef();
  }

  componentDidMount() {
    let image = {};
    if ( this.props.image ) image = this.props.image;
    if ( !image.width ) image.width = 128;
    if ( !image.height ) image.height = 128;
    if ( !image.border ) image.border = 20;
    if ( !image.color ) image.color = [255, 255, 255, 0.6];
    if ( !image.scale ) image.scale = 1.0;
    if ( !image.rotate ) image.rotate = 0;
    if ( !image.borderRadius ) image.borderRadius = 0;    

    image.newImage = image.data;
    this.setState ( {image: image} );
    let imageText = "";
    if ( this.props.imageText ) imageText = this.props.imageText;
    this.setState ( {imageText: imageText} );

    let iref = "";
    if ( this.props.key !== undefined ) iref = this.props.key;
    if ( this.props.iref !== undefined ) iref = this.props.iref;
    this.setState ( { iref: iref } );

    let edit = false;
    if ( this.props.edit ) edit = this.props.edit;
    this.setState ( {edit: edit} );

    let key = false;
    if ( this.props.key ) key = this.props.key;
    this.setState ( {key: key} );

    if ( this.props.arrange !== undefined )
      this.setState ( { arrange: this.props.arrange } );
  }

  componentDidUpdate ( prevProps ) {    

    if ( prevProps.image !== this.props.image ) {
      let image = {};
      if ( this.props.image ) image = this.props.image;
      if ( !image.width ) image.width = 128;
      if ( !image.height ) image.height = 128;
      if ( !image.border ) image.border = 20;
      if ( !image.color ) image.color = [255, 255, 255, 0.6];
      if ( !image.scale ) image.scale = 1.0;
      if ( !image.rotate ) image.rotate = 0;
      if ( !image.borderRadius ) image.borderRadius = 0;
      image.newImage = image.data;
      this.setState ( {image: image} );

      let iref = "";
      if ( this.props.key !== undefined ) iref = this.props.key;
      if ( this.props.iref !== undefined ) iref = this.props.iref;
      this.setState ( { iref: iref } );
    }

    if ( prevProps.edit !== this.props.edit ) {
      let edit = false;
      if ( this.props.edit ) edit = this.props.edit;
      this.setState ( {edit: edit} );
    }

  }

  reset ( ) {
    let nImage = this.state.image;
    nImage.width = 128;
    nImage.height = 128;
    nImage.border = 20;
    nImage.color = [255, 255, 255, 0.6];
    nImage.scale = 1.0;
    nImage.rotate = 0;
    nImage.borderRadius = 0;
    nImage.newImage = nImage.data;
    this.setState ( { image: nImage }, this.setState ( { saveColor: "primary" } ) );
    //;
  }

  save ( ) {
    if (this.editor) {
      if ( this.editor.current ) {
        //let nimg = this.editor.current.getImageScaledToCanvas();
        if ( this.editor.current.canvas ) {
          //let canvas = this.editor.current.canvas;
          let canvas = this.editor.current.getImage();
          //console.log ( canvas.data );
          let dataUrl = canvas.toDataURL()
          let result = fetch(dataUrl)
            .then ( (response) => response )
            .then ( (data) => {
              const items = data;
              if ( items.ok === true ) {
                this.setState ( { imageSaved: true }, this.setState ( { saveColor: "primary" } ) );
                let nImage = this.state.image;
                nImage.newImage = items.url;
                this.setState ( { image: nImage }, this.saveBack(nImage) );
              }
            })
          //let blob = result.blob()
          //this.setState ( { can: canvas } );
        }
      }
    }
  }

  sendChangedState ( e ) {
    /* this.setState ( {selectedValue: e.target.value} );
    if ( this.props.onChangeFunction ) {
      this.props.onChangeFunction ( e.target.value );
    } */
  }

  handleRotateChange ( e ) {
    let image = this.state.image;
    if ( e.target.value ) {
      image.rotate = e.target.value;
      this.setState ( { image: image } );
    }
    this.handleImageEdit ( );
  }

  handleScaleChange ( e ) {
    let image = this.state.image;
    if ( e.target.value ) {
      image.scale = e.target.value;
      this.setState ( { image: image } );
    }
  }
  handleBorderRadiusChange ( e ) {
    let image = this.state.image;
    if ( e.target.value ) {
      image.borderRadius = e.target.value;
      this.setState ( { image: image } );
    }
    this.handleImageEdit ( );
  }

  handleImageChange ( e ) {
    let newImage = this.state.image;
    if ( e.target.files ) {
      if ( e.target.files.length ) {
        if ( e.target.files[0] ) {
          newImage.scale = 1.0;
          newImage.rotate = 0.0;
          newImage.borderRadius = 0.0;
          newImage.data = e.target.files[0];
          this.setState ( { image: newImage }, this.setState ( { saveColor: "error" } ) );
        }
      }
    } 
  }

  handleImageEdit ( ) {
    this.setState ( { saveColor: "error" } );
  }

  saveBack ( image ) {
    if ( this.props.saveBack ) {
      this.props.saveBack ( image.newImage, this.state.iref );
    }
  }
  changeImageModif ( ) {
    let imageModifIdx = parseInt ( this.state.imageModifIdx );


    if (this.editor) {
      if ( this.editor.current ) {
        if ( this.editor.current.canvas ) {
          let canvas = this.editor.current.getImage();
          let dataUrl = canvas.toDataURL()
          let result = fetch(dataUrl)
            .then ( (response) => response )
            .then ( (data) => {
              const items = data;
              if ( items.ok === true ) {
                var img = new Image ( );
                img.src = items.url;
                for (let i = 0; i < img.data.length; i += 4) {
                  const r = img.data[i]
                      , g = img.data[i+1]
                      , b = img.data[i+2]
                      , avg = Math.round((r + g + b) / 3)
                  /*const r = imgData.data[i]
                      , g = imgData.data[i+1]
                      , b = imgData.data[i+2]
                      , avg = Math.round((r + g + b) / 3)
		  */
                  img.data[i] = img.data[i+1] = img.data[i+2] = avg;
                }

                this.setState ( { imageSaved: true }, this.setState ( { saveColor: "primary" } ) );
                let nImage = this.state.image;
                nImage.newImage = img;
                this.setState ( { image: nImage }, this.saveBack(nImage) );
              }
            })
        }
      }
    }


    imageModifIdx += 1;
    if ( imageModifIdx === 3 ) imageModifIdx = 0;
    this.setState ( { imageModifIdx: imageModifIdx } );
  }
  

  render() {
    let image = {};
    let data = noImage;
    let dataPreview = noImage;
    let edited = false;
    let width = 128;
    let height = 128;
    let widthPreview = 128;
    let heightPreview = 128;
    let border = 20;
    let color = [255, 255, 255, 0.3];
    let scale = 1.0;
    let rotate = 0;
    let borderRadius = 0.0;
    let imageText = "";
    
    if ( this.state.image ) {
      if ( this.state.image.data ) {
        data = this.state.image.data;

        if ( !this.state.edit ) {
          let fileName = data.replace(/\.[^/.]+$/, "");
          let fileExtensions = data.split(".").pop();
          dataPreview = fileName + "_m." + fileExtensions;
        }
      }
      if ( this.state.image.edited ) edited = this.state.image.edited;
      if ( this.state.image.width ) width = this.state.image.width;
      if ( this.state.image.height ) height = this.state.image.height;
      if ( this.state.image.border ) border = this.state.image.border;
      if ( this.state.image.color ) color = this.state.image.color;
      if ( this.state.image.scale ) scale = this.state.image.scale;
      if ( this.state.image.rotate ) rotate = this.state.image.rotate;
      if ( this.state.image.borderRadius ) borderRadius = this.state.image.borderRadius;
    }
    if ( this.state.imageText ) imageText = this.state.imageText;

    let textColor = "#797979";
    let labelColor = "#797979";

    //if ( this.state.)

    //console.log ( this.editor );
    //console.log ( this.state.can );

    let rotateDefault = 0.0;
    let scaleDefault = 1.0;
    let brDefault = 0.0;
    let saveColor = "primary";
    if ( this.state.saveColor ) saveColor = this.state.saveColor;

    let imageModif = null; 
    if ( this.state.showImageModif ) {
      switch ( this.state.imageModifIdx ) {
        case 0:
          imageModif = <GradientIcon/>;
          break;
        case 1: 
          imageModif = <ContrastIcon/>;
          break;
        case 2:
          imageModif = <TonalityIcon/>;
          break;
      }
    }

    return (

      <FormControl required sx={{ m: 1, display: 'inline-flex', color: textColor, borderColor: {textColor} }} >
        {this.state.edit ?
          <Box sx={{ display: this.state.arrange, flexDirection: 'row', textAlign: "middle", justifyContent:"center", alignItems: "center", paddingTop: "2px" }}>
            <Box sx={{ display: 'block', flexDirection: 'column', textAlign: "middle", justifyContent:"center", alignItems: "center" }}>
              <AvatarEditor
                ref={this.editor}
                image={data}
                width={width}
                height={height}
                border={border}
                borderRadius={borderRadius}
                color={color} // RGBA
                scale={scale}
                rotate={rotate}
                onImageChange={ this.handleImageEdit }
              />
            </Box>
            <Box sx={{ display: 'block', flexDirection: 'column', textAlign: "middle", justifyContent:"center", alignItems: "center" }}>
              <Stack spacing={0} direction="row" sx={{ mb: 1, display: 'block' }} >
                <IconButton color="primary" aria-label="upload picture" component="label">
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={this.handleImageChange}
                  />
                  <AddPhotoAlternateIcon />
                </IconButton>
                {this.state.showImageModif ?
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    onClick={this.changeImageModif}
                  >
                    {imageModif}
                  </IconButton>
                : ""
                }
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  onClick={this.reset}
                >
                  <RestartAltIcon/>
                </IconButton>
                <IconButton
                  color={saveColor}
                  //sx={{color: {saveColor} }}
                  aria-label="save picture"
                  component="label"
                  onClick={this.save}
                >
                  <SaveIcon/>
                </IconButton>
                
              </Stack>
              <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                <AutorenewIcon/>
                <Slider
                  //value={typeof value === 'number' ? value : 0}
                  value={this.state.image.rotate}
                  defaultValue={rotateDefault}
                  min={0.00}
                  max={360.00}
                  onChange={this.handleRotateChange}
                  aria-labelledby="rotate"
                  valueLabelDisplay="auto"
                />
              </Stack>
              <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                <AspectRatioIcon/>
                <Slider
                  //value={typeof value === 'number' ? value : 0}
                  value={this.state.image.scale}
                  defaultValue={scaleDefault}
                  min={0.20}
                  max={4.00}
                  step={0.1}
                  onChange={this.handleScaleChange}
                  aria-labelledby="scale"
                  valueLabelDisplay="auto"
                />
              </Stack>
              <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                <TripOriginIcon/>
                <Slider
                  //value={typeof value === 'number' ? value : 0}
                  value={this.state.image.borderRadius}
                  defaultValue={brDefault}
                  min={0.20}
                  max={100.00}
                  step={1.0}
                  onChange={this.handleBorderRadiusChange}
                  aria-labelledby="border radius"
                  valueLabelDisplay="auto"
                />
              </Stack>

            </Box>
          </Box>


          : 
          <CardMedia
            component="img"
            height={heightPreview}
            sx={{ width: {widthPreview}, marginLeft: 1 }}
            image={dataPreview}
            alt={imageText}
          />
        }

          {/* <CardMedia
            component="img"
            height={height}
            sx={{ width: {width}, marginLeft: 1 }}
            image={this.state.image.newImage }
            alt={imageText}
          /> */}
      </FormControl>

   
    );

  }
}

export default KImage;
