import React from 'react';

class Visibility {

    constructor (scope) {
        let user_type = 0
        var data = JSON.parse(localStorage.getItem("apiUser"));
        if (data.profile) {
            user_type = parseInt(data.profile.user_type);
        }

        let local_scope = {}
        switch (user_type) {
            case 30:
                local_scope = this.#scopes_all_false;
                local_scope.dashboard.visible = true;
                local_scope.biReports.visible = true;
                local_scope.biReports.sales.visible = true;
                local_scope.biReports.forecast.visible = true;
                local_scope.biReports.products.visible = true;
                local_scope.biReports.categories.visible = true;
                local_scope.biReports.orders.visible = true;
                local_scope.messages.visible = true;
                break;
            case 31:
                local_scope = this.#scopes_all_false;
                local_scope.dashboard.visible = true;
                // local_scope.products.visible = true;
                // local_scope.products.products.visible = true;
                // local_scope.products.combos.visible = true;
                // local_scope.locations.visible = true;
                // local_scope.locations.locations.visible = true;
                // local_scope.priceLists.visible = true;
                // local_scope.priceLists.locations.visible = true;
                // local_scope.priceLists.products.visible = true;
                local_scope.biReports.visible = true;
                local_scope.biReports.sales.visible = true;
                local_scope.biReports.forecast.visible = true;
                local_scope.biReports.products.visible = true;
                local_scope.biReports.categories.visible = true;
                local_scope.biReports.orders.visible = true;
                local_scope.biReports.clients.visible = true;
                local_scope.messages.visible = true;
                // local_scope.messages.internal.visible = true;
                break;
            case 32:
                local_scope = this.#scopes_all_false;
                local_scope.dashboard.visible = true;
                local_scope.products.visible = true;
                local_scope.products.products.visible = true;
                local_scope.products.products.edit = true;
                local_scope.products.combos.visible = true;
                local_scope.products.combos.edit = true;
                local_scope.locations.visible = true;
                local_scope.locations.locations.visible = true;
                local_scope.locations.locations.edit = true;
                local_scope.priceLists.visible = true;
                local_scope.priceLists.locations.visible = true;
                local_scope.priceLists.locations.edit = true;
                local_scope.priceLists.products.visible = true;
                local_scope.priceLists.products.edit = true;
                local_scope.biReports.visible = true;
                local_scope.biReports.sales.visible = true;
                local_scope.biReports.forecast.visible = true;
                local_scope.biReports.products.visible = true;
                local_scope.biReports.categories.visible = true;
                local_scope.biReports.orders.visible = true;
                local_scope.biReports.clients.visible = true;
                local_scope.clients.visible = true;
                local_scope.clients.accounts.visible = true;
                local_scope.clients.families.visible = true;
                local_scope.clients.professional.visible = true;
                local_scope.messages.visible = true;
                // local_scope.messages.internal.visible = true;
                // local_scope.messages.internal.edit = true;
                // local_scope.messages.customers.visible = true;
                // local_scope.messages.customers.edit = true;
                break;
            case 33:
                local_scope = this.#scopes_all_false;
                local_scope.dashboard.visible = true;
                local_scope.products.visible = true;
                local_scope.products.products.visible = true;
                local_scope.products.products.edit = true;
                local_scope.products.combos.visible = true;
                local_scope.products.combos.edit = true;
                local_scope.locations.visible = true;
                local_scope.locations.locations.visible = true;
                local_scope.locations.locations.edit = true;
                local_scope.priceLists.visible = true;
                local_scope.priceLists.locations.visible = true;
                local_scope.priceLists.locations.edit = true;
                local_scope.priceLists.products.visible = true;
                local_scope.priceLists.products.edit = true;
                local_scope.priceLists.acquisitions.visible = true;
                local_scope.priceLists.acquisitions.edit = true;
                local_scope.biReports.visible = true;
                local_scope.biReports.sales.visible = true;
                local_scope.biReports.forecast.visible = true;
                local_scope.biReports.products.visible = true;
                local_scope.biReports.categories.visible = true;
                local_scope.biReports.orders.visible = true;
                local_scope.biReports.clients.visible = true;
                local_scope.registry.visible = true;
                local_scope.registry.companies.visible = true;
                local_scope.registry.companies.edit = true;
                local_scope.messages.visible = true;
                // local_scope.messages.internal.visible = true;
                break;
            case 34:
                local_scope = this.#scopes_all_true;
                //local_scope.products.combos.visible = false;
                // local_scope.products.products.edit = false;
                break;
            case 40:
                local_scope = this.#scopes_all_true;
                break;
            case 50:
                local_scope = this.#scopes_all_true;
                break;
            default:
                local_scope = this.#scopes_all_false;
        }

        switch (scope) {
            case "dashboard":
                this.scope = local_scope.dashboard;
                break;

            case "products":
                this.scope = local_scope.products;
                break;
            case "products_products":
                this.scope = local_scope.products.products;
                break;
            case "products_combos":
                this.scope = local_scope.products.combos;
                break;
            case "products_categories":
                this.scope = local_scope.products.categories;
                break;
            case "products_classes":
                this.scope = local_scope.products.classes;
                break;

            case "priceLists":
                this.scope = local_scope.priceLists;
                break;
            case "priceLists_locations":
                this.scope = local_scope.priceLists.locations;
                break;
            case "priceLists_promotions":
                this.scope = local_scope.priceLists.promotions;
                break;
            case "priceLists_companies":
                this.scope = local_scope.priceLists.companies;
                break;
            case "priceLists_products":
                this.scope = local_scope.priceLists.products;
                break;
            case "priceLists_combos":
                this.scope = local_scope.priceLists.combos;
                break;
            case "priceLists_acquisitions":
                this.scope = local_scope.priceLists.acquisitions;
                break;

            case "clients":
                this.scope = local_scope.clients;
                break;
            case "clients_accounts":
                this.scope = local_scope.clients.accounts;
                break;
            case "clients_families":
                this.scope = local_scope.clients.families;
                break;
            case "clients_professional":
                this.scope = local_scope.clients.professional;
                break;

            case "locations":
                this.scope = local_scope.locations;
                break;
            case "locations_locations":
                this.scope = local_scope.locations.locations;
                break;
            case "locations_groups":
                this.scope = local_scope.locations.groups;
                break;
            case "locations_employees":
                this.scope = local_scope.locations.employees;
                break;

            case "users":
                this.scope = local_scope.users;
                break;
            case "users_internal":
                this.scope = local_scope.users.internal;
                break;
            case "users_clients":
                this.scope = local_scope.users.clients;
                break;

            case "registry":
                this.scope = local_scope.registry;
                break;
            case "registry_persons":
                this.scope = local_scope.registry.persons;
                break;
            case "registry_companiess":
                this.scope = local_scope.registry.companiess;
                break;

            case "config":
                this.scope = local_scope.config;
                break;
            case "config_platform":
                this.scope = local_scope.config.platform;
                break;
            case "config_identity":
                this.scope = local_scope.config.identity;
                break;
            case "config_salesapp":
                this.scope = local_scope.config.salesapp;
                break;
            case "config_website":
                this.scope = local_scope.config.website;
                break;
            case "config_mobileapp":
                this.scope = local_scope.config.mobileapp;
                break;

            case "messages":
                this.scope = local_scope.messages;
                break;
            case "messages_internal":
                this.scope = local_scope.messages.internal;
                break;
            case "messages_customers":
                this.scope = local_scope.messages.customers;
                break;

            case "logs":
                this.scope = local_scope.logs;
                break;
            case "logs_internal":
                this.scope = local_scope.logs.internal;
                break;
            case "logs_customers":
                this.scope = local_scope.logs.customers;
                break;

            case "biReports":
                this.scope = local_scope.biReports;
                break;
            case "biReports_sales":
                this.scope = local_scope.biReports.sales;
                break;
            case "biReports_forecast":
                this.scope = local_scope.biReports.forecast;
                break;
            case "biReports_products":
                this.scope = local_scope.biReports.products;
                break;
            case "biReports_categories":
                this.scope = local_scope.biReports.categories;
                break;
            case "biReports_orders":
                this.scope = local_scope.biReports.orders;
                break;
            case "biReports_clients":
                this.scope = local_scope.biReports.clients;
                break;

            default:
                this.scope = local_scope;
        }
    };

    getAllFalse () {
        let local_scope = this.#scopes_all_false;
        return local_scope;
    }

    #scopes_all_true = {
        dashboard: {
            visible: true,
            edit: true 
        },
        products: {
            visible: true,
            edit: true,
            products: {
                visible: true,
                edit: true
            },
            combos: {
                visible: true,
                edit: true
            },
            categories: {
                visible: true,
                edit: true
            },
            classes: {
                visible: true,
                edit: true
            },
        },
        priceLists: {
            visible: true,
            edit: true,
            locations: {
                visible: true,
                edit: true
            },
            promotions: {
                visible: true,
                edit: true
            },
            companies: {
                visible: true,
                edit: true
            },
            products: {
                visible: true,
                edit: true
            },
            combos: {
                visible: true,
                edit: true
            },
            acquisitions: {
                visible: true,
                edit: true
            }
        },
        clients: {
            visible: true,
            edit: true,
            accounts: {
                visible: true,
                edit: true
            },
            families: {
                visible: true,
                edit: true
            },
            professional: {
                visible: true,
                edit: true
            }
        },
        locations: {
            visible: true,
            edit: true,
            locations: {
                visible: true,
                edit: true
            },
            groups: {
                visible: true,
                edit: true
            },
            employees: {
                visible: true,
                edit: true
            }
        },
        users: {
            visible: true,
            edit: true,
            internal: {
                visible: true,
                edit: true
            },
            clients: {
                visible: true,
                edit: true
            }
        },
        registry: {
            visible: true,
            edit: true,
            persons: {
                visible: true,
                edit: true
            },
            companies: {
                visible: true,
                edit: true
            }
        },
        config: {
            visible: true,
            edit: true,
            platform: {
                visible: true,
                edit: true
            },
            identity: {
                visible: true,
                edit: true
            },
            salesapp: {
                visible: true,
                edit: true
            },
            website: {
                visible: true,
                edit: true
            },
            mobileapp: {
                visible: true,
                edit: true
            }
        },
        messages: {
            visible: true,
            edit: true,
            internal: {
                visible: true,
                edit: true
            },
            customers: {
                visible: true,
                edit: true
            }
        },
        logs: {
            visible: true,
            edit: true,
            internal: {
                visible: true,
                edit: true
            },
            customers: {
                visible: true,
                edit: true
            }
        },
        biReports: {
            visible: true,
            edit: true,
            sales: {
                visible: true,
                edit: true
            },
            forecast: {
                visible: true,
                edit: true
            },
            products: {
                visible: true,
                edit: true
            },
            categories: {
                visible: true,
                edit: true
            },
            orders: {
                visible: true,
                edit: true
            },
            clients: {
                visible: true,
                edit: true
            }
        }
    };

    #scopes_all_false = {
        dashboard: {
            visible: false,
            edit: false 
        },
        products: {
            visible: false,
            edit: false,
            products: {
                visible: false,
                edit: false
            },
            combos: {
                visible: false,
                edit: false
            },
            categories: {
                visible: false,
                edit: false
            },
            classes: {
                visible: false,
                edit: false
            },
        },
        priceLists: {
            visible: false,
            edit: false,
            locations: {
                visible: false,
                edit: false
            },
            promotions: {
                visible: false,
                edit: false
            },
            companies: {
                visible: false,
                edit: false
            },
            products: {
                visible: false,
                edit: false
            },
            combos: {
                visible: false,
                edit: false
            },
            acquisitions: {
                visible: false,
                edit: false
            }
        },
        clients: {
            visible: false,
            edit: false,
            accounts: {
                visible: false,
                edit: false
            },
            families: {
                visible: false,
                edit: false
            },
            professional: {
                visible: false,
                edit: false
            }
        },
        locations: {
            visible: false,
            edit: false,
            locations: {
                visible: false,
                edit: false
            },
            groups: {
                visible: false,
                edit: false
            },
            employees: {
                visible: false,
                edit: false
            }
        },
        users: {
            visible: false,
            edit: false,
            internal: {
                visible: false,
                edit: false
            },
            clients: {
                visible: false,
                edit: false
            }
        },
        registry: {
            visible: false,
            edit: false,
            persons: {
                visible: false,
                edit: false
            },
            companies: {
                visible: false,
                edit: false
            }
        },
        config: {
            visible: false,
            edit: false,
            platform: {
                visible: false,
                edit: false
            },
            identity: {
                visible: false,
                edit: false
            },
            salesapp: {
                visible: false,
                edit: false
            },
            website: {
                visible: false,
                edit: false
            },
            mobileapp: {
                visible: false,
                edit: false
            }
        },
        messages: {
            visible: false,
            edit: false,
            internal: {
                visible: false,
                edit: false
            },
            customers: {
                visible: false,
                edit: false
            }
        },
        logs: {
            visible: false,
            edit: false,
            internal: {
                visible: false,
                edit: false
            },
            customers: {
                visible: false,
                edit: false
            }
        },
        biReports: {
            visible: false,
            edit: false,
            sales: {
                visible: false,
                edit: false
            },
            forecast: {
                visible: false,
                edit: false
            },
            products: {
                visible: false,
                edit: false
            },
            categories: {
                visible: false,
                edit: false
            },
            orders: {
                visible: false,
                edit: false
            },
            clients: {
                visible: false,
                edit: false
            }
        }
    };

    scope = {};

}

export default Visibility;