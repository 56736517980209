import React from 'react';
import { Box, Card, Paper, TableContainer, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
// import { format, intervalToDuration } from 'date-fns';
import Client from '../Client';
import Config from '../ClientConfig';

function thousandsSeparator(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

/* function formatedDate(value) {
  let date = new Date(value);
  const day = date.toLocaleString('default', { day: '2-digit' });
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.toLocaleString('default', { year: 'numeric' });
  return day + '-' + month + '-' + year;
} */

class SalesLastOrders extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      saleslocation_list: [],
      page: 0,
      rowsPerPage: 14,
      sx_hidden: {display: 'none'},
      value: "1",
      filterData: {}
    };

    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);

  }

  componentDidMount() {
    this.getData ( );
  }

  getData ( ) {
    let queryData = { 
      "type": 24,
  }
	  let data = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, queryData );
    if ( data ) {
      this.setState ( { saleslocation_list: data });
    }
    this.setState ( { filterData: queryData } );
  }

  doSync ( ) {
    this.getData ( );
  }

  handleChangePage = (event, newPage) => {
    this.setState ({page: newPage});
  }
  
  handleChangeRowsPerPage = (event) => {
    this.setState ({rowsPerPage: +event.target.value});
    this.setState ({page: 0});
  }
  
  render() {
    let page = this.state.page;
    let rowsPerPage = this.state.rowsPerPage;
    let locationSalesData = [];
    let locationSalesList = [];
    if (this.state.saleslocation_list){
      if (this.state.saleslocation_list.top) {
        let counter = 0;
        Object.keys(this.state.saleslocation_list.top).forEach(key => {
          let fData = this.state.saleslocation_list.top[key];
          let myData = {'x': fData.pos, 'y': (parseInt(fData.value/1000)+ "K")};
          let p = {
            id: counter+1,
            name: fData.pos,
            // date: formatedDate(fData.date),
            date: fData.date.toLocaleString("hi-IN"),
            bon: fData.bon,
            value: thousandsSeparator(fData.value)
          }
          if (counter<10){
            locationSalesData.push ( myData );
          }
          counter += 1;
          locationSalesList.push ( p );
        });
      }
    }

    return (
      <Paper sx={{ width: '100%', borderRadius:"16px", overflow: 'hidden' }}>
      <Card style={{ height: '640px' }} sx={{ borderRadius:"16px", height: '100%' }}>
       {/* <CardHeader title="Last Orders" /> */}
          <Box>
            <TableContainer sx={{ height: '500px' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {/* <TableCell style={{padding: "6px", textAlign: "center" }}>
                    <b>Top</b>
                  </TableCell> */}
                  <TableCell style={{padding: "6px", textAlign: "left" }}>
                    <b>Date</b>
                  </TableCell>                  
                  <TableCell style={{padding: "6px", textAlign: "left" }}>
                    <b>POS</b>
                  </TableCell>
                  <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                    <b>Bon</b>
                  </TableCell>
                  <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                    <b>Value</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {locationSalesList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((location) => {
                  return (
                    <TableRow
                      hover
                      key={location.id}
                      tabIndex={-1}
                    >
                      {/* <TableCell style={{padding: "6px", textAlign: "center" }}>
                        {location.id}
                      </TableCell> */}
                      <TableCell style={{padding: "6px", textAlign: "left" }}>
                        {location.date}
                      </TableCell>
                      <TableCell style={{padding: "6px", textAlign: "left" }}>
                        {location.name}
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                          {location.bon}
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                          {location.value}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[14, { value: locationSalesList.length, label: 'All' }]}
              labelRowsPerPage="Rows:"
              // showFirstButton="True"
              // showLastButton="True"
              component="div"
              count={locationSalesList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </Box>
      </Card>
      </Paper>
    );

  }
}

export default SalesLastOrders;
