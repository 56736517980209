import React from 'react';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { FaHome, FaPizzaSlice, FaHamburger, FaMapMarkedAlt, FaEuroSign, FaUserTie, FaHospitalUser } from "react-icons/fa";
import InputLabel from '@mui/material/InputLabel';
//import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';

//import { TextField, createMuiTheme, ThemeProvider } from "@material-ui/core";

//import './css/sideBar.scss';
//import './css/dashboard.css';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

//import noImage from './../images/icons/noImage_1.png';
import noImage from './../images/noImage.png';

//let theme = createTheme();

const theme = createTheme({
  overrides: {
    MuiInputLabel: { // Name of the component ⚛️ / style sheet
      root: { // Name of the rule
        color: "orange",
        borderColor: "orange",
        "&$focused": { // increase the specificity for the pseudo class
          color: "blue",
          borderColor: "blue",
        }
      }
    }
  }
});

class DropDown extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      edit: false,
      imageStatus: false,
      selectedValue: "",
      options: { },
      error: false
      /* onDark: false,
      textColor: "#47abff",
      labelColor: "#47abff" */
    };

    //this.handleExpandClick = this.handleExpandClick.bind(this);
    this.sendChangedState = this.sendChangedState.bind(this);
    
  }

  componentDidMount() {
    let value = 0;
    if ( this.props.value !== undefined ) value = this.props.value;
    this.setState ( {selectedValue: value} );
    if ( this.props.error !== undefined )
      this.setState ( { error: this.props.error } );
    /* if ( this.props.onDark ) {
      if ( this.props.onDark === true ) {
        this.setState ( { onDark : true } );
        this.setState ( { textColor : "#e3e3e5" } );
        this.setState ( { labelColor : "#e3e3e5" } );
      }
    } */
  }

  componentDidUpdate ( prevProps ) {    
    if ( prevProps.doReset !== this.props.doReset && this.props.doReset === true ){
      //this.setState({doReset: this.props.doReset })
      this.setState ( { imageStatus: false } );
    }

    if ( this.props.error !== undefined ) {
      if ( prevProps.error !== this.props.error )
        this.setState ( { error: this.props.error } );
    }
      
  }

  /* handleExpandClick ( ) {
    this.setState({ expanded: !this.state.expanded });
  } */

  sendChangedState ( e ) {
    this.setState ( {selectedValue: e.target.value} );
    /* let imageStatus = this.state.imageStatus;
    this.props.onClickFunction ( name, type );
    this.setState ( { imageStatus: !imageStatus } ); */

    if ( this.props.onChangeFunction ) {
      this.props.onChangeFunction ( e.target.value );
    }
  }

  render() {
    let doptions = {};
    let name = "undefined";
    let id = "";
    let value = 0;
    let values = [];
    let hasIcons = false;
    let minWidth=150;
    let maxWidth=200;
    let onDark = false;
    let textColor = "#797979";
    let labelColor = "#797979";  
    let margin = 1;
    let marginTop = 0;
    let marginLeft = 0; 
   
    if ( this.props ) {
      if ( this.props.id !== undefined ) id = this.props.id;
      if ( this.props.doptions ) doptions = this.props.doptions;
      if ( this.props.name ) name = this.props.name;
      if ( this.props.value !== undefined ) value = this.props.value;
      if ( this.props.hasIcons ) hasIcons = this.props.hasIcons;
      if ( this.props.minWidth ) minWidth = this.props.minWidth;
      if ( this.props.maxWidth ) maxWidth = this.props.maxWidth;
      if ( this.props.onDark ) onDark = this.props.onDark;
      if ( this.props.margin ) margin = this.props.margin;
      if ( this.props.marginTop ) marginTop = this.props.marginTop;
      if ( this.props.marginLeft ) marginLeft = this.props.marginLeft;
      //if ( this.props.onClickFunction ) onClickFunction = this.props.onClickFunction;
      //if ( this.props.values ) values = this.props.values;
    }

    if ( this.props.onDark === true ) {
      textColor = "#e3e3e5";
      labelColor = "#e3e3e5";
    }

    let dropDownOptions = null;
    if ( hasIcons === true ) {
      for(var i in doptions) {
        values.push([i, doptions [i]]);
      }
      dropDownOptions = values.map( (v) => {
        let img = noImage;
        if ( v[1]["image"] ) img = v[1]["image"];
        return <MenuItem sx={{display: "flex"}}
            key={v[0]} value={v[0]} id={id}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Avatar alt={v[1]["name"]} src={img} sx={{ display: 'flex', width: 24, height: 24 }}/>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: "70%", textAlign: "start", justifyContent:"left", marginLeft: "2px" }}>
                {v[1]["name"]}
              </Box>
            </Box>
          </MenuItem>
      });
    } else {
      for(var i in doptions) values.push([i, doptions [i]]);
      dropDownOptions = values.map( (v) => {
        return <MenuItem
          key={v[0]} value={v[0]} id={id}>{v[1]}
          </MenuItem>
      });
    }

    let selectedValue = "";
    if ( this.props.value !== undefined ) selectedValue = this.props.value;

    let size = "medium";
    if ( this.props.size ) size = this.props.size;
    
    return (

      <FormControl required sx={{ m: {margin}, marginTop: {marginTop}, marginLeft: {marginLeft}, minWidth: {minWidth}, maxWidth: {maxWidth}, display: 'inline-flex', color: textColor, borderColor: {textColor} }} >
        <InputLabel id="demo-simple-select-required-label" sx={{color: {labelColor}}}>{name}</InputLabel>
        <Select
          labelId="demo-simple-select-required-label"
          id={id}
          value={selectedValue} /* {this.state.selectedValue} */
          label={name + "*"}
          onChange={this.sendChangedState}
          sx={{display: "flex", color: {textColor}, minWidth: {minWidth} }}
          size={size}
          required={true}
          error={this.state.error}
          /* inputProps={{ style: { borderColor: "#FF0000 !important" } }}
          SelectDisplayProps={{ style: { borderColor: "#FF0000 !important" } }} */
          /* inputLabelProps = {{ sx : { color: textColor } }} */
        >
          {dropDownOptions}
          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
      </FormControl>

   
    );

  }
}

export default DropDown;