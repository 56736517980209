import React from 'react';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { FaHome, FaPizzaSlice, FaHamburger, FaMapMarkedAlt, FaEuroSign, FaUserTie, FaHospitalUser } from "react-icons/fa";
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Autocomplete from '@mui/material/Autocomplete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isDecimal from 'validator/lib/isDecimal';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CircularProgress from '@mui/material/CircularProgress';
import swal from 'sweetalert';
import noImage from './../images/noImage.png';
import idle from './../images/icons/idle_on.png';
import registered from './../images/icons/registered_on.png';
import active from './../images/icons/active_on.png';
import discontinued from './../images/icons/discontinued_on.png';

import DropDown from './DropDown';

import Client from './Client';
import Config from './ClientConfig';
import BackOfficeConfig from './../Config';

let theme = createTheme();

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#273442",
    color: theme.palette.common.white,
    paddingLeft: "4px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    paddingLeft: "4px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

class User extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      expanded: false,
      edit: false,
      saveButtonVisible: false,
      //combo_mainImage: null,
      row: { },
      firstNameValid: false,
      lastNameValid: false,
      usernameValid: false,
      emailValid: false,
      phoneValid: false,
      personsSearch: [],
      somethingHasChanged: false,
      passwordValid: true,
      passwordvValid: true,
      personValid: false,
      atokenValid: true,
      fpkValid: true,
      newUser: false,
      languages: [],
      saving: false,
      userTypeValid: false,
      allowEdit: false
    };


    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.getElementByName = this.getElementByName.bind(this);
    this.personSearch = this.personSearch.bind(this);
    this.personChange = this.personChange.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
    this.isUserNameValid = this.isUserNameValid.bind(this);
    this.changeType = this.changeType.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.detachPerson = this.detachPerson.bind(this); 
    this.pushData = this.pushData.bind(this); 
    this.generateRandom = this.generateRandom.bind(this);
    this.generateAToken = this.generateAToken.bind(this); 
    this.generateFPK = this.generateFPK.bind(this);
    
       
  }

  componentDidMount() {
    if (this.props)
      if (this.props.allowEdit === true) {
        this.setState ({allowEdit: true});
      }

    if ( Object.entries(this.props.row).length === 0 ) {
      if ( this.props.newUser ) {
        this.setState ( { newUser: true } );
        let password = this.generateRandom ( 12, true );
        let passwordv = password;
        let uRow = this.state.row;
        uRow.password = password;
        uRow.passwordv = password;
        this.setState ( { row: uRow } );
      }
      this.handleEditClick ( );
    } else {
      if ( this.props.row !== null ) {
        let uRow = this.props.row;
        uRow.passwordv = uRow.password;
        this.setState ( { row: uRow } );      

        if ( this.isUserNameValid ( uRow.username ) ) this.setState ( { usernameValid: true } );
        if ( uRow.first_name.length >= 2 ) this.setState ( { firstNameValid: true } );
        if ( uRow.last_name.length >= 2 ) this.setState ( { lastNameValid: true } );
        if ( isEmail ( uRow.email ) ) this.setState ( { emailValid: true } );
        if ( uRow.person ) this.setState ( { personValid: true } );
        if ( uRow.user_type > 0 ) this.setState ( { userTypeValid: true } );
      }      
    }

    

    let languagesJson = localStorage.getItem ( "languages" );
    let languages = JSON.parse ( languagesJson );
    languages.map ( (lang) => {
      lang.name = lang.languageName;
    });
    this.setState ( { languages: languages } );
  }

  handleExpandClick ( ) {
    this.setState({ expanded: !this.state.expanded });
  }

  handleEditClick ( ) {
    if (!this.state.allowEdit)
      return;
    let cEdit = !this.state.edit;
    this.setState({ edit: !this.state.edit });
    if ( this.state.expanded === false && cEdit === true )
      this.state.expanded = true;
    if ( this.state.expanded === true && cEdit === false )
      this.state.expanded = false;
    this.setState({ saveButtonVisible: cEdit });
  }

  getElementByName ( elements, name ) {    
    let e = {
      "iref" : null,
      "name" : "X",
      "image" : "X",
      "image_off" : "X"
    };
    if ( elements !== null && elements !== undefined ) {
      for ( var i = 0; i < elements.length; i++ ) {
        let el = elements [ i ];
        if ( el.length === 2 ) {
          if ( el [ 1 ].name === name ) e = el [ 1 ];
        } else {
          if ( el [ 0 ].name === name ) e = el [ 0 ];
        }        
      }
    }
    return e;    
  }

  personSearch ( e ) {
    //console.log ( e.target.value );
    if ( e.target.value.length > 3 ) {
      let stringLength = e.target.value.length;
      let limit = (stringLength - 2) * 20;
      let queryData = { 
        "nameLike" : e.target.value,
        "notLinked_user": true,
        "limit": limit
      };
      let data = this.state.client.filterPersons ( queryData );
      let newPersons = [];
      let persons = [];
      Object.keys(data.persons).forEach(key => {
        let fData = data.persons [ key ];
        let lineText = fData.iref + " - " + fData.firstName + " " + fData.lastName + " (" + fData.email + ", " + fData.phone + ")";
        let newObj = { key: fData.iref, label: lineText };
        newPersons.push ( newObj );
        persons.push ( fData );
        //counties [ fData.iref ] = fData.name;      
      });

      this.setState ( { personsSearch: newPersons });
      this.setState ( { personValid: false });
      
    }
  }

  personChange ( e ) {
    let text = e.target.textContent;
    let repIref = text.substring ( 0, text.indexOf(" - ") );
    let queryData = { };
    let data = this.state.client.getData ( Config.API_URL_PERSON + repIref, queryData );
    let uUser = this.state.row;
    uUser.person = data;

    if ( !uUser.first_name ) {
      uUser.first_name = data.firstName;
    } else {
      if ( uUser.first_name.length === 0 ) uUser.first_name = data.firstName;
    }
    if ( uUser.first_name.length >= 2 ) this.setState ( { firstNameValid: true } );
    else this.setState ( { firstNameValid: false } );

    if ( !uUser.last_name ) {
      uUser.last_name = data.lastName;
    } else {
      if ( uUser.last_name.length === 0 ) uUser.last_name = data.lastName;
    }
    if ( uUser.last_name.length >= 2 ) this.setState ( { lastNameValid: true } );
    else this.setState ( { lastNameValid: false } );

    if ( !uUser.email ) {
      uUser.email = data.email;
    } else {
      if ( uUser.email.length === 0 ) uUser.email = data.email;
    }
    if ( isEmail ( uUser.email ) ) this.setState ( { emailValid: true } );
    else this.setState ( { emailValid: false } );

    this.setState ( { row: uUser } );
    if ( text.length > 0 ) this.setState ( { personValid: true });
    else this.setState ( { personValid: false });
    this.setState ( { somethingHasChanged: true } );
  }

  isUserNameValid ( username ) {
    /* 
      Usernames can only have: 
      - Lowercase Letters (a-z) 
      - Numbers (0-9)
      - Dots (.)
      - Underscores (_)
    */
    const res = /^[a-z0-9_\.]+$/.exec(username);
    const valid = !!res;
    return valid;
  }

  changeValue ( e ) {
    let id = null;
    if ( e.target.id !== null ) id = e.target.id;
    let value = null;
    if  ( e.target.value !== null ) value = e.target.value;
    //if ( id !== null && value !== null && value !== undefined ) {
    if ( id ) {
      let uRow = this.state.row;
      switch ( id ) {
        case "username":
          uRow.username = value;
          if ( this.isUserNameValid ( value ) ) this.setState ( { usernameValid: true } );
          else this.setState ( { usernameValid: false } );
          break;
        case "firstName":
          uRow.first_name = value;
          if ( value.length >= 2 ) this.setState ( { firstNameValid: true } );
          else this.setState ( { firstNameValid: false } );
          break;
        case "lastName":
          uRow.last_name = value;
          if ( value.length >= 2 ) this.setState ( { lastNameValid: true } );
          else this.setState ( { lastNameValid: false } );
          break;        
        case "email":
          uRow.email = value;
          if ( isEmail ( value ) ) this.setState ( { emailValid: true } );
          else this.setState ( { emailValid: false } );
          break;
        case "password":
          uRow.password = value;
          this.setState ( { passwordValid: false } );
          this.setState ( { passwordvValid: false } );
          if ( uRow.password === uRow.passwordv ) {
            if ( value.length >= 8 ) {
              this.setState ( { passwordValid: true } );
              this.setState ( { passwordvValid: true } );
            } else {
              this.setState ( { passwordValid: false } );
              this.setState ( { passwordvValid: false } );
            }
          }
          break;
        case "passwordv":
          uRow.passwordv = value;
          this.setState ( { passwordValid: false } );
          this.setState ( { passwordvValid: false } );
          if ( uRow.password === uRow.passwordv ) {
            if ( value.length >= 8 ) {
              this.setState ( { passwordValid: true } );
              this.setState ( { passwordvValid: true } );
            } else {
              this.setState ( { passwordValid: false } );
              this.setState ( { passwordvValid: false } );
            }
          }
          break;
        case "atoken":
          uRow.activation_token = value;
          if ( value.length > 16 ) this.setState ( { atokenValid: true } );
          else this.setState ( { atokenValid: false } );
          break;
        case "fpk":
          uRow.forgotPasswordKey = value;
          if ( value.length > 16 ) this.setState ( { fpkValid: true } );
          else this.setState ( { fpkValid: false } );
          break;
        case "fpf":
          //console.log ( e );
          if ( e.target )
            uRow.forgotPasswordFlag = e.target.checked;          
          break;
      }
      this.setState ( { row: uRow } );
      this.setState ( { somethingHasChanged: true } );
    }
  }

  changeType ( e ) {
    if ( e ) {
      if ( this.state.row ) {
        let uRow = this.state.row;
        uRow.user_type = e;
        this.setState ( { row: uRow } );
        this.setState ( { somethingHasChanged: true } );
        if ( parseInt(e) > 0 ) this.setState ( { userTypeValid: true } );
      }      
    }
  }

  changeStatus ( e ) {
    if ( e !== undefined ) {
      if ( this.state.row ) {
        let uRow = this.state.row;
        uRow.user_status = e;
        this.setState ( { row: uRow } );
        this.setState ( { somethingHasChanged: true } );
      }      
    }
  }

  changeLanguage ( e ) {
    if ( e !== undefined ) {
      if ( this.state.row ) {
        let uRow = this.state.row;

        let languages = [];
        if ( this.state.languages ) {
          languages = this.state.languages;
          uRow.language = languages [ e ].iref;
          /* Object.keys(languages).forEach(key => {
            let fData = languages [ key ];
            if ( fData.iref === language_iref ) language_id = key;
          }); */
        }
        this.setState ( { row: uRow } );
        this.setState ( { somethingHasChanged: true } );
      }
    }
  }

  detachPerson ( ) {
    let uRow = this.state.row;
    if ( uRow ) {
      if ( uRow.person ) {
        let prevPerson = uRow.person;
        uRow.person = {};
        uRow.prevPerson = prevPerson;
        this.setState ( { company: uRow } );
        this.setState ( { somethingHasChanged: true } );
        this.setState ( { personValid: false} );
      }
    }    
  }

  pushData ( e ) {

    if ( this.state.firstNameValid && this.state.lastNameValid && this.state.emailValid && this.state.usernameValid ) {

      this.setState ( { saving : true }, () => {
        
        let rdata = this.state.client.pushData ( Config.USER_ADD_API_URL, this.state.row );
        if ( rdata ) {
          if ( rdata.status == "OK" ) {
            /* let uRow = this.state.row;
            uRow.iref = rdata.iref;
            this.setState ( { row: uRow } ); */
            //this.setState ( { row: {} } );
            this.handleEditClick ( );            
            swal ( "Success", "Successfully added/modified user ! (" + rdata.iref + ")", "success", {
              buttons: false,
              timer: 2000,
            })
            .then((value) => {
              this.setState ( { saving : false } );
              this.props.userUpdated ( );
              this.setState ( { somethingHasChanged: false } );
              this.setState ( { expanded: false } );
            });        
          } else {
            if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
            swal ( "Error", "Add/Update user failed !\n" + rdata.message, "error" );
          }
        }
      });

    } else {
      swal ( "Error", "Add/Update user failed !\n", "error" );
    }
  }

  generateRandom ( length, strong = false ) {
    let newToken = "";
    //const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    if ( strong ) characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      newToken += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return newToken;
  }

  generateAToken ( ) {
    /* let newToken = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    ); */
    let newToken = this.generateRandom ( 40 );
    let uRow = this.state.row;
    uRow.activation_token = newToken;
    this.setState ( { row: uRow } );
    this.setState ( { somethingHasChanged: true } );
    this.setState ( { atokenValid : true } );
  }

  generateFPK ( ) {
    let newToken = this.generateRandom ( 32 );
    let uRow = this.state.row;
    uRow.forgotPasswordKey = newToken;
    uRow.forgotPasswordFlag = true;
    this.setState ( { row: uRow } );
    this.setState ( { somethingHasChanged: true } );
    this.setState ( { fpkValid: true } );
  }

  render() {

    let iref = "";
    let firstName = "";
    let lastName = "";
    let email = "";
    let username = "";
    let type = "";
    let status = 0;
    let password = "";
    let passwordv = "";
    let atoken = "";
    let atoken_full = "";
    let atoken_url = "";
    let fpf = false;
    let fpk = "";
    let fpk_url = "";
    let person = {};
    let userImage = noImage;
    let language_iref = "";
    let language_name = "";
    let language_image = "";
    let language_id = "";
    let modifiedOn = "";
    let createdOn = "";

    let userType = "";
    let status_image = noImage;
    let status_alt = "Unknown";

    let person_firstName = "";
    let person_lastName = "";    
    let person_email = "";    
    let person_phone = ""; 
    let person_birthday = "";    
    let person_city = "";
    let person_county = "";
    let person_country = "";
    let location_reg = "";

    let personAddress = {}
    let personAddress_name = "";
    let personAddress_postalCode = undefined;
    let personAddress_street = "";
    let personAddress_number = undefined;
    let personAddress_entrance = "";
    let personAddress_local = "";
    let personAddress_floor = undefined;
    let personAddress_complement = "";
    let personAddress_gps_lt = "0";
    let personAddress_gps_lg = "0";
    let personAddress_city = "";
    let personAddress_county = "";
    let personAddress_country = "";

    let person_gps = {
      "lt": 0.0,
      "lg": 0.0
    };

    let hasPerson = false; 
    if ( this.state.row ) {
      let row = this.state.row;
      if ( row.iref ) iref = row.iref;
      if ( row.first_name ) firstName = row.first_name;
      if ( row.last_name ) lastName = row.last_name;
      if ( row.email ) email = row.email;
      if ( row.username ) username = row.username;
      if ( row.user_type ) type = row.user_type;
      if ( row.user_status !== undefined ) status = parseInt ( row.user_status );
      if ( row.password ) password = row.password;
      if ( row.passwordv ) passwordv = row.passwordv;
      if ( row.activation_token ) {
        atoken = row.activation_token;
        atoken_full = "AT:" + atoken;
        atoken_url = BackOfficeConfig.BACKOFFICE_URL + atoken; //"https://cartos.kalopsia.pro/backoffice/" 
      }
      if ( row.forgotPasswordFlag !== undefined )
        if ( row.forgotPasswordFlag === "true" || row.forgotPasswordFlag ) fpf = true;
      if ( row.forgotPasswordKey ) {
        fpk = row.forgotPasswordKey;
        fpk_url = BackOfficeConfig.BACKOFFICE_URL + fpk; //"https://cartos.kalopsia.pro/backoffice/"
      }
      //if ( row.person ) person = row.person;
      if ( row.createdOn ) createdOn = "Created: " + row.createdOn;
      if ( row.modifiedOn ) modifiedOn = "Modified: " + row.modifiedOn;

      userType = BackOfficeConfig.USER_TYPES [ type ];

      switch ( status ) {
        case 0:
          status_image = registered;
          status_alt = "Registered";
          break;      
        case 1:
          status_image = active;
          status_alt = "Active";
          break;
        case 2:
          status_image = discontinued;
          status_alt = "Discontinued";
          break;
      }

      if ( row.person ) {
        let person = row.person;

        if ( person.image ) {
          if ( 'iref' in person ) hasPerson = true;

          userImage = person.image;
          /* let fileName = userImage.replace(/\.[^/.]+$/, "")
          let fileExtensions = userImage.split(".").pop();
          userImage = fileName + "_m." + fileExtensions; */
        }

        if ( person.firstName ) person_firstName = person.firstName;
        if ( person.lastName ) person_lastName = person.lastName;
        if ( person.email ) person_email = person.email;
        if ( person.phone ) person_phone = person.phone;
        if ( person.birthday ) person_birthday = person.birthday;
  
        if ( person.entrance ) person += ", intrare " + person.entrance;
        if ( person.local ) person += ", local " + person.local;
        if ( person.floor ) person += ", etaj " + person.floor;
        if ( person.complement ) person += ", complement " + person.complement;
        if ( person.city ) {
          person_city = person.city.name; 
          if ( person.city.county ) {
            person_county = person.city.county.name;
            if ( person.city.county.country )
              person_country = person.city.county.country.name;
          }
        }
        if ( person.gps_lt ) person_gps [ "lt" ] = person.gps_lt;
        if ( person.gps_lg ) person_gps [ "lg" ] = person.gps_lg;

        if ( row.person.address ) {          
          personAddress = row.person.address;
          if ( personAddress.name ) personAddress_name = personAddress.name;
          if ( personAddress.postalCode ) personAddress_postalCode = personAddress.postalCode;
          if ( personAddress.street ) personAddress_street = personAddress.street;
          if ( personAddress.number ) personAddress_number = personAddress.number;
          if ( personAddress.entrance ) personAddress_entrance = personAddress.entrance;
          if ( personAddress.local ) personAddress_local = personAddress.local;
          if ( personAddress.floor ) personAddress_floor = personAddress.floor;
          if ( personAddress.complement ) personAddress_complement = personAddress.complement;
          if ( personAddress.gps_lt ) personAddress_gps_lt = personAddress.gps_lt;
          if ( personAddress.gps_lg ) personAddress_gps_lg = personAddress.gps_lg;
          if ( personAddress.city ) personAddress_city = personAddress.city.name;
          if ( personAddress.city.county ) personAddress_county = personAddress.city.county.name;
          if ( personAddress.city.county.country ) personAddress_country = personAddress.city.county.country.name;
        }
      }

      if ( row.language ) language_iref = row.language;
    }
    let userFullName = firstName + " " + lastName;
    let personFullName = person_firstName + " " + person_lastName;

    let personsSearch = [ ];
    if ( this.state.personsSearch ) personsSearch = this.state.personsSearch;

    if ( this.state.newUser && password === "" && passwordv === "" ) {
      password = this.generateRandom ( 12, true );
      passwordv = password;
      let uRow = this.state.row;
      uRow.password = password;
      uRow.passwordv = password;
      
    }

    let showSave = false;
    if ( this.state.edit && this.state.somethingHasChanged && this.state.firstNameValid && this.state.lastNameValid && this.state.usernameValid &&
      this.state.emailValid && this.state.passwordValid && this.state.passwordvValid && this.state.atokenValid && this.state.fpkValid && 
      ( this.state.personValid || type == 27 /*POS*/ ) && this.state.userTypeValid )
      showSave = true;  
      
    let languages = [];
    if ( this.state.languages ) {
      languages = this.state.languages;
      Object.keys(languages).forEach(key => {
        let fData = languages [ key ];
        if ( fData.iref === language_iref ) {
          language_id = key;
          language_image = fData.image;
          language_name = fData.languageName;
        }
      });
    }

    let showLoading = false;
    if ( this.state.saving === true ) showLoading = true;

    let onlyClient = false;
    if ( this.props.onlyClient ) onlyClient = this.props.onlyClient;

    let userTypes = {};
    //console.log ( BackOfficeConfig.USER_TYPES );
    Object.keys(BackOfficeConfig.USER_TYPES).forEach(key => {
      if ( onlyClient ) {
        if ( parseInt(key) == 5 ) {
          let obj = { };
          obj [ key ] = BackOfficeConfig.USER_TYPES[key];
          userTypes = Object.assign ( userTypes, obj );
        }
      } else {
        if ( parseInt(key) > 5 ) {
          let obj = { };
          obj [ key ] = BackOfficeConfig.USER_TYPES[key];
          userTypes = Object.assign ( userTypes, obj );
        }
      }
      
    });


    return (
      /* , display: 'flex' */
    <Card sx={{ borderRadius: '16px', m: 1 }}>

      <Box sx={{ display: 'block', flexDirection: 'row', width: "100%" }}>
        <CardHeader
          avatar={          
            <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%" }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                <Avatar alt={status_alt} src={status_image} title={status_alt}>
                </Avatar>  
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
              <Typography variant="subtitle1" color="text.secondary">
                {iref}
              </Typography>
              </Box>
            </Box>                    
          }        
          action={            
            <IconButton aria-label="settings" onClick={this.handleEditClick}>
              <EditIcon
                sx={{ color: this.state.edit ? "#8f241d" : "#e3e3e5" }}                
              />
            </IconButton>
          }
          title={userFullName}          
          titleTypographyProps={{variant:'h5' }}
          subheader={userType}
        />
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%" }}>

        <Box sx={{ display: 'block', flexDirection: 'column', width: '20%'}}>          
        </Box>
        
        <Box sx={{ display: 'block', flexDirection: 'column', display: "block", width: '30%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "start", justifyContent:"left" }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: "start", justifyContent:"left" }}>
                { this.state.edit ?
                  <Autocomplete
                    disablePortal
                    id="person"
                    name="Person"
                    options={personsSearch}
                    sx={{ width: 400, marginTop: 1 }}
                    onChange={this.personChange}
                    error={this.state.personValid === false}
                    renderInput = {
                      (params) =>
                        <TextField 
                          {...params}
                          label="Person (type keywords)"
                          onChange={this.personSearch}
                        />}
                  />
                  : ""
                }
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: "start", justifyContent:"left" }}>
                { this.state.edit && hasPerson ?
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    sx={{ color: "#b51f0b", width: "100px", marginLeft: "5px", marginTop: 1 }}
                    startIcon={<PersonRemoveIcon />}
                    onClick={this.detachPerson}
                  >
                    Detach Person
                  </Button>
                : ""
                }
              </Box>
          </Box> 
        </Box>

        <Box sx={{ display: 'block', flexDirection: 'column', justifyContent:"right", textAlign: "end", width: '50%' }}>

        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%" }}>

        <Box sx={{ display: 'block', flexDirection: 'column', width: '20%'}}>
          <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "start", justifyContent:"left" }}>
            <CardMedia
              component="img"
              height="120"
              sx={{ width: 128, marginLeft: 1 }}
              image={userImage}
              alt={userFullName}
              title={userFullName}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "start", justifyContent:"left", marginLeft: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: "start", justifyContent:"left", marginLeft: 1 }}>
              {/* <IconButton aria-label="Language" title="Language">       */} 
                <Avatar sx={{ width: 24, height: 24, color: "#273442" }} alt={language_name} src={language_image} title={language_name}>
                </Avatar>
              {/* </IconButton> */}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: "start", justifyContent:"left", marginLeft: 1 }}>
              {person_birthday}
            </Box>
            
          </Box>
          
        </Box>

        <Box sx={{ display: 'block', flexDirection: 'column', display: "block", width: '30%' }}>
          <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "start", justifyContent:"left" }}>
            { this.state.edit ?
              <TextField
                id="username"
                label="Username"
                value={username || ''}
                variant="outlined"
                sx= {{ minWidth: "140px", maxWidth: "180px", marginTop: 1 }}
                onChange={this.changeValue}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"16"}}
                error={this.state.usernameValid === false}
                required={true}
              />
              : username
            }
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "start", justifyContent:"left" }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: "start", justifyContent:"left" }}>
              { this.state.edit ?
                <DropDown
                  name="User Type"
                  value={type !== undefined ? type : ""}
                  doptions={userTypes}
                  hasIcons={false}   
                  minWidth={160}
                  maxWidth={220}
                  onDark={false}
                  onChangeFunction={this.changeType}
                  marginTop={10}
                  margin={0}
                  error={this.state.userTypeValid === false}                  
                />
                : createdOn
              }
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: "start", justifyContent:"left" }}>
              { this.state.edit ?
                <DropDown
                  name="User Status"
                  value={status !== undefined ? status : ''}
                  doptions={BackOfficeConfig.USER_STATUSES_WITH_ICONS}
                  hasIcons={true}   
                  minWidth={120}
                  maxWidth={220}
                  onDark={false}
                  onChangeFunction={this.changeStatus}
                  marginTop={10}
                  marginLeft={7}
                  margin={0}
                />
                : ""
              }
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "start", justifyContent:"left", marginTop: 1 }}>
            <Box sx={{ display: 'inline-flex', flexDirection: 'column', textAlign: "start", justifyContent:"left" }}>
              { this.state.edit ?
                <TextField
                  id="password"
                  label="Password"
                  value={password || ''}
                  variant="outlined"
                  sx= {{ minWidth: "140px", maxWidth: "180px" }}
                  onChange={this.changeValue}
                  onFocus={event => { event.target.select(); }}
                  inputProps={{maxLength:"16"}}
                  error={this.state.passwordValid === false}
                  required={true}
                />
                : modifiedOn
              }
            </Box>
            <Box sx={{ display: 'inline-flex', flexDirection: 'column', textAlign: "start", justifyContent:"left", marginLeft: 1 }}>
            { this.state.edit ?
                <TextField
                  id="passwordv"
                  label="Password Verification"
                  value={passwordv || ''}
                  variant="outlined"
                  sx= {{ minWidth: "140px", maxWidth: "180px"}}
                  onChange={this.changeValue}
                  onFocus={event => { event.target.select(); }}
                  inputProps={{maxLength:"16"}}
                  error={this.state.passwordvValid === false}
                  required={true}
                />
                : ""
              }
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "start", justifyContent:"left", marginTop: 1 }}>
            { this.state.edit ?
              <TextField
                id="atoken"
                label="Activation Token"
                value={atoken || ''}
                variant="outlined"
                sx= {{ minWidth: "400px", maxWidth: "440px"}}
                onChange={this.changeValue}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"255"}}
                error={this.state.atokenValid === false}
                required={true}
              />
              : ""
            }
            {this.state.edit ? 
              <IconButton aria-label="Generate" onClick={this.generateAToken} sx={{marginTop: 2, marginLeft: 0}}>
                <SmartButtonIcon titleAccess="Generate Activation Token"/>
              </IconButton>            
              : ""
            }
            {this.state.edit ? 
              <IconButton aria-label="Generate" onClick={() => {navigator.clipboard.writeText(atoken_url)}} sx={{marginTop: 2, marginLeft: 0}}>
                <ContentCopyIcon titleAccess="Copy to clipboard" fontSize="small"/>
              </IconButton>            
              : ""
            }          
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "start", justifyContent:"left", marginTop: 1 }}>            
            { this.state.edit ?
              <TextField
                id="fpk"
                label="Forgot Password Key"
                value={fpk || ''}
                variant="outlined"
                sx= {{ minWidth: "400px", maxWidth: "440px"}}
                onChange={this.changeValue}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"255"}}
                error={this.state.fpkValid === false}
                required={true}
              />
              : ""
            }
            { this.state.edit ?
              <Checkbox id="fpf" color="success" checked={fpf} onChange={this.changeValue}/>
              : ""
            }
            {this.state.edit ? 
              <IconButton aria-label="Generate" onClick={this.generateFPK} sx={{marginTop: 0, marginLeft: 0}}>
                <SmartButtonIcon titleAccess="Generate Forgot Password Key"/>
              </IconButton>            
              : ""
            }
            {this.state.edit ? 
              <IconButton aria-label="Generate" onClick={() => {navigator.clipboard.writeText(fpk_url)}} sx={{marginTop: 0, marginLeft: 0}}>
                <ContentCopyIcon titleAccess="Copy to clipboard" fontSize="small"/>
              </IconButton>            
              : ""
            }
            
          </Box>

          {/* <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "start", justifyContent:"left" }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: "start", justifyContent:"left" }}>
              { this.state.edit ?
                <Autocomplete
                  disablePortal
                  id="person"
                  name="Person"
                  options={personsSearch}
                  sx={{ width: 400, marginTop: 1 }}
                  onChange={this.personChange}
                  renderInput = {
                    (params) =>
                      <TextField 
                        {...params}
                        label="Person (type keywords)"
                        onChange={this.personSearch}
                      />}
                />
                : ""
              }
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: "start", justifyContent:"left" }}>
              { this.state.edit && hasPerson ?
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  sx={{ color: "#b51f0b", width: "100px", marginLeft: "5px", marginTop: 1 }}
                  startIcon={<PersonRemoveIcon />}
                  onClick={this.detachPerson}
                >
                  Detach Person
                </Button>
              : ""
              }
            </Box>
          </Box>  */} 
        </Box>

        

        <Box sx={{ display: 'block', flexDirection: 'column', justifyContent:"right", textAlign: "end", width: '50%' }}>

          <Box sx={{ display: 'inline-flex', flexDirection: 'row', textAlign: "start", justifyContent:"left", marginRight: "5px" }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: "start", justifyContent:"left" }}>
              { this.state.edit ?
                <TextField
                  id="firstName"
                  label="First Name"
                  value={firstName || ''}
                  variant="outlined"
                  sx= {{ minWidth: "180px", maxWidth: "220px", marginTop: 1}}
                  onChange={this.changeValue}
                  onFocus={event => { event.target.select(); }}
                  inputProps={{maxLength:"32"}}
                  error={this.state.firstNameValid === false}
                  required={true}
                />
                : ""
              }
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: "start", justifyContent:"left" }}>
              { this.state.edit ?
                <TextField
                  id="lastName"
                  label="Last Name"
                  value={lastName || ''}
                  variant="outlined"
                  sx= {{ minWidth: "180px", maxWidth: "220px", marginLeft: "5px", marginTop: 1 }}
                  onChange={this.changeValue}
                  onFocus={event => { event.target.select(); }}
                  inputProps={{maxLength:"32"}}
                  error={this.state.lastNameValid === false}
                  required={true}
                />
                : ""
              }
            </Box>
          </Box>

          <Box sx={{ display: 'inline-flex', flexDirection: 'row', textAlign: "end", justifyContent:"rigth", marginRight: "5px" }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: "start", justifyContent:"left" }}>
              { this.state.edit ?
                <TextField
                  id="email"
                  label="Email"
                  value={email || ''}
                  variant="outlined"
                  sx= {{ minWidth: "180px", maxWidth: "220px", marginTop: "10px"}}
                  onChange={this.changeValue}
                  onFocus={event => { event.target.select(); }}
                  inputProps={{maxLength:"32"}}
                  error={this.state.emailValid === false}
                  required={true}
                />
                : email
              }
            </Box>      
          </Box>

          <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "end", justifyContent:"rigth", marginRight: "5px" }}>
            { this.state.edit ?
              <DropDown
                name="Language"
                value={language_id !== undefined ? language_id : ''}
                doptions={languages}
                hasIcons={true}   
                minWidth={120}
                maxWidth={220}
                onDark={false}
                onChangeFunction={this.changeLanguage}
                marginTop={10}
                margin={0}
              />
              : ""
              
            }
          </Box>

          <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "end", justifyContent:"rigth", marginRight: "5px" }}>
            {person_email}          
          </Box>
          <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "end", justifyContent:"rigth", marginRight: "5px" }}>
            {person_phone}
          </Box>
          <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "end", justifyContent:"rigth", marginRight: "5px" }}>
            {this.state.edit ? 
              ""
              :
              <Box sx={{ display: 'inline-flex', flexDirection: 'row', textAlign: "start", justifyContent:"left", marginRight: "5px" }}>
                <Box sx={{ display: 'block', flexDirection: 'column', justifyContent:"right", textAlign: "end", width: '90%' }}>
                  {atoken_full}
                </Box>
                <Box sx={{ display: 'block', flexDirection: 'column', justifyContent:"right", textAlign: "end", width: '10%' }}>
                  <IconButton aria-label="Generate" onClick={() => {navigator.clipboard.writeText(atoken_url)}} sx={{marginTop: 0, marginLeft: 0}}>
                    <ContentCopyIcon titleAccess="Copy to clipboard" fontSize="small"/>
                  </IconButton>
                </Box>
              </Box>
            }
          </Box>
          <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "end", justifyContent:"rigth", marginRight: "5px" }}>
            {!this.state.edit && fpf ? <TaskAltIcon/> : "" }
            {this.state.edit ?
              ""
              :              
              <Box sx={{ display: 'inline-flex', flexDirection: 'row', textAlign: "start", justifyContent:"left", marginRight: "5px" }}>
                <Box sx={{ display: 'block', flexDirection: 'column', justifyContent:"right", textAlign: "end", width: '90%' }}>
                  {fpk}
                </Box>
                <Box sx={{ display: 'block', flexDirection: 'column', justifyContent:"right", textAlign: "end", width: '10%' }}>
                  <IconButton aria-label="Generate" onClick={() => {navigator.clipboard.writeText(fpk_url)}} sx={{marginTop: 0, marginLeft: 0}}>
                    <ContentCopyIcon titleAccess="Copy to clipboard" fontSize="small"/>
                  </IconButton>
                </Box>
              </Box>
            }
          </Box>

        </Box>
      </Box>         
          
      <Box sx={{ flexDirection: 'row', width: '100%', display: 'flex' }}>
        <Box sx={{ flexDirection: 'column', justifyContent:"right", width: '100%' }}>
          <CardActions disableSpacing sx={{display: "block", textAlign: "end" }}>       
          <ExpandMore
            expand={this.state.expanded}
            onClick={this.handleExpandClick}
            aria-expanded={this.state.expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
          </CardActions>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit sx={{width: "100% !important"}}>
        <CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <b>{personFullName}</b>
          </Box>        
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            {personAddress_name}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>            
            {personAddress_street}
            {personAddress_number ? ", nr. " + personAddress_number : ""}
            {personAddress_floor ? ", et. " + personAddress_floor : "" }
            {personAddress_postalCode ? ", " + personAddress_postalCode : ""}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>            
            {personAddress_city}, {personAddress_county}, {personAddress_country}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>            
            {personAddress_gps_lg}, {personAddress_gps_lt}
          </Box>
          

          <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "middle", justifyContent:"center", alignItems: "center", paddingTop: "8px" }}>
            { /*<Typography>Employees</Typography>*/}
            <Table sx={{ minWidth: 700, textAlign: "middle", justifyContent:"center" }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Image</StyledTableCell>
                  <StyledTableCell align="left">First Name</StyledTableCell>
                  <StyledTableCell align="left">Last Name</StyledTableCell>            
                  <StyledTableCell align="left">Phone</StyledTableCell>            
                  <StyledTableCell align="left">Email</StyledTableCell>
                  <StyledTableCell align="left">Birthday</StyledTableCell>
                  <StyledTableCell align="left">Username</StyledTableCell>
                  <StyledTableCell align="left">Card</StyledTableCell>
                  <StyledTableCell align="left">Level</StyledTableCell>
                  {/* <StyledTableCell align="right">Action</StyledTableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {allData} */}
              </TableBody>
            </Table>
            
          </Box>



          <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "end", justifyContent:"right", alignItems: "right", paddingTop: "8px" }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: "end", justifyContent:"right", alignItems: "right", paddingTop: "8px" }}> 
              { showLoading ?
                <CircularProgress
                size={24}
                sx={{
                  color: "#1e2833",
                  position: 'relative',
                  top: 0,
                  left: 0,
                  zIndex: 99,
                }}
                />
              :
              ""            
              }
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: "end", justifyContent:"right", alignItems: "right", paddingTop: "8px" }}> 
            { showSave ?
              <Button
                variant="contained"
                size="small"
                sx={{ color: "#e3e3e5" }}
                startIcon={<SaveIcon />}
                onClick={this.pushData}
              >
                Save
              </Button>
              : ""
            }
            </Box>
          </Box>
          
          </CardContent>

        </Collapse>

      </Box>
    </Card>
   
    );

  }
}

export default User;
