import React from 'react';
import {Box, Card, TextField, MenuItem} from '@mui/material';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import OverviewTotalSales from './components/biReports/OverviewTotalSales';
import OverviewMonthSales from './components/biReports/OverviewMonthSales';
import OverviewTopSales from './components/biReports/OverviewTopSales';
import OverviewTotalTransactions from './components/biReports/OverviewTotalTransactions';
import SalesTopList from './components/biReports/SalesTopList';
import SalesLastOrders from './components/biReports/SalesLastOrders';
import SalesPeriodLocationList from './components/biReports/SalesPeriodLocationList';
import SalesTransPeriodLocationList from './components/biReports/SalesTransPeriodLocationList';
import Client from './components/Client';
import PeopleIcon from '@mui/icons-material/People';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import { format } from 'date-fns';

// const now = new Date();
let d = new Date();
let today = format(d, 'yyyy-MM-dd');  // mm=week, MM=month

class Stats extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      /* locationGroups: null, */
      edit: false,
	  date: today,
	  tabValue: "value",
	  tab2Value: "order",
	  platform: 0,
	  platforms: [{'iref': 0, 'name': "All"}, {'iref': 3, 'name': "Glovo"}, {'iref': 4, 'name': "Tazz"}, {'iref': 5, 'name': "Bolt"}, {'iref': 6, 'name': "Bringo"}]
    };

	this.handleTabChange = this.handleTabChange.bind(this);
	this.handleTab2Change = this.handleTab2Change.bind(this);
	this.selectPlatform = this.selectPlatform.bind(this);

  }

  componentDidMount() {  
  }

  componentWillUnmount() {    
  }

  /* setLocationsGroups ( groups ) {
    let a = { }
    if ( groups !== null && groups !== undefined ) {
      groups.map ( (locationGroup) => {
        a [ locationGroup.iref ] = locationGroup;        
      });
      this.setState ( { locationGroups : a });
    }
  }
 */

  addDate = (event) => {
	if (event)
		if (event.target)
    		this.setState ({date: event.target.value});
  }

  handleTabChange ( event, newValue ) {
	// this.addDate = this.addDate.bind(this);  
    this.setState ( { tabValue: newValue } );
	// console.log(this.state.date);
  }

  handleTab2Change ( event, newValue ) {
    this.setState ( { tab2Value: newValue } );
  }

  selectPlatform = (event) => {
    this.setState (
      {platform: event.target.value},
      function(){
        // this.doSync ( );
      }
      );
  }


  render() {
	let platforms = [{'iref': 0, 'name': "All"}, {'iref': 3, 'name': "Glovo"}, {'iref': 4, 'name': "Tazz"}, {'iref': 5, 'name': "Bolt"}, {'iref': 6, 'name': "Bringo"}];
	// console.log(this.state.platform);

		return (
			<Box component="main" sx={{flexGrow: 1, py: 8, paddingTop: "30px", paddingBottom: "30px"}}>
				<Container style={{paddingLeft: 5, paddingRight:5}} maxWidth="xl">
					<Grid container spacing={1} sx={{width: 'calc(100% + 60px)', marginLeft: "-32px"}}>
						<Grid item xs={12}>
							<Grid container spacing={1} >
								<Grid item lg={4} md={6} sm={6} xs={6} >
									<OverviewTotalSales />
								</Grid>
								<Grid item lg={4} md={6} sm={6} xs={6}>
									<OverviewTotalTransactions />
								</Grid>
								<Grid item lg={4} md={12} sm={12} xs={12}>
									<Grid container spacing={1}>
										<Grid item sm={6} xs={6} md={6} lg={6}>
											<OverviewMonthSales />
										</Grid>
										<Grid item sm={6} xs={6} md={6} lg={6}>
											<OverviewTopSales />
										</Grid>
										{/* <Grid item sm={6} xs={6} md={6} lg={6}>
											<OverviewTotalCustomers />
										</Grid> */}
										{/* <Grid item sm={6} xs={6} md={6} lg={6}>
											<OverviewReverseOrders />
										</Grid> */}
									</Grid>
								</Grid>		
							</Grid>
						</Grid>	
						{/* <Grid item xs={12}>
							<Grid container spacing={3}>
								<Grid item xs={12} md={8}>
									<OverviewSales />
								</Grid>
								<Grid item xs={12} md={4}>
									<OverviewDemographic />
								</Grid>
							</Grid>	
						</Grid> */}

						<Grid item xs={12}>
							<Grid container spacing={1}>
								{/* <Grid item xs={12} md={4}>
									<OverviewLatestProducts />
								</Grid> */}
								<Grid item xs={12} lg={6} >
									<Card style={{ height: '640px' }} sx={{ borderRadius:"16px"}}>
										<Box sx={{ marginLeft: "10px", borderRadius:"16px", display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'flex-start'}} >
										<TextField
										size='small'
										id="date"
										label="date"
										type="date"
										// defaultValue={currentDate}
										value={this.state.date}
										// inputFormat="DD/MM/YYYY"
										sx={{ marginLeft: "10px", color: "#e3e3e5", width:120}}
										InputLabelProps={{
											shrink: true,
											sx : { color: "#5b5b5b" }
										}}
										InputProps={{ sx : { color: "#5b5b5b" }}}
										inputProps={{ style : { color: "#5b5b5b", paddingRight: 5, paddingLeft: 5} }}
										onChange={this.addDate}
										/>	
										<Tabs
										// style={{maxHeight: "20px", padding: "2px"}}
										value={this.state.tabValue}
										onChange={this.handleTabChange}
										// centered
										indicatorColor="secondary"
										>
										<Tab style={{maxHeight: "48px", minHeight: "20px", minWidth: "30px", maxWidth: "40px", paddingRight: 20, paddingLeft: 40}} label="" value="value" icon={<PointOfSaleIcon />} iconPosition="start"/>
										<Tab style={{maxHeight: "48px", minHeight: "20px", minWidth: "30px", maxWidth: "40px", paddingRight: 40, paddingLeft: 20}} label="" value="transaction" icon={<PeopleIcon />} iconPosition="start"/>
										</Tabs>
										<TextField
											id="standard-select-platform"
											select
											// label="Select"
											defaultValue="0"
											// helperText="Select Platform"
											variant="standard"
											sx={{ width: 100}}
											onChange={this.selectPlatform}
										>
											{platforms.map((option) => (
											<MenuItem key={option.iref} value={option.iref}>
												{option.name}
											</MenuItem>
											))}
										</TextField>
										</Box>							
										{ this.state.tabValue === "value" ?
											<SalesPeriodLocationList date={this.state.date} platform={this.state.platform} />
											: ""
										}
										{ this.state.tabValue === "transaction" ?
											<SalesTransPeriodLocationList date={this.state.date} platform={this.state.platform} />
											: ""
										}
									</Card>
								</Grid>	
								<Grid item xs={12} md={6}>
									<Card style={{ height: '640px' }} sx={{ borderRadius:"16px"}}>
										<Box sx={{ marginLeft: "10px", borderRadius:"16px", display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'flex-start'}} >
										<Tabs
										// style={{maxHeight: "20px", padding: "2px"}}
										value={this.state.tab2Value}
										onChange={this.handleTab2Change}
										// centered
										indicatorColor="secondary"
										>
										<Tab sx={{maxHeight: "20px", minHeight: "48px"}} label="last order" value="order"/>
										<Tab sx={{maxHeight: "20px", minHeight: "48px"}} label="top" value="top"/>
										</Tabs>
										</Box>
										{ this.state.tab2Value === "order" ? 
											<SalesLastOrders/>
											: ""
										}							
										{ this.state.tab2Value === "top" ?
											<SalesTopList/>
											: ""
										}
									</Card>
								</Grid>
								{/* <Grid item xs={12} md={8}>
									<OverviewOrders />
								</Grid> */}
								{/* <Grid item xs={12} md={8}>
									<OverviewLatestCustomers />
								</Grid> */}
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</Box>
		);
	}
}

export default Stats;
