import React from 'react';
import { Avatar, createTheme,Box, Card, Button, TextField, Typography } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { format } from 'date-fns';
import Client from '../Client';
import Config from '../ClientConfig';
import Divider from '@mui/material/Divider';

let theme = createTheme();

function addDays(date, days) {
  if(date) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    result = format(result, 'yyyy-MM-dd');
    return result;
  }
}

let d = new Date();
let today = format(d, 'yyyy-MM-dd');  // mm=week, MM=month

const objectToCsv = function (data) {
  const csvRows = [];
  /* Get headers as every csv data format 
  has header (head means column name)
  so objects key is nothing but column name 
  for csv data using Object.key() function.
  We fetch key of object as column name for 
  csv */
  const headers = Object.keys(data[0]);
  /* Using push() method we push fetched 
     data into csvRows[] array */
  csvRows.push(headers.join(','));
  // Loop to get value of each objects key
  for (const row of data) {
      const values = headers.map(header => {
          const val = row[header]
          return `"${val}"`;
      });
      // To add, separator between each value
      csvRows.push(values.join(','));
  }
  /* To add new line for each objects values
     and this return statement array csvRows
     to this function.*/
  return csvRows.join('\n');
};

class SalesExport extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      saleslocation_list: [],
      sx_hidden: {display: 'none'},
      sx_hiddenP: {display: 'none'},
      dateFrom: today,
      dateTo: today,
      dateFromP: today,
      dateToP: today,
      dates: "",
      datesP: ""
    };

    this.getData = this.getData.bind(this);
    this.getDataP = this.getDataP.bind(this);
    this.addDateFrom = this.addDateFrom.bind(this);
    this.addDateTo = this.addDateTo.bind(this);
    this.addDateFromP = this.addDateFromP.bind(this);
    this.addDateToP = this.addDateToP.bind(this);
    this.queryData = { };
  }

  componentDidMount() {
    this.getData ();
    this.getDataP ();
  }

  getData ( ) {  
    let queryData = { 
      "type": 35,
      "dateFrom": this.state.dateFrom,
      "dateTo": this.state.dateTo
    }
    // console.log(queryData);
	  let data = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, queryData );
    if ( data ) {
      this.setState ( { sales_list: data });
      this.setState ( { dates: new Date(Date.parse(data.start)).toLocaleDateString('ro-RO') + "-" + new Date(Date.parse(data.end)).toLocaleDateString('ro-RO') });
      if (data.result.length > 0) {
        this.setState ({sx_hidden: {}});  
      }
      else {
        this.setState ({sx_hidden: {display: 'none'}});  
      }

    }
    this.setState ( { filterData: queryData } );
  }

  getDataP( ) {  
    let queryData = { 
      "type": 34,
      "dateFrom": this.state.dateFromP,
      "dateTo": this.state.dateToP
    }
    // console.log(queryData);
	  let data = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, queryData );
    if ( data ) {
      this.setState ( { salesproduct_list: data });
      this.setState ( { datesP: new Date(Date.parse(data.start)).toLocaleDateString('ro-RO') + "-" + new Date(Date.parse(data.end)).toLocaleDateString('ro-RO') });
      if (data.result.length > 0) {
        this.setState ({sx_hiddenP: {}});  
      }
      else {
        this.setState ({sx_hiddenP: {display: 'none'}});  
      }

    }
    this.setState ( { filterData: queryData } );
  }

  addDateFrom = (event) => {
    this.setState ({dateFrom: event.target.value});
    this.setState ({dateTo: event.target.value});
  }
  addDateTo = (event) => {
    this.setState ({dateTo: event.target.value});
  }

  addDateFromP = (event) => {
    this.setState ({dateFromP: event.target.value});
    this.setState ({dateToP: event.target.value});
  }
  addDateToP = (event) => {
    this.setState ({dateToP: event.target.value});
  }

  onDownload = () => {
    const link = document.createElement("a");
    link.download = "locationSales_" + this.state.dateFrom  +"_"+ this.state.dateTo + ".csv";
    let csvData = objectToCsv(this.state.sales_list.result);
    let csvHeader = "Location Sales\nDate From: "+ this.state.dateFrom + "\nDate To: "+ this.state.dateTo+ "\n";
    let csv = csvHeader + "\n" + csvData;
    link.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
    link.click();
  };

  onDownloadP = () => {
    const link = document.createElement("a");
    link.download = "productSales_" + this.state.dateFromP  +"_"+ this.state.dateToP + ".csv";
    let csvData = objectToCsv(this.state.salesproduct_list.result);
    let csvHeader = "Product Sales\nDate From: "+ this.state.dateFromP + "\nDate To: "+ this.state.dateToP+ "\n";
    let csv = csvHeader + "\n" + csvData;
    link.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
    link.click();
  };

  render() {

    return (
        <Card variant="outlined" style={{ height: '600px' }} sx={{ paddingTop:"6px", borderRadius:"16px", height: '100%' }}>
           <Divider variant="middle" textAlign="right">Location</Divider>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}>
              <Typography
                sx={{lineHeight: 1.5, padding: '16px'}}
                color="#1a237e"
                variant="button"
              >
                Location sales by date
              </Typography>
            <Box style={{ alignItems: 'left', justifyContent: 'space-around', padding: '16px'}}>
              <TextField
                size='small'
                id="date"
                label="From"
                type="date"
                // defaultValue={currentDate}
                value={this.state.dateFrom}
                // inputFormat="DD/MM/YYYY"
                sx={{ color: "#e3e3e5", alignSelf: "center", marginLeft: "6px", width:120}}
                InputLabelProps={{
                  shrink: true,
                  sx : { color: "#5b5b5b" }
                }}
                InputProps={{ sx : { color: "#5b5b5b" }}}
                inputProps={{ min: "2020-01-01", style : { color: "#5b5b5b", paddingRight: 5, paddingLeft: 5} }}
                onChange={this.addDateFrom}
              />
              <TextField
                size='small'
                id="date"
                label="To"
                type="date"
                // defaultValue={currentDate}
                value={this.state.dateTo}
                sx={{ color: "#e3e3e5", alignSelf: "center", marginLeft: "6px", width:120 }}
                InputLabelProps={{
                  shrink: true,
                  sx : { color: "#5b5b5b" }
                }}
                InputProps={{ sx : { color: "#5b5b5b" }}}
                inputProps={{ min: "2020-01-01", max: addDays(this.state.dateFrom,50), style : { color: "#5b5b5b", paddingRight: 5, paddingLeft: 5} }}
                onChange={this.addDateTo}
              />
              <Button color="inherit" onClick={() => this.getData()} style={{maxWidth: '36px', minWidth: '36px'}}>
                <Avatar sx={{ width: 24, height: 24, bgcolor: theme.palette.primary.light}}> 
                  <AutorenewIcon />
                </Avatar>
              </Button>
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', paddingLeft: '16px', paddingBottom: '16px'}}>
              <Typography
                sx={{lineHeight: 1.5, paddingLeft: '10px', paddingRight: '10px'}}
                color="#1a237e"
                align="center"
                variant="body1"
              >
                Location Sales for {this.state.dates}
              </Typography>
              <Button
                sx={this.state.sx_hidden}
                color="inherit"
                size="small"
                onClick={this.onDownload} 
                variant="contained" 
     
                // color="primary"
                style={{maxWidth: '36px', minWidth: '36px'}}
              >
                <Avatar sx={{ width: 24, height: 24, bgcolor: theme.palette.success.light}}> 
                  <FileDownloadIcon />
                </Avatar>
              </Button>
            </Box>
          </Box>
          <Divider variant="middle" textAlign="right">Product</Divider>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}>
              <Typography
                sx={{lineHeight: 1.5, padding: '16px'}}
                color="#1a237e"
                variant="button"
              >
                Product sales by date and location
              </Typography>
            <Box style={{ alignItems: 'left', justifyContent: 'space-around', padding: '16px'}}>
              <TextField
                size='small'
                id="date"
                label="From"
                type="date"
                variant="standard"  
                // defaultValue={currentDate}
                value={this.state.dateFromP}
                // inputFormat="DD/MM/YYYY"
                sx={{ color: "#e3e3e5", alignSelf: "center", marginLeft: "6px", width:120}}
                InputLabelProps={{
                  shrink: true,
                  sx : { color: "#5b5b5b" }
                }}
                InputProps={{ sx : { color: "#5b5b5b" }}}
                inputProps={{ min: "2020-01-01", style : { color: "#5b5b5b", paddingRight: 5, paddingLeft: 5} }}
                onChange={this.addDateFromP}
              />
              <TextField
                size='small'
                id="date"
                label="To"
                type="date"
                variant="standard"
                // defaultValue={currentDate}
                value={this.state.dateToP}
                sx={{ color: "#e3e3e5", alignSelf: "center", marginLeft: "6px", width:120 }}
                InputLabelProps={{
                  shrink: true,
                  sx : { color: "#5b5b5b" }
                }}
                InputProps={{ sx : { color: "#5b5b5b" }}}
                inputProps={{ min: "2020-01-01", max: addDays(this.state.dateFromP,50), style : { color: "#5b5b5b", paddingRight: 5, paddingLeft: 5} }}
                onChange={this.addDateToP}
              />
              <Button color="inherit" onClick={() => this.getDataP()} style={{maxWidth: '36px', minWidth: '36px'}}>
                <Avatar sx={{ width: 24, height: 24, bgcolor: theme.palette.primary.light}}> 
                  <AutorenewIcon />
                </Avatar>
              </Button>
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', paddingLeft: '16px'}}>
              <Typography
                sx={{lineHeight: 1.5, paddingLeft: '10px', paddingRight: '10px'}}
                color="#1a237e"
                align="center"
                variant="body1"
              >
                Product Sales for {this.state.datesP}
              </Typography>
              <Button
                sx={this.state.sx_hiddenP}
                color="inherit"
                size="small"
                onClick={this.onDownloadP} 
                variant="contained" 
     
                // color="primary"
                style={{maxWidth: '36px', minWidth: '36px'}}
              >
                <Avatar sx={{ width: 24, height: 24, bgcolor: theme.palette.success.light}}> 
                  <FileDownloadIcon />
                </Avatar>
              </Button>
            </Box>
          </Box>
      </Card>
    );

  }
}

export default SalesExport;
