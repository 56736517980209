import React from 'react';
import { Avatar, createTheme, Box, CardContent, Button, TableContainer, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { format } from 'date-fns';
import Client from '../Client';
import Config from '../ClientConfig';

let theme = createTheme();

let d = new Date();
let today = format(d, 'yyyy-MM-dd');  // mm=week, MM=month
// let yesterday = format(new Date().setDate(new Date().getDate() - 1), 'yyyy-MM-dd');

function thousandsSeparator(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


const objectToCsv = function (data) {
  const csvRows = [];
  /* Get headers as every csv data format 
  has header (head means column name)
  so objects key is nothing but column name 
  for csv data using Object.key() function.
  We fetch key of object as column name for 
  csv */
  const headers = Object.keys(data[0]);
  /* Using push() method we push fetched 
     data into csvRows[] array */
  csvRows.push(headers.join(','));
  // Loop to get value of each objects key
  for (const row of data) {
      const values = headers.map(header => {
          const val = row[header]
          return `"${val}"`;
      });
      // To add, separator between each value
      csvRows.push(values.join(','));
  }
  /* To add new line for each objects values
     and this return statement array csvRows
     to this function.*/
  return csvRows.join('\n');
};
class SalesPeriodLocationList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      saleslocation_list: [],
      page: 0,
      platform: 0,
      date: today,
      rowsPerPage: 12,
      sx_hidden: {display: 'none'},
      value: "1",
      filterData: {}
    };

    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);

  }

  componentDidMount() {
    if ( this.props.date ) {this.setState ( { date: this.props.date }, () => {this.getData();} )};
    if ( this.props.platform ) {this.setState ( { platform: this.props.platform }, () => {this.getData()})};
  }

  componentDidUpdate ( prevProps ) {    
    if ( prevProps.date !== this.props.date ) {
      if ( this.props.date ) {this.setState ( { date: this.props.date }, () => {
        this.getData();} 
      )};
    }

    if ( prevProps.platform !== this.props.platform ) {
      if ( this.props.platform >=0 ) {this.setState ( { platform: this.props.platform }, () => {
        this.getData()
      })};
    }
  }

  getData ( ) {
    let queryData = { 
      "type": 11,
      "date": this.state.date,
      "platform": this.state.platform

  }
	  let data = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, queryData );
    if ( data ) {
      this.setState ( { saleslocation_list: data });
    }
    this.setState ( { filterData: queryData } );
  }

  doSync ( ) {
    // console.log(this.props.date);
    // console.log(this.state.date);
    this.getData ( );
    this.setState ({page: 0});
  }

  addDate = (event) => {
    this.setState ({date: event.target.value});

  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  }

  handleChangePage = (event, newPage) => {
    this.setState ({page: newPage});
  }
  
  handleChangeRowsPerPage = (event) => {
    this.setState ({rowsPerPage: +event.target.value});
    this.setState ({page: 0});
  }

  onDownload = () => {
    const link = document.createElement("a");
    link.download = "salesPeriodLocation_" + this.state.date  +"_"+ this.state.platform + ".csv";
    let csvData = objectToCsv(this.state.saleslocation_list.result);
    let csvHeader = "Sales value\nDate: "+ this.state.date + "\nPlatform: "+ this.state.platform;
    let csv = csvHeader + "\n" + csvData;
    link.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
    link.click();
  };
  
  render() {
    let page = this.state.page;
    let rowsPerPage = this.state.rowsPerPage;
    let dtoday = 0;
    // let dyesterday = 0;
    let today_LW = 0;                        
    // let today_LY = 0;
    let today_TWLY = 0;            
    let WTD = 0;
    let WTD_LW = 0;
    let WTD_LY = 0;
    let MTD = 0;
    let MTD_LM = 0;
    let MTD_LY = 0;            
    let YTD = 0;
    let YTD_LY = 0;
    let locationSalesList = [];
    if (this.state.saleslocation_list){
      if (this.state.saleslocation_list.result) {
        let counter = 0;
        Object.keys(this.state.saleslocation_list.result).forEach(key => {
          let fData = this.state.saleslocation_list.result[key];
          let p = {
            id: counter+1,
            name: fData.name,
            salesToday: fData.sales_Today,
            salesToday_LW: fData.sales_TodayLW,
            salesToday_LY: fData.sales_TodayLY,                        
            salesTW: fData.sales_TW,
            salesLW: fData.sales_LW,            
            salesTW_LY: fData.sales_TWLY,
            salesTM: fData.sales_TM,
            salesLM: fData.sales_LM,
            salesTM_LY: fData.sales_TMLY,            
            salesTY: fData.sales_TY,
            salesLY: fData.sales_LY
          }
          counter += 1;
          locationSalesList.push ( p );
        });
      }
      if (this.state.saleslocation_list.dates) {
        Object.keys(this.state.saleslocation_list.dates).forEach(key => {
          let dData = this.state.saleslocation_list.dates[key];
          dtoday = dData.today;
          // dyesterday = dData.yesterday;
          today_LW = dData.today_LW;                        
          // today_LY = dData.today_LY;
          today_TWLY = dData.today_TWLY;            
          WTD = dData.WTD;
          WTD_LW = dData.WTD_LW;
          WTD_LY = dData.WTD_LY;
          MTD = dData.MTD;
          MTD_LM = dData.MTD_LM;
          MTD_LY = dData.MTD_LY;            
          YTD = dData.YTD;
          YTD_LY = dData.YTD_LY
        });
      }
    }

    return (
      <CardContent sx={{borderRadius: "2px", padding: "2px"}}>
          <Box sx={{ marginLeft: "10px", marginRight: "10px", display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between'}}>
              <Box sx={{ justifyContent: 'flex-start'}}>
                <Tabs value={this.state.value} onChange={this.handleChange} aria-label="tabs example">
                  <Tab sx={{minWidth: "70px"}} label="Day" value="1" />
                  <Tab sx={{minWidth: "70px"}} label="Week" value="2" />
                  <Tab sx={{minWidth: "70px"}} label="Month" value="3" />
                  <Tab sx={{minWidth: "70px"}} label="Year" value="4" />
                </Tabs>  
              </Box>
              <Button
                color="inherit"
                size="small"
                onClick={this.onDownload} 
                variant="contained" 
                // color="primary"
                style={{maxWidth: '36px', minWidth: '36px'}}
              >
                <Avatar sx={{ width: 24, height: 24, bgcolor: theme.palette.success.light}}> 
                  <FileDownloadIcon />
                </Avatar>
              </Button>
          </Box>
          <Box>
            {this.state.value === "1" ?
              <Box>
                <TableContainer sx={{ height: '450px' }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell style={{padding: "6px", textAlign: "center" }}>
                        <b></b>
                      </TableCell> */}
                      <TableCell style={{padding: "6px",  paddingLeft: "16px", textAlign: "left" }}>
                        <b>Name</b>
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        <Tooltip title={dtoday} >
                          <b>Today</b>
                        </Tooltip>
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        <Tooltip title={today_LW} >
                          <b>T_LW</b>
                        </Tooltip>                        
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        <b>%_LW</b>
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        <Tooltip title={today_TWLY} >
                          <b>TW_LY</b>
                        </Tooltip>
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        <b>%W_LY</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {locationSalesList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((location) => {
                      return (
                        <TableRow
                          hover
                          key={location.id}
                          tabIndex={-1}
                        >
                          {/* <TableCell style={{padding: "6px", textAlign: "center" }}>
                            {location.id}
                          </TableCell> */}
                          <TableCell style={{padding: "6px", paddingLeft: "16px", textAlign: "left" }}>
                            {location.name}
                          </TableCell>
                          <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                              {thousandsSeparator(parseInt(location.salesToday))}
                          </TableCell>
                          <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                              {thousandsSeparator(parseInt(location.salesToday_LW))}
                          </TableCell>
                          <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                              {location.salesToday_LW === 0 ? 0+"%" : parseInt((location.salesToday/location.salesToday_LW)*100)+"%"} 
                          </TableCell>
                          <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                              {thousandsSeparator(parseInt(location.salesToday_LY))}
                          </TableCell>
                          <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                              {location.salesToday_LY === 0 ? 0+"%" : parseInt((location.salesToday/location.salesToday_LY)*100)+"%"} 
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                </TableContainer>
                <TablePagination 
                  rowsPerPageOptions={[12, { value: locationSalesList.length, label: 'All' }]}
                  labelRowsPerPage="Rows:"
                  // showFirstButton="True"
                  // showLastButton="True"
                  component="div"
                  count={locationSalesList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              </Box>
            : ""
            }
            {this.state.value === "2" ?
              <Box>
                <TableContainer sx={{ height: '450px' }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell style={{padding: "6px", textAlign: "center" }}>
                        <b></b>
                      </TableCell> */}
                      <TableCell style={{padding: "6px", paddingLeft: "16px", textAlign: "left" }}>
                        <b>Name</b>
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        <Tooltip title={WTD} >
                          <b>WTD</b>
                        </Tooltip>
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        <Tooltip title={WTD_LW} >                        
                          <b>WTD_LW</b>
                        </Tooltip>
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        <b>%_LW</b>
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        <Tooltip title={WTD_LY} >
                          <b>WTD_LY</b>
                        </Tooltip>
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        <b>%_LY</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {locationSalesList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((location) => {
                      return (
                        <TableRow
                          hover
                          key={location.id}
                          tabIndex={-1}
                        >
                          {/* <TableCell style={{padding: "6px", textAlign: "center" }}>
                            {location.id}
                          </TableCell> */}
                          <TableCell style={{padding: "6px", paddingLeft: "16px", textAlign: "left" }}>
                            {location.name}
                          </TableCell>
                          <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                              {thousandsSeparator(parseInt(location.salesTW))}
                          </TableCell>
                          <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                              {thousandsSeparator(parseInt(location.salesLW))}
                          </TableCell>
                          <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                              {location.salesLW === 0 ? 0+"%" : parseInt((location.salesTW/location.salesLW)*100)+"%"} 
                          </TableCell>
                          <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                              {thousandsSeparator(parseInt(location.salesTW_LY))}
                          </TableCell>
                          <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                              {location.salesTW_LY === 0 ? 0+"%" : parseInt((location.salesTW/location.salesTW_LY)*100)+"%"} 
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[12, { value: locationSalesList.length, label: 'All' }]}
                  labelRowsPerPage="Rows:"
                  // showFirstButton="True"
                  // showLastButton="True"
                  component="div"
                  count={locationSalesList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              </Box>
              : ""
            }
            {this.state.value === "3" ?
              <Box>
                <TableContainer sx={{ height: '450px' }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell style={{padding: "6px", textAlign: "center" }}>
                        <b></b>
                      </TableCell> */}
                      <TableCell style={{padding: "6px", paddingLeft: "16px", textAlign: "left" }}>
                        <b>Name</b>
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        <Tooltip title={MTD} >
                          <b>MTD</b>
                        </Tooltip>                        
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        <Tooltip title={MTD_LM} >
                          <b>MTD_LM</b>
                        </Tooltip>
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        <b>%_LM</b>
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        <Tooltip title={MTD_LY} >
                          <b>MTD_LY</b>
                        </Tooltip>  
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        <b>%_LY</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {locationSalesList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((location) => {
                      return (
                        <TableRow
                          hover
                          key={location.id}
                          tabIndex={-1}
                        >
                          {/* <TableCell style={{padding: "6px", textAlign: "center" }}>
                            {location.id}
                          </TableCell> */}
                          <TableCell style={{padding: "6px", paddingLeft: "16px", textAlign: "left" }}>
                            {location.name}
                          </TableCell>
                          <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                              {thousandsSeparator(parseInt(location.salesTM))}
                          </TableCell>
                          <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                              {thousandsSeparator(parseInt(location.salesLM))}
                          </TableCell>
                          <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                              {location.salesLM === 0 ? 0+"%" : parseInt((location.salesTM/location.salesLM)*100)+"%"} 
                          </TableCell>
                          <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                              {thousandsSeparator(parseInt(location.salesTM_LY))}
                          </TableCell>
                          <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                              {location.salesTM_LY === 0 ? 0+"%" : parseInt((location.salesTM/location.salesTM_LY)*100)+"%"} 
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[12, { value: locationSalesList.length, label: 'All' }]}
                  labelRowsPerPage="Rows:"
                  // showFirstButton="True"
                  // showLastButton="True"
                  component="div"
                  count={locationSalesList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              </Box>
              : ""
            } 
            {this.state.value === "4" ?
              <Box>
                <TableContainer sx={{ height: '450px' }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell style={{padding: "6px", textAlign: "center" }}>
                        <b></b>
                      </TableCell> */}
                      <TableCell style={{padding: "6px", paddingLeft: "16px", textAlign: "left" }}>
                        <b>Name</b>
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        <Tooltip title={YTD} >
                          <b>YTD</b>
                        </Tooltip>
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        <Tooltip title={YTD_LY} >
                          <b>YTD_LY</b>
                        </Tooltip>
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        <b>%_LY</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {locationSalesList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((location) => {
                      return (
                        <TableRow
                          hover
                          key={location.id}
                          tabIndex={-1}
                        >
                          {/* <TableCell style={{padding: "6px", textAlign: "center" }}>
                            {location.id}
                          </TableCell> */}
                          <TableCell style={{padding: "6px", paddingLeft: "16px", textAlign: "left" }}>
                            {location.name}
                          </TableCell>
                          <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                              {thousandsSeparator(parseInt(location.salesTY))}
                          </TableCell>
                          <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                              {thousandsSeparator(parseInt(location.salesLY))}
                          </TableCell>
                          <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                              {location.salesLY === 0 ? 0+"%" : parseInt((location.salesTY/location.salesLY)*100)+"%"} 
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[12, { value: locationSalesList.length, label: 'All' }]}
                  labelRowsPerPage="Rows:"
                  // showFirstButton="True"
                  // showLastButton="True"
                  component="div"
                  count={locationSalesList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              </Box>
              : ""
            }         
          </Box>  
        </CardContent>
    );
  }
}

export default SalesPeriodLocationList;
