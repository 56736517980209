import React from 'react';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { FaHome, FaPizzaSlice, FaHamburger, FaMapMarkedAlt, FaEuroSign, FaUserTie, FaHospitalUser } from "react-icons/fa";
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isDecimal from 'validator/lib/isDecimal';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import swal from 'sweetalert';

import noImage from './../images/noImage.png';
import idle from './../images/icons/idle_on.png';
import registered from './../images/icons/registered_on.png';
import active from './../images/icons/active_on.png';
import discontinued from './../images/icons/discontinued_on.png';
import noUser_on from './../images/icons/noUser_on.png';
import noUser_off from './../images/icons/noUser_off.png';

import DropDown from './DropDown';
import KImage from './KImage';

import Client from './Client';
import Config from './ClientConfig';
import BackOfficeConfig from './../Config';

let theme = createTheme();

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#273442",
    color: theme.palette.common.white,
    paddingLeft: "4px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    paddingLeft: "4px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

class Person extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      expanded: false,
      edit: false,
      saveButtonVisible: false,
      //combo_mainImage: null,
      person: {
        iref: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        birthday: "",
        image: noImage,
        address: {
          name: "",
          postalCode: "",
          street: "",
          number: "",
          entrance: "",
          local: "",
          floor: "",
          complement: "",
          gps_lt: "",
          gps_lg: "",
          city: {
            iref: "city000000",
            name: "City",
            county: {
              iref: "14f2aa40c2",
              name: "County",
              country: {
                iref: "country061",
                name: "Country"
              }
            }
          }
        },
        user_person: [
          {
            iref: "",
            first_name: "",
            last_name: "",
            user_status: 0,
            user_type: 0,
            email: ""

          }
        ],
        company: {
            iref: "",
            cuid: "",
            ruid: "",
            name: "",
            email: ""
        }
      },
      emailValid: false,
      emailUserValid: false,
      phoneValid: false,
      firstNameValid: false,
      lastNameValid: false,
      somethingChanged: false,
      companiesSearch: [],
      usersSearch: [],
      counties: { },
      cities: { },
      gpsLgValid: false,
      gpsLtValid: false
    };

    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.getElementByName = this.getElementByName.bind(this);
    this.companyChange = this.companyChange.bind(this);
    this.companySearch = this.companySearch.bind(this);
    this.userChange = this.userChange.bind(this);
    this.userSearch = this.userSearch.bind(this);
    this.addCountry = this.addCountry.bind(this);
    this.addCounty = this.addCounty.bind(this);
    this.addCity = this.addCity.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.changeAddress = this.changeAddress.bind(this);
    this.changeBirthday = this.changeBirthday.bind(this);
    this.pushData = this.pushData.bind(this);
    this.updateGeoData = this.updateGeoData.bind(this);
    this.getImage = this.getImage.bind(this);
    this.detachCompany = this.detachCompany.bind(this);
    this.detachUser = this.detachUser.bind(this);
    this.addGender = this.addGender.bind(this);

    
  }

  handleExpandClick ( ) {
    this.setState({ expanded: !this.state.expanded });
  }

  handleEditClick ( ) {
    let cEdit = !this.state.edit;
    this.setState({ edit: !this.state.edit });
    if ( this.state.expanded === false && cEdit === true )
      this.state.expanded = true;
    if ( this.state.expanded === true && cEdit === false )
      this.state.expanded = false;
    this.setState({ saveButtonVisible: cEdit });

    let uPerson = this.state.person;
    let uAddress = { };
    if ( uPerson.address ) uAddress = uPerson.address;
    if ( uAddress ) {
      let city = { };
      let county = { };      
      let country = { };
      country.iref = "country061";
      if ( uAddress.city ) {
        if ( uAddress.city.county ) {
          if ( uAddress.city.county.country )
            country = uAddress.city.county.country;
          county = uAddress.city.county;
        }
        city = uAddress.city;
      }
      county.country = country;
      city.county = county;
      uAddress.city = city;
      uPerson.address = uAddress;

      let queryData = {
        country: country.iref,
        orderBy_Name: true
      }
      //queryData [ "user_type_gte" ] = "15"
      let data = this.state.client.getData ( Config.API_URL_COUNTY, queryData );
      if ( data ) { this.setState ( { counties: data.counties });
      } else { this.setState ( { counties: {} }); }      

      queryData = {
        county: county.iref,
        orderBy_Name: true
      }
      data = this.state.client.getData ( Config.API_URL_CITY, queryData );
      if ( data ) { this.setState ( { cities: data.cities }); }

      this.setState ( { person: uPerson } );
    }
  }

  getElementByName ( elements, name ) {    
    let e = {
      "iref" : null,
      "name" : "X",
      "image" : "X",
      "image_off" : "X"
    };
    if ( elements !== null && elements !== undefined ) {
      for ( var i = 0; i < elements.length; i++ ) {
        let el = elements [ i ];
        if ( el.length === 2 ) {
          if ( el [ 1 ].name === name ) e = el [ 1 ];
        } else {
          if ( el [ 0 ].name === name ) e = el [ 0 ];
        }        
      }
    }
    return e;    
  }

  companySearch ( e ) {
    if ( e.target.value.length > 3 ) {
      let queryData = {
        "nameLike" : e.target.value,
        "notLinked_company": true
      };
      let data = this.state.client.filterCompanies ( queryData );
      let newCompanies = [];
      let companies = [];
      Object.keys(data.Companies).forEach(key => {
        let fData = data.Companies [ key ];
        let lineText = fData.iref + " - " + fData.cuid + " - " + fData.name + " (" + fData.ruid + ", " + fData.email + ")";
        let newObj = { key: fData.iref, label: lineText };
        newCompanies.push ( newObj );
        companies.push ( fData );
        //counties [ fData.iref ] = fData.name;      
      });

      this.setState ( { companiesSearch: newCompanies });
    }
  }

  companyChange ( e ) {
    let text = e.target.textContent;
    let repIref = text.substring ( 0, text.indexOf(" - ") );
    let queryData = { };
    let data = this.state.client.getData ( Config.API_URL_COMPANY + repIref, queryData );
    let uPerson = this.state.person;
    uPerson.company = data;
    this.setState ( { person: uPerson } );    
    this.setState ( { somethingChanged: true } );
  }

  userSearch ( e ) {
    if ( e.target.value.length > 3 ) {
      let stringLength = e.target.value.length;
      let limit = (stringLength - 2) * 20;
      let queryData = {
        "nameLike" : e.target.value,
        "notLinked_person": true,
        "limit": limit
      };
      let data = this.state.client.filterUsers ( queryData );
      let newUsers = [];
      let users = [];
      if ( data.usersProfiles.length > 0 ) {
        Object.keys(data.usersProfiles).forEach(key => {
          let fData = data.usersProfiles [ key ];
          let userType = BackOfficeConfig.USER_TYPES [ fData.user_type ];
          let lineText = fData.iref + " - " + userType + "[" + fData.username + "] - " + fData.first_name + " " + fData.last_name + " (" + fData.ruid + ", " + fData.email + ")";
          let newObj = { key: fData.iref, label: lineText };
          newUsers.push ( newObj );
          users.push ( fData );
          //counties [ fData.iref ] = fData.name;      
        });
      }
      this.setState ( { usersSearch: newUsers });
    }
  }

  userChange ( e ) {
    let text = e.target.textContent;
    let repIref = text.substring ( 0, text.indexOf(" - ") );
    let queryData = { iref: repIref };
    //let data = this.state.client.getData ( Config.USER_API_URL + repIref, queryData );
    let data = this.state.client.getData ( Config.API_URL_USERS_PROFILES, queryData );
    let userWithPerson = {};
    if ( data.usersProfiles ) {
      if ( data.usersProfiles.length == 1 ) userWithPerson = data.usersProfiles [ 0 ];
    }
    let uPerson = this.state.person;
    if ( userWithPerson ) {
      uPerson.user_person = userWithPerson;
      this.setState ( { person: uPerson } );    
      this.setState ( { somethingChanged: true } );
    }    
  }

  addCountry ( e ) {
    if ( this.state.person ) {
      let uPerson = this.state.person;
      let uAddress = { }
      if ( uPerson.address ) {
        uAddress = uPerson.address;        
      } else {
        uAddress.city = { };
        uAddress.city.county = { };
        uAddress.city.county.country = { };
      }
      if ( uAddress.city ) {            
        uAddress.city.iref = "";
        if ( uAddress.city.county ) {             
          uAddress.city.county.iref = "";
          if (  uAddress.city.county.country )
            uAddress.city.county.country.iref = e;
        }        
      }
      uPerson.address = uAddress;
      this.setState ( {person: uPerson} );
      this.setState ( { somethingChanged: true } );
    }

    let queryData = {
      country: e,
      orderBy_Name: true
    }
    //queryData [ "user_type_gte" ] = "15"
    let data = this.state.client.getData ( Config.API_URL_COUNTY, queryData );
    if ( data ) {
        this.setState ( { counties: data.counties });
    } else {
      this.setState ( { counties: {} });
    }      
    this.setState ( { cities: {} });
  }

  addCounty ( e ) {
    if ( this.state.person ) {
      let uPerson = this.state.person;
      if ( uPerson.address ) {
        if ( uPerson.address.city ) {            
          uPerson.address.city.iref = "";
          if ( uPerson.address.city.county ) {             
            uPerson.address.city.county.iref = e;
          }            
        }
      }
      this.setState ( {person: uPerson} );
      this.setState ( { somethingChanged: true } );
    }

    let queryData = {
      county: e,
      orderBy_Name: true
    }
    //queryData [ "user_type_gte" ] = "15"
    let data = this.state.client.getData ( Config.API_URL_CITY, queryData );
    if ( data ) {
      this.setState ( { cities: data.cities });
    }
  }

  addCity ( e ) {
    if ( this.state.person ) {
      let uPerson = this.state.person;
      if ( uPerson.address ) {
        if ( uPerson.address.city ) {            
          uPerson.address.city.iref = e;
          let queryData = { };
          let cityData = this.state.client.getData ( Config.API_URL_CITY + e, queryData );
          if ( cityData ) {
            if ( cityData.zipCode ) {
              let zipCode = cityData.zipCode;
              if ( parseInt(zipCode) !== 0 ) {
                uPerson.address.zipCode = zipCode;
                uPerson.address.postalCode = zipCode;                
              } else {
                uPerson.address.zipCode = "";
                uPerson.address.postalCode = ""; 
              }              
            } else {
              uPerson.address.zipCode = "";
              uPerson.address.postalCode = "";
            }
          }
        }
      }
      this.setState ( {person: uPerson} );
      this.setState ( { somethingChanged: true } );
    }
  }

  changeBirthday ( e ) {
    if ( e ) {
      let year = e.$y;
      let imonth = parseInt(e.$M) + 1;
      let month = String(imonth).padStart(2,'0');
      let iday = parseInt(e.$D);
      let day = String(iday).padStart(2,'0');
      let bday = year + "-" + month + "-" + day;
      let uPerson = this.state.person;
      uPerson.birthday = bday;
      this.setState ( { person: uPerson } );
      this.setState ( { somethingChanged: true } );
    }
  }

  changeValue ( e ) {
    let id = null;
    if ( e.target.id !== null ) id = e.target.id;
    let value = null;
    if  ( e.target.value !== null ) value = e.target.value;
    //if ( id !== null && value !== null && value !== undefined ) {
    if ( id ) {
      let uPerson = this.state.person;
      switch ( id ) {
        case "company":
          uPerson.company = value;
          break;
        case "user":
          uPerson.user_person = value;
          break;
        case "birthday":
          uPerson.birthday = value;
          break;
        case "c_firstName":
          uPerson.firstName = value;
          if ( value.length > 2 ) this.setState ( { firstNameValid: true } );
          else this.setState ( { firstNameValid: false } );
          break;
        case "c_lastName":
          uPerson.lastName = value;
          if ( value.length > 2 ) this.setState ( { lastNameValid: true } );
          else this.setState ( { lastNameValid: false } );
          break;
        
        case "c_phone":
          uPerson.phone = value;
          if ( isMobilePhone ( value ) ) this.setState ( { phoneValid: true } );
          else this.setState ( { phoneValid: false } );
          break;
        case "c_email":
          uPerson.email = value;
          if ( isEmail ( value ) ) this.setState ( { emailValid: true } );
          else this.setState ( { emailValid: false } );
          break;
      }
      this.setState ( { person: uPerson } );
      this.setState ( { somethingChanged: true } );
    }
  }

  changeAddress ( e ) {
    let id = null;
    if ( e.target.id !== null ) id = e.target.id;
    let value = null;
    if  ( e.target.value !== null ) value = e.target.value;

    if ( id !== null && value !== null ) {
      let uPerson = this.state.person;
      let uAddress = { };
      if ( uPerson ) {
        if ( uPerson.address ) uAddress = uPerson.address;
      }
      switch ( id ) {
        case "pA_name":
          uAddress.name = value;
          break;
        case "pA_street":
          uAddress.street = value;
          break;
        case "pA_number":
          uAddress.number = value;
          break;
        case "pA_entrance":
          uAddress.entrance = value;
          break;
        case "pA_local":
          uAddress.local = value;
          break;
        case "pA_floor":
          uAddress.floor = value;
          break;
        case "pA_zip":
          uAddress.postalCode = value;
          break;
        case "pA_complement":
          uAddress.complement = value;
          break;
        case "pA_gps_lg":
          uAddress.gps_lg = value;
          if ( isDecimal ( value ) )
            this.setState ( { gpsLgValid: true } )          
          break;
        case "pA_gps_lt":
          uAddress.gps_lt = value;
          if ( isDecimal ( value ) )
            this.setState ( { gpsLtValid: true } )          
          break;
      }
      uPerson.address = uAddress;
      this.setState ( { person: uPerson } );
      this.setState ( { somethingChanged: true } );
    }
  }

  pushData ( e ) {

    if ( this.state.firstNameValid && this.state.lastNameValid && this.state.emailValid && this.state.phoneValid ) {
      let rdata = this.state.client.pushData ( Config.API_URL_PERSON_ADD, this.state.person );
      if ( rdata ) {
        if ( rdata.status == "OK" ) {
          let uPerson = this.state.person;
          uPerson.iref = rdata.iref;
          this.setState ( { person: uPerson } );
          this.handleEditClick ( );
          swal ( "Success", "Successfully added/modified person ! (" + rdata.iref + ")", "success", {
            buttons: false,
            timer: 2000,
          })
          .then((value) => {
            this.props.personUpdated ( );
            this.setState ( { somethingChanged: false } );
          });        
        } else {
          if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
          swal ( "Error", "Add/Update person failed !", "error" );
        }
      }
    } else {
      swal ( "Error", "Add/Update person failed !", "error" );
    }
  }

  updateGeoData ( country_id, county_id ) {
    let queryData = {
      country: country_id,
      orderBy_Name: true
    }
    let data = this.state.client.getData ( Config.API_URL_COUNTY, queryData );
    if ( data ) {
      this.setState ( { counties: data.counties });
    }

    queryData = {
      county: county_id,
      orderBy_Name: true
    }
    let citiesData = this.state.client.getData ( Config.API_URL_CITY, queryData );
    if ( citiesData ) {
      this.setState ( { cities: citiesData.cities } );
      //this.setState ( { cities: citiesData.cities }, this.setState ( { person : uPerson } ) );
    }    
  }

  getImage ( newImage ) {
    if ( newImage ) {
      if ( this.state.person ) {
        let nPerson = this.state.person;
        nPerson.image = newImage;
        this.setState ( { person: nPerson }, this.setState ( { somethingChanged: true } ) );
      }
    }
  }

  componentDidMount() {
    if ( Object.entries(this.props.person).length === 0 ) {
      this.handleEditClick ( );
    } else {
      if ( this.props.person !== null ) {
        let uPerson = this.props.person;
        if ( uPerson.user_person ) {
         if ( uPerson.user_person.length > 0 ) {
          let up = uPerson.user_person [ 0 ];
          uPerson.user_person = up;
          if ( up.email )
            if ( isEmail ( up.email ) ) this.setState ( { emailUserValid: true } );
         }
        }
        if ( uPerson.email )
          if ( isEmail ( uPerson.email ) ) this.setState ( { emailValid: true } );
        if ( uPerson.phone )
          if ( isMobilePhone ( uPerson.phone ) ) this.setState ( { phoneValid: true } );

        if ( uPerson.address ) {          
          if ( uPerson.address.gps_lg )
            if ( isDecimal ( uPerson.address.gps_lg ) )
              this.setState ( { gpsLgValid: true } )
          if ( uPerson.address.gps_lt )
            if ( isDecimal ( uPerson.address.gps_lt ) )
              this.setState ( { gpsLtValid: true } )
        }

        if ( uPerson.company_person ) {
          if ( uPerson.company_person.length > 0 ) {
            let uCompany = uPerson.company_person [ 0 ];
            uPerson.company = uCompany;
          }
        }
        this.setState ( { person: uPerson } );

        if ( uPerson.firstName.length > 2 ) this.setState ( { firstNameValid: true } );
        if ( uPerson.lastName.length > 2 ) this.setState ( { lastNameValid: true } );
        //if ( isMobilePhone ( uPerson.phone ) ) this.setState ( { phoneValid: true } );
        //if ( isEmail ( uPerson.email ) ) this.setState ( { emailValid: true } );
      }
    }
  }

  componentDidUpdate ( newProps ) {
    if ( newProps.person != this.props.person ) {
      let uPerson = this.props.person; //this.state.person;
      //let uPerson = newProps.person;
      if ( uPerson.user_person ) {
        if ( uPerson.user_person.length > 0 ) {
         let up = uPerson.user_person [ 0 ];
         uPerson.user_person = up;
         if ( up.email )
          if ( isEmail ( up.email ) ) this.setState ( { emailUserValid: true } );
        }
      }
      if ( uPerson.email )
          if ( isEmail ( uPerson.email ) ) this.setState ( { emailValid: true } );
      if ( uPerson.phone )
        if ( isMobilePhone ( uPerson.phone ) ) this.setState ( { phoneValid: true } );

      if ( uPerson.address ) {
        if ( uPerson.address.gps_lg )
          if ( isDecimal ( uPerson.address.gps_lg ) )
            this.setState ( { gpsLgValid: true } )
        if ( uPerson.address.gps_lt )
          if ( isDecimal ( uPerson.address.gps_lt ) )
            this.setState ( { gpsLtValid: true } )
      }

      if ( uPerson.company_person ) {
        if ( uPerson.company_person.length > 0 ) {
          let uCompany = uPerson.company_person [ 0 ];
          uPerson.company = uCompany;
        }
      }
      this.setState ( { person: uPerson } );
    }
  }

  detachCompany ( ) {
    let uPerson = this.state.person;
    let prevCompany = uPerson.company;
    uPerson.company = {};
    uPerson.prevCompany = prevCompany;
    this.setState ( { person: uPerson } );
    this.setState ( { somethingChanged: true } );
  }

  detachUser ( ) {
    let uPerson = this.state.person;
    let prevUser = uPerson.user_person;
    uPerson.user_person = {};
    uPerson.prevUser = prevUser;
    this.setState ( { person: uPerson } );
    this.setState ( { somethingChanged: true } );
  }

  addGender ( e ) {
    if ( this.state.person ) {
      let uPerson = this.state.person;
      uPerson.gender = parseInt ( e );
      this.setState ( { person: uPerson } );
      this.setState ( { somethingChanged: true } );
    }
  }

  render() {
    let iref = "";
    let firstName = "";
    let lastName = "";
    let email = "";
    let phone = "";
    let birthday = "";
    let image = noImage;
    let gender = 9;
    let address = {};

    if ( this.state.person ) {
      let sPerson = this.state.person;
      if ( sPerson.iref ) iref = sPerson.iref;
      if ( sPerson.firstName ) firstName = sPerson.firstName;
      if ( sPerson.lastName ) lastName = sPerson.lastName;
      if ( sPerson.email ) email = sPerson.email;
      if ( sPerson.phone ) phone = sPerson.phone;
      if ( sPerson.birthday ) birthday = sPerson.birthday;
      if ( sPerson.image ) image = sPerson.image;
      if ( sPerson.gender !== undefined ) gender = parseInt ( sPerson.gender );
      if ( sPerson.address ) address = sPerson.address;
    }

    let personFullName = firstName + " " + lastName;

    let personImage = noImage;          
    if ( this.state.person.image ) {
      personImage = this.state.person.image;
      /* image = this.state.person.image;
      let fileName = image.replace(/\.[^/.]+$/, "")
      let fileExtensions = image.split(".").pop();
      personImage = fileName + "." + fileExtensions; */
    }
    
    let user_person_irefu = "";
    let user_person_firstName = "";
    let user_person_lastName = "";    
    let user_person_email = "";    
    let user_person_userType = ""; 
    let user_person_userStatus = 9;    
    let user_person_forgotPasswordFlag = "";
    let user_person_prRequestedOn = "";
    let user_person_forgotPasswordKey = "";
    if ( this.state.person.user_person ) {
      let user_person = this.state.person.user_person;
      if ( user_person.iref ) user_person_irefu = "User ref: " + user_person.iref;      
      if ( user_person.first_name ) user_person_firstName = user_person.first_name;
      if ( user_person.last_name ) user_person_lastName = user_person.last_name;
      if ( user_person.email ) user_person_email = user_person.email;

      if ( user_person.user_type ) user_person_userType = user_person.user_type;
      if ( user_person.user_status ) user_person_userStatus = user_person.user_status;
      if ( user_person.forgotPasswordFlag === true ) {
        //user_person_forgotPasswordFlag = "";
        if ( user_person.prRequestedOn ) {
          let d = new Date ( user_person.prRequestedOn );
          user_person_prRequestedOn = "Password reset requested: " + d.toLocaleDateString("ro") + " " + d.toLocaleTimeString("ro");
        }
        if ( user_person.forgotPasswordKey ) user_person_forgotPasswordKey = "Key: " + user_person.forgotPasswordKey;      
      }
    }

    let user_status_image = noImage;
    let user_status_alt = "Unknown"
    switch ( user_person_userStatus ) {
      case 0:
        user_status_image = registered;
        user_status_alt = "Registered";
        break;      
      case 1:
        user_status_image = active;
        user_status_alt = "Active";
        break;
      case 2:
        user_status_image = discontinued;
        user_status_alt = "Discontinued";
        break;
      case 9:
        user_status_image = noUser_on;
        user_status_alt = "No User";
        
    }

    if ( user_person_userType === null ||  user_person_userType === "" )
      user_person_userType = 0;
    let userType = BackOfficeConfig.USER_TYPES [ user_person_userType ];

    let personAddress = {}
    let personAddress_name = "";
    let personAddress_postalCode = "";
    let personAddress_street = "";
    let personAddress_number = "";
    let personAddress_entrance = "";
    let personAddress_local = "";
    let personAddress_floor = "";
    let personAddress_complement = "";
    let personAddress_gps_lt = "";
    let personAddress_gps_lg = "";
    let personAddress_city = "";
    let personAddress_county = "";
    let personAddress_country = "";


    if ( this.state.person.address ) {
        personAddress = this.state.person.address;
        if ( personAddress.name ) personAddress_name = personAddress.name;
        if ( personAddress.postalCode ) personAddress_postalCode = personAddress.postalCode;
        if ( personAddress.street ) personAddress_street = personAddress.street;
        if ( personAddress.number ) personAddress_number = personAddress.number;
        if ( personAddress.entrance ) personAddress_entrance = personAddress.entrance;
        if ( personAddress.local ) personAddress_local = personAddress.local;
        if ( personAddress.floor ) personAddress_floor = personAddress.floor;
        if ( personAddress.complement ) personAddress_complement = personAddress.complement;
        if ( personAddress.gps_lt ) personAddress_gps_lt = personAddress.gps_lt;
        if ( personAddress.gps_lg ) personAddress_gps_lg = personAddress.gps_lg;
        /* if ( personAddress.city ) personAddress_city = personAddress.city.name;
        if ( personAddress.city.county ) personAddress_county = personAddress.city.county.name;
        if ( personAddress.city.county.country ) personAddress_country = personAddress.city.county.country.name; */
    }

    let companiesSearch = [ ];
    if ( this.state.companiesSearch ) companiesSearch = this.state.companiesSearch;
    let usersSearch = [ ];
    if ( this.state.usersSearch ) usersSearch = this.state.usersSearch;

    let subHeader = "";
    if ( this.state.person ) {
      if ( this.state.person.company ) {
        let comp = this.state.person.company;
        subHeader = comp.iref + " - " + comp.cuid + " - " + comp.name + " (" + comp.ruid + ", " + comp.email + ")";
      }
    }

    let countriesValue = "";
    let countries = {};
    if ( this.props.countries ) countries = this.props.countries;
    if ( Object.keys(countries).length === 1 ) countriesValue = Object.keys(countries)[0];

    let counties = {};
    let countiesObj = {};
    if ( this.state.counties ) countiesObj = this.state.counties;
    Object.keys(countiesObj).forEach(key => {
      let fData = countiesObj [ key ];
      counties [ fData.iref ] = fData.name;      
    });

    let cities = {};
    let citiesObj = {};
    if ( this.state.cities ) citiesObj = this.state.cities;
    Object.keys(citiesObj).forEach(key => {
      let fData = citiesObj [ key ];
      cities [ fData.iref ] = fData.name;      
    });

    let address_country = "Country";
    let address_country_select = "";    
    let address_county = "County";
    let address_county_select = "";
    let address_city = "City";
    let address_city_select = "";
    if ( this.state.person ) {
      if ( this.state.person.address ) {
        let uAddress = this.state.person.address;
        if ( uAddress.city ) {
          address_city_select = uAddress.city.iref;
          address_city = uAddress.city.name;
          if ( uAddress.city.county ) {
            address_county_select = uAddress.city.county.iref;
            address_county = uAddress.city.county.name;
            if ( uAddress.city.county.country ) {
              address_country_select = uAddress.city.county.country.iref;
              address_country = uAddress.city.county.country.name;
            }
          }
        }        
      }
    }

    let kImage = {
      data: personImage,
      height: 128
    };

    let hasCompany = false;
    if ( this.state.person.company ) hasCompany = true;
    let hasUser = false;    
    if ( this.state.person.user_person && 'iref' in this.state.person.user_person ) hasUser = true;

    let genderIcon = noImage;
    let genderName = "Undefined";
    let genders = BackOfficeConfig.GENDER_TYPES_WITH_ICONS;
    if ( gender !== undefined ) {
      if (gender == 0 || gender == 1 || gender == 2 || gender == 9) {
        genderIcon = BackOfficeConfig.GENDER_TYPES_WITH_ICONS [ gender ].image;
        genderName = BackOfficeConfig.GENDER_TYPES_WITH_ICONS [ gender ].name;
      } else {
        genderIcon = noImage;
        genderName = "Unknown";
      }
    }

    //console.log ( genderIcon );

    return (
      /* , display: 'flex' */
    <Card sx={{ borderRadius: '16px', m: 1 }}>

      <Box sx={{ display: 'block', flexDirection: 'row', width: "100%" }}>
        <CardHeader
          avatar={
            <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%" }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                <Avatar alt={user_status_alt} src={user_status_image} title={user_status_alt}>
                </Avatar>  
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
              <Typography variant="subtitle1" color="text.secondary">
                {iref}
              </Typography>
            </Box>
          </Box>
                    
          }        
          action={            
            <IconButton aria-label="settings" onClick={this.handleEditClick}>
              <EditIcon
                sx={{ color: this.state.edit ? "#8f241d" : "#e3e3e5" }}                
              />
            </IconButton>
          }
          title={personFullName}          
          titleTypographyProps={{variant:'h5' }}
          subheader={subHeader}
        />
        
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%" }}>

        <Box sx={{ display: 'block', flexDirection: 'column', width: '20%'}}>
          <KImage
            edit = {this.state.edit}
            image = {kImage}
            imageText = {personFullName}
            saveBack = {this.getImage}
          />
          {/* <CardMedia
            component="img"
            height="120"
            sx={{ width: 128, marginLeft: 1 }}
            image={personImage}
            alt={personFullName}
          /> */}


        </Box>
        
        <Box sx={{ flexDirection: 'column', display: "block", width: '30%', textAlign: "start", justifyContent:"left", marginLeft: "10px" }}>

          <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "start", justifyContent:"left" }}>
            <CardContent sx={{display: 'inline-block', paddingBottom: "1px", padding: "1px", textAlign: "start", justifyContent:"left"}}>
              
              { this.state.edit ?
                <TextField
                  id="c_firstName"
                  label="First Name"
                  value={firstName || ''}
                  variant="outlined"
                  sx= {{ }}
                  onChange={this.changeValue}
                  onFocus={event => { event.target.select(); }}
                  inputProps={{maxLength:"32"}}
                  error={this.state.firstNameValid === false}
                  required={true}
                />
                : ""
              }
              { this.state.edit ?
                <TextField
                  id="c_lastName"
                  label="Last Name"
                  value={lastName || ''}
                  variant="outlined"
                  sx= {{ marginLeft: 1 }}
                  onChange={this.changeValue}
                  onFocus={event => { event.target.select(); }}
                  inputProps={{maxLength:"32"}}
                  error={this.state.lastNameValid === false}
                  required={true}
                />
                : ""
              }
            
              { this.state.edit ?
                <Autocomplete
                  disablePortal
                  id="company"
                  name="Company"
                  options={companiesSearch}
                  sx={{ width: "460px", marginTop: 1 }}
                  onChange={this.companyChange}
                  renderInput = {
                    (params) =>
                      <TextField 
                        {...params}
                        label="Company (type keywords)"
                        onChange={this.companySearch}
                      />}
                />
                : ""
              }
              { this.state.edit ?
                <Autocomplete
                  disablePortal
                  id="user"
                  name="User"
                  options={usersSearch}
                  sx={{ width: "460px", marginTop: 1 }}
                  onChange={this.userChange}
                  renderInput = {
                    (params) =>
                      <TextField 
                        {...params}
                        label="User (type keywords)"
                        onChange={this.userSearch}
                      />}
                />
                :
                <Typography variant="body2" color="text.secondary">
                  {userType}
                </Typography>
              }              
            </CardContent>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "start", justifyContent:"left" }}>
            {/* <CardContent sx={{ padding: "0px", textAlign: "start", justifyContent:"left"}}> */}
            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: "start", justifyContent:"left", minWidth: "150px"}}>
              { this.state.edit ?
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Birthday"
                    id="birthday"
                    inputFormat="YYYY-MM-DD"
                    value={birthday}
                    onChange={this.changeBirthday}
                    renderInput={(params) => <TextField {...params} />}
                    required={true}
                    
                  />
                </LocalizationProvider>
                :
                <Typography variant="body2" color="text.secondary" sx={{marginTop: 1}}>
                  {birthday}
                </Typography>
              }
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: "end", justifyContent:"rigth", marginLeft: 1}}>
              { this.state.edit ?
                  <DropDown
                    name="Gender"
                    value={gender !== 0 ? gender : 0}
                    doptions={genders}
                    hasIcons={true}   
                    minWidth={150}
                    maxWidth={170}
                    onDark={false}
                    onChangeFunction={this.addGender}
                    sx={{marginLeft: 1}}
                  />
                  : 
                  <Avatar alt={genderName} src={genderIcon} title={genderName} sx={{height: "36px"}} />                  
              }
            </Box>              
            {/* </CardContent> */}
          </Box>
          <Box sx={{ display: '-webkit-box', flexDirection: 'row', textAlign: "start", justifyContent:"left", width: "100%", marginTop: 1 }}>
            {/* <CardContent sx={{ padding: "0px", textAlign: "start", justifyContent:"left"}}> */}
            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: "start", justifyContent:"left"}}>
              { this.state.edit && hasCompany ?
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    sx={{ color: "#b51f0b" }}
                    startIcon={<DeleteForeverIcon />}
                    onClick={this.detachCompany}
                  >
                    Detach Company
                  </Button>
                : ""
              }
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: "start", justifyContent:"left", marginLeft: "2px" }}>
              { this.state.edit && hasUser ?
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    sx={{ color: "#b51f0b" }}
                    startIcon={<PersonRemoveIcon />}
                    onClick={this.detachUser}
                  >
                    Detach User
                  </Button>
                : ""
                }
            </Box>
            {/* </CardContent> */}
          </Box>

               
        </Box>

        <Box sx={{ flexDirection: 'column', justifyContent:"right", width: '50%' }}>        
          {/* <CardActions disableSpacing sx={{display: "block", textAlign: "end"}}>
            <b>{person_firstName} {person_lastName}</b>
          </CardActions> */}
          <CardActions disableSpacing sx={{display: "block", textAlign: "end"}}>
            { this.state.edit ?
              <TextField
                id="c_email"
                label="Email"
                value={email || ''}
                variant="outlined"
                sx= {{marginTop: 1 }}
                onChange={this.changeValue}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"128"}}
                error={this.state.emailValid === false}
                required={true}
              />
              : email
            }<br/>
            { this.state.edit ?
              <TextField
                id="c_user_person_email"
                label="User email"
                value={user_person_email || ''}
                variant="outlined"
                sx= {{marginTop: 1 }}
                onChange={this.changeValue}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"128"}}
                error={this.state.emailUserValid === false}
                disabled={true}
              />
              : user_person_email
            }

          </CardActions>
          <CardActions disableSpacing sx={{display: "block", textAlign: "end"}}>
            { this.state.edit ?
              <TextField
                id="c_phone"
                label="Phone"
                value={phone || ''}
                variant="outlined"
                sx= {{marginTop: 1 }}
                onChange={this.changeValue}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"13"}}
                error={this.state.phoneValid === false}
                required={true}
              />
              : phone
            }
          </CardActions>          
          <CardActions disableSpacing sx={{display: "block", textAlign: "end"}}>
            {user_person_irefu}<br/>
            {user_person_forgotPasswordFlag}{user_person_prRequestedOn}<br/>
            {user_person_forgotPasswordKey}
          </CardActions>
        </Box>
      </Box>         
          
      <Box sx={{ flexDirection: 'row', width: '100%', display: 'flex' }}>
        <Box sx={{ flexDirection: 'column', justifyContent:"left", width: '100%' }}>
          <CardActions disableSpacing sx={{display: "flex", textAlign: "start", justifyContent:"left" }}> 
            {/* {person_email} */}
          </CardActions>
        </Box>          
        <Box sx={{ flexDirection: 'column', justifyContent:"right", width: '100%' }}>
          <CardActions disableSpacing sx={{display: "block", textAlign: "end" }}>                
        
          <ExpandMore
            expand={this.state.expanded}
            onClick={this.handleExpandClick}
            aria-expanded={this.state.expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
          </CardActions>
        </Box>
      </Box>
   


      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit sx={{width: "100% !important"}}>
        <CardContent>

          <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "start", justifyContent:"left" }}>
            { this.state.edit ?
              <TextField
                id="pA_name"
                label="Address Name"
                value={personAddress_name || ''}
                variant="outlined"
                sx= {{marginTop: 1, marginLeft: 1 }}
                onChange={this.changeAddress}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"32"}}
              />
              : <b>{personAddress_name + ", "}</b>
            }
            { this.state.edit ?
              <TextField
                id="pA_complement"
                label="Address Complement"
                value={personAddress_complement || ''}
                variant="outlined"
                sx= {{marginTop: 1, marginLeft: 1 }}
                onChange={this.changeAddress}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"64"}}
              />
              : <b>{personAddress_complement}</b>
            }
            <br/>
          {/* </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}> */}
          
            { this.state.edit ?
              <TextField
                id="pA_street"
                label="Street"
                value={personAddress_street || ''}
                variant="outlined"
                sx= {{marginTop: 1, marginLeft: 1 }}
                onChange={this.changeAddress}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"128"}}
              />
              : "Str. " + personAddress_street + ", "
            }
            { this.state.edit ?
              <TextField
                id="pA_number"
                label="Number"
                value={personAddress_number || ''}
                variant="outlined"
                sx= {{marginTop: 1, marginLeft: 1, maxWidth: "15%", minWidth: "10%" }}
                onChange={this.changeAddress}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"32"}}
              />
              : "nr. " + personAddress_number + ", "
            }
            { this.state.edit ?
              <TextField
                id="pA_entrance"
                label="Entrance"
                value={personAddress_entrance || ''}
                variant="outlined"
                sx= {{marginTop: 1, marginLeft: 1, maxWidth: "15%", minWidth: "10%" }}
                onChange={this.changeAddress}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"32"}}
              />
              : "int. " + personAddress_entrance + ", "
            }
            { this.state.edit ?
              <TextField
                id="pA_local"
                label="Local"
                value={personAddress_local || ''}
                variant="outlined"
                sx= {{marginTop: 1, marginLeft: 1, maxWidth: "15%", minWidth: "10%" }}
                onChange={this.changeAddress}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"32"}}
              />
              : "loc. " + personAddress_local + ", "
            }
            { this.state.edit ?
              <TextField
                id="pA_floor"
                label="Floor"
                value={personAddress_floor || ''}
                variant="outlined"
                sx= {{marginTop: 1, marginLeft: 1, maxWidth: "15%", minWidth: "10%" }}
                onChange={this.changeAddress}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"32"}}
              />
              : "et. " + personAddress_floor + ", "
            }
            { this.state.edit ?
              <TextField
                id="pA_zip"
                label="Postal Code"
                value={personAddress_postalCode || ''}
                variant="outlined"
                sx= {{marginTop: 1, marginLeft: 1, maxWidth: "15%", minWidth: "10%" }}
                onChange={this.changeAddress}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"32"}}
              />
              : personAddress_postalCode + ", "
            }
          </Box>
          <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "start", justifyContent:"left" }}>
              { this.state.edit ?
                <DropDown
                  name="Country"
                  value={address_country_select || ''}
                  doptions={countries}
                  hasIcons={false}   
                  minWidth={120}
                  maxWidth={220}
                  onDark={false}
                  onChangeFunction={this.addCountry}
                  marginTop={8}
                  marginLeft={8}
                />
                : address_country + ", "
              }
            
              { this.state.edit ?
                <DropDown
                  name="County"
                  value={address_county_select || ''}
                  doptions={counties}
                  hasIcons={false}   
                  minWidth={120}
                  maxWidth={220}
                  onDark={false}
                  onChangeFunction={this.addCounty}
                  marginTop={8}
                  marginLeft={2}
                />
                : address_county + ", "
              }
            
              { this.state.edit ?
                <DropDown
                  name="City"
                  value={address_city_select || ''}
                  doptions={cities}
                  hasIcons={false}   
                  minWidth={100}
                  maxWidth={200}
                  onDark={false}
                  onChangeFunction={this.addCity}
                  marginTop={8}
                  marginLeft={2}
                />
                : address_city
              }
            {/* {personAddress_city}, {personAddress_county}, {personAddress_country} */}
          {/* </Box>
          <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "start", justifyContent:"left" }}>   */}
            { this.state.edit ?
              <TextField
                id="pA_gps_lg"
                label="GPS Longitude"
                value={personAddress_gps_lg || ''}
                variant="outlined"
                sx= {{marginTop: 1, marginLeft: 1 }}
                onChange={this.changeAddress}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"128"}}
                error={this.state.gpsLgValid === false}
              />
              : ", GPS LG: " + personAddress_gps_lg + ","
            }
            { this.state.edit ?
              <TextField
                id="pA_gps_lt"
                label="GPS Latitude"
                value={personAddress_gps_lt || ''}
                variant="outlined"
                sx= {{marginTop: 1, marginLeft: 1 }}
                onChange={this.changeAddress}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"128"}}
                error={this.state.gpsLtValid === false}
              />
              : "GPS LT: " + personAddress_gps_lt
            }
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "end", justifyContent:"right", alignItems: "right", paddingTop: "8px" }}>
            { this.state.edit && this.state.somethingChanged ?
              <Button
                variant="contained"
                size="small"
                sx={{ color: "#e3e3e5" }}
                startIcon={<SaveIcon />}
                onClick={this.pushData}
              >
                Save
              </Button>
            : ""
            }
            
          </Box>
          

          <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "middle", justifyContent:"center", alignItems: "center", paddingTop: "8px" }}>
            { /*<Typography>Employees</Typography>*/}
            <Table sx={{ minWidth: 700, textAlign: "middle", justifyContent:"center" }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Image</StyledTableCell>
                  <StyledTableCell align="left">First Name</StyledTableCell>
                  <StyledTableCell align="left">Last Name</StyledTableCell>            
                  <StyledTableCell align="left">Phone</StyledTableCell>            
                  <StyledTableCell align="left">Email</StyledTableCell>
                  <StyledTableCell align="left">Birthday</StyledTableCell>
                  <StyledTableCell align="left">Username</StyledTableCell>
                  <StyledTableCell align="left">Card</StyledTableCell>
                  <StyledTableCell align="left">Level</StyledTableCell>
                  {/* <StyledTableCell align="right">Action</StyledTableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {allData} */}
              </TableBody>
            </Table>
            
          </Box>
          
          </CardContent>

        </Collapse>

      </Box>
    </Card>
   
    );

  }
}

export default Person;
