import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import noImage from './images/noImage.png';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BrowserNotSupportedIcon from '@mui/icons-material/BrowserNotSupported';
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import Chip from '@mui/material/Chip';
import CardMedia from '@mui/material/CardMedia';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Autocomplete from '@mui/material/Autocomplete';
import isDecimal from 'validator/lib/isDecimal';
import swal from 'sweetalert';
import DropDown from './components/DropDown';
import KImage from './components/KImage';
import Config from './components/ClientConfig';
import Client from './components/Client';
import BackOfficeConfig from './Config';
import { isNumeric } from 'jquery';

import ClientsGroupsFilters from './components/ClientsGroupsFilters';

import Visibility from './Visibility';

function thousandsSeparator(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class SubRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      edit: false,
      row: {},
      group: "",
      category: "0",
      somethingHasChanged: false,
      nameValid: false,
      descriptionValid: false,
      memberRemoved: false,
      newRow: false,
      usersSearch: [ ],
      allowEdit: false
    };

    
    this.handleEditClick = this.handleEditClick.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.pushData = this.pushData.bind(this);
    this.removeMember = this.removeMember.bind(this);
    this.changeLevel = this.changeLevel.bind(this);
    this.clientChange = this.clientChange.bind(this);
    this.clientSearch = this.clientSearch.bind(this);
  }

  handleEditClick ( ) {
    if (!this.state.allowEdit)
      return;
    let cEdit = !this.state.edit;
    this.setState({ edit: !this.state.edit });
    if ( !cEdit ) this.setState ( { memberRemoved: false } );
  }

  componentDidMount() {
    if (this.props)
      if (this.props.allowEdit ===  true) {
        this.setState ({allowEdit: true});
      }
    let row = {};
    if ( this.props.row ) {
      row = this.props.row;
      this.setState ( { row: row } );
    }
    if ( this.props.edit ) this.setState ( { edit: true } );
    if ( this.props.group ) {
      let group = this.props.group;
      this.setState ( { group: group } );
    }
    if ( this.props.newRow ) {
      this.setState ( { newRow: this.props.newRow } );
    }
  }

  componentDidUpdate ( oldProps ) {
    if ( this.props != oldProps ) {
      if (this.props)
        if (this.props.allowEdit ===  true) {
          this.setState ({allowEdit: true});
        }
      let row = {};
      if ( this.props.row ) {
        row = this.props.row;
        this.setState ( { row: row } );
      }
      if ( this.props.edit ) this.setState ( { edit: true } );
      if ( this.props.group ) {
        let group = this.props.group;
        this.setState ( { group: group } );
      }
      if ( this.props.newRow ) {
        this.setState ( { newRow: this.props.newRow } );
      }
    }
  }

  changeValue ( e ) {
    let id = null;
    if ( e.target.id !== null ) id = e.target.id;
    let value = null;
    if  ( e.target.value !== null ) value = e.target.value;
    //if ( id !== null && value !== null && value !== undefined ) {
    if ( id ) {
      let row = {};
      if ( this.state.row ) row = this.state.row;
      switch ( id ) {
        /* case "name":
          if ( value.length > 0 ) {
            row.name = value;
            this.setState ( { nameValid: true });
          } else {
            row.name = "";
            this.setState ( { nameValid: false });
          }
          this.setState ( { somethingHasChanged: true } );
          break;
        case "description":
          if ( value.length > 0 ) {
            row.description = value;
            this.setState ( { descriptionValid: true });
          } else {
            row.description = "";
            this.setState ( { descriptionValid: false });
          }
          this.setState ( { somethingHasChanged: true } );
          break; */
        case "canUseCompanyCredit":            
            row.canUseCompanyCredit = !row.canUseCompanyCredit;
            this.setState ( { somethingHasChanged: true } );
          break;
      }
      this.setState ( { row: row });
    }
  }

  pushData ( e ) {
    if ( this.state.somethingHasChanged ) {
      let qData = {
        "doRemove": this.state.memberRemoved,
        "clientPro": this.state.group,
        "member": this.state.row,        
      };
      let rdata = this.state.client.pushData ( Config.API_URL_CLIENT_PRO_ALTERMEMBER, qData );
      if ( rdata ) {
        if ( rdata.status == "OK" ) {
         /*  let rData = this.state.row;
          rData.iref = rdata.iref; */
          //this.setState ( { row: rData } );
          this.props.rowModified ( );
          this.handleEditClick ( );
          swal ( "Success", "Successfully added/modified member ! (" + rdata.iref + ")", "success", {
            buttons: false,
            timer: 2000,
          })
          .then((value) => {
            //this.props.personUpdated ( );
            //this.props.rowModified ();
            this.setState ( {edit: false} );
            this.setState ( { somethingHasChanged: false } );
            //this.setState ( { addSubCategory: false } );
          });        
        } else {
          if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
          swal ( "Error", "Add/Update member failed !\n\n" + rdata.message, "error" );
        }
      }
    } else {
      swal ( "Error", "Add/Update member failed ! Some fields are not validated !", "error" );
    }
  }

  removeMember ( ) {
    if (!this.state.allowEdit)
      return;
    this.setState ( { memberRemoved: true } );
    this.setState ( { somethingHasChanged: true } );
  }

  changeLevel ( e ) {
    let uRow = this.state.row;
    uRow.level = parseInt ( e );
    this.setState ( { row: uRow } );
    this.setState ( { somethingHasChanged: true } );
  }

  clientSearch ( e ) {
    if ( e.target.value.length >= 3 ) {
      let stringLength = e.target.value.length;
      let limit = (stringLength - 2) * 20;
      let queryData = {
        "nameLike" : e.target.value,
        "notLinked_pro": true,
        "limit": limit
      };
      let data = this.state.client.filterClients ( queryData );
      let newClients = [];
      //let users = [];
      if ( data.clients.length > 0 ) {
        Object.keys(data.clients).forEach(key => {
          let fData = data.clients [ key ];
          let lineText = fData.iref;
          if ( fData.user ) lineText += " - " + "[" + fData.user.username + "] - " + fData.user.first_name + " " + fData.user.last_name;
          let newObj = { key: fData.iref, label: lineText };
          newClients.push ( newObj );
          //users.push ( fData );
          //counties [ fData.iref ] = fData.name;      
        });        
        this.setState ( { usersSearch: newClients } );
      }
    }
  }

  clientChange ( e ) {
    let text = e.target.textContent;
    let repIref = text.substring ( 0, text.indexOf(" - ") );
    let queryData = { iref: repIref };
    //let data = this.state.client.getData ( Config.USER_API_URL + repIref, queryData );
    let data = this.state.client.getData ( Config.API_URL_CLIENT + repIref, queryData );

    let selectedClient = {};
    if ( data ) {
      selectedClient = data;
    }
    let uRow = this.state.row;
    if ( selectedClient ) {
      if ( this.props.newRow ) uRow [ "client"] = {};
      uRow.client = selectedClient;
      this.setState ( { row: uRow } );    
      this.setState ( { somethingChanged: true } );
    }    
  }

  render() {
    let key = "";
    let iref = "";
    let firstName = "";
    let lastName = "";
    let country = "";
    let county = "";
    let city = "";
    let credit = "";
    let level = "";
    let levelText = "";
    let email = "";
    let canUseCompanyCredit = false;
  
    let img = noImage;

    if ( this.state.row ) {
        let rowC = this.state.row;
        if ( rowC.level !== undefined ){
          level = rowC.level;
          levelText = BackOfficeConfig.COMPANY_LEVELS_DEF [ rowC.level ];
        }
        if ( rowC.canUseCompanyCredit !== undefined ) canUseCompanyCredit = rowC.canUseCompanyCredit;
        if ( rowC.client ) {          
          let row = rowC.client;
          //if ( row.sckey ) key = row.sckey;
          if ( row.iref ) iref = row.iref;
          if ( row.credit !== undefined) credit = thousandsSeparator ( row.credit );
          if ( row.user ) {
            firstName = row.user.first_name;
            lastName = row.user.last_name;
            email = row.user.email;
            if ( row.user.person ) {
              let person = row.user.person;
              if ( person.image ) img = person.image;
              if ( person.address ) {
                let address = person.address;
                if ( address.city ) {
                  city = address.city.name;
                  if ( address.city.county ) {
                    county = address.city.county.name;
                    if ( address.city.county.country ) country = address.city.county.country.name;
                  }
                }
              }
            }
          }
        } 
    }    
    let imageName = "";

    let showSave = false;
    if ( this.state.edit === true && this.state.somethingHasChanged === true )
      showSave = true;

    let memberStatusColor = "#eeeee4";
    if ( this.state.memberRemoved ) memberStatusColor = "#e5cbcd";

    let levels = BackOfficeConfig.COMPANY_LEVELS_DEF;

    let usersSearch = [ ];
    if ( this.state.usersSearch !== undefined ) usersSearch = this.state.usersSearch;

    key = iref;
    if ( iref.length == 0 ) key = (Math.random() + 1).toString(36).substring(10);
    
    return (
      <React.Fragment>
        <TableRow key={key} sx={{ backgroundColor : memberStatusColor }} >
          <TableCell scope="row">
            {this.state.edit && this.state.newRow ?
              <Autocomplete
                disablePortal={true}
                id="user"
                name="User"
                options={usersSearch}
                sx={{ width: "250px", marginTop: 0 }}
                onChange={this.clientChange}
                renderInput = {
                  (params) => (
                    <TextField 
                      {...params}
                      label="User (type keywords)"
                      onChange={this.clientSearch}
                    />)}
              />
            : iref
            }
          </TableCell>
          <TableCell>            
           <CardMedia
              component="img"
              height="64"
              sx={{ width: "64", marginLeft: 1 }}
              image={img}
              alt={imageName}
            />
          </TableCell>
          <TableCell scope="row">{firstName}</TableCell>
          <TableCell scope="row">{lastName}</TableCell>
          <TableCell scope="row">{country}</TableCell>
          <TableCell scope="row">{county}</TableCell>
          <TableCell scope="row">{city}</TableCell>
          <TableCell scope="row">
            <Chip label={credit} color="success" sx={{ width: "100px"}}/>            
          </TableCell>
          <TableCell scope="row">
            {this.state.edit ?
              <DropDown
                name="Level"
                value={level !== 0 ? level : 0}
                doptions={levels}
                hasIcons={false}   
                minWidth={100}
                maxWidth={120}
                onDark={false}
                onChangeFunction={this.changeLevel}
                sx={{marginLeft: 1}}
              />
              :
              levelText
            }
          </TableCell>  

          <TableCell align="left">
            {this.state.edit ?
              <Checkbox id="canUseCompanyCredit" color="primary" checked={canUseCompanyCredit} onChange={this.changeValue}/>
              :
              <Checkbox  color="primary" checked={canUseCompanyCredit} disabled/>
            }
          </TableCell>


          <TableCell align="right">
            <IconButton aria-label="settings" onClick={this.handleEditClick}>
                <EditIcon
                  sx={{ color: this.state.edit ? "#8f241d" : "#e3e3e5" }}                  
               />
            </IconButton>
            {this.state.edit && this.state.memberRemoved === false ?
              <IconButton title="Remove member" aria-label="settings" onClick={this.removeMember}>
                <RemoveCircleOutlineIcon
                  sx={{ color: "#8f241d" }}                  
                />
              </IconButton>
            :
            ""
            }
            {showSave ?
              <IconButton aria-label="settings" onClick={this.pushData}>
                <SaveIcon
                  sx={{ color: "#4287f5" }}                
                />
              </IconButton>
              : ""}
          </TableCell>
        </TableRow>
       

      </React.Fragment>
      
    );

  }
}

  class Row extends React.Component {

    constructor(props) {
      super(props);
  
      this.state = {
        client: new Client ( "none" ),  
        categoriesWithSubCategories: null, 
        edit: false,   
        opened: false,
        row: {},
        members: [],
        nameValid: false,
        creditValid: false,
        somethingHasChanged: false,
        addNewRow: false,
        groupRemoved: false,
        companiesSearch: [],
        allowEdit: false
      };
  
      this.toggleAdd = this.toggleAdd.bind(this);
      this.handleEditClick = this.handleEditClick.bind(this);
      this.handleOpen = this.handleOpen.bind(this);
      this.getImage = this.getImage.bind(this);
      this.changeValue = this.changeValue.bind(this);
      this.pushData = this.pushData.bind(this);
      this.changeOperation = this.changeOperation.bind(this);
      this.rowModified = this.rowModified.bind(this);
      this.removeGroup = this.removeGroup.bind(this);
      this.companyChange = this.companyChange.bind(this);
      this.companySearch = this.companySearch.bind(this);
    }

    handleOpen ( ) {
      let opened = this.state.opened;
      this.setState ( { opened: !opened } );
    }

    handleEditClick ( ) {
      if (!this.state.allowEdit)
        return;
      let cEdit = !this.state.edit;
      this.setState({ edit: !this.state.edit });
    }
    
    getImage ( newImage ) {
      if ( newImage ) {
        if ( this.state.row ) {
          let nRow = this.state.row;
          nRow.image = newImage;
          this.setState ( { row: nRow }, this.setState ( { somethingHasChanged: true } ) );
        }
      }
    }

    changeValue ( e ) {
      let id = null;
      if ( e.target.id !== null ) id = e.target.id;
      let value = null;
      if  ( e.target.value !== null ) value = e.target.value;
      //if ( id !== null && value !== null && value !== undefined ) {
      if ( id ) {
        let row = {};
        if ( this.state.row ) row = this.state.row;
        switch ( id ) {
          case "name":
            if ( value.length > 0 ) {
              row.name = value;
              this.setState ( { nameValid: true });
            } else {
              row.name = "";
              this.setState ( { nameValid: false });
            }
            this.setState ( { somethingHasChanged: true } );
            break;
          case "credit":
            if ( isDecimal ( value.replace ( ',', '') ) ) {
              row.credit = value;
              this.setState ( { creditValid: true });
            } else {
              row.credit = "";
              this.setState ( { creditValid: false });
            }
            this.setState ( { somethingHasChanged: true } );
            break;
        }
        this.setState ( { row: row });
      }
    }

    componentDidMount() {
      let row = {};
      if ( this.props.row ) {
        row = this.props.row;
        if (row.allowEdit === true)
          this.setState ({allowEdit: true});
        this.setState ( { row: row } );
        if ( row.name.length > 0 ) this.setState ( {nameValid: true } );
        if ( isDecimal ( String(row.credit) )) this.setState ( {creditValid: true } );
      }
      if ( this.props.edit ) this.setState ( { edit: true } );
    }

    toggleAdd ( ) {
      if (!this.state.allowEdit)
        return;
      let addButton = this.state.addNewRow;
      this.setState ( {addNewRow: !addButton} );
      //this.props.toggleAdd ( !addButton );
    }

    removeGroup ( ) {
      this.setState ( { groupRemoved: true } );
      this.setState ( { somethingHasChanged: true } );
    }

    pushData ( e ) {
      let qData = {
        "doRemove": this.state.groupRemoved,
        "clientPro": this.state.row,       
      };
      if ( this.state.somethingHasChanged && this.state.nameValid ) {
        let rdata = this.state.client.pushData ( Config.API_URL_CLIENT_PRO_ALTER, qData );
        if ( rdata ) {
          if ( rdata.status == "OK" ) {
            let uRow = this.state.row;
            uRow.iref = rdata.iref;
            this.setState ( { row: uRow } );
            this.handleEditClick ( );
            swal ( "Success", "Successfully added/modified clientPro ! (" + rdata.iref + ")", "success", {
              buttons: false,
              timer: 2000,
            })
            .then((value) => {
              //this.props.personUpdated ( );
              this.setState ( {edit: false} );
              this.setState ( { somethingHasChanged: false } );
              this.props.newAdded ();
            });        
          } else {
            if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
            swal ( "Error", "Add/Update clientPro failed !\n\n" + rdata.message, "error" );
          }
        }
      } else {
        swal ( "Error", "Add/Update clientPro failed ! Some fields are not validated !", "error" );
      }
    }

    changeOperation ( value ) {
      if ( value !== undefined ) {
        let uRow = this.state.row;
        uRow.operation = value;
        this.setState ( { row: uRow } );
        this.setState ( { somethingHasChanged: true } );
      }
    }

    rowModified ( ) {
      //this.props.getData ( );
      let fIref = this.state.row.iref;
      this.setState ( { row: {} });
      let queryData = {};
      queryData [ "orderBy_Name" ] = true;
      let data = this.state.client.getData ( Config.API_URL_CLIENT_PRO + fIref, queryData );
      this.setState ( { row: data });
      this.setState ( {addNewRow: false } );
    }

    companySearch ( e ) {
      if ( e.target.value.length > 3 ) {
        let queryData = {
          "nameLike" : e.target.value,
          "notLinked_company": true
        };
        let data = this.state.client.filterCompanies ( queryData );
        let newCompanies = [];
        let companies = [];
        Object.keys(data.Companies).forEach(key => {
          let fData = data.Companies [ key ];
          let lineText = fData.iref + " - " + fData.cuid + " - " + fData.name + " (" + fData.ruid + ", " + fData.email + ")";
          let newObj = { key: fData.iref, label: lineText };
          newCompanies.push ( newObj );
          companies.push ( fData );
          //counties [ fData.iref ] = fData.name;      
        });
  
        this.setState ( { companiesSearch: newCompanies });
      }
    }
  
    companyChange ( e ) {
      let text = e.target.textContent;
      let repIref = text.substring ( 0, text.indexOf(" - ") );
      let queryData = { };
      let data = this.state.client.getData ( Config.API_URL_COMPANY + repIref, queryData );
      let uRow = this.state.row;
      uRow.company = data;
      this.setState ( { person: uRow } );    
      this.setState ( { somethingHasChanged: true } );
    }

    render() {
      let row = {};
      let members = [];
      let rowIref = "";
      let image = noImage;
      let name = "";
      let credit = "";
      let creditChip = "";
      let membersCredit = "";
      let companyName = "";

      if ( this.state.row ) {
        row = this.state.row;
        if ( row.iref ) rowIref = row.iref;
        if ( row.name ) name = row.name;
        if ( row.image ) image = row.image;
        if ( row.credit !== undefined) {
          credit = row.credit;
          creditChip = thousandsSeparator ( row.credit );
        }
        if ( row.company ) {
          companyName = row.company.iref + " [" + row.company.cuid + ", " + row.company.ruid + "] " + row.company.email; 
        }
        if ( row.clientProfessional_pro !== undefined ) {
          members = row.clientProfessional_pro;
          membersCredit = 0.0;
          members.map((scData) => {
            if ( scData.client ) {
              if ( scData.client.credit !== undefined ) membersCredit += parseFloat ( scData.client.credit );
            }
          });
          membersCredit = thousandsSeparator ( membersCredit );
        }
      }
      let kImage = {
        data: image,
        height: 64,
        width: 64
      };
      let imageName = name + "_on";
  
      let showSave = false;
      if ( this.state.edit === true && this.state.somethingHasChanged === true && this.state.nameValid === true )
        showSave = true;

      let key = rowIref;

      let companiesSearch = [ ];
      if ( this.state.companiesSearch ) companiesSearch = this.state.companiesSearch;
      
      let groupStatusColor = "#ffffff";
      if ( this.state.groupRemoved ) groupStatusColor = "#e5cbcd";

    return (
      <React.Fragment>
        <TableRow key={key} sx={{ '& > *': { borderBottom: 'unset' }, backgroundColor : groupStatusColor }}>

          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => this.handleOpen()}
            >
              {this.state.opened ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
            </IconButton>
            {rowIref}
          </TableCell>

          <TableCell align="left">
            <KImage
              edit = {this.state.edit}
              image = {kImage}
              imageText = {imageName}
              saveBack = {this.getImage}
           />
          </TableCell>

          {/*  */}

          <TableCell component="th" scope="row">
            {this.state.edit ?
              <TextField
                id="name"
                label="Name"
                variant="outlined"
                value={name || ''}
                onChange={this.changeValue}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"100"}}
                error={this.state.nameValid === false}
                required={true}
                sx={{minWidth: "100px"}}
              />
              : name
            }
          </TableCell>
          <TableCell component="th" scope="row">
            {this.state.edit ?
              <Autocomplete
                disablePortal
                id="company"
                name="Company"
                options={companiesSearch}
                sx={{ width: "250px", marginTop: 1 }}
                onChange={this.companyChange}
                renderInput = {
                  (params) =>
                    <TextField 
                      {...params}
                      label="Company (type keywords)"
                      onChange={this.companySearch}
                    />}
              />
              : companyName
            }
          </TableCell>


            {/* <TextField
                id="credit"
                label="Credit"
                variant="outlined"
                value={credit || ''}
                onChange={this.changeValue}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"255"}}
                error={this.state.creditValid === false}
                required={true}
             /> */}

          <TableCell align="left">
            {this.state.edit ?              
                <div>
                  <Chip label={creditChip} color="error" sx={{ width: "100px"}}/>
                  <Chip label={membersCredit} color="success" sx={{ width: "100px"}}/>
                </div>

              :
                <div>
                  <Chip label={creditChip} color="error" sx={{ width: "100px"}}/>
                  <Chip label={membersCredit} color="success" sx={{ width: "100px"}}/>
                </div>
            }
          </TableCell>

          

          <TableCell align="right">
            <IconButton aria-label="settings" onClick={this.handleEditClick}>
                <EditIcon
                  sx={{ color: this.state.edit ? "#8f241d" : "#e3e3e5" }}                  
               />
            </IconButton>
            {this.state.edit && this.state.groupRemoved === false ?
              <IconButton title="Remove group" aria-label="settings" onClick={this.removeGroup}>
                <RemoveCircleOutlineIcon
                  sx={{ color: "#8f241d" }}                  
                />
              </IconButton>
            :
            ""
            }
            {showSave ?
              <IconButton aria-label="settings" onClick={this.pushData}>
                <SaveIcon
                  sx={{ color: "#4287f5" }}                
                />
            </IconButton>
            : ""}
          </TableCell>
        </TableRow>
        <TableRow key={key + "_newSubRow"}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={this.state.opened} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="body1" gutterBottom component="div">
                  Members
                </Typography>
                <Table key={key + "_newSubRowTT"} size="small" aria-label="purchases" sx={{backgroundColor: "#eeeee4"}}>
                  <TableHead key={key + "_newSubRowH"} sx={{backgroundColor: "#ddddd4"}}>
                    <TableRow key={key + "_newSubRowT"}>
                      <TableCell align="left">
                        <IconButton aria-label="Add Location" title="Add Location" onClick={() => this.toggleAdd()}>       
                          <Avatar alt="Add Location" sx={{ width: 24, height: 24, color: "#273442" }}>
                            {this.state.addNewRow ? <FaMinusCircle/> : <FaPlusCircle/>}
                          </Avatar>
                        </IconButton>
                      </TableCell>
                      <TableCell align="left">Image</TableCell>
                      <TableCell align="left">First Name</TableCell>
                      <TableCell align="left">Last Name</TableCell>
                      <TableCell align="left">Country</TableCell>
                      <TableCell align="left">County</TableCell>
                      <TableCell align="left">City</TableCell>
                      <TableCell align="left">Credit</TableCell>
                      <TableCell align="left">Level</TableCell>
                      <TableCell align="left">Can Use Company Credit</TableCell>                      
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody key={key}>
                    {this.state.addNewRow ?
                      <SubRow key={0} group={rowIref} edit={true} rowModified={this.rowModified} newRow={true}/>
                      : ""
                    }
                    {members.map((scData) => (
                      <SubRow
                        key = {scData.iref}
                        row = {scData}
                        group = {rowIref}
                        rowModified={this.rowModified}
                        newRow={false}
                      >
                      </SubRow>
                    ))}
                  </TableBody>

                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
}

class ClientsProfessionals extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),  
      mainData: null,
      addNew: false
    };

    this.getData = this.getData.bind(this);
    this.toggleAdd = this.toggleAdd.bind(this);
    this.newAdded = this.newAdded.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.restricted = new Visibility ("clients_professional");
  }

  componentDidMount() {
    if (this.restricted.scope.visible)
      this.getData ( );
  }

  toggleAdd ( ) {
    let addButton = this.state.addNew;
    this.setState ( {addNew: !addButton} );
    //this.props.toggleAdd ( !addButton );
  }

  getData ( ) {
    let queryData = {};
    queryData [ "limit" ] = 20;
    queryData [ "orderBy_Modified" ] = true;
    //queryData [ "orderBy_Name" ] = true;
    let mainData = this.state.client.getData ( Config.API_URL_CLIENT_PRO, queryData );
    this.setState ( { mainData: mainData.clientFamilies });
  }

  applyFilters ( queryData ) {
    this.setState ( { mainData: [] });
    if ( queryData ) {      
      queryData [ "limit" ] = 20;
      queryData [ "orderBy_Modified" ] = true;
      //queryData [ "orderBy_Name" ] = true;
      let data = this.state.client.getData ( Config.API_URL_CLIENT_PRO, queryData );
      this.setState ( { mainData: data.clientFamilies });
      this.setState ( { queryData: queryData } );
    }
  }

  newAdded ( ) {
    this.getData ( );
    this.setState ( { addNew: false } );
  }

  render() {
    if (!this.restricted.scope.visible)
      return (<div></div>);
    let rowsCounter = 0;
    if ( this.state.mainData ) {
      rowsCounter = this.state.mainData.length;
    }

    let queryData = { };
    if ( this.state.queryData ) queryData = this.state.queryData;


    return (
      <div style={{ backgroundColor: "#273442", height: "100%" }}>
        <div className='sticky'>

          <ClientsGroupsFilters
            applyFilters = {this.applyFilters}            
            toggleAdd = {this.toggleAdd}
            rowsCounter = {rowsCounter}
            queryData = {queryData}
            sx={{ position: 'sticky', top: 0}}
            addNew = {this.state.addNew}
            allowEdit={this.restricted.scope.edit}
          />

        </div>


        <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead sx={{backgroundColor: "#273442", color: "#FFFFFF !important" }}>
            <TableRow>
              <TableCell>
                {/* <IconButton aria-label="Add" title="Add" onClick={() => this.toggleAdd()}>       
                  <Avatar alt="Add" sx={{ width: 24, height: 24, color: "#273442" }}>
                    {this.state.addNew ? <FaMinusCircle/> : <FaPlusCircle/>}
                  </Avatar>
                </IconButton> */}
              </TableCell>
              <TableCell sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Image</TableCell>
              <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Name</TableCell>
              <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Company</TableCell>
              <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Credit</TableCell>          
              <TableCell align="right" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {this.state.addNew ?
            <Row key={0} edit={true} newAdded={this.newAdded} allowEdit={this.restricted.scope.edit}/>
            : ""
            }
            {this.state.mainData ? 
              this.state.mainData.map((row) => (
              <Row key={row.iref} row={row} newAdded={this.newAdded} allowEdit={this.restricted.scope.edit}/>
              ))
              :
              ""
            }
          </TableBody>
        </Table>
      </TableContainer>

    </div>
    );
  }
}

export default ClientsProfessionals;
