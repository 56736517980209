import React from 'react';
import { Box, Card, Paper, TableContainer, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
// import { format, intervalToDuration } from 'date-fns';
import Client from '../Client';
import Config from '../ClientConfig';

class OverviewPosOffline extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      saleslocation_list: [],
      page: 0,
      rowsPerPage: 14,
      sx_hidden: {display: 'none'},
      value: "1",
      filterData: {}
    };

    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);

  }

  componentDidMount() {
    this.getData ( );
  }

  getData ( ) {
    let queryData = { 
      "type": 33,
  }
	  let data = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, queryData );
    if ( data ) {
      this.setState ( { saleslocation_list: data });
    }
    this.setState ( { filterData: queryData } );
  }

  doSync ( ) {
    this.getData ( );
  }

  handleChangePage = (event, newPage) => {
    this.setState ({page: newPage});
  }
  
  handleChangeRowsPerPage = (event) => {
    this.setState ({rowsPerPage: +event.target.value});
    this.setState ({page: 0});
  }
  
  render() {
    let page = this.state.page;
    let rowsPerPage = this.state.rowsPerPage;
    let locationSalesList = [];
    if (this.state.saleslocation_list){
      if (this.state.saleslocation_list.pos) {
        Object.keys(this.state.saleslocation_list.pos).forEach(key => {
          let fData = this.state.saleslocation_list.pos[key];
          let p = {
            name: fData.name,
            lastOnline: new Date(Date.parse(fData.lastSeenOnline)).toLocaleString('ro-RO')
          }
          locationSalesList.push ( p );
        });
      }
    }

    return (
      <Paper sx={{ width: '100%', borderRadius:"16px", overflow: 'hidden' }}>
      <Card style={{ height: '640px' }} sx={{ borderRadius:"16px", height: '100%' }}>
       {/* <CardHeader title="Last Orders" /> */}
          <Box sx={{ paddingLeft: "16px", paddingRight: "16px"}}>
            <TableContainer sx={{ height: '500px' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>         
                  <TableCell style={{padding: "6px", textAlign: "center" }}>
                    <b>POS OFFLINE</b>
                  </TableCell>
                  <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "center" }}>
                    <b>Last Online</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {locationSalesList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((location) => {
                  return (
                    <TableRow
                      hover
                      key={location.name}
                      tabIndex={-1}
                    >
                      <TableCell style={{padding: "6px", paddingRight: "16px", paddingLeft: "20px", textAlign: "left" }}>
                          {location.name}
                      </TableCell>
                      <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "center" }}>
                          {location.lastOnline}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[14, { value: locationSalesList.length, label: 'All' }]}
              labelRowsPerPage="Rows:"
              // showFirstButton="True"
              // showLastButton="True"
              component="div"
              count={locationSalesList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </Box>
      </Card>
      </Paper>
    );

  }
}

export default OverviewPosOffline;
