import React from 'react';
import { Avatar, createTheme, Box, Card, Button, TextField, MenuItem, Table, TableContainer, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ViewListIcon from '@mui/icons-material/ViewList';
import BarChartIcon from '@mui/icons-material/BarChart';
// import TableChartIcon from '@mui/icons-material/TableChart';
import { format } from 'date-fns';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import Chart from './chart';

import Client from '../Client';
import Config from '../ClientConfig';

let theme = createTheme();

function thousandsSeparator(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  result = format(result, 'yyyy-MM-dd');
  return result;
}

let d = new Date();
let today = format(d, 'yyyy-MM-dd');  // mm=week, MM=month
let yesterday = format(new Date().setDate(new Date().getDate() - 1), 'yyyy-MM-dd');
let d1Month = format(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'yyyy-MM-dd'); //beginning of the month
// let dzMonth = format(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), 'yyyy-MM-dd'); //end of the month
// let d1Year = format(new Date(new Date().getFullYear(), 0, 1), 'yyyy-MM-dd'); //beginning of the year
// let dzYear = format(new Date(new Date().getFullYear(), 11, 31), 'yyyy-MM-dd'); //end of the year
let d1Week = format(new Date().setDate(new Date().getDate() - new Date().getDay() + 1), 'yyyy-MM-dd'); //beginning of the week
// let dzWeek = format(new Date().setDate(new Date().getDate() - new Date().getDay() + 7), 'yyyy-MM-dd'); //end of the week
// let d1LWeek = format(new Date().setDate(new Date().getDate() - new Date().getDay() - 13), 'yyyy-MM-dd'); //beginning of last week
// let dzLWeek = format(new Date().setDate(new Date().getDate() - new Date().getDay() + 7), 'yyyy-MM-dd'); //end of last week
// console.log(today);

const period = [
  {
      value: 'today',
      label: 'Today'
  },
  {
    value: 'yesterday',
    label: 'Yesterday'
  },
  {
      value: 'week',
      label: 'This Week'
  },
  {
      value: 'month',
      label: 'This Month'
  },
  {
      value: 'custom',
      label: 'Custom'
  }
];

const objectToCsv = function (data) {
  const csvRows = [];
  /* Get headers as every csv data format 
  has header (head means column name)
  so objects key is nothing but column name 
  for csv data using Object.key() function.
  We fetch key of object as column name for 
  csv */
  const headers = Object.keys(data[0]);
  /* Using push() method we push fetched 
     data into csvRows[] array */
  csvRows.push(headers.join(','));
  // Loop to get value of each objects key
  for (const row of data) {
      const values = headers.map(header => {
          const val = row[header]
          return `"${val}"`;
      });
      // To add, separator between each value
      csvRows.push(values.join(','));
  }
  /* To add new line for each objects values
     and this return statement array csvRows
     to this function.*/
  return csvRows.join('\n');
};

class CategLocationChartGroup extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      saleslocation_list: [],
      page: 0,
      rowsPerPage: 10,
      dateFrom: today,
      dateTo: today,
      category: 0,
      categoryName: "All Categories",
      delivery: 2,
      sx_hidden: {display: 'none'},
      value: "1",
      categorys: [{'iref': 0, 'name': "All Categories"}],
      deliverys: [{'iref': 2, 'name': "All"}, {'iref': 0, 'name': "Restaurant"}, {'iref': 1, 'name': "Delivery"}],
      filterData: {}
    };

    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addDateFrom = this.addDateFrom.bind(this);
    this.addDateTo = this.addDateTo.bind(this);
    this.selectPeriod = this.selectPeriod.bind(this);
    this.selectCategory = this.selectCategory.bind(this);
    this.selectDelivery = this.selectDelivery.bind(this);
    this.queryData = { };
  }

  componentDidMount() {
    // console.log (this.state.dateFrom);
    // console.log (this.state.dateTo);
    this.getData ();
  }

  getData ( ) {  
    let queryData = { 
      "type": 14,
      "category": this.state.category,
      "dateFrom": this.state.dateFrom,
      "dateTo": this.state.dateTo,
      "delivery": this.state.delivery,
      "isForSale": 1,
      "orderBy_name": true      // order categorys by name
    }
    // console.log(queryData);
	  let data = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, queryData );
    if ( data ) {
      this.setState ( { saleslocation_list: data });
    }
    this.setState ( { filterData: queryData } );

    let categorys = this.state.client.getData ( Config.API_URL_PRODUCT_CATEGORY, queryData );
    // console.log(categorys);
    this.setState ( { categorys: categorys.productCategories });
  }
  
  doSync ( ) {
    this.getData ( );
  }

  addDateFrom = (event) => {
    this.setState ({dateFrom: event.target.value});
    this.setState ({dateTo: event.target.value});
  }

  addDateTo = (event) => {
    this.setState ({dateTo: event.target.value});
  }

  selectPeriod = (event) => {
    switch ( event.target.value ) {
       case "today":
        this.setState ({sx_hidden: {display: 'none'}});
        this.setState (
          {dateFrom: today},  //today
          function(){
          }
        );
        this.setState (
          {dateTo: today},  //today
          function(){
            this.doSync ( );
          }
        );
        break;
      case "yesterday":
        this.setState ({sx_hidden: {display: 'none'}});
        this.setState (
          {dateFrom: yesterday}, //yesterday
          function(){
          }
        );
        this.setState (
          {dateTo: yesterday}, //yesterday
          function(){
            this.doSync ( );
          }
        );
        break;
      case "week":
        this.setState ({sx_hidden: {display: 'none'}});  
        this.setState (
          {dateFrom: d1Week},  //beginning of the week
          function(){
          }
        );
        this.setState (
          {dateTo: today},  //today
          function(){
            this.doSync ( );
          }
        );
        break;  
      case "month": 
        this.setState ({sx_hidden: {display: 'none'}});  
        this.setState (
          {dateFrom: d1Month},  //beginning of the month
          function(){
          }
        );
        this.setState (
          {dateTo: today},  //today
          function(){
            this.doSync ( );
          }
        ); 
        break;                   
      default:
        this.setState (
          {sx_hidden: false},
          function(){
            this.doSync ( );
          }
        );
        // console.log(`No period selected.`);
    }
  }

  selectCategory = (event) => {
      this.setState (
        {category: event.target.value,
          categoryName: this.state.categorys.find((o) => o.iref === event.target.value)
        },
        function(){
          this.doSync ( );
        }
        );
  }

  selectDelivery = (event) => {
    this.setState (
      {delivery: event.target.value},
      function(){
        this.doSync ( );
      }
      );
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  }

  handleChangePage = (event, newPage) => {
    this.setState ({page: newPage});
  }
  
  handleChangeRowsPerPage = (event) => {
    this.setState ({rowsPerPage: +event.target.value});
    this.setState ({page: 0});
  }

  onDownload = () => {
    const link = document.createElement("a");
    link.download = "categorySales_"  + this.state.categoryName.name +"_"+ this.state.dateFrom  +"_"+ this.state.dateTo + ".csv";
    let csvData = objectToCsv(this.state.saleslocation_list.result);
    let csvHeader = "Category Sales\nDate From: "+ this.state.dateFrom + "\nDate To: "+ this.state.dateTo + "\nCategory: " + this.state.categoryName.name;
    let csv = csvHeader + "\n" + csvData;
    link.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
    link.click();
  };

  render() {

    let categorys = [{'iref': 0, 'name': "All Categories"}];
    if (this.state.categorys){
      Object.keys(this.state.categorys).forEach(key => {
        let fData = this.state.categorys[key];
        let myData = {'iref': fData.iref, 'name': fData.name};
        categorys.push ( myData );
      });
  }
    let deliverys = [{'iref': 2, 'name': "All"}, {'iref': 0, 'name': "Restaurant"}, {'iref': 1, 'name': "Delivery"}];
    let categorySalesData = [];
		let categorySalesList = [];
		if (this.state.saleslocation_list){
			if (this.state.saleslocation_list.result) {
				let counter = 0;
				Object.keys(this.state.saleslocation_list.result).forEach(key => {
					let fData = this.state.saleslocation_list.result[key];
					let myData = {'x': fData.name, 'y': (parseInt(fData.value))};
					let p = {
						id: counter+1,
						name: fData.name,
						quantity: thousandsSeparator(fData.quantity),
						value: thousandsSeparator(parseInt(fData.value))
					}
					if (counter<10){
						categorySalesData.push ( myData );
					}
					counter += 1;
					categorySalesList.push ( p );
				});
			}
    }

   /*  let today = new Date();
    let month = parseInt(today.getMonth());
    month += parseInt ( 1 );
    if (month < 10)month = '0' + month;
    if (today.getDate() < 10)get.Date = '0' + today.getDate();
    let currentDate = today.getFullYear() + "-" + month + "-" + today.getDate(); */

    return (
        <Card variant="outlined" style={{ height: '600px' }} sx={{ paddingTop:"6px", borderRadius:"16px", height: '100%' }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between'}}>
            <Box sx={{ padding: '16px', alignItems: 'baseline' }}>
              <TextField
                id="standard-select-period"
                select
                // label="Select"
                defaultValue="today"
                // helperText="Select Period"
                variant="standard"
                sx={{ width: 120}}
                onChange={this.selectPeriod}
              >
                {period.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              &nbsp;
              <TextField
                id="standard-select-category"
                select
                // label="Select"
                defaultValue="0"
                // helperText="Select Location"
                variant="standard"
                sx={{ width: 150}}
                onChange={this.selectCategory}
              >
                  {categorys.map((option) => (
                  <MenuItem key={option.iref} value={option.iref}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="standard-select-delivery"
                select
                // label="Select"
                defaultValue="2"
                // helperText="Select Delivery"
                variant="standard"
                sx={{ width: 100}}
                onChange={this.selectDelivery}
              >
                  {deliverys.map((option) => (
                  <MenuItem key={option.iref} value={option.iref}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              </Box >
              <Box sx={{display: this.state.sx_hidden ? 'none' : 'block'}} style={{ alignItems: 'baseline', justifyContent: 'space-around', padding: '16px'}}>
              <TextField
                size='small'
                id="date"
                label="From"
                type="date"
                // defaultValue={currentDate}
                value={this.state.dateFrom}
                // inputFormat="DD/MM/YYYY"
                sx={{ color: "#e3e3e5", alignSelf: "center", marginLeft: "6px", width:120}}
                InputLabelProps={{
                  shrink: true,
                  sx : { color: "#5b5b5b" }
                }}
                InputProps={{ sx : { color: "#5b5b5b" }}}
                inputProps={{ min: "2024-01-01", style : { color: "#5b5b5b", paddingRight: 5, paddingLeft: 5} }}
                onChange={this.addDateFrom}
              />
              <TextField
                size='small'
                id="date"
                label="To"
                type="date"
                // defaultValue={currentDate}
                value={this.state.dateTo}
                sx={{ color: "#e3e3e5", alignSelf: "center", marginLeft: "6px", width:120 }}
                InputLabelProps={{
                  shrink: true,
                  sx : { color: "#5b5b5b" }
                }}
                InputProps={{ sx : { color: "#5b5b5b" }}}
                inputProps={{ min: "2024-01-01", max: addDays(this.state.dateFrom,50), style : { color: "#5b5b5b", paddingRight: 5, paddingLeft: 5} }}
                onChange={this.addDateTo}
              />
              <Button color="inherit" onClick={() => this.doSync()} style={{maxWidth: '36px', minWidth: '36px'}}>
                <Avatar sx={{ width: 24, height: 24, bgcolor: theme.palette.primary.light}}> 
                  <AutorenewIcon />
                </Avatar>
              </Button>
              </Box>
              <Box sx={{ paddingLeft: '10px', rightPadding: '10px'}}>
                <Button
                  color="inherit"
                  size="small"
                  onClick={this.onDownload} 
                  variant="contained" 
                  // color="primary"
                  style={{maxWidth: '36px', minWidth: '36px'}}
                >
                  <Avatar sx={{ width: 24, height: 24, bgcolor: theme.palette.success.light}}> 
                    <FileDownloadIcon />
                  </Avatar>
                </Button>
              </Box>
              <Tabs  sx={{paddingLeft: '10px', rightPadding: '10px'}} value={this.state.value} onChange={this.handleChange} aria-label="tabs example">
                <Tab style= {{}} icon={<ViewListIcon />} value="1" />
                {/* <Tab classes={{ tab: {width: 50}, tabRoot: {width: 50}, root: {width: 50} }} icon={<TableChartIcon />} value="2" /> */}
                <Tab icon={<BarChartIcon />} value="3" />
              </Tabs>  
          </Box>
        <Box>
            {this.state.value === "1" ?
                  <Box>
                  <TableContainer sx={{ height: '380px' }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{padding: "6px", textAlign: "center" }}>
                          <b>Top</b>
                        </TableCell>
                        <TableCell style={{padding: "6px", textAlign: "left" }}>
                          <b>Name</b>
                        </TableCell>
                        <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                          <b>Quantity</b>
                        </TableCell>
                        <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                          <b>Value</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {categorySalesList.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((category) => {
                        return (
                          <TableRow
                            hover
                            key={category.id}
                            tabIndex={-1}
                          >
                            <TableCell style={{padding: "6px", textAlign: "center" }}>
                              {category.id}
                            </TableCell>
                            <TableCell style={{padding: "6px", textAlign: "left" }}>
                              {category.name}
                            </TableCell>
                            <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                                {category.quantity}
                            </TableCell>
                            <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                                {category.value}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, { value: categorySalesList.length, label: 'All' }]}
                    labelRowsPerPage="Rows:"
                    // showFirstButton="True"
                    // showLastButton="True"
                    component="div"
                    count={categorySalesList.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                  />
                </Box>

                : ""
            }
            {/* {this.state.value === "2" ?
              <Chart
              height={350}
              type="bar2v"
              series={[
                {	
                  name: 'Sales YTD',
                  data: categorySalesData
                }
              ]}
              width="100%"
              />
              : ""
            } */}
            {this.state.value === "3" ?
                <Chart
                height={350}
                type="bar2"
                series={[
                  {	
                    name: 'Sales YTD',
                    data: categorySalesData
                  }
                ]}
                width="100%"
              />
              : ""
            }          
        </Box>  
      </Card>
    );

  }
}

export default CategLocationChartGroup;
