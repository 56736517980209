import React from 'react';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { FaHome, FaPizzaSlice, FaHamburger, FaMapMarkedAlt, FaEuroSign, FaUserTie, FaHospitalUser } from "react-icons/fa";

//import './css/sideBar.scss';
import './css/dashboard.css';

let theme = createTheme();

class Profile extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
    };

  }


  render() {

    return (

 
      <div style={{ backgroundColor: "#273442", height: "100%" }}>
        Profile ...

      </div>

    );

  }
}

export default Profile;