import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
//import LockOutlinedIcon from '@mui/icons/LockOutlined';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
//import { makeStyles } from '@mui/styles';
import swal from 'sweetalert';

import { Link } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import './css/Login.css';

//import configureStore from './store/configureStore';
import BackOfficeConfig from './Config';
import Client from './components/Client';


let theme = createTheme();

//export default function Login() {
class Login extends React.Component {

  constructor(props) {
    super(props);

    /* this.state = {
      sideBar_collapsed: 0
    }; */
    this.state = {
      client: new Client ( "none" ),
      username: "",
      password: ""
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  
  handleSubmit ( e ) {
    var that = this;
    e.preventDefault();
    let fuser = "";
    let fpass = "";
    if ( e.target ) {
      let objects = e.target;
      for ( let i = 0; i < objects.length; i++ ) {
        if ( objects [ i ].id === "username" ) fuser = objects [ i ].value;
        if ( objects [ i ].id === "password" ) fpass = objects [ i ].value;
      }
    }  
    //console.log ( that.state.client.prefix + " - " + BackOfficeConfig.DASHBOARD_URL );
    this.state.client.auth ( fuser, fpass, function(){
      window.location.href = that.state.client.prefix + BackOfficeConfig.DASHBOARD_URL; //"/backoffice/Dashboard";
    });    
  }

   render() {

    return (
      <ThemeProvider theme={theme}>

      <Grid container className="login-dark">
        <CssBaseline />
        
          <div className="form-group">
          <div className="illustration"><ion-icon name="lock"></ion-icon></div>

            


            <form className="form-group" noValidate onSubmit={this.handleSubmit}>
                    
            <Typography component="h1" variant="h4">
              <img src={BackOfficeConfig.BACKOFFICE_URL + "logo192.png"} width="48px"></img> kalopsia
            </Typography>
              <TextField
                className="formInput"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                name="username"
                label="Username"
                //inputProps={{ className: "formInput" }}
                inputProps={{ sx : { color: "#47abff" } }}
              
                InputLabelProps={{ sx : { color: "#47abff" } }}
                //onChange={e => this.setUserName(e.target.value)}             
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="password"
                name="password"
                label="Password"
                type="password"
                inputProps={{ sx : { color: "#47abff" } }}
                InputLabelProps={{ sx : { color: "#47abff" } }}
                //onChange={e => this.setPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                //color="primary"
                className="btn-primary"
                //className={classes.submit}
              >              
                <span style={{ color: "#b3d0e3" }}> Login </span>
              </Button>

                <br /><br />
                
            <Typography variant="h6" component="h6" align="right">
              <Box sx={{ textAlign: 'right', fontSize: 12 }}>
                {/* <Link to="requestPasswordReset"> */}
                  Forgot Password
                {/* </Link> */}
              </Box>
            </Typography>

            </form>
          </div>

      </Grid>

      </ThemeProvider>
    );
  }
}

export default Login;