import React from 'react';
import { Avatar, createTheme, Box, Card, Button, TextField,MenuItem, Table, TableContainer, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Client from '../Client';
import Config from '../ClientConfig';
import { format } from 'date-fns';

let theme = createTheme();

function thousandsSeparator(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

let d = new Date();
let today = format(d, 'yyyy-MM-dd');  // mm=week, MM=month

const objectToCsv = function (data) {
  const csvRows = [];
  /* Get headers as every csv data format 
  has header (head means column name)
  so objects key is nothing but column name 
  for csv data using Object.key() function.
  We fetch key of object as column name for 
  csv */
  const headers = Object.keys(data[0]);
  /* Using push() method we push fetched 
     data into csvRows[] array */
  csvRows.push(headers.join(','));
  // Loop to get value of each objects key
  for (const row of data) {
      const values = headers.map(header => {
          const val = row[header]
          return `"${val}"`;
      });
      // To add, separator between each value
      csvRows.push(values.join(','));
  }
  /* To add new line for each objects values
     and this return statement array csvRows
     to this function.*/
  return csvRows.join('\n');
};

class ProdDateChartGroup extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      saleslocation_list: [],
      page: 0,
      rowsPerPage: 10,
      product: 0,
      delivery: 2,
      dateTo: today,
      productName: "All products",
      sx_hidden: {display: 'none'},
      value: "1",
      products: [{'iref': 0, 'name': "All Products"}],
      location: 0,
      locationName: "All locations",
      locations: [{'iref': 0, 'name': "All Locations"}],
      deliverys: [{'iref': 2, 'name': "All"}, {'iref': 0, 'name': "Restaurant"}, {'iref': 1, 'name': "Delivery"}],
      filterData: {}
    };

    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.selectDateTo = this.selectDateTo.bind(this);
    this.selectLocation = this.selectLocation.bind(this);
    this.selectProduct = this.selectProduct.bind(this);
    this.selectDelivery = this.selectDelivery.bind(this);
    this.queryData = { };
  }

  componentDidMount() {
    // console.log (this.state.dateFrom);
    // console.log (this.state.dateTo);
    this.getData ();
  }

  getData ( ) {  
    let queryData = { 
      "type": 32,
      "product": this.state.product,
      "location": this.state.location,
      "dateTo": this.state.dateTo,
      "delivery": this.state.delivery
    }
    // console.log(queryData);
	  let data = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, queryData );
    if ( data ) {
      this.setState ( { saleslocation_list: data });
    }
    this.setState ( { filterData: queryData } );

    let productData = {
      "type": 26,
    }
    // let products = this.state.client.getData ( Config.API_URL_PRODUCT, queryData );
    let products = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, productData );
    this.setState ( { products: products.products });

    let locationData = {
      "type": 25,
    }
    // let locations = this.state.client.getData ( Config.API_URL_LOCATIONS_SIMPLE, queryData );
    let locations = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, locationData );
    this.setState ( { locations: locations.locations });
  }
  
  doSync ( ) {
    this.getData ( );
  }

  selectDateTo = (event) => {
    this.setState (
      {dateTo: event.target.value},
      function(){
        this.doSync ( );
      }
      );
  }

  selectLocation = (event) => {
    this.setState (
      {location: event.target.value,
        locationName: this.state.locations.find((o) => o.iref === event.target.value)
      },
      function(){
        this.doSync ( );
      }
      );
  }

  selectProduct = (event) => {
      this.setState (
        {product: event.target.value,
          productName: this.state.products.find((o) => o.iref === event.target.value)
        },
        function(){
          this.doSync ( );
        }
        );
  }
  selectDelivery = (event) => {
    this.setState (
      {delivery: event.target.value},
      function(){
        this.doSync ( );
      }
      );
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  }

  handleChangePage = (event, newPage) => {
    this.setState ({page: newPage});
  }
  
  handleChangeRowsPerPage = (event) => {
    this.setState ({rowsPerPage: +event.target.value});
    this.setState ({page: 0});
  }

  onDownload = () => {
    const link = document.createElement("a");
    link.download = "productSales_"  + this.state.locationName.name + this.state.productName.name +"_"+ this.state.dateTo + ".csv";
    let csvData = objectToCsv(this.state.saleslocation_list.result);
    let csvHeader = "Product Sales\nLocation: " + this.state.locationName.name + "\nProduct: " + this.state.productName.name + "\nMonth to Date: "+ this.state.dateTo + "\nDelivery: "+ this.state.delivery;
    let csv = csvHeader + "\n" + csvData;
    link.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
    link.click();
  };

  render() {

    let products = [{'iref': 0, 'name': "All Products"}];
    if (this.state.products){
      Object.keys(this.state.products).forEach(key => {
        let fData = this.state.products[key];
        let myData = {'iref': fData.iref, 'name': fData.name};
        products.push ( myData );
      });
    }
    let locations = [{'iref': 0, 'name': "All Locations"}];
    if (this.state.locations){
      Object.keys(this.state.locations).forEach(key => {
        let fData = this.state.locations[key];
        let myData = {'iref': fData.iref, 'name': fData.name};
        locations.push ( myData );
      });
    }

    let deliverys = [{'iref': 2, 'name': "All"}, {'iref': 0, 'name': "Restaurant"}, {'iref': 1, 'name': "Delivery"}];
    let productSalesData = [];
		let productSalesList = [];
		if (this.state.saleslocation_list){
			if (this.state.saleslocation_list.result) {
				let counter = 0;
				Object.keys(this.state.saleslocation_list.result).forEach(key => {
					let fData = this.state.saleslocation_list.result[key];
					let myData = {'x': fData.date, 'y': (parseInt(fData.value))};
					let p = {
						id: counter+1,
						date: fData.date,
						quantity: thousandsSeparator(fData.quantity),
						value: thousandsSeparator(parseInt(fData.value))
					}
					if (counter<10){
						productSalesData.push ( myData );
					}
					counter += 1;
					productSalesList.push ( p );
				});
			}
    }

    return (
        <Card variant="outlined" style={{ height: '600px' }} sx={{ paddingTop:"6px", borderRadius:"16px", height: '100%' }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between'}}>
            <Box sx={{ padding: '16px', alignItems: 'baseline' }}>
              <TextField
                  size='small'
                  id="date"
                  label="monthTo"
                  type="date"
                  // defaultValue={today}
                  value={this.state.dateTo}
                  sx={{ color: "#e3e3e5", alignSelf: "center", marginLeft: "6px", width:120 }}
                  InputLabelProps={{
                    shrink: true,
                    sx : { color: "#5b5b5b" }
                  }}
                  InputProps={{ sx : { color: "#5b5b5b" }}}
                  inputProps={{ min: "2023-01-01", style : { color: "#5b5b5b", paddingRight: 5, paddingLeft: 5} }}
                  onChange={this.selectDateTo}
                />
              <TextField
                  id="standard-select-location"
                  select
                  // label="Select"
                  defaultValue="0"
                  // helperText="Select Location"
                  variant="standard"
                  sx={{ width: 180}}
                  onChange={this.selectLocation}
                >
                    {locations.map((option) => (
                    <MenuItem key={option.iref} value={option.iref}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                &nbsp;
                <TextField
                  id="standard-select-product"
                  select
                  // label="Select"
                  defaultValue="0"
                  // helperText="Select Location"
                  variant="standard"
                  sx={{ width: 180}}
                  onChange={this.selectProduct}
                >
                    {products.map((option) => (
                    <MenuItem key={option.iref} value={option.iref}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="standard-select-delivery"
                  select
                  // label="Select"
                  defaultValue="2"
                  // helperText="Select Delivery"
                  variant="standard"
                  sx={{ width: 100}}
                  onChange={this.selectDelivery}
                >
                    {deliverys.map((option) => (
                    <MenuItem key={option.iref} value={option.iref}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <Button
                  color="inherit"
                  size="small"
                  onClick={this.onDownload} 
                  variant="contained" 
                  // color="primary"
                  style={{maxWidth: '36px', minWidth: '36px'}}
                >
                  <Avatar sx={{ width: 24, height: 24, bgcolor: theme.palette.success.light}}> 
                    <FileDownloadIcon />
                  </Avatar>
                </Button>
              </Box >
          </Box>
          <Box sx={{ paddingLeft: "16px", paddingRight: "16px"}}>
          <TableContainer sx={{ height: '380px' }}>
          <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 350 }}>
            <TableHead>
              <TableRow>
                <TableCell style={{padding: "6px", textAlign: "center" }}>
                  <b>Date</b>
                </TableCell>
                <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                  <b>Quantity</b>
                </TableCell>
                <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                  <b>Value</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productSalesList.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((product) => {
                return (
                  <TableRow
                    hover
                    key={product.id}
                    tabIndex={-1}
                  >
                    <TableCell style={{padding: "6px", textAlign: "center" }}>
                      {product.date}
                    </TableCell>
                    <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        {product.quantity}
                    </TableCell>
                    <TableCell style={{padding: "6px", paddingRight: "16px", textAlign: "right" }}>
                        {product.value}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, { value: productSalesList.length, label: 'All' }]}
            labelRowsPerPage="Rows:"
            // showFirstButton="True"
            // showLastButton="True"
            component="div"
            count={productSalesList.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Box>
      </Card>
    );

  }
}

export default ProdDateChartGroup;
