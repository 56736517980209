import React from 'react';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { FaHome, FaPizzaSlice, FaHamburger, FaMapMarkedAlt, FaEuroSign, FaUserTie, FaHospitalUser } from "react-icons/fa";
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import swal from 'sweetalert';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isNumeric from 'validator/lib/isNumeric';
import DropDown from './DropDown';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

import noImage from './../images/noImage.png';
import idle from './../images/icons/idle_on.png';
import registered from './../images/icons/registered_on.png';
import active from './../images/icons/active_on.png';
import discontinued from './../images/icons/discontinued_on.png';
import noUser_on from './../images/icons/noUser_on.png';
import noUser_off from './../images/icons/noUser_off.png';

import franchise_on from './../images/icons/franchise_on.png';
import franchise_off from './../images/icons/franchise_off.png';
import supplier_on from './../images/icons/supplier_on.png';
import supplier_off from './../images/icons/supplier_off.png';
import client_on from './../images/icons/client_on.png';
import client_off from './../images/icons/client_off.png';
import collaboration_on from './../images/icons/collaboration_on.png';
import collaboration_off from './../images/icons/collaboration_off.png';

import BackOfficeConfig from '../Config';
import Client from './Client';
import Config from './ClientConfig';

let theme = createTheme();

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#273442",
    color: theme.palette.common.white,
    paddingLeft: "4px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    paddingLeft: "4px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

class Company extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      expanded: false,
      edit: false,
      saveButtonVisible: false,
      //combo_mainImage: null,
      company: {
        iref: "",
        name: "",
        ruid: "",
        cuid: "",
        phone: "",
        email: "",
        isFranchise: false,
        isSupplier: false,
        isClient: false,
        isCollaboration: false,
        vat: false,
        address: {
          name: "",
          postalCode: "",
          street: "",
          number: "",
          entrance: "",
          local: "",
          floor: "",
          complement: "",
          gps_lt: "",
          gps_lg: "",
          city: {
            iref: "",
            name: "City",
            county: {
              iref: "",
              name: "County",
              country: {
                iref: "country061",
                name: "Country"
              }
            }
          }
        },
        representative: {
          iref: "Undefined",
          firstName: "Undefined",
          lastName: "Undefined",
          phone: "Undefined",
          email: "Undefined",
          birthday: "Undefined",
          image: noImage,
          fullName: "Undefined",
          address: {
            name: "Undefined",
            postalCode: "Undefined",
            street: "Undefined",
            number: "Undefined",
            entrance: "Undefined",
            local: "Undefined",
            floor: "Undefined",
            complement: "Undefined",
            gps_lt: "Undefined",
            gps_lg: "Undefined",
            city: {
              name: "City"
            },
            county: {
              name: "County"
            },
            country: {
              name: "Country"
            }
          }
        }
      },
      counties: { },
      cities: { },
      representativesSearch: [],
      errorMessage: "",
      emailValid: false,
      phoneValid: false,
      somethingChanged: false,
      companyNameValid: false,
      companyRUIDValid: false,
      companyCUIDValid: false,
    };

    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.getElementByName = this.getElementByName.bind(this);
    this.queryData = this.queryData.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.addCountry = this.addCountry.bind(this);
    this.addCounty = this.addCounty.bind(this);
    this.addCity = this.addCity.bind(this);
    this.editGeoData = this.editGeoData.bind(this);
    this.updateGeoData = this.updateGeoData.bind(this);    
    this.changeColab = this.changeColab.bind(this); 
    this.pushData = this.pushData.bind(this);
    this.representativeChange = this.representativeChange.bind(this);
    this.representativeSearch = this.representativeSearch.bind(this);
    this.vatChange = this.vatChange.bind(this);    
    this.detachUser = this.detachUser.bind(this);
  }

  handleExpandClick ( ) {
    this.setState({ expanded: !this.state.expanded });
  }

  handleEditClick ( ) {
    let cEdit = !this.state.edit;
    this.setState({ edit: !this.state.edit });
    if ( this.state.expanded === false && cEdit === true )
      this.state.expanded = true;
    if ( this.state.expanded === true && cEdit === false )
      this.state.expanded = false;
    this.setState({ saveButtonVisible: cEdit });

    this.editGeoData ( );
  }

  getElementByName ( elements, name ) {    
    let e = {
      "iref" : null,
      "name" : "X",
      "image" : "X",
      "image_off" : "X"
    };
    if ( elements !== null && elements !== undefined ) {
      for ( var i = 0; i < elements.length; i++ ) {
        let el = elements [ i ];
        if ( el.length === 2 ) {
          if ( el [ 1 ].name === name ) e = el [ 1 ];
        } else {
          if ( el [ 0 ].name === name ) e = el [ 0 ];
        }        
      }
    }
    return e;    
  }

  queryData ( ) {
    let uCompany = this.state.company;
    let req = { "cui": this.state.company.cuid };
    let data = this.state.client.callExternalAPI ( Config.API_URL_COMPANY_EXTAPI, req );
    //this.setState ( { companies: data.Companies });
    if ( data ) {
      if ( data.cod === 200 ) {
        if ( data.found ) {
          if ( data.found.length > 0 ) {
            let comp = data.found [ 0 ];
            if ( comp.date_generale ) {
              uCompany.name = comp.date_generale.denumire;
              uCompany.ruid = comp.date_generale.nrRegCom;
              uCompany.cuid = comp.date_generale.cui;
            }
            if ( comp.adresa_sediu_social ) {
              if ( comp.adresa_sediu_social.telefon ) uCompany.phone = comp.adresa_sediu_social.telefon;
              if ( comp.adresa_sediu_social.scod_Postal ) uCompany.address.postalCode = comp.adresa_sediu_social.scod_Postal;
              if ( comp.adresa_sediu_social.sdenumire_Strada ) {
                uCompany.address.street = comp.adresa_sediu_social.sdenumire_Strada;
                let prefix = "Str. ";
                if ( uCompany.address.street.toLowerCase().startsWith ( prefix.toLowerCase() ) )
                  uCompany.address.street = comp.adresa_sediu_social.sdenumire_Strada.slice ( prefix.length );
                
              }
              if ( comp.adresa_sediu_social.snumar_Strada ) uCompany.address.number = comp.adresa_sediu_social.snumar_Strada;
              if ( comp.adresa_sediu_social.sdetalii_Adresa ) uCompany.address.local = comp.adresa_sediu_social.sdetalii_Adresa;              
            }

            if ( comp.inregistrare_scop_Tva ) {
              if ( comp.inregistrare_scop_Tva.scpTVA )  uCompany.vat = comp.inregistrare_scop_Tva.scpTVA;
            }
            //else this.setState ( { tva: false } );

            if ( isMobilePhone ( uCompany.phone) ) this.setState ( { phoneValid: true } );
            if ( uCompany.name )
              if ( uCompany.name.length > 2 ) this.setState ( { companyNameValid: true } );
            if ( uCompany.cuid )
              if ( uCompany.cuid.length > 2 ) this.setState ( { companyCUIDValid: true } );
            if ( uCompany.ruid )
              if ( uCompany.ruid.length > 2 ) this.setState ( { companyRUIDValid: true } );
          }
        }
        if ( data.geo ) {
            if ( !uCompany.address ) {
              uCompany.address = {};
              uCompany.address.city = {};
            }
            if ( !uCompany.address.city ) uCompany.address.city = {};
            uCompany.address.city.iref = data.geo.city;
            if ( !uCompany.address.city.county ) uCompany.address.city.county = {};
            uCompany.address.city.county.iref = data.geo.county;
            if ( !uCompany.address.city.county.country) uCompany.address.city.county.country= {};
            uCompany.address.city.county.country.iref = data.geo.country;

        }
        this.updateGeoData ( data.geo.country, data.geo.county, uCompany )
        this.setState ( { somethingChanged: true } );
      }
    }
  }

  changeValue ( e ) {
    let id = null;
    if ( e.target.id !== null ) id = e.target.id;
    let value = null;
    if  ( e.target.value !== null ) value = e.target.value;
    // && value !== null
    if ( id !== null) {
      let uCompany = this.state.company;
      switch ( id ) {
        case "c_cuid":
          uCompany.cuid = value;
          if ( value.length > 2 ) this.setState ( { companyCUIDValid: true } );
          else this.setState ( { companyCUIDValid: false } );
          break;
        case "c_ruid":
          uCompany.ruid = value;
          if ( value.length > 2 ) this.setState ( { companyRUIDValid: true } );
          else this.setState ( { companyRUIDValid: false } );
          break;
        case "c_name":
          uCompany.name = value;
          if ( value.length > 2 ) this.setState ( { companyNameValid: true } );
          else this.setState ( { companyNameValid: false } );
          break;
        case "c_a_name":
          uCompany.address.name = value;
          break;
        case "c_a_street":
          uCompany.address.street = value;
          break;
        case "c_a_number":
          uCompany.address.number = value;
          break;
        case "c_a_entrance":
          uCompany.address.entrance = value;
          break;
        case "c_a_local":
          uCompany.address.local = value;
          break;
        case "c_a_floor":
          uCompany.address.floor = value;
          break;
        case "c_a_zip":
          uCompany.address.postalCode = value;
          break;
        case "c_phone":
          uCompany.phone = value;
          if ( isMobilePhone ( value ) ) {
            this.setState ( { phoneValid: true } );
          }
          break;
        case "c_email":
          uCompany.email = value;
          if ( isEmail ( value ) ) {
            this.setState ( { emailValid: true } );
          }
          break;
        case "c_a_complement":
          uCompany.address.complement = value;
          break;
      }
      
      this.setState ( { company: uCompany } );
      this.setState ( { somethingChanged: true } );
    }
  }

  addCountry ( e ) {
    if ( this.state.company ) {
      let uCompany = this.state.company;
      if ( uCompany.address ) {
        if ( uCompany.address.city ) {            
          uCompany.address.city.iref = "";
          if ( uCompany.address.city.county ) {             
            uCompany.address.city.county.iref = "";
            if (  uCompany.address.city.county.country )
              uCompany.address.city.county.country.iref = e;
          }            
        }
      }
      this.setState ( {company: uCompany} );
      this.setState ( { somethingChanged: true } );
    }

    let queryData = {
      country: e,
      orderBy_Name: true
    }
    //queryData [ "user_type_gte" ] = "15"
    let data = this.state.client.getData ( Config.API_URL_COUNTY, queryData );
    if ( data ) {
        this.setState ( { counties: data.counties });
    } else {
      this.setState ( { counties: {} });
    }      
    this.setState ( { cities: {} });
  }

  addCounty ( e ) {
    if ( this.state.company ) {
      let uCompany = this.state.company;
      if ( uCompany.address ) {
        if ( uCompany.address.city ) {            
          uCompany.address.city.iref = "";
          if ( uCompany.address.city.county ) {             
            uCompany.address.city.county.iref = e;
          }            
        }
      }
      this.setState ( {company: uCompany} );
      this.setState ( { somethingChanged: true } );
    }

    let queryData = {
      county: e,
      orderBy_Name: true
    }
    //queryData [ "user_type_gte" ] = "15"
    let data = this.state.client.getData ( Config.API_URL_CITY, queryData );
    if ( data ) {
      this.setState ( { cities: data.cities });
    }
  }

  addCity ( e ) {
    if ( this.state.company ) {
      let uCompany = this.state.company;
      if ( uCompany.address ) {
        if ( uCompany.address.city ) {            
          uCompany.address.city.iref = e;
          let queryData = { };
          let cityData = this.state.client.getData ( Config.API_URL_CITY + e, queryData );
          if ( cityData ) {
            if ( cityData.name ) uCompany.address.city.name = cityData.name;
            if ( cityData.county )
              if ( cityData.county.name ) uCompany.address.city.county.name = cityData.county.name;
            /* if ( cityData.county.country )
              if ( cityData.county.country.name ) uCompany.address.city.county.country.name = cityData.county.country.name; */
            
            if ( cityData.zipCode ) {
              let zipCode = cityData.zipCode;
              if ( parseInt(zipCode) !== 0 ) {
                uCompany.address.zipCode = zipCode;
                uCompany.address.postalCode = zipCode;                
              } else {
                uCompany.address.zipCode = "";
                uCompany.address.postalCode = ""; 
              }              
            } else {
              uCompany.address.zipCode = "";
              uCompany.address.postalCode = "";
            }
          }
        }
      }
      this.setState ( {company: uCompany} );
      this.setState ( { somethingChanged: true } );
    }
  }

  editGeoData ( ) {
    let country_id = "";
    let county_id = "";
    if ( this.state.company ) {
      if ( this.state.company.address ) {
        if ( this.state.company.address.city ) {
          if ( this.state.company.address.city.county ) {
            county_id = this.state.company.address.city.county.iref;
            if ( this.state.company.address.city.county.country )
              country_id = this.state.company.address.city.county.country.iref;
          }
        }
      }

      let queryData = {
        country: country_id,
        orderBy_Name: true
      }
      let data = this.state.client.getData ( Config.API_URL_COUNTY, queryData );
      if ( data ) {
        this.setState ( { counties: data.counties });
      }

      queryData = {
        county: county_id,
        orderBy_Name: true
      }
      let citiesData = this.state.client.getData ( Config.API_URL_CITY, queryData );
      if ( citiesData ) {
        this.setState ( { cities: citiesData.cities });
      }

    }
  }

  updateGeoData ( country_id, county_id, uCompany ) {
    let queryData = {
      country: country_id,
      orderBy_Name: true
    }
    let data = this.state.client.getData ( Config.API_URL_COUNTY, queryData );
    if ( data ) {
      this.setState ( { counties: data.counties });
    }

    queryData = {
      county: county_id,
      orderBy_Name: true
    }
    let citiesData = this.state.client.getData ( Config.API_URL_CITY, queryData );
    if ( citiesData ) {
      this.setState ( { cities: citiesData.cities }, this.setState ( { company : uCompany } ) );
    }    
  }

  changeColab ( ) {
    let uCompany = this.state.company;
    uCompany.isCollaboration = !uCompany.isCollaboration;
    this.setState ( { company: uCompany } );
    this.setState ( { somethingChanged: true } );
  }
  changeClient ( ) {
    let uCompany = this.state.company;
    uCompany.isClient = !uCompany.isClient;
    this.setState ( { company: uCompany } );
    this.setState ( { somethingChanged: true } );
  }
  changeFranchise ( ) {
    let uCompany = this.state.company;
    uCompany.isFranchise = !uCompany.isFranchise;
    this.setState ( { company: uCompany } );
    this.setState ( { somethingChanged: true } );
  }
  changeSupplier ( ) {
    let uCompany = this.state.company;
    uCompany.isSupplier = !uCompany.isSupplier;
    this.setState ( { company: uCompany } );
    this.setState ( { somethingChanged: true } );
  }

  pushData ( e ) {
    if ( this.state.companyCUIDValid === true && this.state.companyRUIDValid === true && this.state.companyNameValid === true ) {
      let rdata = this.state.client.pushData ( Config.API_URL_COMPANY_ADD, this.state.company );
      if ( rdata ) {
        if ( rdata.status == "OK" ) {
          let uCompany = this.state.company;
          uCompany.iref = rdata.iref;
          this.setState ( { company: uCompany } );
          this.handleEditClick ( );
          swal ( "Success", "Successfully added/modified company ! (" + rdata.iref + ")", "success", {
            buttons: false,
            timer: 2000,
          })
          .then((value) => {
            this.props.companyUpdated ( );
            this.setState ( { somethingChanged: false } );
            let city = {};
            if ( this.state.company.address.city ) {
              city = this.state.company.address.city;
              if ( city ) this.addCity ( city.iref );
            }
          });        
        } else {
          if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
          swal ( "Error", "Add/Update company failed !", "error" );
        }
      }
    }
  }

  representativeSearch ( e ) {
    //console.log ( e.target.value );
    if ( e.target.value.length > 3 ) {
      let queryData = { "nameLike" : e.target.value };
      let data = this.state.client.filterPersons ( queryData );
      let newRepresentatives = [];
      let representatives = [];
      Object.keys(data.persons).forEach(key => {
        let fData = data.persons [ key ];
        let lineText = fData.iref + " - " + fData.firstName + " " + fData.lastName + " (" + fData.email + ", " + fData.phone + ")";
        let newObj = { key: fData.iref, label: lineText };
        newRepresentatives.push ( newObj );
        representatives.push ( fData );
        //counties [ fData.iref ] = fData.name;      
      });

      this.setState ( { representativesSearch: newRepresentatives });
    }
  }

  representativeChange ( e ) {
    let text = e.target.textContent;
    let repIref = text.substring ( 0, text.indexOf(" - ") );
    let queryData = { };
    let data = this.state.client.getData ( Config.API_URL_PERSON + repIref, queryData );
    let uCompany = this.state.company;
    uCompany.representative = data;
    this.setState ( { company: uCompany } );    
    this.setState ( { somethingChanged: true } );
  }

  vatChange ( e ) {
    let uCompany = this.state.company;
    if ( uCompany ) {
      uCompany.vat = e.target.checked;
      this.setState ( { company: uCompany } );
      this.setState ( { somethingChanged: true } );
    }
  }

  componentDidMount() {
    if ( Object.entries(this.props.company).length === 0 ) {
      this.handleEditClick ( );
    } else {
      if ( this.props.company !== null ) {
        let uCompany = this.props.company;
        if ( uCompany ) {
          if ( uCompany.phone )
            if ( isMobilePhone ( uCompany.phone) ) this.setState ( { phoneValid: true } );
          if ( uCompany.email )
            if ( isEmail ( uCompany.email) ) this.setState ( { emailValid: true } );
          if ( uCompany.name )
            if ( uCompany.name.length > 2 ) this.setState ( { companyNameValid: true } );
          if ( uCompany.cuid )
            if ( uCompany.cuid.length > 2 ) this.setState ( { companyCUIDValid: true } );
          if ( uCompany.ruid )
            if ( uCompany.ruid.length > 2 ) this.setState ( { companyRUIDValid: true } );
        this.setState ( { company: uCompany } );
        }        
      }
    }
  }

  componentDidUpdate ( newProps ) {
    if ( newProps.company != this.props.company ) {
      let uCompany = this.props.company;
      if ( uCompany ) {
        if ( uCompany.phone )
            if ( isMobilePhone ( uCompany.phone) ) this.setState ( { phoneValid: true } );
          if ( uCompany.email )
            if ( isEmail ( uCompany.email) ) this.setState ( { emailValid: true } );
        this.setState ( { company: uCompany } );
      }
    }
  }

  detachUser ( ) {
    let uCompany = this.state.company;
    if ( uCompany ) {
      if ( uCompany.representative ) {
        let prevPerson = uCompany.representative;
        uCompany.representative = {};
        uCompany.prevPerson = prevPerson;
        this.setState ( { company: uCompany } );
        this.setState ( { somethingChanged: true } );
      }
    }    
  }

  render() {
    let vat = "";    
    let subHeader = "";
    let address_complement = "";
    let address_name = "";
    let address_street = "";
    let address_number = "";
    let address_entrance = "";
    let address_local = "";
    let address_floor = "";
    let address_postalCode = "";
    let address_country = "Country";
    let address_country_select = "";
    let address_county = "County";
    let address_county_select = "";
    let address_city = "City";
    let address_city_select = "";

    let countriesValue = "";
    let countries = {};
    if ( this.props.countries ) countries = this.props.countries;
    if ( Object.keys(countries).length === 1 ) countriesValue = Object.keys(countries)[0];

    let counties = {};
    let countiesObj = {};
    if ( this.state.counties ) countiesObj = this.state.counties;
    Object.keys(countiesObj).forEach(key => {
      let fData = countiesObj [ key ];
      counties [ fData.iref ] = fData.name;      
    });

    let cities = {};
    let citiesObj = {};
    if ( this.state.cities ) citiesObj = this.state.cities;
    Object.keys(citiesObj).forEach(key => {
      let fData = citiesObj [ key ];
      cities [ fData.iref ] = fData.name;      
    });

    

    if ( this.state.company ) {
      let cCode = "RO";
      vat = this.state.company.vat ? "RO" : "";
      subHeader = this.state.company.ruid + " - " + ( this.state.company.vat ? "RO" : "" ) + " " + this.state.company.cuid;
      
      if ( this.state.company.address ) {
        address_complement = this.state.company.address.complement;
        address_name = this.state.company.address.name;
        address_street = this.state.company.address.street;
        address_number = this.state.company.address.number;
        address_entrance = this.state.company.address.entrance;
        address_local = this.state.company.address.local;
        address_floor = this.state.company.address.floor;
        address_postalCode = this.state.company.address.postalCode;
       
        if ( this.state.company.address.city ) {
          if ( this.state.company.address.city.county ) {
            if ( this.state.company.address.city.county.country ) {
                address_country_select = this.state.company.address.city.county.country.iref;
                address_country = this.state.company.address.city.county.country.name;

                vat = this.state.company.vat ? this.state.company.address.city.county.country.isoName : "";
                subHeader = this.state.company.ruid + " - " + vat + " " + this.state.company.cuid;
                //this.addCountry ( address_country_select );
            }
            address_county = this.state.company.address.city.county.name;
            address_county_select = this.state.company.address.city.county.iref; 
            //this.addCounty ( address_county_select );
          }
          address_city = this.state.company.address.city.name;
          address_city_select = "";
          address_city_select = this.state.company.address.city.iref;          
          //this.addCity ( address_city_select );
        }
      }      
    }
    
    let representative_county = "";
    let representative_country = "";
    let representative_image = noImage;
    let representative_email = "";
    let representative_phone = "";
    let representative_fullName = "";
    let representative_address_name = "";
    let representative_address_street = "";
    let representative_address_number = "";
    let representative_address_floor = "";
    let representative_address_postalCode = "";
    let representative_address_city_name = "";
    let representative_address_gps_lg = "0";
    let representative_address_gps_lt = "0";
    let representative_address_geo = "";

    if ( this.state.company ) {
      let representative = this.state.company.representative;
      if ( representative ) {
        if ( representative.image ) representative_image = representative.image;
        if ( representative.firstName ) representative_fullName = representative.firstName;
        if ( representative.lastName ) representative_fullName += " " + representative.lastName;
        if ( representative.email ) representative_email = representative.email;
        if ( representative.phone ) representative_phone = representative.phone;
        if ( representative.address ) {
          if ( representative.address.name ) representative_address_name = representative.address.name;
          if ( representative.address.street ) representative_address_street = representative.address.street;
          if ( representative.address.number ) representative_address_number = representative.address.number;
          if ( representative.address.floor ) representative_address_floor = representative.address.floor;
          if ( representative.address.postalCode ) representative_address_postalCode = representative.address.postalCode;          
          if ( representative.address.gps_lg ) representative_address_gps_lg = representative.address.gps_lg;
          if ( representative.address.gps_lt ) representative_address_gps_lt = representative.address.gps_lt;
          if ( representative.address.city ) {
            if ( representative.address.city.name )
              representative_address_geo = representative.address.city.name;
            if ( representative.address.city.county ) {
              representative_address_geo += ", " + representative.address.city.county.name;
              if ( representative.address.city.county.country )
                representative_address_geo += ", " + representative.address.city.county.country.name;
            }
          }
        }
      }
    }

    /* let collaboration_image = collaboration_off;
    if ( this.state.company.isCollaboration ) collaboration_image = collaboration_on; */

    let representativesSearch = [ ];
    if ( this.state.representativesSearch ) representativesSearch = this.state.representativesSearch;

    let hasPerson = false;    
    if ( this.state.company.representative && 'iref' in this.state.company.representative ) hasPerson = true;
        
    return (
    <Card sx={{ borderRadius: '16px', m: 1 }}>

      <Box sx={{ display: 'block', flexDirection: 'row', width: "100%" }}>
        <CardHeader
          avatar = {
            <Typography variant="body2" color="text.secondary">
              {this.state.company.iref}
              </Typography>
          }     
          action={            
            <IconButton aria-label="settings" onClick={this.handleEditClick}>
              <EditIcon
                sx={{ color: this.state.edit ? "#8f241d" : "#e3e3e5" }}
              />
            </IconButton>
          }
          title={this.state.company.name}          
          titleTypographyProps={{variant:'h5' }}
          subheader={subHeader}
        />
        
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%" }}>        
        <Box sx={{ flexDirection: 'column', display: "block", width: '30%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "start", justifyContent:"left" }}>          
            <CardContent sx={{display: 'flex', textAlign: "start", justifyContent:"left"}}>

              { this.state.edit ?
                <IconButton aria-label="colab" title="colab" onClick={() => this.changeColab( )}>
                  <Avatar alt="Collaboration" src={this.state.company.isCollaboration ? collaboration_on : collaboration_off} title="Collaboration" />
                </IconButton>                
                :
                <Avatar alt="Collaboration" src={this.state.company.isCollaboration ? collaboration_on : collaboration_off} title="Collaboration" />
              }
              { this.state.edit ?
                <IconButton aria-label="client" title="client" onClick={() => this.changeClient( )}>
                  <Avatar alt="Client" src={this.state.company.isClient ? client_on : client_off} title="Client" />
                </IconButton>                
                :
                <Avatar alt="Client" src={this.state.company.isClient ? client_on : client_off} title="Client" />
              }
              { this.state.edit ?
                <IconButton aria-label="franchise" title="franchise" onClick={() => this.changeFranchise( )}>
                  <Avatar alt="Franchise" src={this.state.company.isFranchise ? franchise_on : franchise_off} title="Franchise" />
                </IconButton>                
                :
                <Avatar alt="Franchise" src={this.state.company.isFranchise ? franchise_on : franchise_off} title="Franchise" />
              }
              { this.state.edit ?
                <IconButton aria-label="supplier" title="supplier" onClick={() => this.changeSupplier( )}>
                  <Avatar alt="Supplier" src={this.state.company.isSupplier ? supplier_on : supplier_off} title="Supplier" />
                </IconButton>                
                :
                <Avatar alt="Supplier" src={this.state.company.isSupplier ? supplier_on : supplier_off} title="Supplier" />
              }
            </CardContent>
          </Box>

          <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "start", justifyContent:"left" }}>
            <CardContent sx={{textAlign: "start", justifyContent:"left"}}>
              {/* <Typography variant="body2" color="text.secondary"> */}

                { this.state.edit ?
                  <TextField
                    id="c_phone"
                    label="Phone"
                    value={this.state.company.phone || ''}
                    variant="outlined"
                    onChange={this.changeValue}
                    onFocus={event => { event.target.select(); }}
                    inputProps={{maxLength:"13"}}
                    error={this.state.phoneValid === false}
                  />
                  : this.state.company.phone
                }<br/>
                { this.state.edit ?
                  <TextField
                    id="c_email"
                    label="email"
                    value={this.state.company.email || ''}
                    variant="outlined"
                    sx= {{marginTop: 1 }}
                    onChange={this.changeValue}
                    onFocus={event => { event.target.select(); }}
                    inputProps={{maxLength:"128"}}
                    error={this.state.emailValid === false}
                  />
                  : this.state.company.email
                }<br/>
                { this.state.edit ?
                  <TextField
                    id="c_a_complement"
                    label="Complement"
                    value={address_complement || ''}
                    variant="outlined"
                    sx= {{marginTop: 1 }}
                    onChange={this.changeValue}
                    onFocus={event => { event.target.select(); }}
                    inputProps={{maxLength:"64"}}
                  />
                  : address_complement 
                }
              {/* </Typography>   */}            
            </CardContent>            
          </Box>

          <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "start", justifyContent:"left" }}>
          </Box>               
        </Box>

        <Box sx={{ flexDirection: 'column', justifyContent:"right", width: '100%' }}>          
          <CardActions disableSpacing sx={{display: "block", textAlign: "end", lineHeight: "3.75"}}>
          <Typography variant="button" color="text.secondary" sx={{verticalAlign: "middle"}}>
            { this.state.edit ?
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.company.vat}
                    onChange={this.vatChange}
                  />}
                label="VAT"
              />
              : ""
            }
          </Typography>
          { this.state.edit ?
            <TextField
              id="c_cuid"
              label="CUID"
              variant="outlined"
              value={this.state.company.cuid || ''}
              onChange={this.changeValue}
              sx= {{marginTop: 1, maxWidth: "15%", minWidth: "10%" }}
              onFocus={event => { event.target.select(); }}
              inputProps={{maxLength:"16"}}
              required={true}
              error={this.state.companyCUIDValid === false}
            />
            : ""
          }
          { this.state.edit ?
            <IconButton 
              aria-label="delete"
              onClick={this.queryData}
            >
              <HelpCenterIcon />
            </IconButton>
            : ""
          } 
          { this.state.edit ?
            <TextField
              id="c_ruid"
              label="RUID"
              value={this.state.company.ruid || ''}
              variant="outlined"
              onChange={this.changeValue}
              sx= {{marginTop: 1, maxWidth: "15%", minWidth: "10%" }}
              onFocus={event => { event.target.select(); }}
              inputProps={{maxLength:"16"}}
              required={true}
              error={this.state.companyRUIDValid === false}
            />
            : ""
          }
          { this.state.edit ?
            <TextField
              id="c_name"
              label="Company Name"
              value={this.state.company.name || ''}
              variant="outlined"
              onChange={this.changeValue}
              sx= {{marginTop: 1 }}
              onFocus={event => { event.target.select(); }}
              inputProps={{maxLength:"64"}}
              required={true}
              error={this.state.companyNameValid === false}
            />
            : ""
          }
          { this.state.edit ?
            <TextField
              id="c_a_name"
              label="Address Name"
              value={address_name || ''}
              variant="outlined"
              onChange={this.changeValue}
              sx= {{marginTop: 1 }}
              onFocus={event => { event.target.select(); }}
              inputProps={{maxLength:"128"}}
            />
            : address_name
          }
          </CardActions>
          <CardActions disableSpacing sx={{display: "block", textAlign: "end", verticalAlign: "middle"}}>
            { this.state.edit ?
              <TextField
                id="c_a_street"
                label="Street"
                value={address_street || ''}
                variant="outlined"
                onChange={this.changeValue}
                sx={{minWidth: "30%", marginTop: 1}}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"255"}}
              />
              : "Str. " + address_street + ", "
            }
            { this.state.edit ?
              <TextField
                id="c_a_number"
                label="Number"
                value={address_number || ''}
                variant="outlined"
                onChange={this.changeValue}
                sx={{maxWidth: "64px", minWidth: "10%", marginTop: 1}}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"16"}}
              />
              : "nr. " + address_number + ", "
            }
            { this.state.edit ?
              <TextField
                id="c_a_entrance"
                label="Entrance"
                value={address_entrance || ''}
                variant="outlined"
                onChange={this.changeValue}
                sx={{maxWidth: "128px", minWidth: "10%", marginTop: 1}}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"32"}}
              />
              : "int. " + address_entrance + ", "
            }
            { this.state.edit ?
              <TextField
                id="c_a_local"
                label="Local"
                value={address_local || ''}
                variant="outlined"
                onChange={this.changeValue}
                sx={{maxWidth: "128px", minWidth: "10%", marginTop: 1}}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"128"}}
              />
              : "loc. " + address_local + ", "
            }
            { this.state.edit ?
              <TextField
                id="c_a_floor"
                label="Floor"
                value={address_floor || ''}
                variant="outlined"
                onChange={this.changeValue}
                sx={{maxWidth: "64px", minWidth: "10%", marginTop: 1}}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"16"}}
              />
              : "et. " + address_floor
            }
            { this.state.edit ?
              <TextField
                id="c_a_zip"
                label="Postal Code"
                value={address_postalCode || ''}
                variant="outlined"
                onChange={this.changeValue}
                sx={{maxWidth: "96px", minWidth: "10%", marginTop: 1}}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"16"}}
              />
              : ", " + address_postalCode
            }
          </CardActions>
                    
          <CardActions disableSpacing sx={{display: "block", textAlign: "end"}}>
          
              { this.state.edit ?
                <DropDown
                  name="Country"
                  value={address_country_select || ''}
                  doptions={countries}
                  hasIcons={false}   
                  minWidth={100}
                  maxWidth={200}
                  onDark={false}
                  onChangeFunction={this.addCountry}
                />
                : address_country
              },
            
              { this.state.edit ?
                <DropDown
                  name="County"
                  value={address_county_select || ''}
                  doptions={counties}
                  hasIcons={false}   
                  minWidth={100}
                  maxWidth={200}
                  onDark={false}
                  onChangeFunction={this.addCounty}
                />
                : address_county
              },
            
              { this.state.edit ?
                <DropDown
                  name="City"
                  value={address_city_select || ''}
                  doptions={cities}
                  hasIcons={false}   
                  minWidth={100}
                  maxWidth={200}
                  onDark={false}
                  onChangeFunction={this.addCity}
                />
                : address_city
              }
            
          </CardActions>
          
        </Box>
      </Box>         
          
      <Box sx={{ flexDirection: 'row', width: '100%', display: 'flex' }}>
             
        <Box sx={{ flexDirection: 'column', justifyContent:"right", width: '100%' }}>
          <CardActions disableSpacing sx={{display: "block", textAlign: "end" }}>                
        
          <ExpandMore
            expand={this.state.expanded}
            onClick={this.handleExpandClick}
            aria-expanded={this.state.expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
          </CardActions>
        </Box>
      </Box>
   
      { this.state.errorMessage !== "" ?
        <Box sx={{ flexDirection: 'row', width: '100%', display: 'block' }}>
          <CardActions disableSpacing sx={{display: "block", textAlign: "middle"}}>
            <Typography variant="button" color="red" sx={{ color: "red", verticalAlign: "middle" }}>
                {this.state.errorMessage}
            </Typography>
          </CardActions>
        </Box>
        : ""
      }

      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit sx={{width: "100% !important"}}>
        <CardContent sx={{ display: 'flex' }}>
          <Box sx={{ display: 'block', flexDirection: 'column' }}>
          
            { this.state.edit ?
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                name="Representative"
                options={representativesSearch}
                sx={{ width: 500 }}
                onChange={this.representativeChange}
                renderInput = {
                  (params) =>
                    <TextField 
                      {...params}
                      label="Representative (type keywords)"
                      onChange={this.representativeSearch}
                    />}
              />
              : ""
            }
          {/* </CardContent> */}
          </Box>
          <Box sx={{ display: 'block', flexDirection: 'row', marginLeft: "10px" }}>
            { this.state.edit && hasPerson ?
              <Button
                variant="outlined"
                color="error"
                size="small"
                sx={{ color: "#b51f0b" }}
                startIcon={<PersonRemoveIcon />}
                onClick={this.detachUser}
              >
                Detach Representative
              </Button>
            : ""
            }
          </Box>
        </CardContent>
        <CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "start", justifyContent:"left" }}>
            <Box sx={{ flexDirection: 'column', display: "block", width: '30%', justifyContent:"left" }}>
              <CardMedia
                  component="img"
                  height="120"
                  sx={{ width: 128, marginLeft: 1 }}
                  image={representative_image}
                  alt={representative_fullName}
                />
            </Box>
            <Box sx={{ flexDirection: 'column', display: "block", width: '70%', justifyContent:"left" }}>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <b>{representative_fullName}</b>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>            
                {representative_email}, {representative_phone}
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>            
                {representative_address_street}, nr. {representative_address_number}, et. {representative_address_floor}, {representative_address_postalCode}
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                {representative_address_geo}                
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>            
                {representative_address_gps_lg}, {representative_address_gps_lt}
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "end", justifyContent:"right", alignItems: "right", paddingTop: "8px" }}>
            { this.state.edit && this.state.somethingChanged ?
              <Button
                variant="contained"
                size="small"
                sx={{ color: "#e3e3e5" }}
                startIcon={<SaveIcon />}
                onClick={this.pushData}
              >
                Save
              </Button>
            : ""
            }
            
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "middle", justifyContent:"center", alignItems: "center", paddingTop: "8px" }}>
            { /*<Typography>Employees</Typography>*/}
            <Table sx={{ minWidth: 700, textAlign: "middle", justifyContent:"center" }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Image</StyledTableCell>
                  <StyledTableCell align="left">First Name</StyledTableCell>
                  <StyledTableCell align="left">Last Name</StyledTableCell>            
                  <StyledTableCell align="left">Phone</StyledTableCell>            
                  <StyledTableCell align="left">Email</StyledTableCell>
                  <StyledTableCell align="left">Birthday</StyledTableCell>
                  <StyledTableCell align="left">Username</StyledTableCell>
                  <StyledTableCell align="left">Card</StyledTableCell>
                  <StyledTableCell align="left">Level</StyledTableCell>
                  {/* <StyledTableCell align="right">Action</StyledTableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {allData} */}
              </TableBody>
            </Table>
            
          </Box>



          
          
          </CardContent>

        </Collapse>

      </Box>
    </Card>
   
    );

  }
}

export default Company;