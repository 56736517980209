import React from 'react';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
import { FaHome, FaPizzaSlice, FaHamburger, FaMapMarkedAlt, FaEuroSign, FaUserTie, FaHospitalUser, FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
/* import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'; */
import Client from './Client';
import Allergen from './Allergen';
import Category from './Category';
import SubCategory from './SubCategory';
import ProductClass from './ProductClass';
import DropDown from './DropDown';

//import './css/dashboard.css';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Checkbox from '@mui/material/Checkbox';

//import dayjs, { Dayjs } from 'dayjs';
//import Stack from '@mui/material/Stack';
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import noImage from './../images/icons/noImage_1.png';
import idle_off from './../images/icons/idle_on.png';
import idle_on from './../images/icons/idle_off.png';
import registered_off from './../images/icons/registered_on.png';
import registered_on from './../images/icons/registered_off.png';
import active_off from './../images/icons/active_on.png';
import active_on from './../images/icons/active_off.png';
import discontinued_off from './../images/icons/discontinued_on.png';
import discontinued_on from './../images/icons/discontinued_off.png';

let theme = createTheme();

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

class ListsCompanyFilters extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      productCategories: null,
      expanded: false,
      addNewProduct: false,
      filters_reset: 1,
      

      filtersCounter: 0,
      resetState: false,
      resetClasses: false,
      resetCategories: false,
      resetCategoriesList: { },
      resetSubCategoriesList: { },
      resetClassesList: { },
      resetSubCategories: false,
      filters: { },
      countySelected: 0,
      locationGroupSelect: 0,

      checked_active: false,
      checked_withVAT: false,

      dateFrom: "2022-01-01",
      dateTo: "2022-01-01",
    };

    this.queryData = { };
    this.productStatus = { "registered": 0, "active": 1, "idle": 2, "discontinued": 9 };
    this.searchKeyword = React.createRef();
    this.categoriesStates = { };

    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.resetStatus = this.resetStatus.bind(this);
    this.removeQueryElement = this.removeQueryElement.bind(this);
    this.addQueryElement = this.addQueryElement.bind(this);
    this.toggleElement = this.toggleElement.bind(this);
    this.searchKey = this.searchKey.bind(this);
    
    this.resetFilters = this.resetFilters.bind(this);
    this.setFlagsFilters = this.setFlagsFilters.bind(this);
    this.setStatusFilters = this.setStatusFilters.bind(this);
    
   /*  this.getClasses = this.getClasses.bind(this); */
    this.toggleAdd = this.toggleAdd.bind(this);

    this.addCountry = this.addCountry.bind(this);
    this.addCounty = this.addCounty.bind(this);
    this.addCompany = this.addCompany.bind(this);
    this.addDateFrom = this.addDateFrom.bind(this);
    this.addDateTo = this.addDateTo.bind(this);

  }

  componentDidMount() {        
    /* let productsCategories = this.state.client.getProductsCategories ( );
    this.setState ( { productCategories: productsCategories.productCategories }); */
    //Set date = today
    let today = new Date();
    let month = parseInt(today.getMonth());
    month += parseInt ( 1 );
    let currentDate = today.getFullYear() + "-" + month + "-" + today.getDate();
    this.setState ( {dateFrom: currentDate});
    this.setState ( {dateTo: currentDate});    
  }

  componentWillUnmount() {
    //this.searchKeyword = React.useRef(null);
  }

  /* componentWillReceiveProps ( nextProps ) {
    
  } */

  componentDidUpdate ( prevProps ) {

    if ( prevProps.classesList !== this.props.classesList ) {
      let classesRender = "";
      if ( this.props.classesList ) {
        let classesArr = Object.entries ( this.props.classesList );

        var classesStates = this.state.resetClassesList;
        classesRender = classesArr.map ( (cls) => {
          let iref = "";
          if ( cls.length === 2 ) iref = cls [ 1 ].iref;          
          else iref = cls [ 0 ].iref;          
          classesStates [ iref ] = false;
        });
        this.setState ( {resetClassesList: classesStates} );
      }
    }
  }

  handleExpandClick ( ) {
    this.setState({ expanded: !this.state.expanded });
  }

  setStatusFilters ( value ) {
    this.setState ( {filters_status_active: value} );
    this.setState ( {filters_status_idle: value} );
    this.setState ( {filters_status_registered: value} );
    this.setState ( {filters_status_discontinued: value} );
  }

  setFlagsFilters ( value ) {
    //this.setState ( {filters_flags_canGenerateFidelityPoints: value} );
    this.setState ( {filters_flags_isDeliverable: value} );
    this.setState ( {filters_flags_isDiscountable: value} );
    this.setState ( {filters_flags_isForSale: value} );
    this.setState ( {filters_flags_isNew: value} );
    this.setState ( {filters_flags_isSellable: value} );
  }

  toggleAdd ( ) {
    let addButton = this.state.addNewProduct;
    this.setState ( {addNewProduct: !addButton} );
    this.props.toggleAdd ( );
  }

  searchKey ( e ) {    
    let keyword = e.target.value;
    if ( keyword.length > 2 ) {
      this.addQueryElement ( "nameLike", keyword );
      this.props.applyFilters ( this.queryData );
    } else {
      if ( "nameLike" in this.queryData ) {
        delete this.queryData [ "nameLike" ];
        this.props.applyFilters ( this.queryData );
      }
    }
    let filtersCounter = Object.keys(this.queryData).length;
    this.setState ( {filtersCounter: filtersCounter} );
  }

  resetStatus ( element ) {
    /* if ( "status" in this.queryData )        
      delete this.queryData.status; */
    if ( element !== "active" ) this.setState ( { filters_status_active: 0 } );
    if ( element !== "idle" ) this.setState ( { filters_status_idle: 0 } );
    if ( element !== "registered" ) this.setState ( { filters_status_registered: 0 } );
    if ( element !== "discontinued" ) this.setState ( { filters_status_discontinued: 0 } );
  }

  removeQueryElement ( element ) {
    if ( element ) {
      if ( element in this.queryData ) {
        if ( this.queryData [ element ] !== null && this.queryData [ element ] !== undefined ) {
          delete this.queryData [ element ];
        }
      }
    }
  }

  addQueryElement ( element, value ) {
    //console.log ( "addQueryElement: " + element + " -> " + value );
    if ( element ) {
      if ( element in this.queryData ) {
        if ( this.queryData [ element ] !== null && this.queryData [ element ] !== undefined && value !== null && value !== undefined ) {
          if ( this.queryData [ element ] != value ) this.queryData [ element ] = value;
          else delete this.queryData [ element ];
        } else {
          delete this.queryData [ element ];
        }
      } else {
        if ( value !== null && value !== undefined )
          this.queryData [ element ] = value;
        else
          this.queryData [ element ] = 1;
      }
    }

    //console.log ( "addQueryElement ---> " );
    //console.log ( this.queryData );
  }

  toggleElement ( prefix, element, value ) {
    let stateName = "filters_" + prefix + "_" + element;
    let status = this.state [ stateName ];
    this.setState ( { [stateName]: !status } );
    if ( prefix == "status" ) element = "status";
    if ( value !== null && value !== undefined ) {
      this.addQueryElement ( element, value );
    } else {
      this.addQueryElement ( element );
    }
  }


  toggleFilter ( filterType, filterName ) {
    ////console.log ( this.state.productCategories );

    switch ( filterType ) {
      case "status":
        this.resetStatus ( filterName );
        let statusValue = this.productStatus [ filterName ];
        this.toggleElement ( "status", filterName, statusValue );
        let sC = this.state.checked_active;
        this.setState ( {checked_active: !sC })
        break;      
      case "withVAT":        
        this.removeQueryElement ( "withVAT" );        
        this.addQueryElement ( "withVAT", filterName );
        let vC = this.state.checked_withVAT;
        this.setState ( {checked_withVAT: !vC })
        break;
      case "subcategories":
        this.resetAllSubCategoriesButThis ( filterName );
        this.addQueryElement ( "pSubCategory", filterName );
        break;
      case "classes":
        this.resetAllClassesButThis ( filterName );
        this.addQueryElement ( "pClass", filterName );
        this.setState ( {resetClasses: true});
        break;
    }

    let filtersCounter = Object.keys(this.queryData).length;
    this.setState ( {filtersCounter: filtersCounter} );
    //console.log ( this.queryData );

    //if ( Object.keys(this.queryData).length > 0 ) {
    this.props.applyFilters ( this.queryData );
    this.setState ( {resetState: false});
    this.setState ( {resetClasses: false});
    this.setState ( {resetCategories: false});
    this.setState ( {resetSubCategories: false});
    //}
  }

  addCountry ( key ) {
    if ( key === "0" ) this.removeQueryElement ( "country" );
    else this.addQueryElement ( "country", key );
    let filtersCounter = Object.keys(this.queryData).length;
    this.setState ( {filtersCounter: filtersCounter} );    
    this.props.applyFilters ( this.queryData );
  }

  addCounty ( key ) {
    if ( key === "0" ) this.removeQueryElement ( "county" );
    else this.addQueryElement ( "county", key );
    let filtersCounter = Object.keys(this.queryData).length;
    this.setState ( {filtersCounter: filtersCounter} );    
    this.props.applyFilters ( this.queryData );
  }

  addCompany ( key ) {
    if ( key === "0" ) this.removeQueryElement ( "company" );
    else this.addQueryElement ( "company", key );
    let filtersCounter = Object.keys(this.queryData).length;
    this.setState ( {filtersCounter: filtersCounter} );    
    this.props.applyFilters ( this.queryData );
  }

  addDateFrom ( ekey ) {
    let key = "0";
    if ( ekey ) {
      if ( ekey.target )
        if ( ekey.target.value ) key = ekey.target.value;
    }    
    if ( key === "0" ) this.removeQueryElement ( "dateFrom" );
    else this.addQueryElement ( "dateFrom", key );
    let filtersCounter = Object.keys(this.queryData).length;
    this.setState ( {filtersCounter: filtersCounter} );    
    this.props.applyFilters ( this.queryData );
    this.setState ( {dateFrom: key})
  }

  addDateTo ( ekey ) {
    let key = "0";
    if ( ekey ) {
      if ( ekey.target )
        if ( ekey.target.value ) key = ekey.target.value;
    }
    if ( key === "0" ) this.removeQueryElement ( "dateTo" );
    else this.addQueryElement ( "dateTo", key );
    let filtersCounter = Object.keys(this.queryData).length;
    this.setState ( {filtersCounter: filtersCounter} );    
    this.props.applyFilters ( this.queryData );
    this.setState ( {dateTo: key})
  }

  resetAllClassesButThis ( category ) {
    let classesArr = Object.entries ( this.state.resetClassesList );
    let cat = {}
    for ( var filter of classesArr ) {
      if ( filter [ 0 ] === category ) cat [ filter [ 0 ] ] = false; //this.classesStates [ filter ] = false;
      else cat [ filter [ 0 ] ] = true; //this.classesStates [ filter ] = true;
    }
    this.setState ( { resetClassesList : cat } );
  }

  resetFilters ( ) {
    if ( this.queryData ) this.queryData = { };
    this.setStatusFilters ( 0 );
    this.setFlagsFilters ( 0 );
    this.removeQueryElement ( "company" );
    //this.setState ( { locationGroupSelect: 0 } );
    this.removeQueryElement ( "whitVAT" );
    this.setState ( { countySelected: 0 } );
    this.removeQueryElement ( "dateFrom" );
    this.setState ( { locationGroupSelect: 0 } );
    this.removeQueryElement ( "dateTo" );
    this.setState ( {filters_reset: 1} );
    this.searchKeyword.current.value = "";
    this.setState ( {filtersCounter: 0} );
    this.props.applyFilters ( this.queryData );
    this.setState ( {resetState: true});
    this.setState ( {resetClasses: true});
    this.setState ( {resetCategories: true});
    this.setState ( {resetSubCategories: true});
    //this.resetAllClassesButThis ( "" );
    //this.resetAllSubCategoriesButThis ( "" );
    //this.resetAllCategoriesButThis ( "" );
    this.setState ( {checked_active: false});
    this.setState ( {checked_withVAT: false});

    //Set date = today
    let today = new Date();
    let month = parseInt(today.getMonth());
    month += parseInt ( 1 );
    let currentDate = today.getFullYear() + "-" + month + "-" + today.getDate();
    this.setState ( {dateFrom: currentDate});
    this.setState ( {dateTo: currentDate});
  }

  render() { 

    /* let classesRender = null;
    classesRender = this.getClasses ( );
    if ( this.state.filters_reset === 1 ) {
      //classesRender = this.getclasses ( );
      //if ( classesRender != null ) this.setState ( {filters_reset: 0} );
    } */

    let countriesValue = "";
    let countries = {};
    if ( this.props.countries ) countries = this.props.countries;
    if ( Object.keys(countries).length === 1 ) countriesValue = Object.keys(countries)[0];
    //console.log ( countries );

    let counties = {"0": "All"};
    if ( this.props.counties ) Object.assign ( counties, counties, this.props.counties );    
    //console.log ( counties );

    
    let companies = {"0": "All"};
    if ( this.props.companies ) {
      let companiesObject = Object.entries ( this.props.companies );
      //Object.assign ( companies, companies, this.props.companies );
      companiesObject.map ( (l) => {
        companies [ l[1].iref ] = l[1].name + "(" + l[1].cuid + ")";
      });
    } 

    let countySelect = this.state.countySelected;

    //Set date = today
    let today = new Date();
    let month = parseInt(today.getMonth());
    month += parseInt ( 1 );
    let currentDate = today.getFullYear() + "-" + month + "-" + today.getDate();

    let listsCounter = 0;
    if ( this.props ) {
      if ( this.props.listsCounter ) listsCounter = this.props.listsCounter;
    }

    return (
      <Card sx={{ borderRadius: '16px', backgroundColor: "#214a80" }}>
      
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>

        <CardActions disableSpacing sx={{display: "block", textAlign: "flex-end"}}>          
          <IconButton aria-label="Add Company List" title="Add Company List" onClick={() => this.toggleAdd()}>       
            <Avatar alt="Add Company List" sx={{ width: 24, height: 24, color: "#273442" }}>
            {this.state.addNewProduct ? <FaMinusCircle/> : <FaPlusCircle/>}
              </Avatar>
          </IconButton>
        </CardActions>


        <CardActions disableSpacing sx={{display: "flex", textAlign: "start"}}>
          
          <Typography
            variant="body2"
            color="#e3e3e5"
            sx={{ color: "#e3e3e5", marginLeft: "2px" }}
          >
            Status:
          </Typography>
          <Checkbox aria-label="Active" title="Active" onClick={() => this.toggleFilter("status", "active")} checked={this.state.checked_active}>
            {/* <Avatar alt="Active" src={this.state.filters_status_active ? active_on : active_off} sx={{ width: 24, height: 24 }}/> */}
          </Checkbox>

          {/* <IconButton aria-label="Idle" title="Idle" onClick={() => this.toggleFilter( "status", "idle" )}>
            <Avatar alt="Idle" src={this.state.filters_status_idle ? idle_on : idle_off} sx={{ width: 24, height: 24 }}/>
          </IconButton>
          <IconButton aria-label="Registered" title="Registered" onClick={() => this.toggleFilter( "status", "registered" )}>
            <Avatar alt="Registered" src={this.state.filters_status_registered ? registered_on : registered_off} sx={{ width: 24, height: 24 }}/>
          </IconButton> */}
          
        </CardActions>

        {/* <DropDown
          name="Country"
          value={countriesValue}
          doptions={countries}
          hasIcons={false}   
          minWidth={100}
          onDark={true}
          onChangeFunction={this.addCountry}
        />

        <DropDown
          name="County"
          value={countySelect}
          doptions={counties}
          hasIcons={false}   
          minWidth={100}  
          onDark={true}
          onChangeFunction={this.addCounty}
        /> */}

        <DropDown
          name="Company"
          value={this.state.locationGroupSelect}
          doptions={companies}
          hasIcons={false}   
          minWidth={120}  
          onDark={true}
          onChangeFunction={this.addCompany}
          InputLabelProps={{ sx : { color: "#e3e3e5" } }}
        />


        <TextField
          id="date"
          label="From"
          type="date"
          defaultValue={currentDate}
          value={this.state.dateFrom}
          inputFormat="DD/MM/YYYY"
          sx={{ color: "#e3e3e5", alignSelf: "center", marginLeft: "16px" }}
          InputLabelProps={{
            shrink: true,
            sx : { color: "#e3e3e5" }
          }}
          InputProps={{ sx : { color: "#e3e3e5" } }}
          onChange={this.addDateFrom}
        />

        <TextField
          id="date"
          label="To"
          type="date"
          defaultValue={currentDate}
          value={this.state.dateTo}
          sx={{ color: "#e3e3e5", alignSelf: "center", marginLeft: "16px" }}
          InputLabelProps={{
            shrink: true,
            sx : { color: "#e3e3e5" }
          }}
          InputProps={{ sx : { color: "#e3e3e5" } }}
          onChange={this.addDateTo}
        />

        {/* <DesktopDatePicker
          label="From"
          inputFormat="MM/DD/YYYY"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />

        <DesktopDatePicker
          label="To"
          inputFormat="MM/DD/YYYY"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        /> */}
      
        <TextField
          id="standard-basic"
          inputRef={this.searchKeyword}
          label="Keywords"
          variant="standard"
          minWidth={120}  
          inputProps={{ sx : { color: "#e3e3e5" } }}
          InputLabelProps={{ sx : { color: "#e3e3e5" } }}
          onChange={(e) => this.searchKey( e )}
          sx={{ color: "#e3e3e5", marginLeft: "16px" }}
        />


          <Typography
            variant="body2"
            color="#e3e3e5"
            sx={{ color: "#e3e3e5", alignSelf: "center", marginLeft: "16px" }}
          >
            VAT:
          </Typography>
        <Checkbox aria-label="vat" title="vat" onClick={() => this.toggleFilter("withVAT", true)} checked={this.state.checked_withVAT}>
          {/* <Avatar alt="Active" src={this.state.filters_status_active ? active_on : active_off} sx={{ width: 24, height: 24 }}/> */}
        </Checkbox>

        <Typography
          variant="body2"
          color="#e3e3e5"
          sx={{ color: "#e3e3e5", marginTop: "auto", marginLeft: "16px" }}
        >
          Lists: {listsCounter}
        </Typography>

        <Typography
          variant="body2"
          color="#e3e3e5"
          sx={{ color: "#e3e3e5", marginTop: "auto", marginLeft: "16px" }}
        >
          Filters: {this.state.filtersCounter}
        </Typography>
        { this.state.filtersCounter > 0 ?
          <IconButton
            aria-label="Remove Filters"
            title="Remove Filters"
            onClick={() => this.resetFilters ( )}
            sx = {{marginTop: "auto"}}
          >
            <Avatar alt="Remove Filters" sx={{ width: 16, height: 16, color: "#99302e" }}>
              <HighlightOffIcon/>
              </Avatar>
          </IconButton>
            : ""
        }

        <ExpandMore
          expand={this.state.expanded}
          onClick={this.handleExpandClick}
          aria-expanded={this.state.expanded}
          aria-label="show more"
          sx={{ color: "#e3e3e5", textAlign: "end" }}
        >
          <ExpandMoreIcon />
        </ExpandMore>
        
      </Box>    

        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
        <CardContent>

          {/* <Box sx={{ display: 'block', flexDirection: 'row' }}>  
            <CardActions disableSpacing sx={{display: "block", textAlign: "left"}}>
              {classesRender}
            </CardActions>         
          </Box> */}
          
          <Box sx={{ display: 'block', flexDirection: 'row' }}>  
            <CardActions disableSpacing sx={{display: "block", textAlign: "left"}}>
              "aaa"
            

            </CardActions>         
          </Box>

          <Box sx={{ display: 'block', flexDirection: 'row' }}>
          <CardActions disableSpacing sx={{display: "block", textAlign: "left" }}>
                      
            {/* <IconButton aria-label="isDeliverable" title="isDeliverable" onClick={() => this.toggleFilter( "flags", "isDeliverable" )}>
                <Avatar alt="isDeliverable" src={this.state.filters_flags_isDeliverable ? isDeliverable_off : isDeliverable_on} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton aria-label="isDiscountable" title="isDiscountable" onClick={() => this.toggleFilter( "flags", "isDiscountable" )}>
                <Avatar alt="isDiscountable" src={this.state.filters_flags_isDiscountable ? isDiscountable_off : isDiscountable_on} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton aria-label="isForSale" title="isForSale" onClick={() => this.toggleFilter( "flags", "isForSale" )}>
                <Avatar alt="isForSale" src={this.state.filters_flags_isForSale ? isForSale_off : isForSale_on} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            
            <IconButton aria-label="isNew" title="isNew" onClick={() => this.toggleFilter( "flags", "isNew" )}>
                <Avatar alt="isNew" src={this.state.filters_flags_isNew ? isNew_off : isNew_on} sx={{ width: 24, height: 24 }}/>
            </IconButton>
           
            <IconButton aria-label="isSellable" title="isSellable" onClick={() => this.toggleFilter( "flags", "isSellable" )}>
                <Avatar alt="isSellable" src={this.state.filters_flags_isSellable ? isSellable_off : isSellable_on} sx={{ width: 24, height: 24 }}/>
            </IconButton> */}
            
            </CardActions>
          </Box>
            
          </CardContent>

        </Collapse>

        {/* </Box> */}




    </Card>   
    );

  }
}

export default ListsCompanyFilters;