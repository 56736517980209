import React from 'react';
import { useRef } from "react";
//import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import KImage from './KImage';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import { FaExternalLinkAlt } from 'react-icons/fa';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import noImage from './../images/noImage.png';
import Client from './Client';
import Autocomplete from '@mui/material/Autocomplete';
import DropDown from './DropDown';
import Config from './ClientConfig';
import BackOfficeConfig from './../Config';
import swal from 'sweetalert';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#273442",
    color: theme.palette.common.white,
    paddingLeft: "4px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    paddingLeft: "4px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


class KSubCategoriesTable extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      edit: [],
      parentSubCategories: [],
      options: { },
      canvas: null,
      saveURL: "",
      parentIref: "",
      showConfirm: false,
      removeIref: "",
      subCategories: [],
      subCategories_select: []
      //showValue: []
    };

    //this.handleExpandClick = this.handleExpandClick.bind(this);
    this.sendChangedState = this.sendChangedState.bind(this);   
    this.saveBack = this.saveBack.bind(this);    
    this.changeValue = this.changeValue.bind(this);
    this.changePriceType = this.changePriceType.bind(this);
    this.editor = React.createRef();
    this.handleEditClick = this.handleEditClick.bind(this);
    this.changeName = this.changeName.bind(this);
    this.toggleAdd = this.toggleAdd.bind(this);
    this.removeSubCategory = this.removeSubCategory.bind(this);
    this.removeConfirm = this.removeConfirm.bind(this);
    this.removeCancel = this.removeCancel.bind(this);
    this.showConfirmDialog = this.showConfirmDialog.bind(this);
    this.getSubCategories = this.getSubCategories.bind(this);
    this.changeSubCategory = this.changeSubCategory.bind(this);
    /* this.subCategorySearch = this.subCategorySearch.bind(this);
    this.subCategoryChange = this.subCategoryChange.bind(this); */
  }

  componentDidMount() {
    if ( this.props.subCategories ) this.setState ( { parentSubCategories: this.props.subCategories } );
    if ( this.props.edit ) this.setState ( { edit: this.props.edit } );
    if ( this.props.saveURL ) this.setState ( { saveURL: this.props.saveURL } );
    if ( this.props.removeURL ) this.setState ( { removeURL: this.props.removeURL } );
    if ( this.props.parentIref ) this.setState ( { parentIref: this.props.parentIref } );

    //console.log ( this.props.images );
    let edit = false;
    if ( this.props.edit ) edit = this.props.edit;
    this.setState ( {edit: edit} );

    let editArray = [];
    if ( this.props.subCategories )
      if ( this.props.subCategories.length > 0 ) {
        this.props.subCategories.forEach(element => {
          /* let pair = {};
          pair [ element.iref ] = false;
          editArray.push ( pair ); */

          editArray [ element.iref ] = false;
        });
      }

    this.setState ( { edit: editArray } );
    this.getSubCategories ( );
  }

  componentDidUpdate ( prevProps ) {    
    if ( prevProps !== this.props ) {
      if ( this.props.subCategories ) this.setState ( { parentSubCategories: this.props.subCategories } );
      if ( this.props.edit ) this.setState ( { edit: this.props.edit } );
      if ( this.props.saveURL ) this.setState ( { saveURL: this.props.saveURL } );
      if ( this.props.removeURL ) this.setState ( { removeURL: this.props.removeURL } );
      if ( this.props.parentIref ) this.setState ( { parentIref: this.props.parentIref } );

      //console.log ( this.props.subCategories );
      let edit = false;
      if ( this.props.edit ) edit = this.props.edit;
      this.setState ( {edit: edit} );

      let editArray = [];
      if ( this.props.subCategories )
        if ( this.props.subCategories.length > 0 ) {
          this.props.subCategories.forEach(element => {
            /* let pair = {};
            pair [ element.iref ] = false;
            editArray.push ( pair ); */

            editArray [ element.iref ] = false;
          });
        }

      this.setState ( { edit: editArray } );
    }
  }

  getSubCategories ( ) {
    let queryData = { };
    let data = this.state.client.getProductsSubCategories ( queryData );
    if ( data ) {
      let subCategories = {};
      for ( var i = 0; i < data.productSubCategories.length; i++ ) {
        subCategories [ data.productSubCategories [ i ].iref ] = {          
          "name": data.productSubCategories [ i ].name,
          "image": data.productSubCategories [ i ].image
          }
      }
      this.setState ( { subCategories_select: subCategories } );
      this.setState ( { subCategories: data.productSubCategories } );
    }
  }

  sendChangedState ( e ) {
    
  }

  saveBack ( product, e ) {
    let iref = e;
    let uValue = this.state.subCategories;
    for ( let i = 0; i < uValue.length; i++ ) {
      if ( uValue [ i ].iref == iref )
        uValue [ i ].product = product;
    }
    this.setState ( { subCategories: uValue } );

    /* let edits = this.state.edit;
    edits [ iref ] = false;
    this.setState ( { edit: edits } ); */
  }

  pushData ( e ) {
    let image = null;
    let newMain = null;
    let parentIref = e;

    let data = null;
    for ( let i = 0; i < this.state.parentSubCategories.length; i++ ) {
      if ( this.state.parentSubCategories [ i ].iref == parentIref ) {
        data = this.state.parentSubCategories [ i ];
        data.productCategory = {};
        let subCategoryIref = data.productSubCategory.iref;
        data.productSubCategory = {};
        data.subCategoryIref = subCategoryIref;
      }
    }
    if (!data) {
      swal ( "Error", "Add/Update SubCategory failed !", "error" );
      return;
    }
    data.parentIref = this.state.parentIref;
    let rdata = this.state.client.pushData ( this.state.saveURL, data );
    if ( rdata ) {
      //let products = this.state.products;
      if ( rdata ) {
        /* for ( let i = 0; i < products.length; i++ ) {
          if ( products [ i ].iref == e ) {
            products [ i ] = rdata;
          }
        } */
        //this.setState ( { products: products } );
        this.handleEditClick ( e );
        swal ( "Success", "Successfully added/modified SubCategory ! (" + rdata.iref + ")", "success", {
          buttons: false,
          timer: 2000,
        })
        .then((value) => {
          this.setState ( { addNew: false } );
          //this.props.imageUpdated ( );
          this.props.subCategoryUpdated();
          this.setState ( { somethingHasChanged: false } );            
        });        
      } else {
        if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
        swal ( "Error", "Add/Update SubCategory failed !", "error" );
      }
    }
  }

  changeName ( e ) {
    if ( this.state.parentSubCategories ) {
      if ( this.state.parentSubCategories.length > 0 ) {
        let uValue = this.state.parentSubCategories;
        for ( let i = 0; i < uValue.length; i++ ) {
          if ( uValue [ i ].iref == e.target.id )
            uValue [ i ].name = e.target.value;
        }        
        this.setState ( { parentSubCategories: uValue } );
      }
    }
  }

  changeValue ( e ) {
    let subCategories = [];
    //console.log (e);
    let value = e.target.value;
    let iref = e.target.id;
    //Check if float
    if ( this.state.parentSubCategories ) subCategories = this.state.parentSubCategories;
    for ( var element of subCategories ) {
      if ( element.iref == iref ) {
        element.priceValue = value;
      }
    }
    this.setState ( { parentSubCategories: subCategories } );
  }

  changePriceType ( iref, v ) {
    let subCategories = [];
    if ( this.state.parentSubCategories ) subCategories = this.state.parentSubCategories;
    for ( var element of subCategories ) {
      if ( element.iref == iref ) {
        element.priceType = v;
      }
    }
    this.setState ( { parentSubCategories: subCategories } );
  }

  handleEditClick ( e ) {
    let pRef = e;
    let uEdit = this.state.edit;
    let flag = !uEdit [ pRef ];
    uEdit [ pRef ] = flag;
    this.setState({ edit: uEdit });
  }

  toggleAdd ( ) {
    let addNew = this.state.addNew;
    this.setState ( { addNew: !addNew } );

    let uValue = this.state.parentSubCategories;
    
    let uEdit = this.state.edit;
    if ( !addNew ) {
      let newSubCategory = {
        iref: "newSubCategory",
        product: {}
      };
      uValue.unshift ( newSubCategory );
      uEdit [ "newSubCategory" ] = true;
    } else {
      if ( uValue.length > 0 ) {
        if ( uValue [ 0 ].iref === "newSubCategory" )
          uValue.shift();
          delete uEdit [ "newSubCategory" ];
      }
    }
    this.setState ( { parentSubCategories: uValue } );
    this.setState ( { edit: uEdit } );
  }  

  removeSubCategory ( e ) {
    if ( e === "newSubCategory" ) {
      this.handleEditClick ( e );
      this.props.subCategoryUpdated();
      return;
    }
    let subCategories = this.state.parentSubCategories;
    let edit = this.state.edit;
    let product = { "iref": e };
    let rdata = this.state.client.pushData ( this.state.removeURL, product );
    if ( rdata ) {
      if ( rdata.status == "OK" ) {
        for ( let i = 0; i < subCategories.length; i++ ) {
          if ( subCategories [ i ].iref == e ) {
            subCategories.splice ( i, 1 );
            edit.splice ( i, 1 );
          }
        }
        this.setState ( { parentSubCategories: subCategories } );
        this.setState ( { edit: edit } );
        this.handleEditClick ( e );
        swal ( "Success", "Successfully removed Sub Category ! (" + rdata.iref + ")", "success", {
          buttons: false,
          timer: 2000,
        })
        .then((value) => {
          //this.props.productUpdated ( );
          this.props.productUpdated();
          this.setState ( { somethingHasChanged: false } );            
        });        
      } else {
        if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
        swal ( "Error", "Remove Sub Category failed !", "error" );
      }
    }
  }

  showConfirmDialog ( e ) {
    this.setState ( { showConfirm: true } );
    this.setState ( { removeIref: e } );
  }

  removeConfirm ( iref ) {
    //console.log ( "Removing: " + iref );
    let removeIref = this.state.removeIref;
    this.removeSubCategory ( removeIref );
    this.setState ( { showConfirm: false } );
    this.setState ( { removeIref: "" } );
  }

  removeCancel ( ) {
    this.setState ( { showConfirm: false } );
    this.setState ( { removeIref: "" } );
  }

  changeSubCategory ( e,  parentSubCategory_iref ) {
    var uSubCategory = this.state.parentSubCategories;
    for ( var i = 0; i < uSubCategory.length; i++ ) {
      if ( uSubCategory[i].iref === parentSubCategory_iref ) {
        for ( var k = 0; k < this.state.subCategories.length; k++) {
          if ( this.state.subCategories [k].iref === e ) {
            uSubCategory[i]["productSubCategory"] = this.state.subCategories [k];
          }
        }
        //uSubCategory[k]["productSubCategory"] = this.state.subCategories [ parseInt(e) ];
        //delete uSubCategory[k]["productSubCategory"];
        if ( uSubCategory[i].iref == "newSubCategory" ) {
          uSubCategory[i].name = "";
          uSubCategory[i].priceType = 0;
          uSubCategory[i].priceValue = 0;
        }

      }
    }
    this.setState ( { parentSubCategories: uSubCategory } );
    this.setState ( { somethingHasChanged: true } );
  }

  render() {
    let subCategory_name = "";
    let parentSubCategories = {};
    let parentSubCategoriesObj = {};

    let kImage = {
      data: noImage,
      height: 128
    };


    if ( this.state.parentSubCategories ) {
      parentSubCategories = this.state.parentSubCategories;
      parentSubCategoriesObj = this.state.parentSubCategories.map ((parentSubCategory) => {

        let showConfirm = false;
        if ( this.state.showConfirm )
          showConfirm = this.state.showConfirm;

        let priceType = "Unknown";
        if ( parentSubCategory.priceType !== undefined )
          priceType = BackOfficeConfig.PRODUCT_COMBO_PRODCUT_TYPES [ parentSubCategory.priceType ];

        let showValue = false;
        if ( parentSubCategory.priceType == 2 )
          showValue = true;

        let comboCategory_productCombo = {};
        let subCategory_iref = "";
        let subCategoryImage = noImage;
        if (parentSubCategory)
          if (parentSubCategory.productSubCategory) {
            subCategory_iref = parentSubCategory.productSubCategory.iref;
            subCategory_name = parentSubCategory.productSubCategory.name;
            subCategoryImage = parentSubCategory.productSubCategory.image
          }

        return <TableRow key={parentSubCategory.iref}>
          <StyledTableCell>
            {this.state.edit [ parentSubCategory.iref ] ?
              <DropDown
                name="Sub Category"
                id={parentSubCategory.iref}
                key={parentSubCategory.iref}
                value={subCategory_iref !== undefined ? subCategory_iref : ''}
                doptions={this.state.subCategories_select}
                hasIcons={true}   
                minWidth={220}
                maxWidth={220}
                onDark={false}
                onChangeFunction={(e) => this.changeSubCategory(e, parentSubCategory.iref)}
                marginTop={8}
                marginLeft={0}
                margin={0}
                error={this.state.subcategory_valid === false}
              />
              : 
              <IconButton aria-label={subCategory_name} title={subCategory_name}>       
                <Avatar alt={subCategory_name} src={subCategoryImage} sx={{ width: 48, height: 48 }}/>
              </IconButton>
            }
          </StyledTableCell>          

          <StyledTableCell>
              {subCategory_name}
          </StyledTableCell>

          <StyledTableCell>
          {this.state.edit [ parentSubCategory.iref ] ?
              <TextField
                id={parentSubCategory.iref}
                label="Name in Combo"
                value={parentSubCategory.name || ''}
                variant="outlined"
                onChange={this.changeName}
                sx={{minWidth: "90%", marginTop: 1}}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"100"}}
              />
              :
              parentSubCategory.name
            }
          </StyledTableCell>

          <StyledTableCell>
            {this.state.edit [ parentSubCategory.iref ] ?
              <DropDown
                id={parentSubCategory.iref}
                name="Price type"
                value={parentSubCategory.priceType !== undefined ? parentSubCategory.priceType : ''}
                doptions={BackOfficeConfig.PRODUCT_COMBO_PRODCUT_TYPES}
                hasIcons={false}   
                minWidth={120}
                maxWidth={220}
                onDark={false}
                onChangeFunction={(e) => this.changePriceType(parentSubCategory.iref,e)}
                marginTop={10}
                marginLeft={7}
                margin={0}
              />
              :
              priceType
            }            
          </StyledTableCell>

          <StyledTableCell>
            {showValue ? 
              this.state.edit [ parentSubCategory.iref ] ?
                <TextField
                  id={parentSubCategory.iref}
                  label="Value"
                  value={parentSubCategory.priceValue || ''}
                  variant="outlined"
                  /* onChange={() => {this.changeValue(parentSubCategory_iref, value);}} */
                  onChange={this.changeValue}
                  sx={{minWidth: "90%", marginTop: 1}}
                  onFocus={event => { event.target.select(); }}
                  inputProps={{maxLength:"100"}}
                />
                :
                parentSubCategory.priceValue
              : ""
            }
          </StyledTableCell>

          <StyledTableCell align="right" sx={{padding: "1px"}}>
          <IconButton aria-label="settings" id={"ib-" + subCategory_iref} onClick={() => {this.handleEditClick(parentSubCategory.iref);}}>
            <EditIcon
              id={subCategory_iref}
              sx={{ color: this.state.edit [ subCategory_iref ] ? "#8f241d" : "#e3e3e5" }}
            />
          </IconButton>
          {this.state.edit [ parentSubCategory.iref ]  ?
            <IconButton aria-label="settings" id={subCategory_iref} onClick={() => {this.pushData(parentSubCategory.iref);}}>
            <SaveIcon
              sx={{ color: "#4287f5" }}              
            />
          </IconButton>
          : ""}

          {this.state.edit [ parentSubCategory.iref ]  ?
            <IconButton aria-label="settings" id={subCategory_iref} onClick={() => {this.showConfirmDialog(parentSubCategory.iref);}}>
            <DeleteForeverIcon
              sx={{ color: "#8f241d" }}              
            />
          </IconButton>
          : ""}

          <Dialog
            open={showConfirm}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.removeCancel}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Remove product ?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                  This action will NOT delete the sub category ! This sub category will only be detached from the combo.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.removeCancel}>No</Button>
              <Button onClick={() => {this.removeConfirm(parentSubCategory.iref);}}>Yes</Button>
            </DialogActions>
          </Dialog>

        </StyledTableCell>

        </TableRow>
      });
    }
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "middle", justifyContent:"center", alignItems: "center", paddingTop: "2px" }}>
        <Table sx={{ minWidth: 700, textAlign: "middle", justifyContent:"center" }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <IconButton aria-label="Add Image" title="Add Image" onClick={() => this.toggleAdd()}> 
                  <Avatar alt="Add Image" sx={{ width: 24, height: 24, color: "#273442" }}>
                    {this.state.addNew ? <FaMinusCircle/> : <FaPlusCircle/>}
                  </Avatar>
                </IconButton>
                SubCategory
              </StyledTableCell>
              <StyledTableCell align="left">Name</StyledTableCell>
              <StyledTableCell align="left">Name in Combo</StyledTableCell>              
              <StyledTableCell align="left">Price Type</StyledTableCell>
              <StyledTableCell align="left">Value</StyledTableCell>
              <StyledTableCell align="right">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {parentSubCategoriesObj}
          </TableBody>
        </Table>
        
      </Box>
    );

  }
}

export default KSubCategoriesTable;
