import React from 'react';
import { Avatar, Box, Card, SvgIcon, Button, TextField, MenuItem } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { format } from 'date-fns';
import Chart from './chart';
import Client from '../Client';
import Config from '../ClientConfig';

/* function thousandsSeparator(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } */

  function addDays(date, days) {
    if(date) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      result = format(result, 'yyyy-MM-dd');
      return result;
    }
  }

let d = new Date();
let today = format(d, 'yyyy-MM-dd');  // mm=week, MM=month
let yesterday = format(new Date().setDate(new Date().getDate() - 1), 'yyyy-MM-dd');
let d1Month = format(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'yyyy-MM-dd'); //beginning of the month
// let dzMonth = format(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), 'yyyy-MM-dd'); //end of the month
// let d1Year = format(new Date(new Date().getFullYear(), 0, 1), 'yyyy-MM-dd'); //beginning of the year
// let dzYear = format(new Date(new Date().getFullYear(), 11, 31), 'yyyy-MM-dd'); //end of the year
let d1Week = format(new Date().setDate(new Date().getDate() - new Date().getDay() + 1), 'yyyy-MM-dd'); //beginning of the week
// let dzWeek = format(new Date().setDate(new Date().getDate() - new Date().getDay() + 7), 'yyyy-MM-dd'); //end of the week
// let d1LWeek = format(new Date().setDate(new Date().getDate() - new Date().getDay() - 13), 'yyyy-MM-dd'); //beginning of last week
// let dzLWeek = format(new Date().setDate(new Date().getDate() - new Date().getDay() + 7), 'yyyy-MM-dd'); //end of last week
// console.log(today);

const period = [
  {
      value: 'today',
      label: 'Today'
  },
  {
    value: 'yesterday',
    label: 'Yesterday'
  },
  {
      value: 'week',
      label: 'This Week'
  },
  {
      value: 'month',
      label: 'This Month'
  },
  {
      value: 'custom',
      label: 'Custom'
  }
];

export const iconMap = {
  Male: (
    <SvgIcon>
      <ManIcon color="success" />
    </SvgIcon>
  ),
  Female: (
    <SvgIcon>
      <WomanIcon color="secondary"/>
    </SvgIcon>
  ),
  Undefined: (
    <SvgIcon>
      <MoreHorizIcon color="warning" />
    </SvgIcon>
  ),
  'Not Specified': (
    <SvgIcon>
      <NotInterestedIcon color="primary"/>
    </SvgIcon>
  )
};  
  
class SalesYTDCategoryChart extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      salescategory_list: [],
      dateFrom: today,
      dateTo: today,
      location: 0,
      delivery: 2,
      sx_hidden: {display: 'none'},
      value: "1",
      locations: [{'iref': 0, 'name': "All Locations"}],
      deliverys: [{'iref': 2, 'name': "All"}, {'iref': 0, 'name': "Restaurant"}, {'iref': 1, 'name': "Delivery"}],
      filterData: {}
    };

    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);
    this.addDateFrom = this.addDateFrom.bind(this);
    this.addDateTo = this.addDateTo.bind(this);
    this.selectPeriod = this.selectPeriod.bind(this);
    this.selectDelivery = this.selectDelivery.bind(this);

  }

  componentDidMount() {
    this.getData ( );
  }

  getData ( ) {
    let queryData = { 
      "type": 6,
      "location": this.state.location,
      "delivery": this.state.delivery,
      "dateFrom": this.state.dateFrom,
      "dateTo": this.state.dateTo
     }
    let data = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, queryData );
    if ( data ) {
      this.setState ( { salescategory_list: data });
    }
    this.setState ( { filterData: queryData } );

    let locationData = {
      "type": 25,
    }
    // let locations = this.state.client.getData ( Config.API_URL_LOCATIONS_SIMPLE, queryData );
    let locations = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, locationData );
    this.setState ( { locations: locations.locations });
  }

  doSync ( ) {
    this.getData ( );
  }

  addDateFrom = (event) => {
    this.setState ({dateFrom: event.target.value});
    this.setState ({dateTo: event.target.value});
  }

  addDateTo = (event) => {
    this.setState ({dateTo: event.target.value});
  }

  selectPeriod = (event) => {
    switch ( event.target.value ) {
      case "today":
        this.setState ({sx_hidden: {display: 'none'}});  
        this.setState (
          {dateFrom: today},  //today
          function(){
          }
        );
        this.setState (
          {dateTo: today},  //today
          function(){
            this.doSync ( );
          }
        );
        break;
      case "yesterday":
        this.setState ({sx_hidden: {display: 'none'}});  
        this.setState (
          {dateFrom: yesterday}, //yesterday
          function(){
          }
        ); 
        this.setState (
          {dateTo: yesterday}, //yesterday
          function(){
            this.doSync ( );
          }
        );
        break;
      case "week":
        this.setState ({sx_hidden: {display: 'none'}});  
        this.setState (
          {dateFrom: d1Week},  //beginning of the week
          function(){
          }
        );
        this.setState (
          {dateTo: today},  //today
          function(){
            this.doSync ( );
          }
        );
        break;  
      case "month": 
        this.setState ({sx_hidden: {display: 'none'}});  
        this.setState (
          {dateFrom: d1Month},  //beginning of the month
          function(){
          }
        );
        this.setState (
          {dateTo: today},  //today
          function(){
            this.doSync ( );
          }
        ); 
        break;                  
      default:
        this.setState (
          {sx_hidden: {}},
          function(){
            this.doSync ( );
          }
        );
        // console.log(`No period selected.`);
    }
  }

  selectLocation = (event) => {
      this.setState (
        {location: event.target.value},
        function(){
          this.doSync ( );
        }
        );
  }
  selectDelivery = (event) => {
    this.setState (
      {delivery: event.target.value},
      function(){
        this.doSync ( );
      }
      );
  }

  render() {

    let locations = [{'iref': 0, 'name': "All Locations"}];
    if (this.state.locations){
      Object.keys(this.state.locations).forEach(key => {
        let fData = this.state.locations[key];
        let myData = {'iref': fData.iref, 'name': fData.name};
        locations.push ( myData );
      });
    }
    let deliverys = [{'iref': 2, 'name': "All"}, {'iref': 0, 'name': "Restaurant"}, {'iref': 1, 'name': "Delivery"}];
    let categoryLabels = [];
		let categoryData = [];
		if (this.state.salescategory_list){
			if (this.state.salescategory_list.result) {
				Object.keys(this.state.salescategory_list.result).forEach(key => {
					let fData = this.state.salescategory_list.result[key];			
					categoryLabels.push ( fData.name );
					categoryData.push ( parseInt(fData.value) );
				});
			}
		}

    return (
        // <Card style={{ height: '600px' }} sx={{ borderRadius:"16px", height: '100%' }}>
        <Card variant="outlined" style={{ height: '600px' }} sx={{ paddingTop:"6px", borderRadius:"16px", height: '100%' }}>
          {/* <CardHeader title="Categories Sales Today" /> */}
          {/* <CardContent> */}
            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between'}}>
              <Box sx={{ padding: '16px', alignItems: 'baseline' }}>
              <TextField
                id="standard-select-period"
                select
                // label="Select"
                defaultValue="today"
                // helperText="Select Period"
                variant="standard"
                sx={{ width: 120}}
                onChange={this.selectPeriod}
              >
                {period.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              &nbsp;
              <TextField
                id="standard-select-location"
                select
                // label="Select"
                defaultValue="0"
                // helperText="Select Location"
                variant="standard"
                sx={{ width: 150}}
                onChange={this.selectLocation}
              >
                  {locations.map((option) => (
                  <MenuItem key={option.iref} value={option.iref}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="standard-select-delivery"
                select
                // label="Select"
                defaultValue="2"
                // helperText="Select Delivery"
                variant="standard"
                sx={{ width: 100}}
                onChange={this.selectDelivery}
              >
                  {deliverys.map((option) => (
                  <MenuItem key={option.iref} value={option.iref}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              </Box >
              <Box sx={this.state.sx_hidden} style={{ alignItems: 'baseline', justifyContent: 'space-around'}}>
              <TextField
                size='small'
                id="date"
                label="From"
                type="date"
                // defaultValue={currentDate}
                value={this.state.dateFrom}
                // inputFormat="DD/MM/YYYY"
                sx={{ color: "#e3e3e5", alignSelf: "center", marginLeft: "6px", width:120}}
                InputLabelProps={{
                  shrink: true,
                  sx : { color: "#5b5b5b" }
                }}
                InputProps={{ sx : { color: "#5b5b5b" }}}
                inputProps={{ min: "2020-01-01", style : { color: "#5b5b5b", paddingRight: 5, paddingLeft: 5} }}
                onChange={this.addDateFrom}
              />
              <TextField
                size='small'
                id="date"
                label="To"
                type="date"
                // defaultValue={currentDate}
                value={this.state.dateTo}
                sx={{ color: "#e3e3e5", alignSelf: "center", marginLeft: "6px", width:120 }}
                InputLabelProps={{
                  shrink: true,
                  sx : { color: "#5b5b5b" }
                }}
                InputProps={{ sx : { color: "#5b5b5b" }}}
                inputProps={{ min: "2020-01-01", max: addDays(this.state.dateFrom,50), style : { color: "#5b5b5b", paddingRight: 5, paddingLeft: 5} }}
                onChange={this.addDateTo}
              />
              <Button color="inherit" onClick={() => this.doSync()} style={{maxWidth: '36px', minWidth: '36px'}}>
                <Avatar sx={{ width: 24, height: 24, bgcolor: "#3498db"}}> 
                  <AutorenewIcon />
                </Avatar>
              </Button>
              </Box>
            </Box>
            <Chart
              height='auto'
              // minHeight={400}
              maxHeight={500}
              type="pie"
              series={categoryData}
              labels={categoryLabels}
              // width="100%"
            />
            {/* <Stack
              alignItems="center"
              direction="row"
              justifyContent="center"
              spacing={2}
              sx={{ mt: 2 }}
            >
              {categoryData.map((item, index) => {
                const label = categoryLabels[index];

                return (
                  <Box
                    key={label}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: "100%",
                      borderRadius: "16px",
                      justifyContent: "center"
                    }}
                  >
                  <Typography
                      sx={{ my: 1 }}
                      variant="h8"
                    >
                      {label}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="subtitle2"
                    >
                      {item}
                    </Typography>  
                  </Box>
                );
              })}
            </Stack> */}
          {/* </CardContent> */}
        </Card>
    );

  }
}

export default SalesYTDCategoryChart;
