import React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import Chart from './chart';
import Client from '../Client';
import Config from '../ClientConfig'; 
  
class ClientsByCounty extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      byCounty: {},
      filterData: {}
    };

    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);

  }

  componentDidMount() {
    this.getData ( );
  }

  getData ( ) {
    let queryData = { "type": 0 }
    let data = this.state.client.getData ( Config.API_URL_REPORTS_GEOGRAPHIC, queryData );
    if ( data ) {
      this.setState ( { byCounty: data });
    }
    this.setState ( { filterData: queryData } );
  }

  doSync ( ) {
    this.getData ( );
  }

  render() {
    let countiesData = [];

    if (this.state.byCounty){
      Object.keys(this.state.byCounty).forEach(key => {
        let fData = this.state.byCounty[key];			
        let counter = 0;
        let myData = {'x': key, 'y': (parseInt(fData))};
        if (counter<10){
          countiesData.push ( myData );
        }
        counter += 1;
      });
    }

    return (
        <Card style={{ height: '500px' }} sx={{ borderRadius:"16px", height: '100%' }}>
          <CardHeader title="Clients By County" />
          <CardContent>
            <Chart
              height={350}
              type="bar2v"
              series={[
                {	
                  name: 'Clients',
                  data: countiesData
                }
              ]}
              width="100%"
            />
          </CardContent>
        </Card>
    );

  }
}

export default ClientsByCounty;