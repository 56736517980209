import React from 'react';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FaHome, FaPizzaSlice, FaHamburger, FaMapMarkedAlt, FaEuroSign, FaUserTie, FaHospitalUser } from "react-icons/fa";
import Skeleton from '@mui/material/Skeleton';

//import './css/sideBar.scss';
import './css/elements.css';
import './css/dashboard.css';

import Client from './components/Client';
import Config from './components/ClientConfig';
//import Product from './components/Product';
import ClientAccount from './components/ClientAccount';
import ClientsFilters from './components/ClientsFilters';

import Visibility from './Visibility';

let theme = createTheme();

class ClientsAccounts extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      clients: null,
      //locationsGroups: null,
      addNewClient: false,
      queryData: {},
      countrySelected: "country061",
      closeExpanded: false
    };

    const { loading = false } = props;
    this.queryData = {
      "user_type_gte": "15",
      "limit": "20"
    };
    this.applyFilters = this.applyFilters.bind(this);    
    this.toggleAdd = this.toggleAdd.bind(this);
    this.clientUpdated = this.clientUpdated.bind(this);
    this.getData = this.getData.bind(this);
    this.restricted = new Visibility ("clients_accounts");
  }

  getData ( ) {
    this.setState ( { clients: [] });
    let queryData = {};
    queryData [ "limit" ] = "20";
    this.setState ( { queryData: queryData } );
    //queryData [ "country" ] = this.state.countrySelected;
    let data = this.state.client.getData ( Config.API_URL_CLIENT, queryData );
    this.setState ( { clients: data.clients });
  }

  componentDidMount() {
    if (this.restricted.scope.visible)
      this.getData ( );
  }

  componentWillUnmount() {    
  }

  //Function to change the main Products filter state
  applyFilters ( queryData ) {
    this.setState ( { clients: [] });
    if ( queryData ) {
      //console.log ( queryData );
      let data = this.state.client.filterClients ( queryData );
      this.setState ( { clients: data.clients });
      this.setState ( { queryData: queryData } );
    }
  }

 
  toggleAdd ( buttonState ) {
    let doAdd = this.state.addNewClient;
    if ( buttonState !== undefined ) doAdd = buttonState;
    this.setState ( {addNewClient: doAdd} );
  }

  clientUpdated ( ) {
    //this.setState ( { addNewClient: false } );
    this.setState ( { closeExpanded: true } );
    this.applyFilters ( this.state.queryData );
    //this.toggleAdd ( false );
    this.getData ( );
    window.scrollTo(0, 0);
    this.setState ( { addNewClient: false } );
  }

  render() {
    if (!this.restricted.scope.visible)
      return (<div></div>);
    let clientsList = [];
    let clientsCounter = 0;
    if ( this.state.clients ) {
      clientsCounter = this.state.clients.length;
      clientsList = this.state.clients.map ( (client) => {
        return <ClientAccount
          key={client.iref}
          row={client}
          clientUpdated={this.clientUpdated}
          onlyClient={false}
          allowEdit={this.restricted.scope.edit}
          /* firstName={user.first_name}
          lastName={user.last_name}
          email={user.email}
          username={user.username}
          type={user.user_type}
          status={user.user_status}
          person={user.person} */
        />
      });
    }

    let geoData = {};
    if ( this.props.geoData ) geoData = this.props.geoData;
    //let aa = Object.entries ( geoData );

    let countries = {};
    let counties = {};
    Object.keys(geoData).forEach(key => {
      let fData = geoData [ key ];
      countries [ key ] = fData.name;
      Object.keys ( fData ).forEach( fKey => {
        if ( fKey !== "name" ) counties [ fKey ] = fData [ fKey ].name;
      });
    });

    let groups = {};
    let locationsGroups = {};

    let queryData = { };
    if ( this.queryData ) queryData = this.queryData;

    return (

 
      <div style={{ backgroundColor: "#273442", height: "100%" }}>
        <div className='sticky'>

          <ClientsFilters
            applyFilters = {this.applyFilters}            
            toggleAdd = {this.toggleAdd}
            clientsCounter = {clientsCounter}
            countries = {countries}
            counties = {counties}    
            queryData = {queryData}
            sx={{ position: 'sticky', top: 0}}
            addNewClient = {this.state.addNewClient}
            allowEdit={this.restricted.scope.edit}
          />

        </div>

        { this.state.addNewClient ?
        <ClientAccount
          key="0"
          row={{}}
          newClient={true}
          clientUpdated={this.clientUpdated}
          onlyClient={false}
          allowEdit={this.restricted.scope.edit}
        />
        : ""
        }

        {this.loading ? <Skeleton animation="wave" variant="rounded" width={800} height={400} /> : clientsList }

      </div>

    );

  }
}

export default ClientsAccounts;