import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
// import OverviewTotalSales from './components/biReports/OverviewTotalSales';
import OverviewReverseOrders from './components/biReports/OverviewReverseOrders';
import OverviewReverseValue from './components/biReports/OverviewReverseValue';
import OverviewOrders from './components/biReports/OverviewOrders';
import OverviewMonthSales from './components/biReports/OverviewMonthSales';
import OverviewMonthCustomers from './components/biReports/OverviewMonthCustomers';
import PaymentLocationList from './components/biReports/PaymentLocationList';

import Client from './components/Client';
// import Config from './components/ClientConfig';

// const now = new Date();
class RapOrders extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      /* locationGroups: null, */
      edit: false
    };
  }

  componentDidMount() {    
  }

  componentWillUnmount() {    
  }

  /* setLocationsGroups ( groups ) {
    let a = { }
    if ( groups !== null && groups !== undefined ) {
      groups.map ( (locationGroup) => {
        a [ locationGroup.iref ] = locationGroup;        
      });
      this.setState ( { locationGroups : a });
    }
  } */

  
  render() {
		return (
			<Box component="main" sx={{flexGrow: 1, py: 8, paddingTop: "30px", paddingBottom: "30px"}}>
			<Container style={{paddingLeft: 5, paddingRight:5}}  maxWidth="xl">
				<Grid container spacing={1} sx={{width: 'calc(100% + 60px)', marginLeft: "-32px"}}>
					<Grid item xs={12}>
						<Grid container spacing={1} >
							<Grid item lg={4} md={6} sm={6} xs={6} >
								<OverviewMonthSales />
							</Grid>
							<Grid item lg={4} md={6} sm={6} xs={6}>
								<OverviewMonthCustomers />
							</Grid>
							<Grid item lg={4} md={12} sm={12} xs={12}>
								<Grid container spacing={1}>
									<Grid item sm={6} xs={6} md={6} lg={6}>
										<OverviewReverseValue />
									</Grid>
									<Grid item sm={6} xs={6} md={6} lg={6}>
										<OverviewReverseOrders />
									</Grid>
								</Grid>
							</Grid>		
						</Grid>
					</Grid>	
				<Grid item xs={12} md={6} lg={5}>
					<Card style={{ height: '640px' }} sx={{ borderRadius:"16px"}}>
						<PaymentLocationList />
					</Card>
				</Grid>
				<Grid item xs={12} md={6} lg={7}>
					<OverviewOrders/>
				</Grid>			
			  </Grid>
			</Container>
		  </Box>
		);
	}
}

export default RapOrders;
