import React from 'react';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FaHome, FaPizzaSlice, FaHamburger, FaMapMarkedAlt, FaEuroSign, FaUserTie, FaHospitalUser } from "react-icons/fa";
import Skeleton from '@mui/material/Skeleton';

//import './css/sideBar.scss';
import './css/elements.css';
import './css/dashboard.css';

import Client from './components/Client';
import Config from './components/ClientConfig';
//import Product from './components/Product';
import Company from './components/Company';
import CompaniesFilters from './components/CompaniesFilters';



let theme = createTheme();

class Companies extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      companies: null,
      //locationsGroups: null,
      addNewCompany: false,
      filterData: {}
    };

    const { loading = false } = props;

    this.applyFilters = this.applyFilters.bind(this);    
    this.toggleAdd = this.toggleAdd.bind(this);
    this.companyUpdated = this.companyUpdated.bind(this);
    this.companyAdded = this.companyAdded.bind(this);
  }

  componentDidMount() {
    //this.handleProfile ( );
    /* let queryData = {
      "status" : 2
    } */
    let queryData = {
      "orderByReverse" : "modifiedOn",
      "limit" : 20
    } 
    //queryData [ "user_type_gte" ] = "15"
    let data = this.state.client.getData ( Config.API_URL_COMPANY, queryData );
    this.setState ( { companies: data.Companies });
    this.setState ( { filterData: queryData } );
  }

  componentWillUnmount() {    
  }

  //Function to change the main Products filter state
  applyFilters ( queryData ) {
    if ( queryData ) {
      ////console.log ( queryData );
      let data = this.state.client.filterCompanies ( queryData );
      this.setState ( { companies: data.Companies });
      this.setState ( { filterData: queryData } );
    }
  }

  toggleAdd ( buttonState ) {
    let doAdd = this.state.addNewCompany;
    if ( buttonState !== undefined ) doAdd = buttonState;
    this.setState ( {addNewCompany: doAdd} );
  }

  companyUpdated ( ) {
    let queryData = { };
    if ( this.state.filterData ) queryData = this.state.filterData;
    this.applyFilters ( queryData );

    //this.setState ( { companies: [] } );
    let data = this.state.client.getData ( Config.API_URL_COMPANY, queryData ); 
    if ( data ) {
      if ( data.Companies )
        this.setState ({ companies: data.Companies });
    }    
    this.setState ( { filterData: queryData } );

    window.scrollTo(0, 0);
  }

  companyAdded ( ) {
    let queryData = { };
    if ( this.state.filterData ) queryData = this.state.filterData;
    this.applyFilters ( queryData );
    let data = this.state.client.getData ( Config.API_URL_COMPANY, queryData );    
    if ( data ) {
      if ( data.Companies ) this.setState ( { companies: data.Companies });
    }    
    this.setState ( { filterData: queryData } );
    this.toggleAdd ( false ); 
    window.scrollTo(0, 0);
  }

  render() {

    let geoData = {};
    if ( this.props.geoData ) geoData = this.props.geoData;
    //let aa = Object.entries ( geoData );
    let countries = {};
    let counties = {};
    Object.keys(geoData).forEach(key => {
      let fData = geoData [ key ];
      countries [ key ] = fData.name;
      //console.log ( fData );
      Object.keys ( fData ).forEach( fKey => {
        if ( fKey !== "name" ) counties [ fKey ] = fData [ fKey ].name;
      });
    });

    let companiesList = "";
    let companiesCounter = 0;
    if ( this.state.companies ) {
      companiesCounter = this.state.companies.length;
      companiesList = this.state.companies.map ( (company) => {
        return <Company
          key = {company.iref}
          company = {company}          
          geoData = {this.props.geoData}
          countries = {countries}
          companyUpdated = {this.companyUpdated}
        />
      });
    }
    let groups = {};
    let locationsGroups = {};

    return (

 
      <div style={{ backgroundColor: "#273442", height: "100%" }}>
        <div className='sticky'>

          <CompaniesFilters
            applyFilters = {this.applyFilters}            
            toggleAdd = {this.toggleAdd}
            companiesCounter = {companiesCounter}
            countries = {countries}
            counties = {counties}
            addNewCompany = {this.state.addNewCompany}
            sx={{ position: 'sticky', top: 0}}
          />

        </div>

        { this.state.addNewCompany ?
        <Company
          company={{}}        
          geoData = {this.props.geoData}
          countries = {countries}
          companyUpdated = {this.companyAdded}
        />
        : ""
        }

        {/* {this.loading ? <Skeleton animation="wave" variant="rounded" width={800} height={400} /> : companiesList } */}
        {companiesList}

      </div>

    );

  }
}

export default Companies;