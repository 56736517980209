import React from 'react';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { FaHome, FaPizzaSlice, FaHamburger, FaMapMarkedAlt, FaEuroSign, FaUserTie, FaHospitalUser } from "react-icons/fa";

//import './css/sideBar.scss';
//import './css/dashboard.css';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import noImage from './../images/icons/noImage_1.png';

let theme = createTheme();

class SubCategory extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      edit: false,
      imageStatus: false
    };

    //this.handleExpandClick = this.handleExpandClick.bind(this);
    
  }

  /* handleExpandClick ( ) {
    this.setState({ expanded: !this.state.expanded });
  } */

  componentDidMount ( ) {
    if ( this.props ) {
      if ( this.props.isOn )
        this.setState ( { imageStatus: true } );
    }
  }

  componentDidUpdate ( prevProps ) {  
    if ( prevProps !== this.props ) {  
      if ( prevProps.doReset !== this.props.doReset && this.props.doReset === true ){
        //this.setState({doReset: this.props.doReset })
        this.setState ( { imageStatus: false } );
      } else {
        if ( this.props.isOn )
          this.setState ( { imageStatus: true } );
      }
    }
  }

  sendToggleFilter ( name, type ) {
    let imageStatus = this.state.imageStatus;
    this.props.onClickFunction ( name, type );
    this.setState ( { imageStatus: !imageStatus } );
  }

  render() {
    let iref = null;
    let name = "";
    let image = null;
    let image_on = "";
    let image_off = "";
    let onClickFunction = null;

    if ( this.props ) {
      if ( this.props.iref ) iref = this.props.iref;
      if ( this.props.name ) name = this.props.name;
      if ( this.props.image ) image = this.props.image;
      if ( this.props.image_on ) image_on = this.props.image_on;
      if ( this.props.image_off ) image_off = this.props.image_off;
      if ( this.props.onClickFunction ) onClickFunction = this.props.onClickFunction;
    }

    return (
    
          <IconButton
            aria-label={name}
            title={name}
            onClick={ onClickFunction ? () => this.sendToggleFilter ( "subcategories", iref ) : "" } 
          >       
            <Avatar
              alt={name}
              src={image ? image : this.state.imageStatus ? image_on : image_off}
              sx={{ width: 32, height: 32 }}
            />
          </IconButton>      
          
   
    );

  }
}

export default SubCategory;