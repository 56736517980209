import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import OverviewTotalSales from './components/biReports/OverviewTotalSales';
// import OverviewTopSales from './components/biReports/OverviewTopSales';
import OverviewMonthSales from './components/biReports/OverviewMonthSales';
import OverviewMonthCustomers from './components/biReports/OverviewMonthCustomers';
import OverviewTotalTransactions from './components/biReports/OverviewTotalTransactions';
import OverviewSalesMultiple from './components/biReports/OverviewSales_multiple';
import SalesYTDLocationList from './components/biReports/SalesYTDLocationList';
import SalesYTDLocationListDelivery from './components/biReports/SalesYTDLocationList_delivery';
import Client from './components/Client';
// import Config from './components/ClientConfig';

// const now = new Date();
class RapSales extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      /* locationGroups: null, */
      edit: false,
    };

 
  }

  componentDidMount() {    
  }

  componentWillUnmount() {    
  }

  /* setLocationsGroups ( groups ) {
    let a = { }
    if ( groups !== null && groups !== undefined ) {
      groups.map ( (locationGroup) => {
        a [ locationGroup.iref ] = locationGroup;        
      });
      this.setState ( { locationGroups : a });
    }
  } */

  
  render() {

		return (
			<Box component="main" sx={{flexGrow: 1, py: 8, paddingTop: "30px", paddingBottom: "30px"}}>
			<Container style={{paddingLeft: 5, paddingRight:5}}  maxWidth="xl">
				<Grid container spacing={1} sx={{width: 'calc(100% + 60px)', marginLeft: "-32px"}}>
					<Grid item xs={12}>
						<Grid container spacing={1} >
							<Grid item lg={4} md={6} sm={6} xs={6} >
								<OverviewTotalSales />
							</Grid>
							<Grid item lg={4} md={6} sm={6} xs={6}>
								<OverviewTotalTransactions />
							</Grid>
							<Grid item lg={4} md={12} sm={12} xs={12}>
								<Grid container spacing={1}>
									<Grid item sm={6} xs={6} md={6} lg={6}>
										<OverviewMonthSales />
									</Grid>
									<Grid item sm={6} xs={6} md={6} lg={6}>
										<OverviewMonthCustomers />
									</Grid>
								</Grid>
							</Grid>		
						</Grid>
					</Grid>	
				<Grid item xs={12} lg={12}>
					<OverviewSalesMultiple />
				</Grid>
				<Grid item xs={12} md={6} lg={6}>
					<SalesYTDLocationListDelivery />
				</Grid>
				<Grid item xs={12} md={6} lg={6}>
					<SalesYTDLocationList />
				</Grid>
			  </Grid>
			</Container>
		  </Box>
		);
	}
}

export default RapSales;
