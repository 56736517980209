import React from 'react';
import { Avatar, Box, Card, Paper, TableContainer, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Button  } from '@mui/material';
// import { format, intervalToDuration } from 'date-fns';
import Client from '../Client';
import Config from '../ClientConfig';
import SearchIcon from '@mui/icons-material/Search';

/* function formatedDate(value) {
  let date = new Date(value);
  const day = date.toLocaleString('default', { day: '2-digit' });
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.toLocaleString('default', { year: 'numeric' });
  return day + '-' + month + '-' + year;
} */

class SalesOrderView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      saleslocation_list: [],
      irefOrder: 0,
      page: 0,
      rowsPerPage: 8,
      sx_hidden: {display: 'none'},
      value: "1",
      filterData: {}
    };

    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);

  }

  componentDidMount() {
    this.getData ( );
  }

  getData ( ) {
    let queryData = { 
      "type": 31,
      "iref": this.state.irefOrder
  }
	  let data = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, queryData );
    if ( data ) {
      this.setState ( { saleslocation_list: data });
    }
    this.setState ( { filterData: queryData } );
  }

  doSync ( ) {
    this.getData ( );
  }

  handleChangePage = (event, newPage) => {
    this.setState ({page: newPage});
  }
  
  handleChangeRowsPerPage = (event) => {
    this.setState ({rowsPerPage: +event.target.value});
    this.setState ({page: 0});
  }

  setOrder = (event) => {
    let iref = event.target.value;
        if (!Number(iref)) {
            return;
        }
        this.setState({
          irefOrder: iref
        });
    // this.setState ({irefOrder: +event.target.value});
  }

  render() {
    let page = this.state.page;
    let rowsPerPage = this.state.rowsPerPage;
    let productSalesList = [];
    let fOrder = [];
    if (this.state.saleslocation_list.result){
      fOrder = this.state.saleslocation_list.result;
      if (this.state.saleslocation_list.result.orderlines) {
        Object.keys(this.state.saleslocation_list.result.orderlines).forEach(key => {
          let fData = this.state.saleslocation_list.result.orderlines[key];
          let p = {
            id: fData.index,
            product: fData.productName,
            quantity: fData.quantity,
            price: parseFloat(parseFloat(fData.productValue) + parseFloat(fData.vat)).toFixed(2),
            value: fData.value,
            valueNoVAT: fData.valueNoVAT
          }
          productSalesList.push ( p );
          // console.log(productSalesList);
        });
      }
    }

    return (
      <Paper sx={{ width: '100%', borderRadius:"16px", overflow: 'hidden' }}>
      <Card style={{ height: '640px' }} sx={{ borderRadius:"16px", height: '100%' }}>
       {/* <CardHeader title="Last Orders" /> */}
          <Box sx={{ marginLeft: "30px", borderRadius:"5px", display: 'flex', flexWrap: 'wrap', overflowX: 'auto', alignItems: 'center', justifyContent: 'flex-start'}} >
            <TextField
              label="OrderNo"
              variant="standard"
              id="OrderNo"
              sx={{ minWidth: 40}}
              inputProps={{min: 0, style: { textAlign: 'center' }}} 
              onChange={this.setOrder}
              required={true}
            />
            &nbsp;
            <Button
              color="inherit"
              size="small"
              onClick={this.getData} 
              variant="contained" 
              // color="primary"
              style={{maxWidth: '36px', minWidth: '36px', maxHeight: '36px', minHeight: '36px'}}
            >
              <Avatar sx={{ width: 24, height: 24}}> 
                <SearchIcon />
              </Avatar>
            </Button>
          </Box>
          <Box sx={{ padding: '10px'}}>
            <TableContainer sx={{ height: '100px' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                <TableCell style={{padding: "6px", textAlign: "center" }}>
                    <b>Order</b>
                  </TableCell>
                  <TableCell style={{padding: "6px", textAlign: "left" }}>
                    <b>Location</b>
                  </TableCell>
                  <TableCell style={{padding: "6px", textAlign: "left" }}>
                    <b>Date</b>
                  </TableCell>    
                  <TableCell style={{padding: "6px", textAlign: "left" }}>
                    <b>Delivery</b>
                  </TableCell> 
                  <TableCell style={{padding: "6px", textAlign: "center" }}>
                    <b>Value</b>
                  </TableCell>                  
                  <TableCell style={{padding: "6px", textAlign: "center" }}>
                    <b>valueNoVAT</b>
                  </TableCell>
                  <TableCell style={{padding: "6px", textAlign: "center" }}>
                    <b>Lines</b>
                  </TableCell>     
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell style={{padding: "6px", textAlign: "center" }}>
                    {fOrder.order}
                  </TableCell> 
                  <TableCell style={{padding: "6px", textAlign: "left" }}>
                    {fOrder.location}
                  </TableCell>
                  <TableCell style={{padding: "6px", textAlign: "left" }}>
                    {fOrder.date}
                  </TableCell>
                  <TableCell style={{padding: "6px", textAlign: "left" }}>
                      {fOrder.delivery}
                  </TableCell>
                  <TableCell style={{padding: "6px", textAlign: "center" }}>
                      {fOrder.valueWVat}
                  </TableCell>
                  <TableCell style={{padding: "6px", textAlign: "center" }}>
                      {fOrder.valueWoVat}
                  </TableCell>
                  <TableCell style={{padding: "6px", textAlign: "center" }}>
                      {fOrder.lines}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            </TableContainer>
          </Box>
          <Box sx={{ padding: '10px'}}>
            <TableContainer sx={{ height: '330px' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                <TableCell style={{padding: "6px", textAlign: "center" }}>
                    <b>Index</b>
                  </TableCell>
                  <TableCell style={{padding: "6px", textAlign: "left" }}>
                    <b>Product</b>
                  </TableCell>
                  <TableCell style={{padding: "6px", textAlign: "center" }}>
                    <b>Quantity</b>
                  </TableCell> 
                  <TableCell style={{padding: "6px", textAlign: "center" }}>
                    <b>Price</b>
                  </TableCell> 
                  <TableCell style={{padding: "6px", textAlign: "center" }}>
                    <b>Value</b>
                  </TableCell>                
                  <TableCell style={{padding: "6px", textAlign: "center" }}>
                    <b>valueNoVAT</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productSalesList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((product) => {
                  return (
                    <TableRow
                      hover
                      key={product.id}
                      tabIndex={-1}
                    >
                      <TableCell style={{padding: "6px", textAlign: "center" }}>
                        {product.id}
                      </TableCell> 
                      <TableCell style={{padding: "6px", textAlign: "left" }}>
                        {product.product}
                      </TableCell>
                      <TableCell style={{padding: "6px", textAlign: "center" }}>
                        {product.quantity}
                      </TableCell>
                      <TableCell style={{padding: "6px", textAlign: "center" }}>
                        {product.price}
                      </TableCell>
                      <TableCell style={{padding: "6px", textAlign: "center" }}>
                          {product.value}
                      </TableCell>
                      <TableCell style={{padding: "6px", textAlign: "center" }}>
                          {product.valueNoVAT}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[8, { value: productSalesList.length, label: 'All' }]}
              labelRowsPerPage="Rows:"
              // showFirstButton="True"
              // showLastButton="True"
              component="div"
              count={productSalesList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </Box>
      </Card>
      </Paper>
    );

  }
}

export default SalesOrderView;
